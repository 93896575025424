import request from 'superagent/lib/client'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath =  process.env.API_VERSION_PATH
const contentsPath = '/plans'

export default {
   // Get all the plans from the API.
   getPlans: () => {
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + contentsPath)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err,response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}