import keyMirror from 'keymirror';

export default keyMirror({
  RECEIVE_SETTINGS: null,
  RECEIVE_SETTINGS_ERROR: null,
  CHANGE_SETTINGS: null,
  CHANGE_SETTINGS_ERROR: null,
  RECEIVE_PUBLIC_SETTINGS: null,
  RECEIVE_PUBLIC_SETTINGS_ERROR: null,
  CHANGE_PUBLIC_SETTINGS: null,
  CHANGE_PUBLIC_SETTINGS_ERROR: null,
  SHOW_DIALOG_SETTINGS: null,
  RECEIVE_DARK_MODE_SETTINGS: null,
  RECEIVE_DARK_MODE_SETTINGS_ERROR: null,
  CHANGE_DARK_MODE_SETTINGS: null,
  CHANGE_DARK_MODE_SETTINGS_ERROR: null
});
