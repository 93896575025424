import React, { Component } from 'react'
import ContentActions from '../actions/ContentActions'
import ContentStore from '../stores/ContentStore'
import PublicContentItem from './PublicContentItem'
import FilterActions from '../actions/FilterActions'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'
import(/* webpackPreload: true */ '../styles/Contents.css');
import ConfirmDialogYesNo from './ConfirmDialogYesNo'
import ContentConstants from '../constants/ContentConstants'
import ContentEditComponent from './ContentEdit'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import NotificationSystem from 'react-notification-system'
import ContentAPI from '../apis/ContentsAPI'
import SessionStore from '../stores/SessionStore'

function getContentItem(
  content,
  index,
  handler,
  editHandler,
  editingFlag,
  cancelHandler,
  saveHandler,
  resetPageHandler,
  addToMyAccountHandler,
  populateAndShowMethod,
  callLogInDialog,
  navigateRequestHandler,
  saveForNavigateHandler,
  shareContentHandler,
  embedContentHandler,
  network,
  openLinksNewTab,
  notURLNotification,
  openAccountLinkHandler) {
  return (
    <PublicContentItem
      key={content.id}
      content={content}
      callLogInDialog={callLogInDialog}
      addtomyaccounthandler={addToMyAccountHandler}
      populateAndShowMethod={populateAndShowMethod}
      handler={handler}
      index={index}
      edithandler={editHandler}
      editingflag={editingFlag}
      cancel={cancelHandler}
      save={saveHandler}
      resetPage={resetPageHandler}
      navigateRequest={navigateRequestHandler}
      shareContent={shareContentHandler}
      ref={'content_' + index}
      disabled={true}
      saveForNavigate={saveForNavigateHandler}
      network={network}
      embedContent={embedContentHandler}
      openLinksNewTab={openLinksNewTab}
      notURLNotification={notURLNotification}
      openAccountLink={openAccountLinkHandler}
    />
  )
}

function getContent(content) {
  if (content.length == 0) {
    return (<Row><Col>The content does not exist</Col></Row>)
  } else { return content }
}

class ShareContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contents: [],
      started: true,
      selectedId: '',
      editing: [],
      showEditConfirm: false,
      duplicated_id: '',
      currentIndex: -1,
      oldValues: {},
      showConfirm: false,
      topDuplicatedEditing: false,
      countAlert: 0,
      addRenderTime: false,
      addingToMyAccount: false,
      invalidToken: false,
      navigateContentRequest: false,
      navigationIndex: -1,
      network: [],
      notAllowed: false
    }
    this.onChange = this.onChange.bind(this)
    this.onConfirmUpdateSubmit = this.onConfirmUpdateSubmit.bind(this)
    this.setupMenuOptions = this.setupMenuOptions.bind(this)
    this.editRequest = this.editRequest.bind(this)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.onDuplicatedSubmit = this.onDuplicatedSubmit.bind(this)
    this.onNoConfirm = this.onNoConfirm.bind(this)
    this.onYesConfirm = this.onYesConfirm.bind(this)
    this.onUnload = this.onUnload.bind(this)
    this.onShareContentChange = this.onShareContentChange.bind(this)
    this.onPermalinkContentChange = this.onPermalinkContentChange.bind(this)
    this.stayClicked = this.stayClicked.bind(this)
    this.leaveClicked = this.leaveClicked.bind(this)
    this.topDuplicatedSave = this.topDuplicatedSave.bind(this)
    this.topDuplicatedCancel = this.topDuplicatedCancel.bind(this)
    this.topEditComponent = React.createRef()
    this.topEditorDetector = this.topEditorDetector.bind(this)
    this.notSavedEditing = this.notSavedEditing.bind(this)
    this.props.setNotSavedEditingFunction(this.notSavedEditing)
    this.onError = this.onError.bind(this)
    this.componentId = 'sharecontent'
    this.props.setOnYesConfirmFunction(this.onYesConfirm)
    this.notificationSystem = React.createRef()
    this.addToMyAccountClick = this.addToMyAccountClick.bind(this)
    this.resetEdit = this.resetEdit.bind(this)
    this.props.setResetEdit(this.resetEdit)
    this.navigateRequest = this.navigateRequest.bind(this)
    this.saveForNavigate = this.saveForNavigate.bind(this)
    this.navigateToContentRequest = this.navigateToContentRequest.bind(this)
    this.addToMyAccountRequest = this.addToMyAccountRequest.bind(this)
    this.onConfirmAddSubmit = this.onConfirmAddSubmit.bind(this)
    this.embedContentClick = this.embedContentClick.bind(this)
    this.onNetworkChange = this.onNetworkChange.bind(this)
    this.onLogin = this.onLogin.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.setupMenuOptions()
    ContentStore.addPublicContentChangeListener(this.onChange)
    ContentStore.addShareContentChangeListener(this.onShareContentChange)
    ContentStore.addPermalinkChangeListener(this.onPermalinkContentChange)
    ContentStore.addUpdatedListener(this.onConfirmUpdateSubmit)
    ContentStore.addDuplicatedListener(this.onDuplicatedSubmit)
    window.addEventListener('beforeunload', this.onUnload)
    ContentStore.addTopEditorListener(this.topEditorDetector)
    ContentStore.addConfirmListener(this.onConfirmAddSubmit)
    ErrorStore.addErrorListener(this.onError)
    ContentStore.addNetworkChangeListener(this.onNetworkChange)
    SessionStore.addLoginListener(this.onLogin)
    if (this.props.params.token) {
      FilterActions.searchbar_setup('', true);
      if (this.props.params.token.includes('-')) {
        ContentActions.getContentByShareLink(this.componentId, this.props.params.token, true)
      }
      else {
        ContentActions.getContentByPermalink(this.componentId, this.props.params.token)
      }
    }
  }

  componentWillUnmount() {
    ContentStore.removePublicContentChangeListener(this.onChange)
    ContentStore.removeShareContentChangeListener(this.onShareContentChange)
    ContentStore.removePermalinkChangeListener(this.onPermalinkContentChange)
    ContentStore.removeUpdatedListener(this.onConfirmUpdateSubmit)
    ContentStore.removeDuplicatedListener(this.onDuplicatedSubmit)
    window.removeEventListener('beforeunload', this.onUnload)
    ContentStore.removeTopEditorListener(this.topEditorDetector)
    ContentStore.removeConfirmListener(this.onConfirmAddSubmit)
    ContentStore.removeNetworkChangeListener(this.onNetworkChange)
    ErrorStore.removeErrorListener(this.onError)
    SessionStore.removeLoginListener(this.onLogin)
  }

  setupMenuOptions() {
    var menuOptions = {
      'text': false,
      'type': false,
      'not': false,
      'date': false,
      'tags': false
    }
    FilterActions.filter_setup(menuOptions);
  }

  onShareContentChange() {
    var _content;
    if (this.props.params.token.includes('-')) { _content = ContentStore.getShareContent() }
    else {
      _content = ContentStore.getPermalinkContent()
      var isPublicContent = ContentStore.getIsPublicPermalink()
      var is_public = Object.keys(isPublicContent).length > 0 ? isPublicContent.isPublic : false
      this.setState({
        notAllowed: is_public
      })
    }
    var _contents = [];
    _contents.push(_content.content)
    var _editing = Array(_contents.length).fill(false)
    this.setState({
      started: false,
      topDuplicatedEditing: false,
      duplicated_id: '',
      currentIndex: -1,
      oldValues: {},
      contents: _contents,
      editing: _editing
    }, () => {
      if (SessionStore.getLogged()) {
        ContentActions.getNetwork(this.componentId)
      }
    })
  }

  onPermalinkContentChange() {
    var _content = ContentStore.getPermalinkContent()
    var _contents = [];
    _contents.push(_content.content)
    var _editing = Array(_contents.length).fill(false)
    this.setState({
      started: false,
      topDuplicatedEditing: false,
      duplicated_id: '',
      currentIndex: -1,
      oldValues: {},
      contents: _contents,
      editing: _editing
    }, () => {
      if (SessionStore.getLogged()) {
        ContentActions.getNetwork(this.componentId)
      }
    })
  }

  onChange(action) {
    if (action == ContentConstants.RECEIVE_CONTENT) {
      var content = ContentStore.getContent()
      if (this.state.topDuplicatedEditing === true) {
        var values = {}
        values['id'] = content.id
        values['content_id'] = content.content_id
        values['content_data'] = content.content_data
        values['content_title'] = content.content_title
        values['content_rating'] = content.content_rating
        values['tags'] = content.tags
        if (Array.isArray(content.local_properties.notes)) {
          values['notes'] = content.local_properties.notes[0]
        }
        else {
          values['notes'] = content.local_properties.notes
        }
        this.topEditComponent.current.setValues(values)
        this.setState({
          oldValues: values
        })

        if (this.state.saveCalledFromNavigate == true) {
          this.refs['content_' + this.state.navigationIndex.toString()].closeNavigate()
          this.setState({
            saveCalledFromNavigate: false,
            navigationIndex: -1
          })
        }
      }
      else if (this.state.currentIndex > -1) {
        var values = {}
        values['id'] = content.id
        values['content_id'] = content.content_id
        values['content_data'] = content.content_data
        values['content_title'] = content.content_title
        values['content_rating'] = content.content_rating
        values['tags'] = content.tags
        if (Array.isArray(content.local_properties.notes)) {
          values['notes'] = content.local_properties.notes[0]
        }
        else {
          values['notes'] = content.local_properties.notes
        }
        this.refs['content_' + this.state.currentIndex.toString()].editComponent.current.setValues(values)
        this.setState({
          oldValues: values
        })
      }
      else if (this.state.addingToMyAccount == true) {
        this.props.populateAndShow(content)
        this.setState({
          addingToMyAccount: false
        })
      }
      else if (this.state.navigateContentRequest == true) {
        this.refs['content_' + this.state.navigationIndex.toString()].navigateRef.current.setValues(content)
        var values = {}
        values['id'] = content.id
        values['content_id'] = content.content_id
        values['content_data'] = content.content_data
        values['content_title'] = content.content_title
        values['content_rating'] = content.content_rating
        values['tags'] = content.tags
        if (Array.isArray(content.local_properties.notes)) {
          values['notes'] = content.local_properties.notes[0]
        }
        else {
          values['notes'] = content.local_properties.notes
        }
        this.setState({
          oldValues: values,
          navigateContentRequest: false
        })
      }
    }
  }

  updateContentInList(content, oldIndex) {
    var tags = [];
    if (typeof content.tags !== 'undefined' && content.tags.length > 0) {
      tags = content.tags.reduce((a, o) => (o != process.env.TAG_KEY_ONE && o != process.env.TAG_KEY_TWO && a.push(o), a), [])
    }
    var _contents = [...this.state.contents]
    _contents[oldIndex].id = content.id
    _contents[oldIndex].content_title = content.content_title
    _contents[oldIndex].content_data = content.content_data
    _contents[oldIndex].content_rating = content.content_rating
    _contents[oldIndex].tags = [...tags]
    _contents[oldIndex].local_properties.notes = content.local_properties.notes
    _contents[oldIndex].share = content.share
    this.setState({
      contents: _contents,
      currentIndex: -1
    })
  }

  onConfirmUpdateSubmit() {
    var _editing = Array(this.state.contents.length).fill(false)
    if (this.props.params.token) {
      if (this.props.params.token.includes('-')) {
        ContentActions.getContentByShareLink(this.componentId, this.props.params.token, true)
      }
      else {
        ContentActions.getContentByPermalink(this.componentId, this.props.params.token)
      }
    }
    this.setState({
      editing: _editing,
      duplicated_id: '',
      content_id: ''
    })
  }

  editRequest(index) {
    if (this.state.topDuplicatedEditing === true) {
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
      else {
        this.setState({
          showConfirm: true,
          currentIndex: index
        })
      }
    }
    else {
      var _editing = [...this.state.editing]
      var editingFound = false
      var editingIndex = -1
      for (var idx = 0; idx < _editing.length; idx++) {
        if (_editing[idx] === true) {
          editingFound = true
          editingIndex = idx
          break
        }
      }
      if (editingFound === true) {
        var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
        if (this.match(this.state.oldValues, values) === true) {
          editingFound = false
        }
      }
      if (editingFound === false) {
        _editing = Array(this.state.contents.length).fill(false)
        _editing[index] = true
        this.setState({
          currentIndex: index,
          duplicated_id: ''
        }, () => {
          var content = this.refs['content_' + index.toString()].props.content
          ContentActions.getContent(this.componentId, content.content_id)
          this.setState({
            editing: _editing
          })
        })
      }
      else {
        this.setState({
          currentIndex: index,
          showConfirm: true
        })
      }
    }
  }

  match(original, edited) {
    var equal = true
    if (original.id !== edited.id) {
      equal = false
    }
    if (original.content_title !== edited.content_title) {
      equal = false
    }
    if (original.content_data !== edited.content_data) {
      equal = false
    }
    if (original.content_rating !== edited.content_rating) {
      equal = false
    }
    if (JSON.stringify(original.tags) !== JSON.stringify(edited.tags)) {
      equal = false
    }
    if (Array.isArray(edited.notes)) {
      edited.notes = edited.notes[0]
    }
    if ((original.notes !== edited.notes) && !(original.notes === null && edited.notes === '')) {
      equal = false
    }
    return equal
  }

  save(index, values, change_tags) {
    if (this.match(this.state.contents[index], values)) {
      this.cancel(index)
    }
    else {
      this.setState({
        currentIndex: index,
        changeSearchTags: change_tags
      })
      ContentActions.updateContent(this.componentId, values)
    }
  }

  cancel(index) {
    var _editing = [...this.state.editing]
    _editing[index] = false
    this.setState({
      editing: _editing,
      currentIndex: -1
    })
  }

  saveForNavigate(values) {
    this.setState({
      saveCalledFromNavigate: true
    }, () => {
      ContentActions.sendContent(this.componentId, values)
    })
  }

  onDuplicatedSubmit(duplicated_id, duplicated_content_id, source) {
    if (source === 'put' && this.state.currentIndex !== -1) {
      this.setState({
        showEditConfirm: true,
        duplicated_id: duplicated_id,
        content_id: duplicated_content_id
      });
    }
    else {
      this.setState({
        duplicated_id: duplicated_id,
        content_id: duplicated_content_id
      }, () => {
        ContentActions.getContent(this.componentId, this.state.content_id)
      })
    }
  }

  onYesConfirm() {
    var _editing = Array(this.state.contents.length).fill(false)
    this.setState({
      editing: _editing,
      showEditConfirm: false
    }, () => {
      var duplicatedInCurrentPage = false
      for (var idx = 0; idx < this.state.contents.length; idx++) {
        if (this.state.contents[idx].content_id === this.state.content_id) {
          this.editRequest(idx)
          duplicatedInCurrentPage = true
          break
        }
      }
      if (duplicatedInCurrentPage == false) {
        ContentActions.enableTopEditor(this.state.content_id);
      }
    })
    if (this.state.saveCalledFromNavigate == true) {
      this.refs['content_' + this.state.navigationIndex.toString()].closeNavigate()
      this.setState({
        saveCalledFromNavigate: false,
        navigationIndex: -1
      })
    }
  }

  onNoConfirm() {
    this.setState({
      showEditConfirm: false,
      duplicated_id: '',
      content_id: ''
    })
  }

  onUnload(e) {
    var editingFound = false
    var _editing = [...this.state.editing]
    var editingIndex = -1
    for (var idx = 0; idx < _editing.length; idx++) {
      if (_editing[idx] === true) {
        editingFound = true
        editingIndex = idx
        break
      }
    }
    if (editingFound === true) {
      var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
    }
    if (this.state.topDuplicatedEditing) {
      editingFound = true
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
    }
    if (editingFound === false) {
      for (var x in this.refs) {
        var x_index = parseInt(x.toString().split('_')[1])
        if (this.refs[x.toString()].getNavigate() === true) {
          editingFound = true
          editingIndex = x_index
          break
        }
      }
      if (editingFound === true) {
        var values = this.refs['content_' + editingIndex].navigateRef.current.getValues()
        if (this.match(this.state.oldValues, values) === true) {
          editingFound = false
        }
      }
    }
    if (editingFound === true) {
      e.returnValue = 'true'
    }
  }

  leaveClicked() {
    if (this.state.topDuplicatedEditing === true) {
      ContentActions.disableTopEditor();
    }
  }

  stayClicked() {
    this.setState({
      showConfirm: false
    })
  }

  topDuplicatedSave() {
    var values = this.topEditComponent.current.getValues()
    ContentActions.updateContent(this.componentId, values)
    ContentActions.disableTopEditor()
  }

  topDuplicatedCancel() {
    ContentActions.disableTopEditor()
  }

  topEditorDetector() {
    var topEditorState = ContentStore.getTopEditor()
    if (topEditorState === true) {
      this.setState({
        topDuplicatedEditing: true
      }, () => {
        ContentActions.getContent(this.componentId, ContentStore.getContentIdToEdit())
      })
    }
    else {
      this.setState({
        topDuplicatedEditing: false
      })
    }
  }

  notSavedEditing() {
    var notSaved = false
    var _editing = [...this.state.editing]
    var editingFound = false
    var editingIndex = -1
    for (var idx = 0; idx < _editing.length; idx++) {
      if (_editing[idx] === true) {
        editingFound = true
        editingIndex = idx
        break
      }
    }
    if (editingFound === true) {
      var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === false) {
        notSaved = true
      }
    }
    if (this.state.topDuplicatedEditing === true) {
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === false) {
        notSaved = true
      }
    }
    return notSaved
  }

  onError(source, error) {
    if (error.description == 'Invalid share link' || error.description == 'Content cannot be found') {
      this.setState({
        invalidToken: true
      })
    } else {
      if (source == this.componentId && error.code == 0) {
        this.setState({ countAlert: this.state.countAlert + 1 }, () => {
          if (this.state.countAlert == 1) {
            HandleException.handle_error(error);
          } else if (this.state.countAlert == 3) {
            this.setState({ countAlert: 0 });
          }
        })
      } else if (source == this.componentId) {
        HandleException.handle_error(error);
      }
    }
  }

  resetEdit() {
    var _content;
    if (this.props.params.token.includes('-')) { ContentStore.getShareContent() }
    else { ContentStore.getPermalinkContent() }
    var _contents = [];
    _contents.push(_content)
    var _editing = Array(_contents.length).fill(false)
    this.setState({
      contents: _contents,
      editing: _editing,
      topDuplicatedEditing: false
    })
  }

  addToMyAccountClick(index) {
    var content = this.refs['content_' + index.toString()].props.content
    this.setState({
      addingToMyAccount: true
    }, () => {
      ContentActions.getPublicContent(this.componentId, content.content_id)
    })
  }

  navigateRequest(componentId, index, location, nth) {
    var showConfirmFlag = false
    var editingFound = false
    var editingIndex = -1
    if (this.state.topDuplicatedEditing === true) {
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
        this.setState({
          topDuplicatedEditing: false
        }, () => {
          this.refs['content_' + index.toString()].openNavigate()
          this.setState({
            currentIndex: -1,
            navigationIndex: index,
            navigateContentRequest: true
          }, () => {
            ContentActions.getPublicNthContent(componentId, location, nth)
          })
        })
      }
      else {
        this.setState({
          showConfirm: true,
          currentIndex: index,
          location: location
        })
      }
    }
    else {
      if (this.state.editing.includes(true)) {
        var _editing = [...this.state.editing]
        var editingFound = false
        var editingIndex = -1
        for (var idx = 0; idx < _editing.length; idx++) {
          if (_editing[idx] === true) {
            editingFound = true
            editingIndex = idx
            break
          }
        }
        if (editingFound === true) {
          var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
          if (this.match(this.state.oldValues, values) === true) {
            editingFound = false
          }
        }
        if (editingFound == false) {
          var _editing = Array(this.state.contents.length).fill(false)
          this.setState({
            editing: _editing,
            duplicated_id: ''
          })
        }
        else {
          showConfirmFlag = true
          this.setState({
            currentIndex: index,
            showConfirm: true,
            location: location
          })
        }
      }
      if (showConfirmFlag == false) {
        for (var x in this.refs) {
          var x_index = parseInt(x.toString().split('_')[1])
          if ((index != x_index) && (this.refs[x.toString()].getNavigate() === true)) {
            editingFound = true
            editingIndex = x_index
            break
          }
        }
        if (editingFound === true) {
          var values = this.refs['content_' + editingIndex].navigateRef.current.getValues()
          if (this.match(this.state.oldValues, values) === true) {
            this.refs['content_' + editingIndex].closeNavigate()
            editingFound = false
          }
        }
        if (editingFound === false) {
          if (this.refs['content_' + index.toString()].getNavigate() === false) {
            this.refs['content_' + index.toString()].openNavigate()
          }
          this.setState({
            currentIndex: -1,
            navigationIndex: index,
            navigateContentRequest: true
          }, () => {
            ContentActions.getPublicNthContent(componentId, location, nth)
          })
        }
        else {
          this.setState({
            currentIndex: index,
            showConfirm: true,
            location: location
          })
        }
      }
    }
  }

  navigateToContentRequest(content_id) {
    for (var x in this.refs) {
      if (content_id === this.refs[x.toString()].props.content.content_id) {
        this.refs[x.toString()].saveOptionClicked()
        break;
      }
    }
    setTimeout(() => ContentActions.navigateToSaveOff(), 100);
  }

  addToMyAccountRequest(content_id) {
    for (var x in this.refs) {
      if (content_id === this.refs[x.toString()].props.content.content_id) {
        this.refs[x.toString()].addToMyAccountClick()
        break;
      }
    }
    setTimeout(() => ContentActions.addToMyAccountOff(), 100);
  }

  onConfirmAddSubmit() {
    if (this.state.saveCalledFromNavigate == true) {
      this.refs['content_' + this.state.navigationIndex.toString()].closeNavigate()
      this.setState({
        saveCalledFromNavigate: false,
        navigationIndex: -1
      })
    }
  }

  embedContentClick(content) {
    ContentAPI.getShareLink(content).then(shareLinkData => {
      if (shareLinkData) {
        this.props.embedDialog && this.props.embedDialog(shareLinkData)
      }
    })
  }

  onNetworkChange() {
    if (JSON.stringify(this.state.network) !== JSON.stringify(ContentStore.getNetwork())) {
      this.setState({
        network: ContentStore.getNetwork()
      })
    }
  }

  onLogin() {
    var content_guest = ContentStore.getContentGuest();
    if (Object.keys(content_guest).length > 0) {
      this.saveForNavigate(content_guest)
    }
  }
  render() {
    if (SessionStore.getLogged() && this.props.params.token.length == 12) {
      if (this.state.contents.length > 0 && SessionStore.getSessionAccountName() != this.state.contents[0].account_name &&
        this.state.notAllowed == false) {
        var link = window.location.href
        return (
          <div className='signal34-contents-grid'>
            <Container>
              <div className="signal34-content row">Access to this content item <a href={link}>{link}</a> is restricted</div>
            </Container>
          </div>
        )
      }
    }
    else if (this.state.notAllowed == false && this.props.params.token.length == 12) {
      var link = window.location.href
      return (
        <div className='signal34-contents-grid'>
          <Container>
            <div className="signal34-content row">Access to this content item <a href={link}>{link}</a> is restricted</div>
          </Container>
        </div>
      )
    }
    if (this.state.invalidToken) {
      return (
        <div className='signal34-contents-grid'>
          <Container>
            <h3>404 page not found</h3>
            <div className="signal34-content row">We are sorry but the page you are looking for does not exist</div>
          </Container>
        </div>
      )
    } else if (this.state.started) {
      return (
        <div></div>
      )
    } else {
      this.props.searchRef && this.props.searchRef.current.clearPlaceholder && this.props.searchRef.current.clearPlaceholder()
      let contentListItems = this.state.contents.map((content, index) => getContentItem(
        content,
        index,
        null,
        this.editRequest,
        this.state.editing[index],
        this.cancel,
        this.save,
        null,
        this.addToMyAccountClick,
        this.props.populateAndShow,
        this.props.callLogInDialog,
        this.navigateRequest,
        this.saveForNavigate,
        null,
        this.embedContentClick,
        this.state.network,
        null,
        null,
        this.props.openAccountLink)
      )
      return (
        <div className='signal34-contents'>
          <NotificationSystem ref={this.notificationSystem} />
          <div className='signal34-contents-grid'>
            <Container>
              {getContent(contentListItems)}
            </Container>
          </div>
          <div className='signal34-contents-grid'>
            <Container>
              <Row>
                <Col>
                  <ContentEditComponent
                    showedit={this.state.topDuplicatedEditing}
                    ref={this.topEditComponent}
                    save={this.topDuplicatedSave}
                    cancel={this.topDuplicatedCancel}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <ConfirmDialogYesNo
            message={'Content with the same "Location" field already exists would you like to edit that record?'}
            title={'Edit Content'}
            onNoConfirm={this.onNoConfirm}
            onYesConfirm={this.onYesConfirm}
            show={this.state.showEditConfirm}
            yesText={'Yes'}
            noText={'No'}
          />
          <Modal show={this.state.showConfirm}>
            <Modal.Body>
              <p>This page is asking you to confirm that you want to leave - data you have entered may not be saved.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.stayClicked}>Stay on Page</Button>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.leaveClicked}>Leave Page</Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    }
  }
}
export default ShareContent