import { Link } from 'react-router';
import React, { Component } from 'react';
import UserActions from '../actions/UserActions';
import UserStore from '../stores/UserStore';
import { Alert, Form } from 'react-bootstrap';
import LoaderButton from './LoaderButton';
import '../styles/Login.css';
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore';

const emailRequired = 'email is required.';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
      validationMessage: {}
    };
    this.onNew = this.onNew.bind(this);
    this.onFeedback = this.onFeedback.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onError = this.onError.bind(this);
    this.componentId = 'forgotpassword';
  }

  UNSAFE_componentWillMount() {
    this.setMsg('email', '');
    UserStore.addNewListener(this.onNew);
    UserStore.addFeedbackListener(this.onFeedback);
    ErrorStore.addErrorListener(this.onError);
  }

  componentWillUnmount() {
    UserStore.removeNewListener(this.onNew);
    UserStore.removeFeedbackListener(this.onFeedback);
    ErrorStore.removeErrorListener(this.onError);
  }

  onFeedback() {
    var feedback = UserStore.getFeedback()
    var val = this.state.validationMessage;
    val['email'] = feedback['email'];
    if (val['email'] === '') {
      alert('User request sent, check email account to get the reset password link ...');
      this.setState({ email: '' });
    }
    this.setState({ validationMessage: val });
  }
  onNew() {
  }

  validateForm() {
    this.setMsg('email', '');
    if (this.state.email.length == 0) this.setMsg('email', emailRequired);
    return (this.state.validationMessage['email'] === '');
  }

  setMsg(field, msg) {
    var val = this.state.validationMessage;
    val[field] = msg;
    this.setState({ validationMessage: val });
  }

  handleChangeEmail(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ isLoading: true });
      UserActions.forgotPassword(this.componentId, this.state.email)
      this.setState({ isLoading: false });
      this.props.router.push('/')
    }
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    return (
      <div className='Login Forgot'>
        <div className='card'>
          <div className="center-text-login"><p>Forgot Password</p></div>
          <Form onSubmit={this.handleSubmit}>
            <div>Please enter your email address below, and we&#39;ll send you instructions to reset your password.</div>
            <p></p>
            <Form.Group controlId='email'>
              <Form.Control autoFocus type='email' value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email" />
              <Alert variant={'light'}>{this.state.validationMessage['email']}</Alert>
            </Form.Group>

            <LoaderButton block className="signal34-bnt-primary" variant="primary" type='submit' isLoading={this.state.isLoading}
              text='Submit' loadingText='Submit…' />
            <br />
          </Form>
        </div>
        <footer>
          <div className='text-center small copyright signal34-footer'>
            <div>
              <span>© 2019 Signal34.com</span> | <a target='_blank' href='./about'>About</a> | <Link to={'/'}>Home</Link> | <Link to={'/signup'}>Sign Up</Link> | <a target='_blank' href='./howto'>HowTo</a> | <a target='_blank' href='./personal_terms_of_service'>Terms of Service</a> | <a target='_blank' href='./privacy_policy'>Privacy policy</a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
