import AppDispatcher from '../dispatcher/AppDispatcher'
import InvoiceConstants from '../constants/InvoiceConstants'
import InvoicesAPI from '../apis/InvoicesAPI'

export default {
  getInvoice: (source, invoice_id) => {
    InvoicesAPI
      .getInvoice(invoice_id)
      .then(invoice_data => {
        AppDispatcher.dispatch({
          actionType: InvoiceConstants.RECEIVE_INVOICE,
          invoice_data: invoice_data
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: InvoiceConstants.RECEIVE_INVOICE_ERROR,
          message: message,
          source: source
        })
      })
  },
  emailInvoice: (source, invoice_number) => {
    InvoicesAPI
      .emailInvoice(invoice_number)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: InvoiceConstants.EMAIL_INVOICE
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: InvoiceConstants.EMAIL_INVOICE_ERROR,
          message: message,
          source: source
        })
      })
  }
}
