import request from 'superagent/lib/client'
import SessionStore from '../stores/SessionStore'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const settingsPath = '/settings'
const receivePath = '/receive'
const changePath = '/change'
const darkModePath = '/dark_mode'

export default {
  receiveSettings: () => {
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + settingsPath + receivePath + '/' + SessionStore.getSessionUserName().toString())
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  changeSettings: (sort_by, sort_order, records_per_page, openLinksNewTab) => {
    var data = { 'sort_by': sort_by, 'sort_order': sort_order, 'records_per_page': records_per_page, 'open_links_new_tab': openLinksNewTab }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + settingsPath + changePath + '/' + SessionStore.getSessionUserName().toString())
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(data)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  receiveDarkModeSettings: () => {
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + settingsPath + receivePath + darkModePath + '/' + SessionStore.getSessionUserName().toString())
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  changeDarkModeSettings: (dark_mode) => {
    var data = { 'dark_mode': dark_mode }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + settingsPath + changePath + darkModePath + '/' + SessionStore.getSessionUserName().toString())
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(data)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}
