import request from 'superagent/lib/client'
import SessionStore from '../stores/SessionStore'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const subscriptionsPath = '/subscriptions'

export default {
  getSubscription: () => {
    var params = '/users/' + SessionStore.getSessionUserName()
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + subscriptionsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  renewSubscription: (subscription_data) => {
    var subscription_body = {
      user_id: SessionStore.getSessionUserName().toString(),
      payment_token: subscription_data.payment_token,
      account_type_name: subscription_data.account_type_name,
      amount: subscription_data.amount,
      quantity: subscription_data.quantity,
      interval: subscription_data.interval,
      interval_count: subscription_data.interval_count,
      user_name: subscription_data.user_name,
      account_name: subscription_data.account_name,
      full_name: subscription_data.full_name,
      old_email: SessionStore.getSessionUserEmail().toString(),
      new_email: subscription_data.email
    }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + subscriptionsPath)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(subscription_body))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  updatePaymentMethod: (subscription_data) => {
    var subscription_body = {
      user_id: SessionStore.getSessionUserName().toString(),
      payment_token: subscription_data.payment_token
    }
    var action = '/payment_method'
    return new Promise((resolve, reject) => {
      request
        .put(host + versionPath + subscriptionsPath + action)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(subscription_body))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  setAutoRenew: (auto_renew_data) => {
    var subscription_body = {
      user_id: SessionStore.getSessionUserName().toString(),
      auto_renew: auto_renew_data
    }
    var action = '/auto_renew'
    return new Promise((resolve, reject) => {
      request
        .put(host + versionPath + subscriptionsPath + action)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(subscription_body))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  updateAccountProfile: (account_profile) => {
    var subscription_body = {
      user_id: SessionStore.getSessionUserName().toString(),
      account_name: account_profile.account_name,
      user_name: account_profile.user_name,
      full_name: account_profile.full_name,
      account_type_name: account_profile.account_type_name,
      old_email: SessionStore.getSessionUserEmail().toString(),
      new_email: account_profile.email
    }
    var action = '/account_profile'
    return new Promise((resolve, reject) => {
      request
        .put(host + versionPath + subscriptionsPath + action)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(subscription_body))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  validateAccountProfile: (user_name, account_name) => {
    var subscription_body = {
      user_id: SessionStore.getSessionUserName().toString(),
      user_name: user_name,
      account_name: account_name
    }
    var action = '/account_profile/validate'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + subscriptionsPath + action)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(subscription_body))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}
