import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import(/* webpackPreload: true */ '../styles/App.css');
import EmbedContent from './EmbedContent'

class EmbedDialog extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      codeEmbed: '',
      title: '',
      slug: ''
    }
    this.clear = this.clear.bind(this)
    this.setContentLink = this.setContentLink.bind(this)
    this.onShow = this.onShow.bind(this)
    this.inputRef = React.createRef()
  }

  clear() {
    this.setState({
      codeEmbed: '',
      title: '',
      slug: ''
    })
  }

  setContentLink(title, slug) {
    if (slug && slug.length > 0) {
      var host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
      var embedCode = '<div id="widget-content" token="' + slug + '"></div>' +
        '<script type="text/javascript" defer src="' + host + '/widget.js" crossorigin="anonymous"></script>' +
        '<script type="text/javascript" defer src="' + host + '/runtime.js" crossorigin="anonymous"></script>' +
        '<script type="text/javascript" defer src="' + host + '/client.bundle.js" crossorigin="anonymous"></script>' +
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"/>'
      this.setState({
        title: title,
        codeEmbed: embedCode,
        slug: slug
      })
    }
  }

  onShow() {
    setTimeout(() => { this.inputRef && this.inputRef.current && this.inputRef.current.focus() }, 1);
  }
  handleFocus = (event) => event.target.select();

  render() {
    const { codeEmbed, slug } = this.state
    let preview = ''
    if (slug) {
      preview = <EmbedContent token={slug} />
    }
    return (
      <Modal show={this.props.show} backdrop={false} autoFocus={true} onHide={this.props.onHide} onShow={this.onShow} className="modal-embed-content">
        <Modal.Header closeButton>
          <Modal.Title>Embed Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="embed-row">
            <label>Copy this code and paste into your web site</label>
            <textarea className="form-control" rows="4" id="code" readOnly autoFocus={true} defaultValue={codeEmbed} maxLength={this.maxLengthNotes} ref={this.inputRef} onFocus={this.handleFocus}></textarea>
          </div>
          <div className="embed-row">
            <label>Preview</label>
            {preview}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.props.onClickOK}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default EmbedDialog;