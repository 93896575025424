import request from 'superagent/lib/client';
import SessionStore from '../stores/SessionStore'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const userPath = '/users';
const passwordPath = '/passwords';

export default {
  postUser: (data) => {
    var body = '{"user":{"email":"' + data.email.trim() + '","password":"' + data.password.replace('\\','\\\\').replace('\'', '\\\'').replace('\"', '\\\"') + '"}}'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath)
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  postBusinessUser: (data) => {
    var body = '{"user":{"email":"' + data.email.trim() + '","password":"' + data.password.replace('\\','\\\\').replace('\'', '\\\'').replace('\"', '\\\"') + '"}}'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/create_business')
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  validateEmail: (email) => {
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + userPath + '/validate/' + email)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).response);
          }
        })
    });
  },
  forgotPassword: (email) => {
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + passwordPath + '/forgot/' + email)
        .set('Content-Type', 'application/json')
        .send('')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  confirmEmail: (token) => {
    let body = '{"token":"' + token.trim() + '"}'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/confirm_email')
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  resetPassword: (token, password) => {
    let body = '{"token":"' + token.trim() + '","password":"' + password.trim() + '"}'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + passwordPath + '/reset')
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  updatePassword: (old_password, password) => {
    let body = '{"user":{"email":"' + SessionStore.getSessionUserEmail() + '","password":"' + old_password.trim() + '","new_password":"' + password.trim() + '"}}'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/update_password')
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  deleteUser: (user_id) => {
    return new Promise((resolve, reject) => {
      request
        .delete(host + versionPath + userPath + '/destroy/' + user_id)
        .withCredentials()
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve({ response: 'Ok' })
          }
        })
    })
  },
  exportToEmail: (user_id) => {
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/export/' + user_id)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send('')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  getStatistics: (user_id) => {
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/statistic/' + user_id)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send('')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  isLocked: (user_id) => {
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/islocked/' + user_id)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send('')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  state: (user_id) => {
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/state/' + user_id)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send('')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  },
  updateEmail: (user_id, old_email, new_email) => {
    let body = '{"user_id":"' + user_id + '","old_email":"' + old_email.trim() + '","new_email":"' + new_email.trim() + '"}'
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + userPath + '/update_email')
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    });
  }
}
