import AppDispatcher from '../dispatcher/AppDispatcher'
import SubscriptionConstants from '../constants/SubscriptionConstants'
import SubscriptionAPI from '../apis/SubscriptionAPI'

export default {
  getSubscription: (source, id) => {
    SubscriptionAPI
      .getSubscription(id)
      .then(subscription_data => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.RECEIVE_SUBSCRIPTION,
          subscription_data: subscription_data
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.RECEIVE_SUBSCRIPTION_ERROR,
          message: message,
          source: source
        })
      })
  },
  renewSubscription: (source, subscription_data) => {
    SubscriptionAPI
      .renewSubscription(subscription_data)
      .then(subscription_result => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.CREATE_SUBSCRIPTION,
          subscription_result: subscription_result
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.CREATE_SUBSCRIPTION_ERROR,
          message: message,
          source: source
        })
      })
  },
  changeSubscriptionPayment: (subscription) => {
    setTimeout(
      function () {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.CHANGE_SUBSCRIPTION_PAYMENT,
          subscription: subscription
        });
      }.bind(this), 10
    );
  },
  setAutoRenew: (source, auto_renew_data) => {
    SubscriptionAPI
      .setAutoRenew(auto_renew_data)
      .then(subscription_result => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.SET_AUTO_RENEW,
          subscription_result: subscription_result
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.SET_AUTO_RENEW_ERROR,
          message: message,
          source: source
        })
      });
  },
  updatePaymentMethod: (source, payment_data) => {
    SubscriptionAPI
      .updatePaymentMethod(payment_data)
      .then(subscription_result => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.UPDATE_PAYMENT_METHOD,
          subscription_result: subscription_result
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.UPDATE_PAYMENT_METHOD_ERROR,
          message: message,
          source: source
        })
      })
  },
  updateAccountProfile: (source, account_profile_data) => {
    SubscriptionAPI
      .updateAccountProfile(account_profile_data)
      .then(subscription_result => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.UPDATE_ACCOUNT_PROFILE,
          subscription_result: subscription_result
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.UPDATE_ACCOUNT_PROFILE_ERROR,
          message: message,
          source: source
        })
      })
  },
  validateAccountProfile: (source, user_name, account_name) => {
    SubscriptionAPI
      .validateAccountProfile(user_name, account_name)
      .then(subscription_result => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.VALIDATE_ACCOUNT_PROFILE,
          subscription_result: subscription_result
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SubscriptionConstants.VALIDATE_ACCOUNT_PROFILE_ERROR,
          message: message,
          source: source
        })
      })
  }
}
