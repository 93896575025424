import keyMirror from 'keymirror';

export default keyMirror({
  CREATE_SESSION: null,
  CREATE_SESSION_ERROR: null,
  DESTROY_SESSION: null,
  DESTROY_SESSION_ERROR: null,
  AUTHENTICATE_SESSION: null,
  AUTHENTICATE_SESSION_ERROR: null,
  CREATE_SOCIAL_SESSION: null,
  CREATE_SOCIAL_SESSION_ERROR: null
});
