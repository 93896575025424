import React, { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap'
import UserActions from '../actions/UserActions';
import UserStore from '../stores/UserStore';
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFeedback = this.onFeedback.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.saveClicked = this.saveClicked.bind(this)
    this.cancelClicked = this.cancelClicked.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.close = this.close.bind(this)
    this.receiveKey = this.receiveKey.bind(this);
    this.onError = this.onError.bind(this);

    this.state = {
      old_password: '',
      password: '',
      validationMessage: {}
    };
    this.componentId = 'password';
  }

  UNSAFE_componentWillMount() {
    UserStore.addChangeListener(this.onChange);
    UserStore.addFeedbackListener(this.onFeedback);
    document.addEventListener('keydown', this.receiveKey, false);
    ErrorStore.addErrorListener(this.onError);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onChange);
    UserStore.removeFeedbackListener(this.onFeedback);
    document.removeEventListener('keydown', this.receiveKey, false);
    ErrorStore.removeErrorListener(this.onError);
  }

  onFeedback() {
    let feedback = UserStore.getFeedback()
    let val = this.state.validationMessage;
    val['old_password'] = feedback['old_password'];
    val['password'] = feedback['password'];
    this.setState({ validationMessage: val });
  }

  onChange() {
    this.clearForm()
    this.props.onHide()
  }

  close() {
    this.clearForm()
  }

  handleInputChange(e) {
    e.preventDefault();
    let val = this.state.validationMessage;
    val[e.target.id] = '';
    this.setState({
      [e.target.id]: e.target.value,
      validationMessage: val
    })
  }

  validateForm() {
    if (this.state.old_password.length === 0) this.setMessage('old_password', 'current password');
    if (this.state.password.length === 0) this.setMessage('password', 'new password');
    return (this.state.old_password.length > 0 && this.state.password.length > 0)
  }

  setMessage(field, msg) {
    let val = this.state.validationMessage;
    val[field] = msg + ' is required.';
    this.setState({ validationMessage: val })
  }

  saveClicked = () => {
    if (this.validateForm()) {
      UserActions.updatePassword(this.componentId, this.state.old_password, this.state.password)
    }
  }

  cancelClicked() {
    this.clearForm()
    this.props.onHide()
  }

  clearForm() {
    this.setState({
      old_password: '',
      password: '',
      validationMessage: {}
    })
  }

  receiveKey(event) {
    if (event.keyCode === 13) {
      this.saveClicked()
    } else if (event.keyCode === 27) {
      this.cancelClicked()
    }
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    return (
      <Modal {...this.props} onHide={this.close}>
        <Modal.Header closeButton onClick={this.cancelClicked}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <input id="userName" name="username" autoComplete="username" style={{display : 'none'}}/>
            <div>
              <span>Current password:</span>
              <input id="old_password" name="password" placeholder="Enter current password" type="password" value={this.state.old_password} onChange={this.handleInputChange} autoFocus={true} tabIndex={1} autoComplete="current-password"/>
              <Alert variant={'light'}>{this.state.validationMessage['old_password']}</Alert>
              <span>New password:</span>
              <input id="password" placeholder="Enter new password" type="password" value={this.state.password} onChange={this.handleInputChange} tabIndex={2} autoComplete="new-password"/>
              <Alert variant={'light'}>{this.state.validationMessage['password']}</Alert>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.cancelClicked} tabIndex={3}>Cancel</Button>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.saveClicked} tabIndex={4}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangePassword;
