import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import { components, createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import Tooltip from '@atlaskit/tooltip';
import SearchByTextFilter from './SearchByTextFilter'
import PublicSearchByNotTagFilter from './PublicSearchByNotTagFilter'
import SearchByDateFilter from './SearchByDateFilter'
import FilterActions from '../actions/FilterActions'
import FilterStore from '../stores/FilterStore'
import ContentStore from '../stores/ContentStore'
import SearchSettings from './SearchSettings'
import { Dropdown, Image } from 'react-bootstrap'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import TagsAPI from '../apis/TagsAPI'
import FiltersBuilder, { FILTER_BY_DATE, FILTER_BY_TYPE, FILTER_BY_TEXT } from '../utils/FiltersBuilder'
import SettingStore from '../stores/SettingStore'

const _ = require('lodash')

const background_color_default = 'var(--signal34MultiValueBackgroundColor)'
const background_color_text_search = 'var(--signal34MultiValueBackgroundTextSearchColor)'
const color_default = 'var(--signal34MultivalueColor)'
const color_text_search = 'var(--signal34MultivalueTextSearchColor)'

const defaultBackgroundColor = 'var(--primaryBackgroundColor)'
const defaultTextColor = 'var(--primaryTextColor)'
const defaultBarSelectColor = 'var(--signal34SearchSelectBarColor)'
const defaultMenuListColor = 'var(--signal34MenuListColor)'

const placeholderTextColor = 'var(--signal34SearchPlaceHolderTextColor)'
const transparentBackgroundColor = 'transparent'
const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'var(--primaryBackgroundColor)' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? defaultBackgroundColor
          : isFocused
            ? defaultBarSelectColor
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? defaultTextColor
          : isFocused
            ? defaultTextColor
            : null
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: transparentBackgroundColor
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: defaultMenuListColor
    }
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: placeholderTextColor
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.value == FILTER_BY_TEXT ? background_color_text_search : background_color_default,
      borderColor: data.value == FILTER_BY_TEXT ? color_text_search : background_color_default
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.value == FILTER_BY_TEXT ? color_text_search : color_default,
    backgroundColor: data.value == FILTER_BY_TEXT ? background_color_text_search : background_color_default,
    borderColor: data.value == FILTER_BY_TEXT ? color_text_search : background_color_default,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '3px 0px 0px 3px',
    borderRight: 'none'
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.value == FILTER_BY_TEXT ? color_text_search : color_default,
    backgroundColor: data.value == FILTER_BY_TEXT ? background_color_text_search : background_color_default,
    borderColor: data.value == FILTER_BY_TEXT ? color_text_search : background_color_default,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '0px 3px 3px 0px',
    borderLeft: 'none',
    ':hover': {
      backgroundColor: data.value == FILTER_BY_TEXT ? background_color_text_search : background_color_default,
      borderColor: data.value == FILTER_BY_TEXT ? color_text_search : background_color_default
    }
  })
}

class PublicSearchComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textFilterShow: false,
      notTagFilterShow: false,
      dateFilterShow: false,
      searchSettingsShow: false,
      selectedOption: [],
      inputValue: '',
      options: [],
      isFocusInMenuList: false,
      applyFilter: true,
      menuOptions: {
        'text': true,
        'type': true,
        'not': true,
        'date': true,
        'tags': true
      },
      query: '',
      filter_query: '',
      defaultOptions: []
    }
    this.addValue = this.addValue.bind(this)
    this.onClearSearchBar = this.onClearSearchBar.bind(this);
    this.onNotTagFilter = this.onNotTagFilter.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onSetup = this.onSetup.bind(this);
    this.componentId = 'search';
    this.onDeleted = this.onDeleted.bind(this)
    this.enableSearchOption = this.enableSearchOption.bind(this)
    this.onError = this.onError.bind(this)
    this.onInputKeyDown = this.onInputKeyDown.bind(this)
    this.onFilterChanged = this.onFilterChanged.bind(this)
    this.asyncRef = React.createRef();
    this.searchTextRef = React.createRef()
    this.selectDateRef = React.createRef()
    this.selectNotTagRef = React.createRef()
    this.getOptions = _.debounce(this.getOptions.bind(this), 100)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onShowSettingsDialog = this.onShowSettingsDialog.bind(this)
    this.loadDefaultOptions = this.loadDefaultOptions.bind(this)
  }

  UNSAFE_componentWillMount() {
    FilterStore.addClearSearchBarListener(this.onClearSearchBar)
    FilterStore.addAddNotTagListener(this.onNotTagFilter)
    FilterStore.addSetupListener(this.onSetup)
    FilterStore.addChangeListener(this.onFilterChanged)
    ContentStore.addDeletedListener(this.onDeleted)
    ErrorStore.addErrorListener(this.onError)
    SettingStore.addShowListener(this.onShowSettingsDialog)
  }

  componentWillUnmount() {
    FilterStore.removeClearSearchBarListener(this.onClearSearchBar)
    FilterStore.removeAddNotTagListener(this.onNotTagFilter)
    FilterStore.removeSetupListener(this.onSetup)
    FilterStore.removeChangeListener(this.onFilterChanged)
    ContentStore.removeDeletedListener(this.onDeleted)
    ErrorStore.removeErrorListener(this.onError)
    SettingStore.removeShowListener(this.onShowSettingsDialog)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.inputValue !== prevState.inputValue) {
      this.setState({
        isFocusInMenuList: false
      });
    }
  }

  getOptions = (inputValue, callback) => {
    const { query, filter_query } = this.state
    TagsAPI.prefixSearch(inputValue, query, filter_query, '', this.props.accounts).then(result => {
      this.setState({
        options: result
      }, () => callback(result));
    })
  }

  /* Clear the bar search */
  onClearSearchBar() {
    this.setState({ applyFilter: false }, () => {
      this.asyncRef.clearValue();
    });
    if (this.state.menuOptions.tags) {
      FilterActions.filter_refresh()
    }
  }

  /* Add not tag filter */
  onNotTagFilter() {
    var tag = FilterStore.getNotTag();
    var taglabel = 'not:' + tag;
    this.addFilter(tag, taglabel);
  }

  /* Search bar change event */
  onSelectChange = (selectedOption, info) => {
    if (selectedOption == null) selectedOption = []
    var tags = selectedOption.filter(element => typeof element.value !== 'number');
    this.setState({ selectedOption });
    if (info.action == 'select-option' || (info.action == 'set-value' && typeof selectedOption[selectedOption.length - 1].value == 'string')) {
      FilterActions.filter_tag(tags);
    }
    else if (info.action == 'remove-value' || info.action == 'pop-value') {
      var removedFilter = info.removedValue;
      if (removedFilter && typeof removedFilter.value == 'number') {
        switch (removedFilter.value) {
          case FILTER_BY_TEXT:
            this.searchTextRef.current.handleFilterChange({ 'target': { 'value': '' } })
            FilterActions.filter_text('');
            break;
          case FILTER_BY_TYPE:
            FilterActions.filter_type('');
            break;
          case FILTER_BY_DATE:
            this.selectDateRef.current.resetSelection()
            FilterActions.filter_date('');
            break;
        }
      } else if (removedFilter && typeof removedFilter.value == 'string') {
        this.selectNotTagRef.current.resetSelection()
        FilterActions.filter_tag(tags);
      }
    } else if (info.action == 'clear') {
      FilterActions.clear();
    }
  }

  /* Add a new filter to the search bar */
  addFilter = (newValue, newLabel, tooltip = '') => {
    var selcomponent = this.asyncRef;
    var val = typeof newValue === 'number' ? newValue : newValue.trim();
    var lab = newLabel.trim();
    if (selcomponent.state.selectValue != undefined && selcomponent.state.selectValue.length == 0) {
      selcomponent.setValue([{ value: val, label: lab, tooltip: tooltip }], 'select-option');
    }
    else {
      var values = [];
      var found = false;
      for (let index = 0; index < selcomponent.state.selectValue.length; index++) {
        var element = selcomponent.state.selectValue[index];
        var isFilter = typeof element.value === 'number';
        if (isFilter && element.value == val) {
          element.value = val;
          element.label = lab;
          element.tooltip = tooltip;
          found = true;
        } else if (!isFilter && element.value == val) {
          found = true;
        }
        values.push(element);
      }
      if (!found) {
        values.push({ value: val, label: lab, tooltip: tooltip });
        selcomponent.setValue(values, 'select-option');
      }
    }
  }

  /* An filter was applied */
  onClick(filterType, value) {
    FiltersBuilder.buildLabelFilter(filterType, value, this.addFilter);
  }

  /* Add value to tags filter event */
  addValue = (newValue) => {
    this.addFilter(newValue, newValue);
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue })
  }

  onSetup(setupOptions) {
    if (setupOptions) {
      this.setState({ menuOptions: setupOptions }, function () {
        let params = new URLSearchParams(window.location.search);
        let tag = params.get('tag');
        // filtet by URL tag
        if (typeof (tag) !== 'undefined' && tag !== null) {
          if (history.pushState) {
            var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
            window.history.pushState({ path: newurl }, '', newurl);
          }
          var selcomponent = this.asyncRef;
          selcomponent.setValue([{ value: tag, label: tag, tooltip: '' }], 'select-option');
          FilterActions.filter_tag([{ value: tag, label: tag, tooltip: '' }]);

        } else { // Clear filter
          FilterActions.clear_searchbar();
          FilterActions.clear();
        }
        this.setState({ options: [] });
      });
    }
  }

  onDeleted() {
    var changed = false
    var getCurrentTagFilter = [...FilterStore.getTag()]
    var getTagsToRemove = [...ContentStore.getTagFilter()]
    if ((getTagsToRemove != null) && (getTagsToRemove != [])) {
      for (var i = 0; i < getTagsToRemove.length; i++) {
        if ((getCurrentTagFilter != null) && (getCurrentTagFilter != [])) {
          for (var j = (getCurrentTagFilter.length - 1); j >= 0; j--) {
            if (getTagsToRemove[i] == getCurrentTagFilter[j]['label']) {
              changed = true
              var tagToRemove = getTagsToRemove[i]
              var filterNodes = ReactDOM.findDOMNode(this.asyncRef).firstChild.firstChild.getElementsByClassName('css-4xzr66')
              for (var k = 0; k < filterNodes.length; k++) {
                var label = ReactDOM.findDOMNode(filterNodes[k]).getElementsByClassName('css-1odkcbt')[0].innerHTML
                if (label == tagToRemove) {
                  var close = ReactDOM.findDOMNode(filterNodes[k]).getElementsByClassName('css-1ksbdsw')[0]
                  setTimeout((closeElement) => {
                    closeElement.click()
                    FilterActions.filter_refresh()
                  }, 200, close);
                }
              }
            }
          }
        }
      }
    }
    if (changed === false) {
      FilterActions.filter_refresh()
    }
  }

  enableSearchOption(optionChoosed) {
    switch (optionChoosed) {
      case 'notTagFilter':
        this.setState({ notTagFilterShow: true })
        break;
      case 'textFilter':
        this.setState({ textFilterShow: true })
        break;
      case 'dateFilter':
        this.setState({ dateFilterShow: true })
        break;
      case 'settings':
        this.setState({ searchSettingsShow: true })
        break;
    }
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  onInputKeyDown(event) {
    const { inputValue, selectedOption, isFocusInMenuList } = this.state
    if (!inputValue) return;
    switch (event.key) {
      case 'ArrowUp':
        if (!isFocusInMenuList) {
          this.setState({
            isFocusInMenuList: true
          });
        }
        break;

      case 'ArrowDown':
        if (!isFocusInMenuList) {
          this.setState({
            isFocusInMenuList: true
          });
        }
        break;

      case 'Enter':
      case 'Tab':
        if (!isFocusInMenuList) {
          event.preventDefault();
          var fields = this.searchTextRef.current.getCheckboxes()
          if (Object.values(fields).every((x) => (x === false)) === false) {
            if (selectedOption.length == 0) {
              FilterActions.filter_text(inputValue, fields)
              FiltersBuilder.buildLabelFilter('Text', inputValue, this.addFilter)
              this.searchTextRef.current.handleFilterChange({ 'target': { 'value': inputValue } })
            }
            else {
              var textFilterFound = false
              for (var cnt = 0; cnt < selectedOption.length; cnt++) {
                if (selectedOption[cnt].value == FILTER_BY_TEXT) {
                  textFilterFound = true
                  var newFilter = selectedOption[cnt].tooltip + ' ' + inputValue
                  FilterActions.filter_text(newFilter, fields)
                  FiltersBuilder.buildLabelFilter('Text', newFilter, this.addFilter)
                  this.searchTextRef.current.handleFilterChange({ 'target': { 'value': newFilter } })
                  this.setState({
                    inputValue: ''
                  })
                  break
                }
              }
              if (textFilterFound == false) {
                FilterActions.filter_text(inputValue, fields)
                FiltersBuilder.buildLabelFilter('Text', inputValue, this.addFilter)
                this.searchTextRef.current.handleFilterChange({ 'target': { 'value': inputValue } })
              }
            }
          }
          else {
            this.setState({
              inputValue: ''
            })
          }
        }
        break;
      case 'Escape':
        return false;
    }
  }

  onFilterChanged() {
    var options = FilterStore.getTag();
    var query = FiltersBuilder.createFilterByTags(options);
    var filter_text_query = '';

    // Get filter by date
    var dateFilter = FilterStore.getDate();
    if (dateFilter !== null && dateFilter !== '') {
      var dateFilterTxt = FiltersBuilder.createFilterByDate(query, dateFilter)
      query += dateFilterTxt
    }

    // Get filter by text
    var textFilter = FilterStore.getText();
    var fieldsFilter = FilterStore.getFields();
    if (textFilter !== null && textFilter !== '' && fieldsFilter !== {}) {
      var querytxt = FiltersBuilder.createFilterByText(textFilter, fieldsFilter)
      filter_text_query = querytxt
    }

    this.setState({
      query: query,
      filter_query: filter_text_query
    }, () => {
      this.loadDefaultOptions()
    })
  }

  onShowSettingsDialog() {
    this.setState({ searchSettingsShow: true })
  }

  loadDefaultOptions = (accounts) => {
    const { query, filter_query } = this.state
    if (window.location.pathname.split('/')[2] != null && accounts == null)
      accounts = window.location.pathname.split('/')[2];
    TagsAPI.prefixSearch('', query, filter_query, '', accounts).then(defaultOptions => {
      this.setState({ defaultOptions }, () => {
        this.asyncRef.getNextFocusedOption = () => null;
      });
    })
  }

  handleMultiValueClick = (e, props) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.data && props.data.value && props.data.value == FILTER_BY_TEXT) {
      this.setState({ textFilterShow: true })
    }
  }

  render() {
    let textFilterClose = () => this.setState({ textFilterShow: false });
    let notTagFilterClose = () => this.setState({ notTagFilterShow: false });
    let dateTagFilterClose = () => this.setState({ dateFilterShow: false });
    let searchSettingsClose = () => this.setState({ searchSettingsShow: false });
    const MultiValueContainer = (props) => {
      const { data } = props;
      const { tooltip } = data;
      return (
        <Tooltip content={tooltip}>
          <components.MultiValueContainer {...props} />
        </Tooltip>
      );
    };
    const MultiValueLabel = (props) => {
      return (
        <div onClick={(e) => this.handleMultiValueClick(e, props)}>
          <components.MultiValueLabel {...props} />
        </div>
      );
    };
    const { selectedOption, inputValue, menuOptions, options, defaultOptions } = this.state;
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: 'start'
    };
    let placeholder = !menuOptions.tag && !menuOptions.not ?
      'Contents without tags, only: :text and :date search terms can be used.' : 'Type your tags query…';
    return (
      <table className="search-elements">
        <tbody>
          <tr>
            <td className="select-element">
              <AsyncSelect className="autocomplete-input"
                options={options}
                value={selectedOption}
                onChange={this.onSelectChange}
                isMulti
                styles={customStyles}
                placeholder={placeholder}
                ref={ref => { this.asyncRef = ref; }}
                inputValue={inputValue}
                onInputChange={this.handleInputChange}
                components={{ MultiValueContainer, MultiValueLabel }}
                isDisabled={false}
                filterOption={createFilter(filterConfig)}
                onKeyDown={this.onInputKeyDown}
                loadOptions={this.getOptions}
                defaultOptions={defaultOptions}
                tabSelectsValue={false}
              />
            </td>
            <td className="menu-element">
              <Dropdown id="search-settings-menu" className="search-settings-menu" disabled={false} align="end">
                <Dropdown.Toggle id="search-settings-menu-toggle" bsPrefix="btn-settings-menu" className="search-settings-menu-toggle" variant="secondary" data-testid="search-settings-menu-toggle">
                  <Image src='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 16" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"><path fill="currentColor" d="M4 8a2 2 0 1 1-3.999.001A2 2 0 0 1 4 8z"/><path fill="currentColor" d="M12 8a2 2 0 1 1-3.999.001A2 2 0 0 1 12 8z"/><path fill="currentColor" d="M20 8a2 2 0 1 1-3.999.001A2 2 0 0 1 20 8z"/></svg>' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item disabled={!menuOptions.not} onClick={() => this.enableSearchOption('notTagFilter')}>:not</Dropdown.Item>
                  <Dropdown.Item disabled={!menuOptions.text} onClick={() => this.enableSearchOption('textFilter')}>:text</Dropdown.Item>
                  <Dropdown.Item disabled={!menuOptions.date} onClick={() => this.enableSearchOption('dateFilter')}>:date</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.enableSearchOption('settings')}>Settings...</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <SearchByTextFilter show={this.state.textFilterShow} onHide={textFilterClose} onClick={this.onClick} ref={this.searchTextRef} />
              <PublicSearchByNotTagFilter showtags={menuOptions.not.toString()} show={this.state.notTagFilterShow} onHide={notTagFilterClose} onClick={this.onClick} ref={this.selectNotTagRef} />
              <SearchByDateFilter show={this.state.dateFilterShow} onHide={dateTagFilterClose} onClick={this.onClick} ref={this.selectDateRef} />
              <SearchSettings show={this.state.searchSettingsShow} onHide={searchSettingsClose} onClick={this.onClick} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
export default PublicSearchComponent;
