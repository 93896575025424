import React, { Component } from 'react'
import Checkbox from './Checkbox'
import SessionStore from '../stores/SessionStore'

import SessionActions from '../actions/SessionActions';
import SubscriptionActions from '../actions/SubscriptionActions'
import SubscriptionStore from '../stores/SubscriptionStore'
import ConfirmDialogYesNo from './ConfirmDialogYesNo'
import ConfirmDialogAgree from './ConfirmDialogAgree'
import ConfirmDialogAlert from './ConfirmDialogAlert'
import UserActions from '../actions/UserActions'
import UserStore from '../stores/UserStore'
import PayCreditCard from './PayCreditCard'
import UpdatePaymentMethod from './UpdatePaymentMethod'
import PlanActions from '../actions/PlanActions'
import PlanStore from '../stores/PlanStore'
import { Overlay, Popover } from 'react-bootstrap'
import Moment from 'react-moment';
import 'moment-timezone';
import InvoiceActions from '../actions/InvoiceActions'
import InvoiceStore from '../stores/InvoiceStore'
import Invoice from './Invoice'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import Error from './Error'
import Authentication from '../components/Authentication'
import * as ErrorCode from '../constants/ErrorCodesConstants'
import dateformat from 'dateformat'

class SubscriptionComponent extends Component {
  constructor(props) {
    super(props)
    this.fullNameRef = target => this.setState({ fullNameState: target })
    this.emailRef = target => this.setState({ emailState: target })
    this.accountNameRef = target => this.setState({ accountNameState: target })
    this.userNameRef = target => this.setState({ userNameState: target })
    this.iAgreeRef = target => this.setState({ iAgreeState: target })
    this.state = {
      created_on: new Date(SessionStore.getSessionUserCreatedAt()).toLocaleString(),
      status,
      period: '',
      date_purchased: '',
      renewal_date: '',
      bookmarks: 0,
      tags: 0,
      history: [],
      personal: true,
      iagree: false,
      showConfirm: false,
      email: '',
      subscription_personal: {},
      subscription_business: {},
      subscription: {},
      currency: 'USD',
      first_payment: false,
      remaining_days: 0,
      threshold_days: 90,
      showConfirmAgree: false,
      validationMessage: {},
      user_name: '',
      account_name: '',
      full_name: '',
      validated: false,
      showAlert: false,
      title: '',
      message: '',
      showInvoice: false,
      card_type: '',
      card_last4: '',
      number_user: 0,
      error: false,
      started: true,
      countAlert: 0,
      showAuthentication: false,
      authenticate: '',
      edit_mode: false,
      user_placeholder: '',
      account_placeholder: '',
      expire_datetime: '',
      pending_email: ''
    }

    this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this)
    this.handleIAgreeChange = this.handleIAgreeChange.bind(this)
    this.onChanged = this.onChanged.bind(this)
    this.onYesConfirm = this.onYesConfirm.bind(this)
    this.onNoConfirm = this.onNoConfirm.bind(this)
    this.toDeleteAccount = this.toDeleteAccount.bind(this)
    this.onExported = this.onExported.bind(this)
    this.onStatisticCalculated = this.onStatisticCalculated.bind(this)
    this.onGetPlans = this.onGetPlans.bind(this)
    this.handleConfirmationAgree = this.handleConfirmationAgree.bind(this)
    this.onOKConfirmAgree = this.onOKConfirmAgree.bind(this)
    this.onOKConfirm = this.onOKConfirm.bind(this)
    this.onCancelConfirmAgree = this.onCancelConfirmAgree.bind(this)
    this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this)
    this.handleUpdatedSuccess = this.handleUpdatedSuccess.bind(this)
    this.payCreditCardRef = React.createRef()
    this.handleValidate = this.handleValidate.bind(this)
    this.validateAccountProfile = this.validateAccountProfile.bind(this)
    this.validateIAgreeCheckbox = this.validateIAgreeCheckbox.bind(this)
    Moment.globalFormat = 'MM/DD/YYYY hh:mm A';
    this.clickInvoice = this.clickInvoice.bind(this)
    this.onReceivedInvoice = this.onReceivedInvoice.bind(this)
    this.onDeletedUser = this.onDeletedUser.bind(this)
    this.onCloseAuthentication = this.onCloseAuthentication.bind(this)
    this.onError = this.onError.bind(this)
    this.onClickEdit = this.onClickEdit.bind(this)
    this.onSavedAccountProfile = this.onSavedAccountProfile.bind(this)
    this.onValidateAccountProfile = this.onValidateAccountProfile.bind(this)
    this.handleUserNameChange = this.handleUserNameChange.bind(this)
    this.handleAccountNameChange = this.handleAccountNameChange.bind(this)
    this.expr = /^[a-zA-Z0-9._]*$/;
    this.emailExpr = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    this.componentId = 'subscription'
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    SubscriptionStore.addChangeListener(this.onChanged)
    SubscriptionStore.addUpdateAccountProfile(this.onSavedAccountProfile)
    SubscriptionStore.addValidateAccountProfile(this.onValidateAccountProfile)
    UserStore.addExportListener(this.onExported)
    UserStore.addStatisticListener(this.onStatisticCalculated)
    PlanStore.addChangeListener(this.onGetPlans)
    InvoiceStore.addReceiveListener(this.onReceivedInvoice)
    UserStore.addDeleteListener(this.onDeletedUser)
    ErrorStore.addErrorListener(this.onError)
  }

  componentDidMount() {
    PlanActions.receivePlans(this.componentId + '_first')
    SubscriptionActions.getSubscription(this.componentId + '_first', SessionStore.getSessionUserName().toString())
    UserActions.getStatistics(this.componentId + '_first', SessionStore.getSessionUserName().toString())
  }

  componentWillUnmount() {
    SubscriptionStore.removeChangeListener(this.onChanged)
    SubscriptionStore.removeUpdateAccountProfile(this.onSavedAccountProfile)
    SubscriptionStore.removeValidateAccountProfile(this.onValidateAccountProfile)
    UserStore.removeExportListener(this.onExported)
    UserStore.removeStatisticListener(this.onStatisticCalculated)
    PlanStore.removeChangeListener(this.onGetPlans)
    InvoiceStore.removeReceiveListener(this.onReceivedInvoice)
    UserStore.removeDeleteListener(this.onDeletedUser)
    ErrorStore.removeErrorListener(this.onError)
  }

  onChanged() {
    var subscription_data = SubscriptionStore.getSubscription()
    var agree = process.env.SUBSCRIPTION_ENABLED != 'false' ? !(subscription_data.first_payment) : true;
    var status = subscription_data.status.replace('_', ' ');
    this.setState({
      status: status.charAt(0).toUpperCase() + status.slice(1),
      period: subscription_data.period,
      date_purchased: subscription_data.date_purchased,
      renewal_date: subscription_data.renewal_date,
      history: subscription_data.history,
      personal: subscription_data.personal,
      first_payment: subscription_data.first_payment,
      remaining_days: subscription_data.remaining_days,
      iagree: agree,
      user_name: subscription_data.user_name,
      account_name: subscription_data.account_name,
      full_name: subscription_data.full_name,
      created_on: subscription_data.account_created_at,
      card_type: subscription_data.card_type,
      card_last4: subscription_data.card_last4,
      number_user: subscription_data.number_user,
      subscription: subscription_data.personal ? this.state.subscription_personal : this.state.subscription_business,
      email: subscription_data.email
    }, () => {
      if (this.state.personal) {
        SubscriptionActions.changeSubscriptionPayment(this.state.subscription_personal)
      } else {
        SubscriptionActions.changeSubscriptionPayment(this.state.subscription_business)
      }
      var result = SubscriptionStore.getAccountProfileResult();
      if (result != undefined && result.new_email !== undefined && result.expire_datetime != undefined) this.setState({
        pending_email: result.new_email,
        expire_datetime: result.expire_datetime
      })
    });
  }

  onGetPlans() {
    var plans = PlanStore.getPlans();
    plans.map(item => {
      if (item.name == 'personal') {
        this.setState({
          subscription_personal: {
            name: item.name,
            amount: item.amount,
            amount_cents: item.amount_cents,
            period: item.period,
            quantity: item.quantity,
            currency: item.currency
          }
        }, () => {
          this.setState({ error: false, started: false })
        });
      }
      if (item.name == 'business') {
        this.setState({
          error: false,
          started: false,
          subscription_business: {
            name: item.name,
            amount: item.amount,
            amount_cents: item.amount_cents,
            period: item.period,
            quantity: item.quantity,
            currency: item.currency
          }
        }, () => {
          this.setState({ error: false, started: false })
        })
      }
    });
  }

  handleSubscriptionChange(changeEvent) {
    const { id } = changeEvent.target
    const { subscription_business, subscription_personal, user_name } = this.state
    if (id.toLowerCase() === 'business') {
      this.setState({
        personal: false,
        subscription: subscription_business,
        validated: false,
        validationMessage: {}
      }, () => {
        SubscriptionActions.changeSubscriptionPayment(subscription_business)
      })
    } else if (id.toLowerCase() === 'personal') {
      this.setState({
        account_name: user_name,
        personal: true,
        subscription: subscription_personal,
        validated: false,
        validationMessage: {}
      }, () => {
        SubscriptionActions.changeSubscriptionPayment(subscription_personal)
      })
    }
  }

  handleIAgreeChange(e) {
    const { id } = e.target
    const { iagree } = this.state
    if (id == 'agree') {
      this.setState({
        iagree: !iagree,
        validationMessage: {},
        validated: false
      })
    }
  }

  onExported() {
    var user_id = SessionStore.getSessionUserName();
    UserActions.deleteUser(this.componentId, user_id);
  }

  onDeletedUser() {
    SessionActions.deleteSession(this.componentId);
  }

  onStatisticCalculated() {
    this.setState({
      bookmarks: UserStore.getBookmarks(),
      tags: UserStore.getTags()
    })
  }

  toDeleteAccount() {
    this.setState({
      showAuthentication: true,
      authenticate: 'delete'
    })

  }

  onYesConfirm() {
    this.setState({ showConfirm: false });
    UserActions.exportToEmail(this.componentId, SessionStore.getSessionUserName());
  }

  onNoConfirm() {
    this.setState({ showConfirm: false });
  }

  handleConfirmationAgree() {
    this.setState({
      showConfirmAgree: true
    })
  }

  onOKConfirmAgree() {
    this.setState({ showConfirmAgree: false });
    this.setState({ iagree: true }, () => {
      this.payCreditCardRef.current.onClick();
    })
  }

  onCancelConfirmAgree() {
    this.setState({ showConfirmAgree: false });
  }

  handlePaymentSuccess() {
    var result = SubscriptionStore.getSubscriptionResult()
    if (result.new_email !== undefined && result.expire_datetime != undefined) this.setState({
      pending_email: result.new_email,
      expire_datetime: result.expire_datetime
    })
    this.setState({ showAlert: true, title: 'Renew subscription', message: 'Your payment has been successful' });
  }

  handleUpdatedSuccess() {
    this.setState({ showAlert: true, title: 'Update payment method', message: 'The payment method was updated successfully.' });
  }

  onOKConfirm() {
    SessionStore.cleanData();
    this.setState({ showAlert: false, title: '', message: '' }, () => {
      SubscriptionActions.getSubscription(this.componentId + '_first', SessionStore.getSessionUserName().toString())
    });
  }

  setMessage(field, label, required = true) {
    var val = this.state.validationMessage;
    if (required) {
      val[field] = label + ' is required.';
    } else {
      val[field] = label;
    }
    this.setState({ validationMessage: val });
  }

  validateAccountProfile() {
    if (this.state.full_name.length === 0) {
      if (this.state.personal == true)
        this.setMessage('full_name', 'full name');
      else
        this.setMessage('full_name', 'business name');
    }
    var validated = this.state.full_name.length > 0;
    var isvalid_email = this.emailExpr.test(this.state.email);
    var isvalid_user_name = this.expr.test(this.state.user_name);
    var isvalid_account_name = this.expr.test(this.state.account_name);
    if (!isvalid_email) this.setMessage('email', 'Malformed email', false);
    if (!isvalid_user_name) this.setMessage('user_name', 'Must contain only letters, numbers, periods, and underscores', false);
    if (!isvalid_account_name) this.setMessage('account_name', 'Must contain only letters, numbers, periods, and underscores', false);
    validated = validated && isvalid_email && isvalid_user_name && isvalid_account_name;
    return validated;
  }

  validateIAgreeCheckbox() {
    if (this.state.iagree == false) {
      this.setMessage('iagree', 'Please click here to indicate that you have read and agree with our Terms of Use and Privacy Policy.', false)
      return false;
    } else {
      return true;
    }
  }

  handleValidate() {
    var validated = this.validateAccountProfile();
    if (validated) {
      SubscriptionActions.validateAccountProfile(this.componentId, this.state.user_name, this.state.account_name)
    }
  }

  onReceivedInvoice() {
    this.setState(
      {
        showInvoice: true
      }
    )
  }

  clickInvoice(id) {
    InvoiceActions.getInvoice(this.componentId, id)
  }

  onError(source, error) {
    if (source == this.componentId + '_first') {
      this.setState({ countAlert: this.state.countAlert + 1 }, () => {
        if (this.state.countAlert == 1 && error.code == 0) {
          HandleException.handle_error(error);
        }
        else if (this.state.countAlert == 1 && error.code == ErrorCode.UNAUTHORIZED) {
          HandleException.handle_error(error);
        }
      })
    } else if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  onCloseAuthentication(authenticated) {
    this.setState({
      showAuthentication: false
    })
    if (authenticated) {
      switch (this.state.authenticate) {
        case 'delete':
          this.setState({
            showConfirm: true
          });
          break;
        case 'edit':
          this.setState({
            edit_mode: true
          });
          break;
      }
    }
  }

  onClickEdit() {
    if (this.state.edit_mode) {
      if (this.validateAccountProfile() && this.validateIAgreeCheckbox()) {
        var subscription_data = {
          'account_name': this.state.account_name,
          'user_name': this.state.user_name,
          'full_name': this.state.full_name,
          'account_type_name': this.state.subscription.name,
          'email': this.state.email
        }
        SubscriptionActions.updateAccountProfile(this.componentId, subscription_data)
      }
    } else {
      this.setState({
        showAuthentication: true,
        authenticate: 'edit'
      })
    }
  }

  onValidateAccountProfile() {
    var result = SubscriptionStore.getAccountProfileResult();
    if (result.errors !== undefined) {
      if (result.errors.account_name !== undefined) this.setMessage('account_name', result.errors.account_name, false)
      if (result.errors.user_name !== undefined) this.setMessage('user_name', result.errors.user_name, false)
    } else {
      this.setState({ validated: true }, () => {
        this.payCreditCardRef.current.onClick();
      })
    }
  }

  onSavedAccountProfile() {
    var result = SubscriptionStore.getAccountProfileResult();
    if (result.errors !== undefined) {
      if (result.errors.account_name !== undefined) this.setMessage('account_name', result.errors.account_name, false)
      if (result.errors.user_name !== undefined) this.setMessage('user_name', result.errors.user_name, false)
    }
    else {
      if (result.new_email !== undefined && result.expire_datetime != undefined) this.setState({
        pending_email: result.new_email,
        expire_datetime: result.expire_datetime
      })
      if (result['user_name'] != null) {
        SessionStore.setUserName(result['user_name'])
      }
      if (result['account_name'] != null) {
        SessionStore.setAccountName(result['account_name'])
      }
      this.setState({
        edit_mode: false
      }, () => {
        if (result.user_name !== undefined && result.account_name != undefined) {
          this.setState({
            account_name: result.account_name,
            user_name: result.user_name
          })
        }
      });
    }
  }

  handleUserNameChange(e) {
    if (this.state.personal) this.setState({ account_name: e.target.value })
    this.setState({ user_name: e.target.value, validationMessage: {}, validated: false },
      () => {
        if (this.state.user_name.trim().length > 0) {
          if (!this.expr.test(this.state.user_name)) this.setMessage('user_name', 'Must contain only letters, numbers, periods, and underscores', false);
          this.setState({ user_placeholder: '' })
        } else {
          this.setState({ user_placeholder: 'auto-assign name' })
          if (this.state.personal) this.setState({ account_placeholder: 'auto-assign name' })
        }
      })
  }

  handleAccountNameChange(e) {
    this.setState({ account_name: e.target.value, validationMessage: {}, validated: false },
      () => {
        if (this.state.account_name.trim().length > 0) {
          if (!this.expr.test(this.state.account_name)) this.setMessage('account_name', 'Must contain only letters, numbers, periods, and underscores', false);
          this.setState({ account_placeholder: '' })
        } else {
          this.setState({ account_placeholder: 'auto-assign name' })
        }
      }
    )
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value, validationMessage: {}, validated: false },
      () => {
        var isvalid_email = this.emailExpr.test(this.state.email);
        if (!isvalid_email) this.setMessage('email', 'Malformed email', false);
      })
  }

  render() {
    const { created_on, date_purchased, renewal_date, bookmarks, tags,
      personal, iagree, email, showConfirm, subscription,
      currency, status, period, first_payment, remaining_days, showConfirmAgree,
      threshold_days, history, subscription_personal, subscription_business,
      user_name, account_name, full_name, validationMessage, showAlert,
      title, message, card_type, card_last4, number_user, showAuthentication,
      edit_mode, user_placeholder, account_placeholder
    } = this.state
    let statusFlow = (status == 'Trial' || status == 'Paid') && process.env.SUBSCRIPTION_ENABLED == 'false' ? 'Registered' : status
    let invoiceClose = () => this.setState({ showInvoice: false });
    let render
    const exportPath = process.env.API_URL + process.env.API_VERSION_PATH + '/contents/users/' + SessionStore.getSessionUserName().toString() + '/export'
    const extendedLoginLabel = (this.state.edit_mode == false && this.state.pending_email.length > 0)
    let amount = personal === true ? subscription_personal.amount : subscription_business.amount;
    let subscriptionMessage = process.env.SUBSCRIPTION_ENABLED != 'false' ? <tr><td colSpan={5}>Subscription fee ${amount} per year per user, non refundable.</td></tr> : '';
    let accountProfile = process.env.SUBSCRIPTION_ENABLED != 'false' ?
      <div>
        <tr className='blank_row' />
        <tr className='subscription-section'>
          <td colSpan={5}>Payment information:</td>
        </tr>
        <tr className='blank_row' />
        <tr>
          <td colSpan={5}>
            {
              !first_payment ?
                <React.Fragment>
                  Last payment, ${amount} successfully made on: <Moment>{new Date(date_purchased)}</Moment>
                </React.Fragment>
                :
                'None payment done'
            }
          </td>
        </tr>
        <tr className='blank_row' />
        <tr>
          <td colSpan={5}>
            Number of users: {number_user}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            Current bill total: ${number_user * amount} per year (# of users x ${amount} per year)
          </td>
        </tr>
        <tr className='blank_row' />
        <tr>
          <td colSpan={5}>
            Next payment due date: {
              !first_payment ?
                <Moment>{new Date(renewal_date)}</Moment>
                : 'N/A'
            }
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            Payment amount: {
              !first_payment ?
                <React.Fragment>
                  ${number_user * amount} (# of users * ${amount})
                </React.Fragment>
                : 'N/A'
            }
          </td>
        </tr> <tr>
          <td colSpan={5}>
            Subscription period: {period}
          </td>
        </tr>
        <tr className='blank_row' />
        <tr>
          <td colSpan={5}>
            <div className="signal34-update-payment-container">
              PAYMENT METHOD:{
                !first_payment ?
                  <React.Fragment>
                    {' ' + card_type + ' ends in ' + card_last4 + ' '}
                  </React.Fragment>
                  : ''
              }
              <UpdatePaymentMethod
                label='[Update]'
                handleUpdatedEvent={this.handleUpdatedSuccess}
                disabled={first_payment ? true : false}
              />
            </div>
          </td>
        </tr>
        <tr className='blank_row' />
        <tr>
          <td colSpan={5}>
            {!first_payment && remaining_days <= threshold_days ? '"Renew subscription" button will activate ' + threshold_days + ' days prior to subscription renewal date' : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <PayCreditCard label='Pay Subscription'
              subscription={subscription}
              disabled={first_payment ? false : remaining_days <= threshold_days ? false : true}
              agree={iagree}
              currency={subscription.currency ? subscription.currency : currency}
              ref={this.payCreditCardRef}
              handleClick={this.handleConfirmationAgree}
              handlePaymentSuccess={this.handlePaymentSuccess}
              handleValidate={this.handleValidate}
              validated={this.state.validated}
              user_name={user_name}
              account_name={account_name}
              full_name={full_name}
              email={email}
            />
          </td>
        </tr>
        <tr className='subscription-section'>
          <td colSpan={5}>Payment history:</td>
        </tr>
        <tr className='blank_row' />
        <tr>
          <td>Date</td>
          <td>Item</td>
          <td>Amount</td>
          <td>Via</td>
          <td>Invoice</td>
        </tr>
        {history.map((data, key) => {
          return (
            <tr key={key}>
              <td>{dateformat(data.date, 'mm/dd/yyyy hh:MM:ss TT')}</td>
              <td>{data.item}</td>
              <td>${data.amount}</td>
              <td>{data.via}</td>
              <td><a className='invoice_link' onClick={() => this.clickInvoice(data.invoice_number)}>[Invoice]</a></td>
            </tr>
          )
        })}
      </div> : null
    if (SessionStore.getLogged()) {
      if (this.state.started) {
        render = null
      } else {
        render =
          this.state.error ? (
            <Error />) :
            (
              <div className='signal34-contents subscription-form col-md-8 col-sm-12 col-xs-12'>
                <table>
                  <thead>
                    <tr className='blank_row' />
                    <tr>
                      <td className='subscription-title'><span>Manage Subscription:</span></td>
                    </tr>
                    <tr className='blank_row' />
                  </thead>
                  <tbody>
                    <tr className='subscription-section'>
                      <td colSpan={5}>Account information:</td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        Created on: <Moment>{new Date(created_on)}</Moment>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        Status: {statusFlow}
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        Bookmarks: {bookmarks}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        Tags: {tags}
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr className='subscription-section'>
                      <td colSpan={5}>Account Profile:</td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5} >
                        <div className='label-checkbox'>Account type: </div>
                        <div key={'div-personal'} className='text-checkbox'>
                          <label htmlFor={'Personal'}>
                            {
                              process.env.SUBSCRIPTION_ENABLED == 'false' ?
                                <input type='radio' id={'Personal'} name={'Type'} checked={personal ? 'checked' : ''} onChange={this.handleSubscriptionChange} disabled={edit_mode ? false : true} />
                                :
                                <input type='radio' id={'Personal'} name={'Type'} checked={personal ? 'checked' : ''} onChange={this.handleSubscriptionChange} disabled={edit_mode || first_payment ? false : true} />
                            }
                            <span>{'Personal'}</span>
                          </label>
                        </div>
                        <div key={'div-business'} className='text-checkbox'>
                          <label htmlFor={'Business'}>
                            {
                              process.env.SUBSCRIPTION_ENABLED == 'false' ?
                                <input type='radio' id={'Business'} name={'Type'} checked={!personal ? 'checked' : ''} onChange={this.handleSubscriptionChange} disabled={edit_mode ? false : true} />
                                :
                                <input type='radio' id={'Business'} name={'Type'} checked={!personal ? 'checked' : ''} onChange={this.handleSubscriptionChange} disabled={edit_mode || first_payment ? false : true} />
                            }
                            <span>{'Business'}</span>
                          </label>
                        </div>
                        {
                          (!first_payment || process.env.SUBSCRIPTION_ENABLED == 'false') ?
                            <React.Fragment>
                              <div className='edit_link'>
                                <button type="button" onClick={this.onClickEdit} className="signal34-payment-button btn btn-link">{edit_mode ? '[Save]' : '[Edit]'}</button>
                              </div>
                            </React.Fragment>
                            :
                            null
                        }
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        By creating an account or by accessing or using the Service, you agree to the bound by
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        these: <a target='_blank' href={personal === true ? './personal_terms_of_service' : './business_terms_of_service'}> {personal === true ? '' : 'Business '}Terms of Use</a> and <a target='_blank' href='./privacy_policy'>Privacy policy</a>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        {extendedLoginLabel ? <div>Login email:(pending change to <span><a href={'"mailto:' + this.state.pending_email + '""'}>{this.state.pending_email}</a></span> please confirm new</div> : <div>Login email:</div>}
                        {extendedLoginLabel ? <div className='login-indent'>email otherwise this request will expire on {this.state.expire_datetime})</div> : <div></div>}
                        {
                          process.env.SUBSCRIPTION_ENABLED == 'false' ?
                            <input type="text" value={email} onChange={this.handleEmailChange} ref={this.emailRef} maxLength="30" disabled={edit_mode ? false : true} />
                            :
                            <input type="text" value={email} onChange={this.handleEmailChange} ref={this.emailRef} maxLength="30" disabled={edit_mode || first_payment ? false : true} />
                        }
                        <Overlay show={validationMessage['email'] !== undefined && validationMessage['email'] !== ''} target={this.state.emailState} placement="bottom">
                          <Popover id='popover-user-name' outofboundaries='true'>
                            <Popover.Body className='signal34-popover-error'>
                              {validationMessage['email']}
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        Enter user name: (must be unique)
                        {
                          process.env.SUBSCRIPTION_ENABLED == 'false' ?
                            <input type="text" value={user_name} onChange={this.handleUserNameChange} ref={this.userNameRef} maxLength="30" disabled={edit_mode ? false : true} placeholder={user_placeholder} />
                            :
                            <input type="text" value={user_name} onChange={this.handleUserNameChange} ref={this.userNameRef} maxLength="30" disabled={edit_mode || first_payment ? false : true} placeholder={user_placeholder} />
                        }
                        <Overlay show={validationMessage['user_name'] !== undefined && validationMessage['user_name'] !== ''} target={this.state.userNameState} placement="bottom">
                          <Popover id='popover-user-name' outofboundaries='true'>
                            <Popover.Body className='signal34-popover-error'>
                              {validationMessage['user_name']}
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        Enter account name: (must be unique):
                        {
                          process.env.SUBSCRIPTION_ENABLED == 'false' ?
                            <input type="text" value={account_name} onChange={this.handleAccountNameChange} ref={this.accountNameRef} maxLength="30" disabled={personal || (!edit_mode)} placeholder={account_placeholder} />
                            :
                            <input type="text" value={account_name} onChange={this.handleAccountNameChange} ref={this.accountNameRef} maxLength="30" disabled={personal || (!edit_mode && !first_payment)} placeholder={account_placeholder} />
                        }
                        <Overlay show={validationMessage['account_name'] !== undefined && validationMessage['account_name'] !== ''} target={this.state.accountNameState} placement="bottom">
                          <Popover id='popover-account-name' outofboundaries='true'>
                            <Popover.Body className='signal34-popover-error'>
                              {validationMessage['account_name']}
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        {!personal ? 'Enter business name:' : 'Enter full name:'}
                        {
                          process.env.SUBSCRIPTION_ENABLED == 'false' ?
                            <input type="text" value={full_name} onChange={e => this.setState({ full_name: e.target.value, validationMessage: {}, validated: false })} ref={this.fullNameRef} maxLength="255" disabled={edit_mode ? false : true} />
                            :
                            <input type="text" value={full_name} onChange={e => this.setState({ full_name: e.target.value, validationMessage: {}, validated: false })} ref={this.fullNameRef} maxLength="255" disabled={edit_mode || first_payment ? false : true} />
                        }
                        <Overlay show={validationMessage['full_name'] !== undefined && validationMessage['full_name'] !== ''} target={this.state.fullNameState} placement="bottom">
                          <Popover id='popover-full-name' outofboundaries='true'>
                            <Popover.Body className='signal34-popover-error'>
                              {validationMessage['full_name']}
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        <div key={'div-i-agree'} className='text-checkbox'>
                          {
                            process.env.SUBSCRIPTION_ENABLED == 'false' ?
                              <Checkbox id={'agree'} label={'I AGREE.'} isSelected={iagree} onCheckboxChange={this.handleIAgreeChange} key={'IAgree'} disabled={edit_mode ? false : true} reference={this.iAgreeRef} />
                              :
                              <Checkbox id={'agree'} label={'I AGREE.'} isSelected={iagree} onCheckboxChange={this.handleIAgreeChange} key={'IAgree'} disabled={edit_mode || first_payment ? false : true} reference={this.iAgreeRef} />
                          }
                          <Overlay show={validationMessage['iagree'] !== undefined && validationMessage['iagree'] !== ''} target={this.state.iAgreeState} placement="bottom">
                            <Popover id='popover-iagree' outofboundaries='true'>
                              <Popover.Body className='signal34-popover-error'>
                                {validationMessage['iagree']}
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </div>
                      </td>
                    </tr>
                    {subscriptionMessage}
                    {accountProfile}
                    <tr className='blank_row' />
                    <tr className='subscription-section'>
                      <td colSpan={5}>Options:</td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan='5'>
                        <a href={exportPath}>Export account data</a>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        <div className='imitate_link' onClick={this.toDeleteAccount}>Delete account</div>
                      </td>
                    </tr>
                    <tr className='blank_row' />
                    <tr>
                      <td colSpan={5}>
                        Problems or questions? contact us at <a href='mailto:support@signal34.com'>support@signal34.com</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Invoice
                  show={this.state.showInvoice}
                  onHide={invoiceClose}
                />
                <ConfirmDialogYesNo
                  message={'All your Account, User and data will be deleted'}
                  title={'Please confirm:'}
                  onNoConfirm={this.onNoConfirm}
                  onYesConfirm={this.onYesConfirm}
                  show={showConfirm}
                  yesText={'OK'}
                  noText={'Cancel'}
                />
                <ConfirmDialogAgree
                  onNoConfirm={this.onCancelConfirmAgree}
                  onYesConfirm={this.onOKConfirmAgree}
                  show={showConfirmAgree}
                  personal={personal}
                />
                <ConfirmDialogAlert
                  onOKConfirm={this.onOKConfirm}
                  title={title}
                  message={message}
                  OKText={'OK'}
                  show={showAlert}
                />
                <Authentication
                  show={showAuthentication}
                  onClose={this.onCloseAuthentication}
                  email={email}
                />
              </div>
            )
      }
    } else {
      this.props.router.push('/')
      render = null
    }
    return render
  }
}

export default SubscriptionComponent
