import React, { Component } from 'react'
import InvoiceActions from '../actions/InvoiceActions'
import InvoiceStore from '../stores/InvoiceStore'
import SessionStore from '../stores/SessionStore'
import dateformat from 'dateformat'
import { Modal, Button } from 'react-bootstrap'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

class InvoiceComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      invoice_number: '',
      issued_date: '',
      account_name: '',
      account_email: '',
      item: '',
      duration: '',
      price: ''
    }

    this.onReceived = this.onReceived.bind(this)
    this.close = this.close.bind(this)
    this.cancelClicked = this.cancelClicked.bind(this)
    this.emailInvoiceClicked = this.emailInvoiceClicked.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.onError = this.onError.bind(this)
    this.componentId = 'invoice'
  }

  UNSAFE_componentWillMount () {
    InvoiceStore.addReceiveListener(this.onReceived)
    ErrorStore.addErrorListener(this.onError)
  }

  componentWillUnmount () {
    InvoiceStore.removeReceiveListener(this.onReceived)
    ErrorStore.removeErrorListener(this.onError)
  }

  onReceived () {
    this.clearForm()
    var invoice_data = InvoiceStore.getInvoice()
    var parts = invoice_data.issued_date.split('-')
    let issued_date = parts[0] + '/' + parts[1] + '/' + parts[2]
    this.setState(
      {
        invoice_number: invoice_data.number,
        issued_date: issued_date,
        account_name: invoice_data.account_name,
        account_email: invoice_data.account_email,
        item: invoice_data.item,
        duration: invoice_data.duration,
        price: invoice_data.price
      }
    )
  }

  clearForm() {
    this.setState({
      invoice_number: '',
      issued_date: '',
      account_name: '',
      account_email: '',
      item: '',
      duration: '',
      price: ''
    })
  }

  close() {
    this.clearForm()
  }

  cancelClicked() {
    this.clearForm()
    this.props.onHide()
  }

  emailInvoiceClicked () {
    InvoiceActions.emailInvoice(this.componentId, this.state.invoice_number)
    this.cancelClicked()
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render () {
    const {invoice_number, issued_date, account_email, item, duration, price } = this.state
    let render
    if (SessionStore.getLogged()) {
      render = (
        <Modal show={this.props.show} onHide={this.props.onHide} className="modal-invoice">
          <Modal.Header closeButton onHide={this.close}>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div id='invoice-to-print col-sm-4 col-xs-4'>
                <h2>
              Invoice # {invoice_number}
                </h2>

                <div>
                  <div>
              Product : Signal34 subscription
              </div>
                  <div>
              Company: SDIBO Inc
              </div>
                  <div>
              Address: 174 Nassau St. #403, Princeton, NJ 08532
              </div>
                  <div>
              support email: <a href='mailto:support@signal34.com'>support@signal34.com</a>
                  </div>
                </div>
                <div className='blank_row' />
                <div>
                  <strong>
                    {dateformat(issued_date, 'mmmm d, yyyy')}
                  </strong>
                </div>
                <div className='blank_row' />
                <div>
                  <strong>
                    {item} subscription
                  </strong>
                </div>
                <div>
              &lt;{account_email}&gt;
              </div>
                <div className='blank_row' />
                <div className='blank_row' />
                <table>
                  <thead className='invoice-header col-sm-12 col-xs-12'>
                    <tr>
                      <td className='invoice-item col-sm-6 col-xs-6'>
              Item
              </td>
                      <td className='invoice-duration col-sm-3 col-xs-3'>
              Duration
              </td>
                      <td className='invoice-price col-sm-3 col-xs-3'>
              Price
              </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='col-sm-6 col-xs-6'>
                        {item} subscription
                      </td>
                      <td className='col-sm-3 col-xs-3'>
                        {duration}
                      </td>
                      <td className='col-sm-3 col-xs-3'>
                        {(Number(price)).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='blank_row' />
                <div className='blank_row' />
                <div>
              Payment was received via Stripe on {dateformat(issued_date, 'mm/dd/yyyy')}
                </div>
              </div>
              <div className='blank_row' />
            </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.cancelClicked}>Close</Button>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.emailInvoiceClicked}>Email Invoice</Button>
            </Modal.Footer>
          </Modal>
          )
    } else {
      this.props.router.push('/')
      render = null
    }
    return render
  }
}

export default InvoiceComponent
