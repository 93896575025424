import React, { Component } from 'react';
import Select, { createFilter } from 'react-select'

const defaultBackgroundColor = 'var(--primaryBackgroundColor)'
const defaultTextColor = 'var(--primaryTextColor)'
const defaultBarSelectColor = 'var(--signal34SearchSelectBarColor)'
const defaultMenuListColor = 'var(--signal34MenuListColor)'
const transparentBackgroundColor = 'transparent'

const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'var(--primaryBackgroundColor)' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? defaultBackgroundColor
          : isFocused
            ? defaultBarSelectColor
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? defaultTextColor
          : isFocused
            ? defaultTextColor
            : null
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: transparentBackgroundColor
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: defaultMenuListColor
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      backgroundColor: defaultBackgroundColor,
      borderColor: defaultBackgroundColor,
      color: defaultTextColor
    };
  }
}

class NoTagSelect extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: 'start'
    };
    return (
      <div>
        <Select
          value={this.props.value}
          onChange={this.props.onChange}
          options={this.props.options}
          autoFocus={true}
          tabIndex={this.props.tabIndex}
          filterOption={createFilter(filterConfig)}
          styles={customStyles}
        />
      </div>
    );
  }
}
export default NoTagSelect;
