import React, { Component } from 'react'
import PublicContentsList from './PublicContentsList'
import SettingOfflineStore from '../stores/SettingOfflineStore'
import SettingStore from '../stores/SettingStore'
import SessionStore from '../stores/SessionStore'
import PublicSettingActions from '../actions/SettingOfflineActions'
import SettingsActions from '../actions/SettingActions'

class PublicContents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: '',
      sortBy: '',
      sortOrder: '',
      perPage: 0,
      openLinksNewTab: false
    }
    this.onReceiveSettings = this.onReceiveSettings.bind(this)
    this.onReceiveSettingsOffline = this.onReceiveSettingsOffline.bind(this)
    this.setSettings = this.setSettings.bind(this)
    SettingOfflineStore.addReceiveListener(this.onReceiveSettingsOffline)
    SettingStore.addReceiveListener(this.onReceiveSettings)
  }

  componentDidMount() {
    this.setState({ account: this.props.params.account });
    if(this.props.setNavigationDefault != null) this.props.setNavigationDefault()
    if (SessionStore.getLogged()) {
      SettingsActions.receiveSettings(this.componentId);
    } else {
      PublicSettingActions.receiveSettings();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.params.account != nextState.account) {
      this.setState({ account: this.props.params.account });
    }
    return true;
  }

  componentWillUnmount() {
    SettingOfflineStore.removeReceiveListener(this.onReceiveSettingsOffline)
    SettingStore.removeReceiveListener(this.onReceiveSettings)
  }

  setSettings(sortBy, sortOrder, perPage, openLinksNewTab) {
    this.setState({
      sortBy: sortBy,
      sortOrder: sortOrder,
      perPage: perPage,
      openLinksNewTab: openLinksNewTab
    })
  }

  onReceiveSettingsOffline() {
    this.setSettings(
      SettingOfflineStore.getSortBy(),
      SettingOfflineStore.getSortOrder(),
      SettingOfflineStore.getResultPerPage(),
      SettingOfflineStore.getOpenLinksNewTab()
    )
  }

  onReceiveSettings() {
    this.setSettings(
      SettingStore.getSortBy(),
      SettingStore.getSortOrder(),
      SettingStore.getResultPerPage(),
      SettingStore.getOpenLinksNewTab()
    )
  }

  render() {
    if (this.state.sortBy == '' || this.state.sortOrder == '' || this.state.perPage == 0) {
      return (
        <div></div>
      )
    } else {
      return (
        <div>
          <PublicContentsList
            sortBy={this.state.sortBy}
            sortOrder={this.state.sortOrder}
            perPage={this.state.perPage}
            callLogInDialog={this.props.callLogInDialog}
            setNotSavedEditingFunction={this.props.setNotSavedEditingFunction}
            setOnYesConfirmFunction={this.props.setOnYesConfirmFunction}
            setResetEdit={this.props.setResetEdit}
            addDialogVisible={this.props.addDialogVisible}
            populateAndShow={this.props.populateAndShow}
            shareDialog={this.props.shareDialog}
            embedDialog={this.props.embedDialog}
            openLinksNewTab={this.state.openLinksNewTab}
            queryByAccount={this.state.account}
            notURLNotification={this.props.notURLNotification}
            openAccountLink={this.props.openAccountLink}
            pasteNotification={this.props.pasteNotification}
          />
        </div>
      )
    }
  }
}
export default PublicContents
