export default {
  init: () => {
    return new Promise((resolve) => {
      if (typeof FB !== 'undefined') {
        resolve();
      } else {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: process.env.FACEBOOK_APPID,
            cookie: false,
            localStorage: false,
            status: false,
            version: process.env.FACEBOOK_API_VERSION
          });
          resolve();
        };
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }
    });
  },
  checkLoginState: (params) => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        response.status === 'connected' || response.status === 'not_authorized' || response.status == 'unknown'? resolve(response) : reject(response);
      },
        params);
    });
  },
  login: (params) => {
    return new Promise((resolve, reject) => {
      window.FB.login((response) => {
        response.status === 'connected' ? resolve(response) : reject(response);
      },
        params);
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      window.FB.logout((response) => {
        response.authResponse ? resolve(response) : reject(response);
      });
    });
  },
  fetch: () => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        '/me',
        { fields: 'id, email, name' },
        response => response.error ? reject(response) : resolve(response)
      );
    });
  },
  removePermissions: () => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        '/me/permissions',
        'delete',
        response => response.error ? reject(response) : resolve(response)
      );
    });
  },
  subscribeEvent: (event, callback) => {
    window.FB.Event.subscribe(event, callback);
  }
}