import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

class ConfirmDialogAlert extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault()
    this.props.onOKConfirm && this.props.onOKConfirm();
    return false
  }

  render() {
    return (
      <Modal show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleFormSubmit}>
          <Modal.Body>
            <p>{this.props.message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className='signal34-bnt-default' variant='outline-secondary' type='submit' autoFocus={true} tabIndex={0}>{this.props.OKText}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ConfirmDialogAlert;