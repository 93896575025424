import AppDispatcher from '../dispatcher/AppDispatcher'
import SessionConstants from '../constants/SessionConstants'
import SessionsAPI from '../apis/SessionsAPI'
import SocialAPI from '../apis/SocialAPI'

export default {
  saveSession: (source, data) => {
    SessionsAPI
      .postSession(data)
      .then(session => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.CREATE_SESSION,
          session: session
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.CREATE_SESSION_ERROR,
          message: message,
          source: source
        })
      })
  },
  deleteSession: (source) => {
    SessionsAPI
      .destroySession()
      .then(() => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.DESTROY_SESSION
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.DESTROY_SESSION_ERROR,
          message: message,
          source: source
        })
      })
  },
  authenticate: (source, data) => {
    SessionsAPI
      .postAuthenticate(data)
      .then(auth_data => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.AUTHENTICATE_SESSION,
          auth_data: auth_data
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.AUTHENTICATE_SESSION_ERROR,
          message: message,
          source: source
        })
      })
  },
  saveSocialSession: (source, email, provider) => {
    SocialAPI
      .createSocialSession(email, provider)
      .then(session => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.CREATE_SOCIAL_SESSION,
          session: session
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SessionConstants.CREATE_SOCIAL_SESSION_ERROR,
          message: message,
          source: source
        })
      })
  }
}
