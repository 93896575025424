import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
class ImageToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <Image ref={this.props.innerRef} onClick={this.handleClick} src='data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style="" stroke="green" fill="green"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none"/><g class="currentLayer" style=""><title>Layer 1</title><path d="M5.65306120461896,8 l3.3061224785378163,4 l3.3061224785378163,-4 z" id="svg_1" class=""/></g></svg>' className='arrow-drop-down-button' />
    );
  }
}

export default React.forwardRef((props, ref) => <ImageToggle innerRef={ref} {...props}/>)