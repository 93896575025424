import React, { Component } from 'react';

// Return the corresponding React node for an icon.
const _iconNode = (icon) => {
  // If it is already a React Element just return it.
  if (React.isValidElement(icon)) {
    return icon;
  }
  // If it is an object, try to use it as a CSS style object.
  if (typeof icon === 'object' && icon !== null) {
    return <span style={icon} />;
  }
  // If it is a string, use it as class names.
  if (Object.prototype.toString.call(icon) === '[object String]') {
    return <span className={icon} />;
  }
};

class RatingSymbol extends Component {
  render() {
    const {
      index,
      inactiveIcon,
      activeIcon,
      percent,
      direction,
      readonly,
      onClick
    } = this.props;
    const backgroundNode = _iconNode(inactiveIcon);
    const iconNode = _iconNode(activeIcon);
    const iconContainerStyle = {
      display: 'inline-block',
      position: 'absolute',
      overflow: 'hidden',
      top: 0,
      [direction === 'rtl' ? 'right' : 'left']: 0,
      width: `${percent}%`
    };
    const style = {
      cursor: !readonly ? 'pointer' : 'inherit',
      display: 'inline-block',
      position: 'relative'
    };

    function handleMouseClick(e) {
      if (onClick) {
        // [Supporting both TouchEvent and MouseEvent](https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent)
        // We must prevent firing click event twice on touch devices.
        e.preventDefault();
        onClick(index, e);
      }
    }

    return (
      <span
        style={style}
        onClick={handleMouseClick}
      >
        {backgroundNode}
        <span style={iconContainerStyle}>
          {iconNode}
        </span>
      </span>
    );
  }
}

export default RatingSymbol;
