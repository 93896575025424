import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'
import SessionStore from '../stores/SessionStore'

const NEW_EVENT = 'new';
const CHANGE_EVENT = 'change';
const FEEDBACK_EVENT = 'feedback';
const DELETE_EVENT = 'delete'
const DELETING_EVENT = 'deleting'
const EXPORT_EVENT = 'export'
const STATISTIC_EVENT = 'statistic'
const ISLOCKED_EVENT = 'islocked'
const CONFIRM_EVENT = 'confirm'
const STATE_EVENT = 'state'
const EMAIL_UPDATED_EVENT = 'emailupdated'

let _user = {};
let _msg = {};
let _bookmarks = '';
let _tags = '';
let _isLocked = false;
let _state = '';
let _timeStatus = 0;

function setUser(user) {
  _user = user;
}

function setFeedback(msg) {
  _msg = msg;
}

function updateFeedback(field, msg) {
  var message = _msg;
  message[field] = msg;
  _msg = message;
}

function setStatistic(bookmarks, tags) {
  _bookmarks = bookmarks
  _tags = tags
}

function setIsLocked(isLocked) {
  _isLocked = isLocked;
}

function setState(state) {
  _state = state;
}

function setTimeStatus(timeStatus) {
  _timeStatus = timeStatus;
}

class UserStoreClass extends EventEmitter {

  emitNew() {
    this.emit(NEW_EVENT);
  }

  emitFeedback() {
    this.emit(FEEDBACK_EVENT);
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  emitDeleted() {
    this.emit(DELETE_EVENT)
  }

  emitDeleting() {
    this.emit(DELETING_EVENT)
  }

  emitExported() {
    this.emit(EXPORT_EVENT)
  }

  emitStatistic() {
    this.emit(STATISTIC_EVENT)
  }

  emitIsLocked() {
    this.emit(ISLOCKED_EVENT)
  }

  emitConfirm() {
    this.emit(CONFIRM_EVENT)
  }

  emitState() {
    this.emit(STATE_EVENT)
  }

  emitEmailUpdated() {
    this.emit(EMAIL_UPDATED_EVENT)
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  addNewListener(callback) {
    this.on(NEW_EVENT, callback)
  }

  removeNewListener(callback) {
    this.removeListener(NEW_EVENT, callback)
  }

  addFeedbackListener(callback) {
    this.on(FEEDBACK_EVENT, callback)
  }

  removeFeedbackListener(callback) {
    this.removeListener(FEEDBACK_EVENT, callback)
  }

  addDeleteListener(callback) {
    this.on(DELETE_EVENT, callback)
  }

  removeDeleteListener(callback) {
    this.removeListener(DELETE_EVENT, callback)
  }

  addDeletingListener(callback) {
    this.on(DELETING_EVENT, callback)
  }

  removeDeletingListener(callback) {
    this.removeListener(DELETING_EVENT, callback)
  }

  addExportListener(callback) {
    this.on(EXPORT_EVENT, callback)
  }

  removeExportListener(callback) {
    this.removeListener(EXPORT_EVENT, callback)
  }

  addStatisticListener(callback) {
    this.on(STATISTIC_EVENT, callback)
  }

  removeStatisticListener(callback) {
    this.removeListener(STATISTIC_EVENT, callback)
  }

  addIsLockedListener(callback) {
    this.on(ISLOCKED_EVENT, callback)
  }

  removeIsLockedListener(callback) {
    this.removeListener(ISLOCKED_EVENT, callback)
  }

  addConfirmListener(callback) {
    this.on(CONFIRM_EVENT, callback)
  }

  removeConfirmListener(callback) {
    this.removeListener(CONFIRM_EVENT, callback)
  }

  addStateListener(callback) {
    this.on(STATE_EVENT, callback)
  }

  removeStateListener(callback) {
    this.removeListener(STATE_EVENT, callback)
  }

  addEmailUpdatedListener(callback) {
    this.on(EMAIL_UPDATED_EVENT, callback)
  }

  removeEmailUpdatedListener(callback) {
    this.removeListener(EMAIL_UPDATED_EVENT, callback)
  }

  getUser() {
    return _user;
  }

  getFeedback() {
    return _msg;
  }

  getBookmarks() {
    return _bookmarks;
  }

  getTags() {
    return _tags;
  }

  getIsLocked() {
    return _isLocked;
  }

  getState() {
    return _state;
  }

  getTimeStatus() {
    return _timeStatus;
  }
}

const UserStore = new UserStoreClass();

UserStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {

    case UserConstants.CREATE_USER:
      setUser(action.user);
      UserStore.emitNew();
      break;

    case UserConstants.CREATE_USER_ERROR:
      setFeedback(action.message);
      UserStore.emitFeedback();
      break;

    case UserConstants.VALID_EMAIL:
      updateFeedback('email', '');
      UserStore.emitFeedback();
      break;

    case UserConstants.USED_EMAIL:
      updateFeedback('email', 'email has already been taken.');
      UserStore.emitFeedback();
      break;

    case UserConstants.UPDATE_PASSWORD:
      UserStore.emitChange();
      break;

    case UserConstants.UPDATE_PASSWORD_ERROR:
      if(action.message.includes('incorrectly typed')) {
        updateFeedback('old_password', action.message);
      }
      else {
        ErrorStore.emitError(action.source, action.message);
        setFeedback(action.message);
      }
      UserStore.emitFeedback();
      break;

    case UserConstants.CONFIRM_EMAIL:
      UserStore.emitConfirm();
      break;

    case UserConstants.CONFIRM_EMAIL_ERROR:
      setFeedback(action.message);
      UserStore.emitFeedback();
      break;

    case UserConstants.EMAIL_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case UserConstants.EMAIL_EXISTS:
      updateFeedback('email', '');
      UserStore.emitFeedback();
      break;

    case UserConstants.EMAIL_NOT_EXISTS:
      updateFeedback('email', 'Email address not found. Please check and try again.');
      UserStore.emitFeedback();
      break;

    case UserConstants.RESET_PASSWORD:
      updateFeedback('retype_password', '');
      UserStore.emitFeedback();
      break;

    case UserConstants.RESET_PASSWORD_ERROR:
      updateFeedback('retype_password', 'The reset token seems to be invalid. Try requesting for a new one.');
      UserStore.emitFeedback();
      break;

    case UserConstants.DELETE_USER:
      UserStore.emitDeleted();
      break;

    case UserConstants.DELETE_USER_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case UserConstants.DELETING_USER:
      UserStore.emitDeleting();
      break;

    case UserConstants.EXPORT_USER:
      UserStore.emitExported();
      break;

    case UserConstants.EXPORT_USER_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case UserConstants.STATISTICS_USER:
      setStatistic(JSON.parse(action['data']).bookmarks, JSON.parse(action['data']).tags);
      UserStore.emitStatistic();
      break;

    case UserConstants.STATISTICS_USER_ERROR:
      updateFeedback('user', 'user data cannot be exported and send to user email.');
      UserStore.emitFeedback();
      ErrorStore.emitError(action.source, action.message);
      break;

    case UserConstants.ISLOCKED_USER:
      setIsLocked(action['data'].account_state)
      UserStore.emitIsLocked();
      break;

    case UserConstants.ISLOCKED_USER_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case UserConstants.STATE_USER:
      setState(action['data'].account_state)
      if (action['data'].account_state == 'trial') {
        setTimeStatus(action['data'].time_status)
      }
      UserStore.emitState();
      break;

    case UserConstants.STATE_USER_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case UserConstants.CREATE_SOCIAL_USER:
      var session = action.session;
      SessionStore.setUserSession(session['user_name'], session['email'], session['provider'], session['created_at'], session['account_name']);
      SessionStore.emitLogin();
      break;

    case UserConstants.CREATE_SOCIAL_USER_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

      case UserConstants.UPDATE_EMAIL:
        UserStore.emitEmailUpdated();
        break;

      case UserConstants.UPDATE_EMAIL_ERROR:
        setFeedback(action.message);
        UserStore.emitFeedback();
        break;
    default:
  }
});

export default UserStore;
