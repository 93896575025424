import AppDispatcher from '../dispatcher/AppDispatcher';
import FilterConstants from '../constants/FilterConstants';

export default {
  filter_tag: (query) => {
    AppDispatcher.dispatch({
      actionType: FilterConstants.FILTER_TAG,
      query: query
    });
  },
  clear: () => {
    AppDispatcher.dispatch({
      actionType: FilterConstants.FILTER_CLEAR
    });
  },
  clear_searchbar: () => {
    setTimeout(
      function () {
        AppDispatcher.dispatch({
          actionType: FilterConstants.FILTER_CLEAR_SEARCH_BAR
        });
      }.bind(this), 10
    );
  },
  filter_not_tag: (tag) => {
    AppDispatcher.dispatch({
      actionType: FilterConstants.FILTER_NOT_TAG,
      tag: tag
    });
  },
  filter_text: (text, fields) => {
    AppDispatcher.dispatch({
      actionType: FilterConstants.FILTER_TEXT,
      text: text,
      fields: fields
    });
  },
  filter_date: (query) => {
    AppDispatcher.dispatch({
      actionType: FilterConstants.FILTER_DATE,
      query: query
    });
  },
  filter_type: (type) => {
    AppDispatcher.dispatch({
      actionType: FilterConstants.FILTER_TYPE,
      type: type
    });
  },
  filter_setup: (options) => {
    setTimeout(
      function () {
        AppDispatcher.dispatch({
          actionType: FilterConstants.FILTER_SETUP,
          options: options
        });
      }.bind(this), 10
    );
  },
  filter_refresh: () => {
    setTimeout(
      function () {
        AppDispatcher.dispatch({
          actionType: FilterConstants.FILTER_REFRESH
        });
      }.bind(this), 10
    );
  },
  searchbar_setup: (placeholder, disabled) => {
    setTimeout(
      function () {
        AppDispatcher.dispatch({
          actionType: FilterConstants.FILTER_SETTINGS_SEARCH_BAR,
          placeholder: placeholder,
          disabled: disabled
        });
      }.bind(this), 10
    );
  },
  filter_reload_default: () => {
    setTimeout(
      function () {
        AppDispatcher.dispatch({
          actionType: FilterConstants.FILTER_RELOAD_DEFAULTS
        });
      }.bind(this), 10
    );
  }
}