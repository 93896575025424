import request from 'superagent/lib/client'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const socialPath = '/socials'

export default {
  createSocialSession: (email, provider) => {
    var body = '{"email":"' + email.trim() + '", "provider":"' + provider + '"}';
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + socialPath + '/check')
        .set('Content-Type', 'application/json')
        .withCredentials()
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  createSocialUser: (email, provider) => {
    var body = '{"email":"' + email.trim() + '", "provider":"' + provider + '"}';
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + socialPath + '/create')
        .set('Content-Type', 'application/json')
        .withCredentials()
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}
