import React, { Component } from 'react';
import(/* webpackPreload: true */ '../styles/App.css');
import(/* webpackPreload: true */ '../styles/PublicContents.css');
import TagButton from './TagButton';
import { Row, Col, Dropdown } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';
import Ratings from './RatingAPILayer';
import Common from '../utils/Common';
import ContentEditComponent from './ContentEdit';
import ImageToggle from './ImageToggle';
import SessionStore from './../stores/SessionStore'
import(/* webpackPreload: true */ '../styles/open-iconic-bootstrap.css');
import ContentNavigate from './ContentNavigate';
import ContentActions from '../actions/ContentActions';

function getTags(tag, disabled) {
  return (
    <TagButton
      disabled={disabled}
      key={tag}
      tag={tag}
    />
  );
}

function isValidUrl(string) {
  try {
    new URL(string)
  }
  catch (_) {
    return false;
  }
  return true
}

class PublicContentItem extends Component {
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
    this.editComponent = React.createRef();
    this.navigateRef = React.createRef();
    this.saveOptionClicked = this.saveOptionClicked.bind(this)
    this.state = {
      tags: [],
      dropdownOpen: false,
      navigateFlag: false,
      current: 0,
      total: props.content['count']
    }
    this.componentId = 'publicContentItem'
    this.populateNavigate = this.populateNavigate.bind(this)
    this.navigateRequest = this.navigateRequest.bind(this)
    this.getNavigate = this.getNavigate.bind(this)
    this.openNavigate = this.openNavigate.bind(this)
    this.closeNavigate = this.closeNavigate.bind(this)
    this.addToMyAccountClick = this.addToMyAccountClick.bind(this)
    this.shareContentClick = this.shareContentClick.bind(this)
    this.embedContentClick = this.embedContentClick.bind(this)
    this.follow = this.follow.bind(this)
    this.unFollow = this.unFollow.bind(this)
    this.notURLNotification = this.notURLNotification.bind(this)
    this.onClickUrl = this.onClickUrl.bind(this);
  }

  onToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  addToMyAccountClick() {
    const { content } = this.props;
    if (SessionStore.getLogged()) {
      this.props.addtomyaccounthandler && this.props.addtomyaccounthandler(this.props.index)
    }
    else {
      this.props.callLogInDialog(content.content_id, 'addtomyaccount')
    }
  }

  saveOptionClicked() {
    const { content } = this.props;
    if (this.state.navigateFlag === false) {
      this.props.navigateRequest(this.componentId, this.props.index, content.content_data, 0)
    }
  }

  populateNavigate(content) {
    this.navigateRef.setValues(content)
  }

  navigateRequest(direction) {
    const { content } = this.props;
    const current = this.state.current
    if (direction === 'next') {
      if ((current + 1) < this.state.total) {
        this.setState({
          current: (current + 1)
        }, () => {
          this.props.navigateRequest(this.componentId, this.props.index, content.content_data, this.state.current)
        })
      }
    }
    else if (direction === 'prev') {
      if ((current - 1) >= 0) {
        this.setState({
          current: (current - 1)
        }, () => {
          this.props.navigateRequest(this.componentId, this.props.index, content.content_data, this.state.current)
        })
      }
    }
  }

  openNavigate() {
    this.setState({
      current: 0,
      navigateFlag: true
    })
  }

  closeNavigate() {
    this.setState({
      navigateFlag: false
    })
  }

  getNavigate() {
    return this.state.navigateFlag;
  }

  shareContentClick() {
    this.props.shareContent && this.props.shareContent(this.props.content)
  }

  embedContentClick() {
    this.props.embedContent && this.props.embedContent(this.props.content)
  }

  follow() {
    if (SessionStore.getLogged()) {
      ContentActions.addToMyNetwork(this.componentId, this.props.content.account_id)
    }
    else {
      this.props.callLogInDialog(this.props.content.account_id, 'accounttofollow')
    }
  }

  unFollow() {
    ContentActions.removeFromMyNetwork(this.componentId, this.props.content.account_id)
  }

  notURLNotification() {
    this.props.notURLNotification()
    this.saveOptionClicked()
  }

  openAccountLinkClicked(e, accountName) {
    e.preventDefault();
    this.props.openAccountLink && this.props.openAccountLink(accountName);
  }

  onClickUrl(event) {
    var url = new URL(event.target.href);
    if (url.protocol == 'file:') {
      event.preventDefault();
      navigator.clipboard.writeText(url.href)
      this.props.pasteNotification && this.props.pasteNotification()
    }
  }

  render() {
    var pathName = window.location.pathname
    var empty_row;
    if (pathName.split('/')[1] == 'explore' || pathName.split('/')[1] == '' || pathName.split('/')[1] == 'contents') {
      empty_row = <Col>
        <div className="empty-row">
        </div>
        <div className="empty-row">
        </div>
      </Col>
    }
    const { content } = this.props;
    let tags;
    this.state.tags = content.tags;
    if (this.state.tags) {
      tags = this.state.tags.map(tag => getTags(tag, this.props.disabled));
    }
    let contentEditClose = () => {
      this.setState({ showEdit: false })
    }
    var urlValue = content.content_data;
    var notesValue = content.local_properties.notes && content.local_properties.notes.length > 0 && content.local_properties.notes.join('') ? content.local_properties.notes.toString() : 'Notes not available for this entry.';
    var titleValue = (content.content_title === undefined || content.content_title === null || /^\s*$/.test(content.content_title) === true) ? content.content_data : content.content_title
    var titleElement = <a href={urlValue} rel="external" target={this.props.openLinksNewTab ? '_blank' : '_self'} onClick={this.onClickUrl}>{titleValue}</a>
    if (!isValidUrl(urlValue)) {
      titleElement = <span onClick={this.notURLNotification}>{titleValue}</span>
    }
    var urlElement = <a href={urlValue} rel="external" target={this.props.openLinksNewTab ? '_blank' : '_self'} onClick={this.onClickUrl}>{urlValue}</a>
    if (!isValidUrl(urlValue)) {
      urlElement = <span onClick={this.notURLNotification}>{urlValue}</span>
    }
    let menuOptionShare;
    let optionShare;
    if (this.props.shareContent) {
      menuOptionShare = <Dropdown.Item onClick={this.shareContentClick}>Share</Dropdown.Item>
      optionShare = <span onClick={this.shareContentClick}>share</span>
    } else {
      menuOptionShare = ''
      optionShare = <span></span>
    }
    let menuOptionEmbed
    if (this.props.embedContent) {
      menuOptionEmbed = <Dropdown.Item onClick={this.embedContentClick}>Embed</Dropdown.Item>
    } else {
      menuOptionEmbed = ''
    }
    let followMenu;
    if (this.props.network.includes(content.account_id)) {
      followMenu = <span className='signal34-option' onClick={this.unFollow}>unfollow</span>
    }
    else {
      followMenu = <span className='signal34-option' onClick={this.follow}>follow</span>
    }
    var dateValue = new Date(content.created_at).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    let content_created_ago;
    let url_account =
      <a href={'/contents/' + content.account_name} rel="external" onClick={(e) => { this.openAccountLinkClicked(e, content.account_name) }}>{content.account_name}</a>
    let other = '';
    if (content.count >= 2) {
      other += ' (& ' + (content.count - 1) + ' other'
      if (content.count >= 3) {
        other += 's'
      }
      other += ')'
    }
    content_created_ago =
      <span className='signal34-ago' onDoubleClick={this.saveOptionClicked}>
        posted  {content.created_ago} ago by  {url_account} {other}
      </span>
    let optionsLine;
    if (this.props.hideOptionsLine) {
      optionsLine = ''
    } else {
      optionsLine = <Col className='signal34-last-line'>
        {content_created_ago}
        <span className='signal34-options-menu'>
          {followMenu}
          <span className='signal34-option' onClick={this.saveOptionClicked}>
            save
        </span>
          <span className='signal34-option'>
            {optionShare}
          </span>
        </span>
      </Col>
    }
    let menuDrowpDown;
    if (this.props.hideMenuDropDown) {
      menuDrowpDown = <span></span>
    }
    else {
      menuDrowpDown =
        <Dropdown id="add-to-account-menu" className="add-to-account-menu" show={this.state.dropdownOpen} onToggle={this.onToggle}>
          <Dropdown.Toggle id="add-to-account-menu-toggle" className="add-to-account-menu-toggle" variant="secondary" as={ImageToggle}>
          </Dropdown.Toggle>
          <Dropdown.Menu className='add-to-account-menu'>
            <Dropdown.Item onClick={this.addToMyAccountClick}>Add to my content</Dropdown.Item>
            {menuOptionEmbed}
            {menuOptionShare}
          </Dropdown.Menu>
        </Dropdown>;
    }
    return (
      <div>
        <Row className='signal34-content'>
          <Col className='signal34-name'>
            <div className='signal34-data' onDoubleClick={this.saveOptionClicked}>
              <div className='signal34-title'>
                {titleElement}
              </div>
              <div className='signal34-url'>
                {urlElement}
              </div>
              <div className='signal34-menu-score'>
                {menuDrowpDown}
                <span className='signal34-score'>
                  <Ratings
                    stop={5}
                    initialRating={Common.score2start(content.content_rating)}
                    emptySymbol={<span className="oi oi-star" style={{ color: 'rgb(203,203,203)', paddingLeft: '2px' }} />}
                    fullSymbol={<span className="oi oi-star" style={{ color: 'rgb(255,192,0)', paddingLeft: '2px' }} />}
                    readonly={true}
                    quiet={true}
                  />
                </span>
              </div>
            </div>
          </Col>
          <Col className='signal34-date-notes'>
            <span onDoubleClick={this.saveOptionClicked}>
              <TextTruncate
                line={2}
                truncateText="…"
                text={dateValue.toString() + ' - ' + notesValue}
              />
            </span>
          </Col>
          <Col className='signal34-tags'>
            <span className='signal34-tags-row' onDoubleClick={this.saveOptionClicked}>
              {tags}
            </span>
          </Col>
          {optionsLine}
          <Col className='signal34-edit'>
            <ContentEditComponent
              showedit={this.props.editingflag}
              content_id={content.content_id}
              record_id={content.id}
              location={content.content_data}
              title={content.content_title}
              tags={content.tags}
              rating={content.content_rating}
              notes={content.local_properties.notes}
              onHide={contentEditClose}
              ref={this.editComponent}
              save={this.props.save}
              cancel={this.props.cancel}
              index={this.props.index}
            />
          </Col>
          <Col className='signal34-navigate'>
            <ContentNavigate
              showNavigate={this.state.navigateFlag}
              ref={this.navigateRef}
              navigateRequest={this.navigateRequest}
              cancel={this.closeNavigate}
              save={this.props.saveForNavigate}
              callLogInDialog={this.props.callLogInDialog}
              content={this.props.content}
            />
          </Col>
          {empty_row}
        </Row>
      </div>
    );
  }
}

export default PublicContentItem;
