import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import UsersAPI from '../apis/UsersAPI';
import SocialAPI from '../apis/SocialAPI';

export default {
  saveUser: (source, data) => {
    UsersAPI
      .postUser(data)
      .then(user => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CREATE_USER,
          user: user
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CREATE_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  saveBusinessUser: (source, data) => {
    UsersAPI
      .postBusinessUser(data)
      .then(user => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CREATE_USER,
          user: user
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CREATE_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  verifyEmail: (source, email) => {
    UsersAPI
      .validateEmail(email)
      .then(response => {
        if (response) {
          AppDispatcher.dispatch({ actionType: UserConstants.VALID_EMAIL });
        } else {
          AppDispatcher.dispatch({ actionType: UserConstants.USED_EMAIL });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.EMAIL_ERROR,
          message: message,
          source: source
        });
      });
  },
  forgotPassword: (source, email) => {
    UsersAPI
      .forgotPassword(email)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: UserConstants.EMAIL_EXISTS
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.EMAIL_NOT_EXISTS,
          message: message,
          source: source
        });
      });
  },
  confirmEmail: (source, token) => {
    UsersAPI
      .confirmEmail(token)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CONFIRM_EMAIL
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CONFIRM_EMAIL_ERROR,
          message: message,
          source: source
        });
      });
  },
  resetPassword: (source, token, password) => {
    UsersAPI
      .resetPassword(token, password)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: UserConstants.RESET_PASSWORD
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.RESET_PASSWORD_ERROR,
          message: message,
          source: source
        });
      });
  },
  updatePassword: (source, old_password, password) => {
    UsersAPI
      .updatePassword(old_password, password)
      .then((result) => {
        if('message' in result) {
          AppDispatcher.dispatch({
            actionType: UserConstants.UPDATE_PASSWORD_ERROR,
            message: result['message'],
            source: source
          });
        }
        else {
          AppDispatcher.dispatch({
            actionType: UserConstants.UPDATE_PASSWORD
          });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.UPDATE_PASSWORD_ERROR,
          message: message,
          source: source
        });
      });
  },
  deleteUser: (source, user_id) => {
    UsersAPI
      .deleteUser(user_id)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: UserConstants.DELETE_USER
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.DELETE_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  deletingUser: () => {
    AppDispatcher.dispatch({
      actionType: UserConstants.DELETING_USER
    });
  },
  exportToEmail: (source, user_id) => {
    UsersAPI
      .exportToEmail(user_id)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: UserConstants.EXPORT_USER
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.EXPORT_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  getStatistics: (source, user_id) => {
    UsersAPI
      .getStatistics(user_id)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: UserConstants.STATISTICS_USER,
          data: data[1]
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.STATISTICS_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  isLocked: (source, user_id) => {
    UsersAPI
      .isLocked(user_id)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: UserConstants.ISLOCKED_USER,
          data: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.ISLOCKED_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  state: (source, user_id) => {
    UsersAPI
      .state(user_id)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: UserConstants.STATE_USER,
          data: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.STATE_USER_ERROR,
          message: message,
          source: source
        });
      });
  },
  saveSocialUser: (source, email, provider) => {
    SocialAPI
      .createSocialUser(email, provider)
      .then(session => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CREATE_SOCIAL_USER,
          session: session
        })
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.CREATE_SOCIAL_USER_ERROR,
          message: message,
          source: source
        })
      })
  },
  updateEmail: (source, user_id, old_email, new_email) => {
    UsersAPI
      .updateEmail(user_id, old_email, new_email)
      .then((result) => {
        if('message' in result) {
          AppDispatcher.dispatch({
            actionType: UserConstants.UPDATE_EMAIL_ERROR,
            message: result['message'],
            source: source
          });
        }
        else {
          AppDispatcher.dispatch({
            actionType: UserConstants.UPDATE_EMAIL
          });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: UserConstants.UPDATE_EMAIL_ERROR,
          message: message,
          source: source
        });
      });
  }
}
