import(/* webpackPreload: true */ '../styles/App.css');
import React, { Component } from 'react';
import TagSelect from './TagSelect';
import Common from '../utils/Common';
import { Button, Alert, Image } from 'react-bootstrap'
import Ratings from './RatingAPILayer'
import Checkbox from './Checkbox'
import SessionStore from './../stores/SessionStore'
import ContentActions from '../actions/ContentActions';

const OPTIONS = ['public', 'share'];
const maxLen = Common.MAXLEN_TAG_LABEL;
class ContentNavigate extends Component {
  constructor(props) {
    super(props);
    this.maxLengthNotes = Common.MAXLEN_NOTES
    this.maxLength = Common.MAXLEN_LOCATION
    this.state = {
      showEdit: props.showedit,
      tags_inputValue: '',
      tags_value: [],
      duplicated_id: '',
      record_id: '',
      content_id: '',
      content_data: '',
      content_title: '',
      content_rating: 0,
      content_tags: [],
      characters_notes: this.maxLengthNotes,
      content_notes: '',
      validationMessage: {},
      isFocusInMenuList: false,
      checkboxes: OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: false
        }),
        {}
      )
    }
    this.onKeyPress = this.onKeyPress.bind(this)
    this.cancelClicked = this.cancelClicked.bind(this)
    this.saveClicked = this.saveClicked.bind(this)
    this.handleContentDataChange = this.handleContentDataChange.bind(this)
    this.handleContentTitleChange = this.handleContentTitleChange.bind(this)
    this.handleRatingChange = this.handleRatingChange.bind(this)
    this.handleResetRating = this.handleResetRating.bind(this)
    this.handleSelectTagChange = this.handleSelectTagChange.bind(this)
    this.handleInputTagChange = this.handleInputTagChange.bind(this)
    this.handleKeyDownTag = this.handleKeyDownTag.bind(this)
    this.handleNoOptionsMessage = this.handleNoOptionsMessage.bind(this)
    this.handleNotesChange = this.handleNotesChange.bind(this)
    this.setValues = this.setValues.bind(this)
    this.getValues = this.getValues.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.getCheckboxes = this.getCheckboxes.bind(this)
    this.nextClicked = this.nextClicked.bind(this)
    this.prevClicked = this.prevClicked.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tags_inputValue !== prevState.tags_inputValue) {
      this.setState({
        isFocusInMenuList: false
      });
    }
  }

  handleContentDataChange(e) {
    this.setState({ content_data: e.target.value })
  }

  handleContentTitleChange(e) {
    this.setState({ content_title: e.target.value })
  }

  handleRatingChange(rate) {
    this.setState({
      content_rating: rate
    })
  }

  handleResetRating() {
    this.setState({
      content_rating: 0
    })
  }

  handleSelectTagChange = (value) => {
    this.setState({ tags_value: (value == null) ? [] : value }, function () {
      this.setState({
        content_tags: Common.buildList(this.state.tags_value),
        change_tags: true
      })
    })
  }

  handleInputTagChange = (inputValue) => {
    this.setState({ tags_inputValue: inputValue });
  }

  handleKeyDownTag = (event) => {
    const { tags_value, tags_inputValue, isFocusInMenuList } = this.state;
    if (!tags_inputValue) return;
    switch (event.key) {
      case 'ArrowUp':
        if (!isFocusInMenuList) {
          this.setState({
            isFocusInMenuList: true
          });
        }
        break;

      case 'ArrowDown':
        if (!isFocusInMenuList) {
          this.setState({
            isFocusInMenuList: true
          });
        }
        break;

      case 'Enter':
      case 'Tab':
        if (!isFocusInMenuList) {
          event.preventDefault();
          if (!Common.checkExist(tags_value, tags_inputValue)) {
            event.preventDefault();
            this.setState({
              tags_inputValue: '',
              tags_value: [...this.state.tags_value, Common.createOption(tags_inputValue)]
            }, function () {
              this.setState({
                content_tags: Common.buildList(this.state.tags_value),
                change_tags: true
              })
            });
          }
        }
        break;
    }
  }

  handleNoOptionsMessage = () => {
    setTimeout(() => { this.setState({ isFocusInMenuList: false }) }, 1);
    return 'New tag: ' + this.state.tags_inputValue
  }

  handleNotesChange(e) {
    var length = e.target.value.length
    var remainCharacters = this.maxLengthNotes - length
    this.setState({ content_notes: e.target.value, characters_notes: remainCharacters })
  }

  setMessage(field, msg) {
    let val = this.state.validationMessage;
    val[field] = msg
    this.setState({ validationMessage: val })
  }

  validateForm() {
    const { content_data } = this.state;
    if (content_data === '' || content_data.trim() == '') {
      this.setMessage('content_data', 'Content Location is required.')
    }
    return (content_data.length > 0 && content_data.trim().length > 0)
  }

  clearForm() {
    if (this.props.clear == 'true') {
      this.setState({
        tags_inputValue: '',
        tags_value: [],
        duplicated_id: '',
        record_id: '',
        content_id: '',
        content_data: '',
        content_title: '',
        content_rating: 0,
        content_tags: [],
        content_notes: '',
        validationMessage: {},
        change_tags: false,
        characters_notes: this.maxLengthNotes,
        checkboxes: OPTIONS.reduce(
          (options, option) => ({
            ...options,
            [option]: false
          }),
          {}
        )
      })
    }
  }

  setValues(values) {
    var tags_value = []
    if (!(values.tags === null || values.tags === undefined || values.tags.length == 0)) {
      var tags = values.tags.reduce((a, o) => (o != process.env.TAG_KEY_ONE && o != process.env.TAG_KEY_TWO && a.push(o), a), [])
      var isPublic = values.tags.includes(process.env.TAG_KEY_ONE)
      var isShare = values.tags.includes(process.env.TAG_KEY_TWO);
      this.setState({
        checkboxes: {
          public: isPublic,
          share: isShare
        }
      })
      tags_value = tags.map(function (val) {
        var tag = val.tag ? val.tag : val;
        var tooltip = '';
        var label = '';
        if (tag.length > maxLen) {
          tooltip = tag;
          label = tag.substring(0, maxLen) + '...';
        } else {
          label = tag;
        }
        return { value: tag, label: label, tooltip: tooltip }
      })
    }
    var notes_value = ''
    if (!(values.notes === null || values.notes === undefined || values.notes == 0)) {
      notes_value = values.notes
    }
    if (!(values.local_properties.notes === null || values.local_properties.notes === undefined || values.local_properties.notes == 0)) {
      notes_value = values.local_properties.notes
    }
    var len = notes_value != null ? Array.isArray(notes_value) ? notes_value[0].length : notes_value.length : 0
    var remainCharacters = this.maxLengthNotes - len
    this.setState({
      tags_inputValue: '',
      tags_value: tags_value,
      record_id: values.id,
      content_id: values.content_id,
      content_data: values.content_data,
      content_title: values.content_title,
      content_rating: Common.score2start(values.content_rating),
      content_tags: Common.buildList(tags_value),
      content_notes: notes_value,
      characters_notes: remainCharacters,
      validationMessage: {}
    });
  }

  getValues() {
    var values = {}
    var commands = this.getCheckboxes()
    const { content_data, content_title, content_rating, content_notes, record_id, content_tags, content_id } = this.state
    values['id'] = record_id
    values['content_id'] = content_id
    values['content_data'] = content_data
    if (content_title === '' || content_title === '') {
      values['content_title'] = content_data
    }
    else {
      values['content_title'] = content_title
    }
    values['content_rating'] = Common.start2score(content_rating)
    values['tags'] = content_tags
    values['notes'] = content_notes
    values['public'] = commands['public']
    values['share'] = commands['share']
    return values
  }

  cancelClicked() {
    this.props.cancel(this.props.index)
  }

  saveClicked() {
    var validate = this.validateForm();
    if (SessionStore.getLogged()) {
      if (validate) {
        this.props.save(this.getValues())
      }
    }
    else {
      if (validate) {
        ContentActions.contentUpdatedOnGuest(this.getValues())
        this.props.callLogInDialog(this.props.content.content_id, 'explore')
      }
    }
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.saveClicked()
    }
  }

  handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }));
  }

  getCheckboxes() {
    var fields = {}
    Object.keys(this.state.checkboxes).forEach(checkbox => {
      fields[checkbox] = this.state.checkboxes[checkbox]
    })
    return fields
  }

  nextClicked() {
    this.props.navigateRequest('next')
  }

  prevClicked() {
    this.props.navigateRequest('prev')
  }

  renderEdit() {
    let formUI
    if (this.props.showNavigate === true) {
      const { content_data, content_title, content_rating, tags_value, tags_inputValue, content_notes, characters_notes } = this.state
      formUI = (
        <div>
          <div>
            <div className="modal-edit-content signal34-navigate">
              <div>
                <span className="location-class-left">
                  <label htmlFor="content_data" className="form-label location-class" >Location:</label>
                </span>
                <span className="location-class-right-options">
                  <span onClick={this.nextClicked}>next</span>
                  <span onClick={this.prevClicked}>prev</span>
                </span>
                <span className="location-class-right">
                  <Alert variant={'light'} >{this.state.validationMessage['content_data']}</Alert>
                </span>
                <input id="content_data" placeholder="Enter a unique location for your content such as: URL or file path" type="text" value={content_data} onChange={this.handleContentDataChange}
                  className='form-control' autoFocus={true} tabIndex={0} onKeyPress={this.onKeyPress} maxLength={this.maxLength} />
                <br />
              </div>

              <div>
                <label htmlFor="content_title" className="form-label" >Title:</label>
                <input id="content_title" placeholder="Enter a descriptive title for your content" type="text" value={content_title} onChange={this.handleContentTitleChange}
                  className='form-control' onKeyPress={this.onKeyPress} maxLength={this.maxLength} />
                <br />
              </div>
              <div>
                <label htmlFor="tags" className="form-label tags-label" >Tags:</label><span className="tags-text">Press &lt;Enter&gt; key to add more tags.</span>
                <TagSelect
                  id="tags"
                  value={tags_value}
                  inputValue={tags_inputValue}
                  onChange={this.handleSelectTagChange}
                  onInputChange={this.handleInputTagChange}
                  onKeyDown={this.handleKeyDownTag}
                  noOptionsMessage={this.handleNoOptionsMessage}
                  content={this.props.content}
                />
                <br />
              </div>
              <div>
                <div>
                  <div className="content-rating-label">
                    <label htmlFor="content_rating" className="form-label rating-label" >Rating:</label>
                  </div>
                  <div className="content-checkbox">
                    <div className="content-checkbox text-checkbox">
                      <Checkbox
                        label={'Share'}
                        name={'share'}
                        isSelected={this.state.checkboxes['share']}
                        onCheckboxChange={this.handleCheckboxChange}
                      />
                    </div>
                    <div className="content-checkbox text-checkbox">
                      <Checkbox
                        label={'Public'}
                        name={'public'}
                        isSelected={this.state.checkboxes['public']}
                        onCheckboxChange={this.handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="rating-box">
                  <Image onClick={this.handleResetRating} src='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 8 8" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"><path fill="silver" d="M4 0C1.79 0 0 1.79 0 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM2.5 1.78L4 3.28l1.5-1.5.72.72L4.72 4l1.5 1.5-.72.72L4 4.72l-1.5 1.5-.72-.72L3.28 4l-1.5-1.5.72-.72z"/></svg>' />
                  <Ratings
                    initialRating={0}
                    fractions={2}
                    stop={5}
                    step={1}
                    emptySymbol={<span className="oi oi-star" style={{ color: 'rgb(203,203,203)', paddingLeft: '2px' }} />}
                    fullSymbol={<span className="oi oi-star" style={{ color: 'rgb(255,192,0)', paddingLeft: '2px' }} />}
                    placeholderSymbol={<span className="oi oi-star" style={{ color: 'rgb(255,192,0)', paddingLeft: '2px' }} />}
                    placeholderRating={content_rating}
                    onChange={this.handleRatingChange}
                  />
                </div>
              </div>

              <div>
                <br />
                <label htmlFor="notes" className="form-label" >Notes:</label>
                <textarea className="form-control" rows="5" id="notes" placeholder="Enter free text such as summary or notes for your content" value={content_notes} onChange={this.handleNotesChange} maxLength={this.maxLengthNotes}></textarea>
                <label className="notes-counter-chars">{characters_notes} characters remaining</label>
              </div>
              <div className="edit-buttons">
                <div>
                  <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.cancelClicked}>Cancel</Button>
                  <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.saveClicked}>Save</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      formUI = (
        <div>
        </div>
      )
    }

    return formUI
  }
  render() {
    return (
      <div>
        {this.renderEdit()}
      </div>
    )
  }
}

export default ContentNavigate;
