import UserStore from '../stores/UserStore';
import React, { Component } from 'react';
import UserActions from '../actions/UserActions';
import '../styles/Login.css';
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
    this.onFeedback = this.onFeedback.bind(this);
    this.onError = this.onError.bind(this);
    this.componentId = 'confirmemail';
  }

  UNSAFE_componentWillMount() {
    UserStore.addConfirmListener(this.onConfirmSubmit);
    UserStore.addFeedbackListener(this.onFeedback);
    ErrorStore.addErrorListener(this.onError);
  }

  componentDidMount() {
    this.confirmEmail(this.props.params.token);
  }

  componentWillUnmount() {
    UserStore.removeConfirmListener(this.onConfirmSubmit);
    UserStore.removeFeedbackListener(this.onFeedback);
    ErrorStore.removeErrorListener(this.onError);
  }

  confirmEmail(token) {
    UserActions.confirmEmail(this.componentId, token);
  }

  onConfirmSubmit(){
    this.props.router.push('/confirm_success');
  }

  onFeedback() {
    this.props.router.push('/confirm_error');
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    return (
      <div className='Login'>
      </div>
    );
  }
}

export default ConfirmEmail;
