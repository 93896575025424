import AppDispatcher from '../dispatcher/AppDispatcher';
import PlanConstants from '../constants/PlanConstants';
import PlanAPI from '../apis/PlanAPI';

export default {
  receivePlans: (source) => {
    PlanAPI
      .getPlans()
      .then(data => {
        AppDispatcher.dispatch({
          actionType: PlanConstants.RECEIVE_PLANS,
          plans: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: PlanConstants.RECEIVE_PLANS_ERROR,
          message: message,
          source: source
        });
      });
  }
}