import request from 'superagent/lib/client'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const authPath = '/auth'

export default {
  postSession: (data) => {
    var body = '{"email":"' + data.email.trim() + '","password":"' + data.password.replace('\\','\\\\').replace('\'', '\\\'').replace('\"', '\\\"') + '"}';
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + authPath + '/create')
        .set('Content-Type', 'application/json')
        .withCredentials()
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  destroySession: () => {
    return new Promise((resolve, reject) => {
      request
        .delete(host + versionPath + authPath + '/destroy')
        .withCredentials()
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve({ response: 'Ok' })
          }
        })
    })
  },
  postAuthenticate: (data) => {
    var body = '{"email":"' + data.email.trim() + '","password":"' + data.password.replace('\\','\\\\').replace('\'', '\\\'').replace('\"', '\\\"') + '","provider":"' + data.provider + '"}';
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + authPath + '/check')
        .set('Content-Type', 'application/json')
        .withCredentials()
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}
