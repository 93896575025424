import AppDispatcher from '../dispatcher/AppDispatcher';
import ContentConstants from '../constants/ContentConstants';
import TagConstants from '../constants/TagConstants';
import ContentsAPI from '../apis/ContentsAPI';

export default {
  receiveContents: (source, page_number, per_page, query, query_by_text, sort) => {
    ContentsAPI
      .getContents(page_number, per_page, query, query_by_text, sort)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENTS,
          contents: data.contents,
          numFound: data.numFound,
          queryTime: (data.QTime / 1000).toFixed(2),
          version: data.version
        });
        if (!query || (query && ((query.startsWith('tags_str:') || query.startsWith('-tags_str:'))))) {
          AppDispatcher.dispatch({
            actionType: TagConstants.RECEIVE_ALL_CONTENTS_TAGS,
            tags: data.tags
          });
          AppDispatcher.dispatch({
            actionType: TagConstants.RECEIVE_ALL_TAGS,
            tags: data.tags
          });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENTS_ERROR,
          message: message,
          source: source
        });
      });
  },
  receiveNotagsContents: (source, page_number, per_page, query, query_by_text, sort) => {
    var _not = '-tags:(* TO *)';
    if (query && query.length > 0) {
      query = _not + ' AND ' + query;
    }
    else {
      query = _not;
    }
    ContentsAPI
      .getContents(page_number, per_page, query, query_by_text, sort)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_NOTAGS_CONTENTS,
          contents: data.contents,
          numFound: data.numFound,
          queryTime: (data.QTime / 1000).toFixed(2)
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_NOTAGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  getContent: (source, id) => {
    ContentsAPI
      .getContent(id)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT,
          content: content
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_ERROR,
          message: message,
          source: source
        });
      });
  },
  getPublicContent: (source, id) => {
    ContentsAPI
      .getPublicContent(id)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT,
          content: content
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_ERROR,
          message: message,
          source: source
        });
      });
  },
  getPublicNthContent: (source, location, nth) => {
    ContentsAPI
      .getNthContentSameLocation(location, nth)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT,
          content: content
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_ERROR,
          message: message,
          source: source
        });
      });
  },
  sendContent: (source, data) => {
    ContentsAPI
      .createContent(data)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.SEND_CONTENT
        });
      })
      .catch(message => {
        if (message.id) {
          AppDispatcher.dispatch({
            actionType: ContentConstants.SEND_CONTENT_DUPLICATE,
            message: message.error,
            status: message.status,
            id: message.id,
            content_id: message.content_id
          });
        } else {
          AppDispatcher.dispatch({
            actionType: ContentConstants.SEND_CONTENT_ERROR,
            message: message,
            source: source
          });
        }
      });
  },
  updateContent: (source, data) => {
    ContentsAPI
      .putContent(data)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.PUT_CONTENT,
          content: content
        });
      })
      .catch(message => {
        if (message.id) {
          AppDispatcher.dispatch({
            actionType: ContentConstants.PUT_CONTENT_DUPLICATE,
            message: message.error,
            status: message.status,
            id: message.id,
            content_id: message.content_id
          });
        } else {
          AppDispatcher.dispatch({
            actionType: ContentConstants.PUT_CONTENT_ERROR,
            message: message,
            source: source
          });
        }
      });
  },
  deleteContent: (source, content_id) => {
    ContentsAPI
      .deleteContent(content_id)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.DELETE_CONTENT,
          toRemoveTags: data['to_remove_tags']
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.DELETE_CONTENT_ERROR,
          message: message,
          source: source
        });
      });
  },
  editContent: (content_id) => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.EDIT_CONTENT,
      id: content_id
    });
  },
  exportContents: (source, query, query_by_text, sort) => {
    ContentsAPI
      .exportContents(query, query_by_text, sort)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.EXPORT_CONTENT,
          contents: data.contents
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.EXPORT_CONTENT_ERROR,
          message: message,
          source: source
        });
      });
  },
  enableTopEditor: (content_id_to_edit) => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.ENABLE_TOP_EDITOR,
      content_id_to_edit: content_id_to_edit
    });
  },
  disableTopEditor: () => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.DISABLE_TOP_EDITOR
    });
  },
  receivePublicContents: (source, page_number, per_page, query, query_by_text, sort) => {
    ContentsAPI
      .getPublicContents(page_number, per_page, query, query_by_text, sort)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_PUBLIC_CONTENTS,
          contents: data.contents,
          numFound: data.numFound,
          network: data.network,
          queryTime: (data.QTime / 1000).toFixed(2)
        });
        if (!query || (query && ((query.startsWith('tags_str:') || query.startsWith('-tags_str:'))))) {
          AppDispatcher.dispatch({
            actionType: TagConstants.RECEIVE_ALL_CONTENTS_TAGS,
            tags: data.tags
          });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_PUBLIC_ERROR,
          message: message,
          source: source
        });
      });
  },
  receivePublicContentsByAccount: (source, account, page_number, per_page, query, query_by_text, sort) => {
    ContentsAPI
      .getPublicContentsByAccount(account, page_number, per_page, query, query_by_text, sort)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_PUBLIC_CONTENTS_BY_ACCOUNT,
          contents: data.contents,
          numFound: data.numFound,
          network: data.network,
          queryTime: (data.QTime / 1000).toFixed(2)
        });
        if (!query || (query && ((query.startsWith('tags_str:') || query.startsWith('-tags_str:'))))) {
          AppDispatcher.dispatch({
            actionType: TagConstants.RECEIVE_ALL_CONTENTS_TAGS,
            tags: data.tags
          });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_PUBLIC_ERROR,
          message: message,
          source: source
        });
      });
  },
  addToMyAccountOn: (content_id) => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.ADD_TO_MY_ACCOUNT_ON,
      content_id: content_id
    });
  },
  addToMyAccountOff: () => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.ADD_TO_MY_ACCOUNT_OFF
    });
  },
  navigateToSaveOn: (content_id) => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.NAVIGATE_TO_SAVE_ON,
      content_id: content_id
    });
  },
  navigateToSaveOff: () => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.NAVIGATE_TO_SAVE_OFF
    });
  },
  getShareLink: (source, data) => {
    ContentsAPI
      .getShareLink(data)
      .then(sharelink => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_SHARE_LINK,
          sharelink: sharelink
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_SHARE_LINK_ERROR,
          message: message,
          source: source
        });
      });
  },
  getContentByShareLink: (source, slug, count) => {
    ContentsAPI
      .getContentByShareLink(slug, count)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_BY_SHARE_LINK,
          content: content
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_BY_SHARE_LINK_ERROR,
          message: message,
          source: source
        });
      });
  },
  getPermaLink: (source, data) => {
    ContentsAPI
      .getPermaLink(data)
      .then(permalink => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_PERMALINK,
          permalink: permalink
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_PERMALINK_ERROR,
          message: message,
          source: source
        });
      });
  },
  getContentByPermalink: (source, slug) => {
    ContentsAPI
      .getContentByPermalink(slug)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_BY_PERMALINK,
          content: content
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_CONTENT_BY_PERMALINK_ERROR,
          message: message,
          source: source
        });
      });
  },
  isPermalinkPublic: (source, slug) => {
    ContentsAPI
      .isPermalinkPublic(slug)
      .then(content => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_IS_PUBLIC_PERMALINK,
          content: content
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_IS_PUBLIC_PERMALINK_ERROR,
          message: message,
          source: source
        });
      });
  },
  receiveFollowContents: (source, page_number, per_page, query, query_by_text, sort) => {
    ContentsAPI
      .getFollowContents(page_number, per_page, query, query_by_text, sort)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_FOLLOW_CONTENTS,
          contents: data.contents,
          numFound: data.numFound,
          queryTime: (data.QTime / 1000).toFixed(2)
        });
        if (!query || (query && ((query.startsWith('tags_str:') || query.startsWith('-tags_str:'))))) {
          AppDispatcher.dispatch({
            actionType: TagConstants.RECEIVE_ALL_CONTENTS_TAGS,
            tags: data.tags
          });
        }
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_FOLLOW_CONTENTS_ERROR,
          message: message,
          source: source
        });
      });
  },
  getNetwork: (source) => {
    ContentsAPI
      .getNetwork()
      .then(network => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_NETWORK,
          network: network
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_NETWORK_ERROR,
          message: message,
          source: source
        });
      });
  },
  addToMyNetwork: (source, account_id) => {
    ContentsAPI
      .addToMyNetwork(account_id)
      .then(network => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_NETWORK,
          network: network
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.ADD_TO_MY_NETWORK_ERROR,
          message: message,
          source: source
        });
      });
  },
  removeFromMyNetwork: (source, account_id) => {
    ContentsAPI
      .removeFromMyNetwork(account_id)
      .then(network => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.RECEIVE_NETWORK,
          network: network
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: ContentConstants.REMOVE_FROM_MY_NETWORK_ERROR,
          message: message,
          source: source
        });
      });
  },
  contentUpdatedOnGuest: (content) => {
    AppDispatcher.dispatch({
      actionType: ContentConstants.RECEIVE_CONTENT_GUEST,
      content: content
    });
  }
}
