import React, { Component } from 'react'

class HelpComponent extends Component {

  render () {
    return (
      <div>
        Help
      </div>
    )
  }
}

export default HelpComponent
