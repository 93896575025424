import request from 'superagent/lib/client'
import HandleException from '../utils/HandleException';

export default {
  getVersion: () => {
    var host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
    return new Promise((resolve, reject) => {
      request
        .get(host + '/version')
        .set('Content-Type', 'text/html')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(response.text);
          }
        })
    });
  }
}