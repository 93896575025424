import { Link } from 'react-router'
import React, { Component } from 'react'
import SessionStore from '../stores/SessionStore'
import '../styles/Login.css'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import VersionStore from '../stores/VersionStore'
import VersionActions from '../actions/VersionActions'
import LoginCard from '../containers/LoginCard'

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.emailRef = target => this.setState({ emailState: target })
    this.passwordRef = target => this.setState({ passwordState: target })
    this.state = {
      email: '',
      password: '',
      validationMessage: {}
    }

    this.redirectToContents = this.redirectToContents.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.componentId = 'login'
    this.onChangeVersion = this.onChangeVersion.bind(this)
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.redirectToContents()
    VersionActions.receiveVersion(this.componentId);
  }

  UNSAFE_componentWillMount() {
    ErrorStore.addErrorListener(this.onError)
    VersionStore.addChangeListener(this.onChangeVersion)
  }

  componentWillUnmount() {
    ErrorStore.removeErrorListener(this.onError)
    VersionStore.removeChangeListener(this.onChangeVersion)
  }

  onLogin() {
    this.redirectToContents()
  }

  redirectToContents() {
    if (SessionStore.getLogged()) {
      this.props.route.history.push({ pathname: 'contents' });
    }
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  onChangeVersion() {
    window.location.reload(true)
  }

  render() {
    let children =
      <div className='Login' ref={this.container}>
        <LoginCard handleLogin={this.onLogin} />
        <footer>
          <div className='text-center small copyright signal34-footer'>
            <div>
              <span>© 2019 Signal34.com</span> | <a target='_blank' href='./about'>About</a> | <Link to={'/'}>Home</Link> | <Link to={'/signup'}>Sign Up</Link> | <a target='_blank' href='./howto'>HowTo</a> | <a target='_blank' href='./personal_terms_of_service'>Terms of Service</a> | <a target='_blank' href='./privacy_policy'>Privacy policy</a>
            </div>
          </div>
        </footer>
      </div>

    return children
  }
}