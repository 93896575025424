import React, { Component } from 'react'

class ErrorComponent extends Component {
  render() {
    return (
      <div/>
    )
  }
}

export default ErrorComponent