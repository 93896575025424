import React, { Component } from 'react'
import SessionStore from '../stores/SessionStore'
import { Dropdown, Modal, Button, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router'
import SessionActions from '../actions/SessionActions';
import FaceAPI from '../apis/FaceAPI'
import Search from './Search'
import PublicSearch from './PublicSearch'
import FilterActions from '../actions/FilterActions'
import ChangePassword from './ChangePassword'
import ContentAddComponent from './ContentAdd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import ContentStore from '../stores/ContentStore'
import UserStore from '../stores/UserStore'
import UserActions from '../actions/UserActions'
import NotificationSystem from 'react-notification-system'
import SettingActions from '../actions/SettingActions'
import * as MenuCode from '../constants/MenuConstants'
import FilterStore from '../stores/FilterStore'
import VersionStore from '../stores/VersionStore'
import VersionActions from '../actions/VersionActions'
import LoginDialog from '../containers/LoginDialog'
import ContentActions from '../actions/ContentActions'
import ShareDialog from './ShareDialog'
import PermalinkDialog from './PermalinkDialog'
import EmbedDialog from './EmbedDialog'
import FollowSearch from './FollowSearch'
import TagStore from '../stores/TagStore'
import TagActions from '../actions/TagActions'
import PlanActions from '../actions/PlanActions'
import SubscriptionActions from '../actions/SubscriptionActions'
import SettingOfflineStore from '../stores/SettingOfflineStore'
import SettingStore from '../stores/SettingStore'
import FiltersBuilder from '../utils/FiltersBuilder'
import ErrorStore from '../stores/ErrorStore'
import * as ErrorCode from '../constants/ErrorCodesConstants'
import Signal34Logo from '../images/logo.png'
import Signal34LogoDark from '../images/logo_dark.png'

function download(content, fileName, contentType) {
  var blob = new Blob([JSON.stringify(content)], {
    type: contentType
  });
  var element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', window.URL.createObjectURL(blob));
  element.setAttribute('download', fileName);
  element.style.display = '';
  element.click();

  document.body.removeChild(element);
}

const DARK_MODE_LABEL = 'Dark Mode'
const LIGHT_MODE_LABEL = 'Light Mode'
const MESSAGE_PASTE_LINK = 'paste the link when the new tab is opened'
const MESSAGE_NO_VALID_URL = 'no valid url found opening in edit mode...'
const MESSAGE_COPIED = 'Copied'

class AppComponent extends Component {
  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
    this.onLogout = this.onLogout.bind(this)
    this.logoutFacebook = this.logoutFacebook.bind(this)
    this.logoutGoogle = this.logoutGoogle.bind(this)
    this.toContents = this.toContents.bind(this)
    this.toAllContents = this.toAllContents.bind(this)
    this.toTags = this.toTags.bind(this)
    this.toWithoutTags = this.toWithoutTags.bind(this)
    this.toManageSubscription = this.toManageSubscription.bind(this)
    this.editDuplicated = this.editDuplicated.bind(this)
    this.onExportContent = this.onExportContent.bind(this)
    this.onState = this.onState.bind(this);
    this.notificationSystem = React.createRef();
    this.handleStorage = this.handleStorage.bind(this);
    this.handleClickLogo = this.handleClickLogo.bind(this);
    this.handleDropdownItem = this.handleDropdownItem.bind(this);
    this.setNotSavedEditingFunction = this.setNotSavedEditingFunction.bind(this);
    this.stayClicked = this.stayClicked.bind(this)
    this.leaveClicked = this.leaveClicked.bind(this)
    this.setOnYesConfirmFunction = this.setOnYesConfirmFunction.bind(this)
    this.setResetEdit = this.setResetEdit.bind(this)
    this.onChangeVersion = this.onChangeVersion.bind(this)
    this.openAccountLink = this.openAccountLink.bind(this)
    this.componentId = 'app';
    this.state = {
      userAccountName: '',
      accounts: '',
      changePasswordShow: false,
      contentAddShow: false,
      contentEditShow: false,
      content_id_to_edit: '',
      clearEdit: true,
      isNotVerified: false,
      state: '',
      showConfirm: false,
      notSavedFunction: null,
      onYesConfirmFunction: null,
      callResetEditFunction: null,
      menukey: -1,
      showSignIn: false,
      content_id_to_my_account: '',
      content_id_to_navigate: '',
      addToMyAccountCallBack: null,
      shareShow: false,
      embedShow: false,
      permalinkShow: false,
      account_id_to_follow: 0,
      navigationMore: [
        { 'name': 'My Untagged', 'key': MenuCode.MENU_KEY_UNTAGGED, 'link': '/untagged' },
        { 'name': 'Manage Tags', 'key': MenuCode.MENU_KEY_TAGS, 'link': 'tags' },
        { 'name': 'Manage Subscription', 'key': MenuCode.MENU_KEY_SUBSCRIPTION, 'link': '/subscription' },
        { 'name': 'Settings...', 'key': MenuCode.MENU_KEY_SETTINGS, 'link': '' }
      ],
      navigationMenu: {
        'main': [
          { 'name': 'Account', 'key': MenuCode.MENU_KEY_CONTENTS, 'link': '/contents' },
          { 'name': 'Subscriptions', 'key': MenuCode.MENU_KEY_FOLLOW, 'link': '/network' },
          { 'name': 'Explore', 'key': MenuCode.MENU_KEY_ALL_CONTENTS, 'link': '/explore' }
        ],
        'active': null,
        'more': []
      },
      activeKey: '',
      selectedKey_after_login: '',
      selectedName_after_login: '',
      isDarkMode: false,
      toggleDarkLabel: DARK_MODE_LABEL,
      isLoaded: true
    }
    this.handleAddContent = this.handleAddContent.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleOpenLoginDialog = this.handleOpenLoginDialog.bind(this)
    this.handleCloseLoginDialog = this.handleCloseLoginDialog.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.switchMenu = this.switchMenu.bind(this)
    this.callLogInDialog = this.callLogInDialog.bind(this)
    this.addDialogVisible = this.addDialogVisible.bind(this)
    this.populateAndShow = this.populateAndShow.bind(this)
    this.addRef = React.createRef();
    this.onLogin = this.onLogin.bind(this)
    this.shareDialog = this.shareDialog.bind(this)
    this.permalinkDialog = this.permalinkDialog.bind(this)
    this.onCopyLink = this.onCopyLink.bind(this)
    this.shareRef = React.createRef()
    this.embedDialog = this.embedDialog.bind(this)
    this.embedRef = React.createRef()
    this.permalinkRef = React.createRef()
    this.getUserState = this.getUserState.bind(this)
    this.navigationSelect = this.navigationSelect.bind(this)
    this.getNavigationMenu = this.getNavigationMenu.bind(this)
    this.setNavigationDefault = this.setNavigationDefault.bind(this)
    this.notURLNotification = this.notURLNotification.bind(this)
    this.pasteNotification = this.pasteNotification.bind(this)
    this.onRemovePasteNotification = this.onRemovePasteNotification.bind(this)
    this.publicSearchRef = React.createRef();
    this.toggleDarkMode = this.toggleDarkMode.bind(this)
    this.changeDarkMode = this.changeDarkMode.bind(this)
    this.onChangeDarkMode = this.onChangeDarkMode.bind(this)
    this.onReceiveDarkMode = this.onReceiveDarkMode.bind(this)
    this.onError = this.onError.bind(this)
    this._ismounted = false
  }

  UNSAFE_componentWillMount() {
    this._ismounted = true;
    SessionStore.addLogoutListener(this.onLogout)
    ContentStore.addExportListener(this.onExportContent)
    UserStore.addStateListener(this.onState)
    window.addEventListener('storage', this.handleStorage)
    VersionStore.addChangeListener(this.onChangeVersion)
    SessionStore.addLoginListener(this.onLogin)
    var navigationMenu = this.state.navigationMenu
    navigationMenu.more = this.state.navigationMore
    this.setState({ navigationMenu: navigationMenu })
    SettingStore.addChangeDarkModeListener(this.onChangeDarkMode)
    SettingStore.addReceiveDarkModeListener(this.onReceiveDarkMode)
    var pathName = this.props.location.pathname
    if (pathName.indexOf('/contents/') >= 0 && !SessionStore.getLogged()) {
      var pathNameArray = pathName.split('/');
      var aName = pathNameArray.pop();
      this.setState({
        isLoaded: false,
        selectedKey_after_login: MenuCode.MENU_KEY_ACCOUNT_NAME,
        selectedName_after_login: aName
      });
      this.handleOpenLoginDialog()
    }
    if (pathName.split('/')[1] && pathName.split('/')[1].length == 12) {
      var aName = pathName.split('/')[1];
      ContentActions.isPermalinkPublic(this.componentId, aName)
      this.setState({
        isLoaded: false,
        selectedKey_after_login: MenuCode.MENU_KEY_PERMALINK,
        selectedName_after_login: aName,
        activeKey: MenuCode.MENU_KEY_PERMALINK,
        navigationMenu: this.getNavigationMenu(MenuCode.MENU_KEY_PERMALINK, aName)
      });
    }
    if (pathName.includes('-') && pathName.split('-')[1].length == 12) {
      var aName = pathName.split('/')[1];
      this.setState({
        isLoaded: false,
        selectedKey_after_login: MenuCode.MENU_SHARED_LINK,
        selectedName_after_login: aName,
        activeKey: MenuCode.MENU_SHARED_LINK,
        navigationMenu: this.getNavigationMenu(MenuCode.MENU_SHARED_LINK, aName)
      });
    }
    else {
      ErrorStore.addErrorListener(this.onError);
      var navigationMenu = this.state.navigationMenu
      navigationMenu.more = this.state.navigationMore
      this.setState({ navigationMenu: navigationMenu })
      this.setNavigationDefault()
      SettingStore.addChangeDarkModeListener(this.onChangeDarkMode)
      SettingStore.addReceiveDarkModeListener(this.onReceiveDarkMode)
      if (SessionStore.getLogged()) {
        setTimeout(() => SettingActions.receiveDarkModeSettings(this.componentId), 500);
      } else {
        this.setState({ isLoaded: false });
      }
    }
  }

  getUserState() {
    if (SessionStore.getLogged() && SessionStore.getSessionUserName()) {
      UserActions.state('app', SessionStore.getSessionUserName().toString());
    }
  }

  handleStorage() {
    if (SessionStore.isSessionRemoved()) {
      window.location.href = '/';
    } else {
      if (SessionStore.getLogged()) {
        this.changeDarkMode(SessionStore.getDarkMode())
      }
    }
  }

  switchMenu(menukey) {
    if (menukey == MenuCode.MENU_KEY_CONTENTS) {
      this.toContents();
    } else if (menukey == MenuCode.MENU_KEY_UNTAGGED) {
      this.toWithoutTags();
    } else if (menukey == MenuCode.MENU_KEY_ALL_CONTENTS) {
      this.toAllContents();
    } else if (menukey == MenuCode.MENU_KEY_TAGS) {
      this.toTags();
    } else if (menukey == MenuCode.MENU_KEY_SUBSCRIPTION) {
      this.toManageSubscription();
    } else if (menukey == MenuCode.MENU_KEY_FOLLOW) {
      this.toFollow();
    }
  }

  handleDropdownItem = (event) => {
    event.preventDefault()
    UserActions.isLocked(this.componentId, SessionStore.getSessionUserName().toString());
    var menuselected = event.target.getAttribute('menukey');
    if (menuselected !== undefined) {
      var menukey = Number(menuselected)
      var notSaved = this.state.notSavedFunction !== null ? this.state.notSavedFunction() : false
      if (notSaved === true) {
        this.setState({
          menukey: menukey,
          showConfirm: true
        })
      }
      else {
        this.switchMenu(menukey)
        this.setState({
          activeKey: menukey,
          navigationMenu: this.getNavigationMenu(menukey)
        })
      }
    }
  }

  handleAddContent() {
    UserActions.isLocked(this.componentId, SessionStore.getSessionUserName().toString());
    this.setState({
      contentAddShow: true
    });
  }

  handleChangePassword() {
    UserActions.isLocked(this.componentId, SessionStore.getSessionUserName().toString());
    this.setState({ changePasswordShow: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.darkMode !== prevState.darkMode) {
      if (this.state.darkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }

  componentWillUnmount() {
    SessionStore.removeLogoutListener(this.onLogout)
    ContentStore.removeExportListener(this.onExportContent)
    UserStore.removeStateListener(this.onState)
    window.removeEventListener('storage', this.handleStorage)
    VersionStore.removeChangeListener(this.onChangeVersion)
    SessionStore.removeLoginListener(this.onLogin)
    SettingStore.removeChangeDarkModeListener(this.onChangeDarkMode)
    SettingStore.removeReceiveDarkModeListener(this.onReceiveDarkMode)
    ErrorStore.removeErrorListener(this.onError);
    this._ismounted = false;
  }

  onLogout() {
    if (SessionStore.getSessionProvider() === 'facebook') { this.logoutFacebook(); }
    if (SessionStore.getSessionProvider() === 'google') { this.logoutGoogle(); }
    SessionStore.cleanData();
  }

  onState() {
    var state = UserStore.getState();
    const notification = this.notificationSystem.current;

    if (this.props.location.pathname == '/contents' || this.props.location.pathname == 'contents') {
      if (state === 'not_verified') {
        notification.addNotification({
          title: 'Please verify your account',
          message: 'Please check the message we sent to your email to verify your signal34 account.',
          level: 'warning',
          position: 'tr'
        });
      } else if (state === 'trial') {
        var timeStatus = UserStore.getTimeStatus()
        if (timeStatus <= 5) {
          notification.addNotification({
            title: 'Trial',
            message: 'Your trial expires in ' + timeStatus.toString() + ' days ',
            level: 'warning',
            position: 'tr',
            children: (
              <form action="/subscription">
                Click<button type="submit" className="btn btn-default no-lr-padding">here</button>to subscribe.
              </form>
            )
          });
        }
      } else if (state === 'locked') {
        notification.addNotification({
          title: 'Your trial period expired',
          message: '',
          level: 'error',
          position: 'tr',
          children: (
            <form action="/subscription">
              Click<button type="submit" className="btn btn-default no-lr-padding">here</button>to subscribe.
            </form>
          )
        });
      }
    }
  }

  logout() {
    if (SessionStore.getLogged()) {
      SessionActions.deleteSession(this.componentId);
    }
    SessionStore.cleanData();
  }

  logoutFacebook() {
    FaceAPI.init()
      .then(
        FaceAPI.checkLoginState,
        error => { throw error; }
      )
      .then(
        FaceAPI.logout,
        () => { console.log('logout Facebook, status: unknown'); }
      )
      .then(
        () => { console.log('logout Facebook, loading:false, status: unknown'); },
        error => { throw error; }
      )
      .catch(error => {
        console.warn(error);
      });
  }

  logoutGoogle() {
    if (window.gapi !== undefined) {
      let auth2 = window.gapi.auth2.getAuthInstance()
      auth2.signOut().then(function () { });
    }
  }

  clearFilters() {
    FilterActions.clear();
  }

  toContents() {
    VersionActions.receiveVersion();
    this.getUserState();
    this.props.route.history.push({ pathname: 'contents' });
  }

  toAllContents() {
    VersionActions.receiveVersion();
    if (this.publicSearchRef.current != null) {
      this.publicSearchRef.current.loadDefaultOptions('');
    }
    this.props.route.history.push({ pathname: 'explore' });
  }

  toWithoutTags() {
    VersionActions.receiveVersion();
    this.props.route.history.push({ pathname: 'untagged' });
  }

  toTags() {
    VersionActions.receiveVersion();
    this.props.route.history.push({ pathname: 'tags' });
  }

  toManageSubscription() {
    VersionActions.receiveVersion();
    this.props.route.history.push({ pathname: 'subscription' });
  }

  toFollow() {
    VersionActions.receiveVersion();
    this.props.route.history.push({ pathname: 'network' });
  }

  toContentsAccount() {
    VersionActions.receiveVersion();
    var accountName = this.state.userAccountName
    this.props.route.history.push({
      pathname: 'contents/' + accountName
    });
    this.setState({
      activeKey: MenuCode.MENU_KEY_ACCOUNT_NAME,
      navigationMenu: this.getNavigationMenu(MenuCode.MENU_KEY_ACCOUNT_NAME, accountName)
    })
  }

  onExportContent() {
    var contents = ContentStore.getContentsToExport();
    download(contents, 'account_data.json', 'text/json;charset=utf-8')
  }

  editDuplicated(content_id_to_edit) {
    this.setState({
      contentAddShow: false
    }, () => {
      this.state.onYesConfirmFunction(content_id_to_edit)
    })
  }

  setNotSavedEditingFunction(_notSavedFunction) {
    this.setState({
      notSavedFunction: _notSavedFunction
    })
  }

  setOnYesConfirmFunction(_onYesConfirmFunction) {
    this.setState({
      onYesConfirmFunction: _onYesConfirmFunction
    })
  }

  setResetEdit(_callResetEdit) {
    this.setState({
      callResetEditFunction: _callResetEdit
    })
  }

  handleClickLogo(e) {
    e.preventDefault()
    // check if there are not filters
    var emptyFilters =
      (FilterStore.getTag().length == 0) &&
      (FilterStore.getNotTag().length == 0) &&
      (FilterStore.getText() == '') &&
      ((FilterStore.getFields() == undefined ||
        FilterStore.getFields().length == 0) ||
        (Object.keys(FilterStore.getFields()).length === 0 &&
          FilterStore.getFields().constructor === Object)) &&
      (FilterStore.getDate() == '')
    // user logged
    if (SessionStore.getLogged()) {
      // check if there is a content does not saved
      var notSaved = this.state.notSavedFunction !== null ? this.state.notSavedFunction() : false
      if (notSaved === false) {
        UserActions.isLocked(this.componentId, SessionStore.getSessionUserName().toString());
        if (emptyFilters) {
          // account, follows, explore and untagged
          if (this.props.location.pathname == 'contents' ||
            this.props.location.pathname == 'network' ||
            this.props.location.pathname == '/' ||
            this.props.location.pathname == '/explore' ||
            this.props.location.pathname == 'untagged') {
            FilterActions.clear();
            SettingActions.receiveSettings(this.componentId);
            FilterActions.filter_text('', {})
          }
          // tab tags
          else if (this.props.location.pathname == '/tags' ||
            this.props.location.pathname == 'tags') {
            var page = 1;
            var size = TagStore.getSizePerPage();
            FilterActions.searchbar_setup('', true);
            TagActions.recieveTags(this.componentId, page, size);
          }
          // tab manage subscription
          else if (this.props.location.pathname == 'subscription') {
            PlanActions.receivePlans(this.componentId + '_first')
            SubscriptionActions.getSubscription(this.componentId + '_first', SessionStore.getSessionUserName().toString())
            UserActions.getStatistics(this.componentId + '_first', SessionStore.getSessionUserName().toString())
          }
        }
        else {
          this.clearFilters();
        }
      }
      else {
        this.setState({
          showConfirm: true
        })
      }
    }
    // user not logged
    else {
      if (emptyFilters) {
        // explore
        if (this.props.location.pathname == '/') {
          var pageNumber = 1;
          var perPage = SettingOfflineStore.getResultPerPage();
          var query = '';
          var filterQuery = '';
          var sort = FiltersBuilder.createSortQuery(SettingOfflineStore.getSortBy(), SettingOfflineStore.getSortOrder());
          ContentActions.receivePublicContents(this.componentId, pageNumber, perPage, query, filterQuery, sort);
        }
      }
      else {
        this.clearFilters();
      }
    }
  }
  setNotSavedEditingFunction(_notSavedFunction) {
    this.setState({
      notSavedFunction: _notSavedFunction
    })
  }

  leaveClicked() {
    this.setState({
      showConfirm: false,
      notSavedFunction: null
    }, () => {
      if (this.state.menukey == -1) {
        this.state.callResetEditFunction()
        this.clearFilters();
        this.toContents();
        SettingActions.receiveSettings();
      }
      else {
        var selectedKey = this.state.menukey;
        this.setState({
          activeKey: selectedKey,
          navigationMenu: this.getNavigationMenu(selectedKey)
        })
        if (this.state.menukey == MenuCode.MENU_KEY_CONTENTS) {
          this.toContents();
        } else if (this.state.menukey == MenuCode.MENU_KEY_UNTAGGED) {
          this.toWithoutTags();
        } else if (this.state.menukey == MenuCode.MENU_KEY_TAGS) {
          this.toTags();
        } else if (this.state.menukey == MenuCode.MENU_KEY_SUBSCRIPTION) {
          this.toManageSubscription();
        } else if (this.state.menukey == MenuCode.MENU_KEY_ALL_CONTENTS) {
          this.toAllContents();
        } else if (this.state.menukey == MenuCode.MENU_KEY_FOLLOW) {
          this.toFollow();
        } else if (this.state.menukey == MenuCode.MENU_KEY_ACCOUNT_NAME) {
          this.toContentsAccount();
        }

        this.setState({
          menukey: -1
        })
      }
    })
  }

  stayClicked() {
    this.setState({
      menukey: -1,
      showConfirm: false
    })
  }

  onChangeVersion() {
    window.location.reload(true)
  }

  handleOpenLoginDialog() {
    this.setState({ showSignIn: true })
  }

  handleCloseLoginDialog() {
    if (this.props.location.pathname.indexOf('/contents/') >= 0 && !SessionStore.getLogged()) {
      window.location.pathname = '/'
    }
    this.setState({
      showSignIn: false,
      addToMyAccountCallBack: null
    })
  }

  handleLogin() {
    this.setState({
      showSignIn: false
    })
  }

  onLogin() {
    const content_id_to_navigate = this.state.content_id_to_navigate
    const content_id_to_my_account = this.state.content_id_to_my_account
    const account_id_to_follow = this.state.account_id_to_follow
    const selectedKey_after_login = this.state.selectedKey_after_login
    const selectedName_after_login = this.state.selectedName_after_login
    if (SessionStore.getLogged()) {
      setTimeout(() => SettingActions.receiveDarkModeSettings(this.componentId), 500);
      SettingActions.receiveSettings(this.componentId);
      if (content_id_to_navigate != '') {
        this.setState({
          content_id_to_navigate: ''
        }, () => {
          setTimeout(() => ContentActions.navigateToSaveOn(content_id_to_navigate), 500);
        })
      }
      else if (content_id_to_my_account != '') {
        this.setState({
          content_id_to_my_account: ''
        }, () => {
          setTimeout(() => ContentActions.addToMyAccountOn(content_id_to_my_account), 500);
        })
      }
      else if (account_id_to_follow > 0) {
        this.setState({
          account_id_to_follow: 0
        }, () => {
          setTimeout(() => ContentActions.addToMyNetwork('onLogin', parseInt(account_id_to_follow)), 500);
        })
      }
      else if (selectedKey_after_login != '') {
        setTimeout(() => {
          var accountName = null
          this.switchMenu(selectedKey_after_login)
          if (selectedKey_after_login == MenuCode.MENU_KEY_FOLLOW) {
            this.props.route.history.push({
              pathname: '/network'
            });
            this.setState({
              selectedName_after_login: '',
              activeKey: selectedKey_after_login,
              navigationMenu: this.getNavigationMenu(selectedKey_after_login)
            })
          } else if (selectedKey_after_login == MenuCode.MENU_KEY_CONTENTS) {
            this.props.route.history.push({
              pathname: '/contents'
            });
            this.setState({
              selectedName_after_login: '',
              activeKey: selectedKey_after_login,
              navigationMenu: this.getNavigationMenu(selectedKey_after_login)
            })
          } else if (selectedKey_after_login == MenuCode.MENU_KEY_PERMALINK) {
            accountName = selectedName_after_login
            this.props.route.history.push({
              pathname: '/' + accountName
            });
          } else if (selectedKey_after_login == MenuCode.MENU_SHARED_LINK) {
            accountName = selectedName_after_login
            this.props.route.history.push({
              pathname: '/' + accountName
            });
          } else {
            this.setState({
              activeKey: selectedKey_after_login,
              navigationMenu: this.getNavigationMenu(selectedKey_after_login, accountName)
            })
          }
        }, 500);
      }
      else {
        if (this.props.location.pathname = '/') {
          this.props.route.history.push({
            pathname: '/contents'
          });
          this.setState({
            activeKey: MenuCode.MENU_KEY_CONTENTS,
            navigationMenu: this.getNavigationMenu(MenuCode.MENU_KEY_CONTENTS)
          })
        }
      }
    }
  }

  callLogInDialog(content_id, source) {
    this.setState({
      showSignIn: true
    }, () => {
      if (source == 'addtomyaccount') {
        this.setState({
          content_id_to_my_account: content_id
        })
      }
      else if (source == 'savetonavigate') {
        this.setState({
          content_id_to_navigate: content_id
        })
      }
      else if (source == 'accounttofollow') {
        this.setState({
          account_id_to_follow: content_id
        })
      }
      else if (source == 'explore') {
        this.setState({
          content_id_to_navigate: ''
        })
      }
    })
  }

  addDialogVisible(visibleFlag) {
    this.setState({
      contentAddShow: visibleFlag
    })
  }

  populateAndShow(content) {
    this.addRef.current.populateDialog(content)
    this.setState({
      contentAddShow: true
    })
  }

  shareDialog(content) {
    this.shareRef.current && this.shareRef.current.clear()
    this.setState({
      shareShow: true
    }, () => {
      var host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
      this.shareRef.current && this.shareRef.current.setShareLink(content.content_title, host + '/' + content.slug)
    })
  }

  permalinkDialog(content) {
    this.permalinkRef.current && this.permalinkRef.current.clear()
    this.setState({
      permalinkShow: true
    }, () => {
      var host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
      this.permalinkRef.current && this.permalinkRef.current.setPermalink(content.content_title, host + '/' + content.permalink)
    })
  }

  onCopyLink() {
    const notification = this.notificationSystem.current;
    this.setState({
      shareShow: false,
      permalinkShow: false
    }, () => {
      notification.addNotification({
        message: MESSAGE_COPIED,
        level: 'success',
        position: 'bc',
        autoDismiss: 1
      });
    });
  }

  embedDialog(content) {
    this.embedRef.current && this.embedRef.current.clear()
    this.setState({
      embedShow: true
    }, () => {
      this.embedRef.current && this.embedRef.current.setContentLink(content.content_title, content.slug)
    })
  }

  getNavigationMenu(selectedKey, accountName = null) {
    var navigationMenu = this.state.navigationMenu
    var navigationMenuMore = [];
    navigationMenu.active = null;
    if (accountName) {
      var accountItem = { 'name': accountName, 'key': MenuCode.MENU_KEY_ACCOUNT_NAME, 'link': '/contents/' + accountName }
      navigationMenu.active = accountItem;
    }
    if (selectedKey == MenuCode.MENU_KEY_PERMALINK) {
      var permalinkItem = { 'name': 'Permalink', 'key': MenuCode.MENU_KEY_PERMALINK, 'link': '/' + accountName }
      navigationMenu.active = permalinkItem;
    } else {
    } if (selectedKey == MenuCode.MENU_SHARED_LINK) {
      var sharedLinkItem = { 'name': 'Shared', 'key': MenuCode.MENU_SHARED_LINK, 'link': '/' + accountName }
      navigationMenu.active = sharedLinkItem;
    }
    else {
      this.state.navigationMore.forEach(function (item) {
        if (item.key == selectedKey && item.key != MenuCode.MENU_KEY_SETTINGS) {
          navigationMenu.active = item;
        } else {
          navigationMenuMore.push(item);
        }
      });
      navigationMenu.more = navigationMenuMore;
    }
    return navigationMenu;
  }

  setNavigationDefault() {
    var pathName = window.location.pathname
    var selectedKey = MenuCode.MENU_KEY_ALL_CONTENTS
    var accountName = null
    if (pathName == '/network') {
      selectedKey = MenuCode.MENU_KEY_FOLLOW
    } else if (pathName == '/contents') {
      selectedKey = MenuCode.MENU_KEY_CONTENTS
    } else if (pathName == '/untagged') {
      selectedKey = MenuCode.MENU_KEY_UNTAGGED
    } else if (pathName == '/tags') {
      selectedKey = MenuCode.MENU_KEY_TAGS
    } else if (pathName == '/subscription') {
      selectedKey = MenuCode.MENU_KEY_SUBSCRIPTION
    } else if (pathName.indexOf('/contents/') >= 0) {
      selectedKey = MenuCode.MENU_KEY_ACCOUNT_NAME
      var pathNameArray = pathName.split('/');
      accountName = pathNameArray.pop();
    } else if (pathName.split('/')[1].length == 12) {
      selectedKey = MenuCode.MENU_KEY_PERMALINK
      accountName = pathName.split('/')[1];
    }
    else if (pathName.split('/')[1] && pathName.split('/')[1].includes('-')) {
      selectedKey = MenuCode.MENU_SHARED_LINK
      accountName = pathName.split('/')[1];
    }
    if (pathName == '/' && SessionStore.getLogged()) {
      this.props.route.history.push({ pathname: 'contents' });
      var selectedKey = MenuCode.MENU_KEY_CONTENTS
      this.setState({
        activeKey: selectedKey,
        navigationMenu: this.getNavigationMenu(selectedKey, accountName)
      })
    } else {
      this.setState({
        activeKey: selectedKey,
        navigationMenu: this.getNavigationMenu(selectedKey, accountName)
      })
    }
  }

  navigationSelect(selectedKey, event) {
    event.preventDefault()
    var link = event.target.href
    var notSaved = this.state.notSavedFunction !== null ? this.state.notSavedFunction() : false
    if (selectedKey == MenuCode.MENU_KEY_SETTINGS) {
      SettingActions.showDialogSettings();
    } else if (selectedKey == MenuCode.MENU_KEY_ALL_CONTENTS) {
      if (notSaved === true) {
        this.setState({
          menukey: selectedKey,
          showConfirm: true
        })
      } else {
        this.switchMenu(selectedKey)
        this.setState({
          activeKey: selectedKey,
          navigationMenu: this.getNavigationMenu(selectedKey),
          selectedKey_after_login: ''
        })
      }
    } else if (selectedKey == MenuCode.MENU_KEY_ACCOUNT_NAME && link.toString().indexOf('/contents/') >= 0) {
      var accountName = null
      var pathNameArray = link.split('/');
      accountName = pathNameArray.pop();
      this.switchMenu(selectedKey)
      this.setState({
        activeKey: selectedKey,
        navigationMenu: this.getNavigationMenu(selectedKey, accountName)
      })
    } else if (selectedKey == MenuCode.MENU_KEY_PERMALINK || selectedKey == MenuCode.MENU_SHARED_LINK) {
      var accountName = window.location.pathname.split('/')[1]
      this.switchMenu(selectedKey)
      this.setState({
        activeKey: selectedKey,
        navigationMenu: this.getNavigationMenu(selectedKey, accountName)
      })
    } else if (SessionStore.getLogged()) {
      if (notSaved === true) {
        this.setState({
          menukey: selectedKey,
          showConfirm: true
        })
      } else {
        this.switchMenu(selectedKey)
        this.setState({
          activeKey: selectedKey,
          navigationMenu: this.getNavigationMenu(selectedKey)
        })
      }
    } else {
      this.setState({
        selectedKey_after_login: selectedKey
      }, () => {
        this.handleOpenLoginDialog()
      })
    }
  }

  notURLNotification() {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: 'Information',
      message: MESSAGE_NO_VALID_URL,
      level: 'info',
      position: 'tc'
    });
  }

  openAccountLink(accountName) {
    this.setState({
      userAccountName: accountName
    })
    if (SessionStore.getLogged()) {
      var notSaved = this.state.notSavedFunction !== null ? this.state.notSavedFunction() : false
      if (notSaved === false) {
        this.props.route.history.push({
          pathname: 'contents/' + accountName
        });
        if (this.publicSearchRef.current != null) {
          this.publicSearchRef.current.loadDefaultOptions(window.location.pathname.split('/')[2]);
        }
        this.setState({
          activeKey: MenuCode.MENU_KEY_ACCOUNT_NAME,
          navigationMenu: this.getNavigationMenu(MenuCode.MENU_KEY_ACCOUNT_NAME, accountName)
        })
      }
      else {
        this.setState({
          showConfirm: true,
          menukey: MenuCode.MENU_KEY_ACCOUNT_NAME
        })
      }
    } else {
      this.setState({
        selectedKey_after_login: MenuCode.MENU_KEY_ACCOUNT_NAME,
        selectedName_after_login: accountName
      }, () => {
        this.handleOpenLoginDialog()
      })
    }
  }

  pasteNotification() {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: 'Information',
      message: MESSAGE_PASTE_LINK,
      level: 'info',
      position: 'tc',
      autoDismiss: 2,
      onRemove: this.onRemovePasteNotification
    });
  }

  onRemovePasteNotification() {
    window.open('', '_blank').focus();
  }

  changeDarkMode(isDarkMode) {
    var label = isDarkMode ? LIGHT_MODE_LABEL : DARK_MODE_LABEL
    this.setState({ isDarkMode: isDarkMode, toggleDarkLabel: label })
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }

  toggleDarkMode() {
    var darkMode = !this.state.isDarkMode;
    SettingActions.updateDarkModeSettings(this.componentId, darkMode);
  }

  onChangeDarkMode() {
    var dark_mode = SettingStore.getDarkMode();
    this.changeDarkMode(dark_mode);
    SessionStore.setDarkMode(dark_mode);
  }

  onReceiveDarkMode() {
    if (this._ismounted) {
      var dark_mode = SettingStore.getDarkMode();
      this.changeDarkMode(dark_mode);
      SessionStore.setDarkMode(dark_mode);
      this.setState({ isLoaded: false })
    }
  }

  onError(source, error) {
    if (source == 'app' && error.code == ErrorCode.UNAUTHORIZED) {
      this.setState({ isLoaded: false })
    }
  }

  render() {
    var ispublicpage = window.location.pathname == '/' || window.location.pathname == '/explore'
    var isfollowpage = window.location.pathname == '/network'
    var ispublicpageaccount = window.location.pathname == '/content'
    var ispublicpagecontentaccount = (window.location.pathname.includes(this.state.userAccountName) && this.state.userAccountName !== '') || (window.location.pathname.split('/')[2] != null)
    let changePasswordClose = () => this.setState({ changePasswordShow: false });
    let contentAddClose = () => this.setState({ contentAddShow: false });
    let shareClose = () => this.setState({ shareShow: false })
    let embedClose = () => this.setState({ embedShow: false })
    let permalinkClose = () => this.setState({ permalinkShow: false })
    const { navigationMenu, activeKey, isDarkMode, toggleDarkLabel, isLoaded } = this.state;
    let addElement = <ContentAddComponent
      show={this.state.contentAddShow}
      onHide={contentAddClose}
      calledit={this.editDuplicated}
      ref={this.addRef}
    />;
    let navigationColor;
    if (isDarkMode) {
      navigationColor = 'white'
    } else {
      navigationColor = 'black'
    }
    let navigation =
      <div className="signal34-navigation">
        <Nav variant="pills" activeKey={activeKey} onSelect={this.navigationSelect}>
          {navigationMenu.main.map(item => (
            <Nav.Item key={item.key} > <Nav.Link href={item.link} eventKey={item.key}>{item.name}</Nav.Link> </Nav.Item>
          ))}
          {navigationMenu.active != null && <Nav.Item> <Nav.Link href={navigationMenu.active.link} eventKey={navigationMenu.active.key}>{navigationMenu.active.name}</Nav.Link> </Nav.Item>}
          <NavDropdown title={
            <span className="signal34-navigation-menu">
              <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill={navigationColor}>
                </path>
              </svg>More
            </span>
          } id="nav-dropdown" className="signal34-navigation-more">
            {navigationMenu.more.map(item => (
              <NavDropdown.Item key={item.key} href={item.link} eventKey={item.key}>{item.name}</NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav >
      </div>
    let notification = <NotificationSystem ref={this.notificationSystem} />;
    let footer =
      <div className="text-center small copyright signal34-footer">
        <div>
          © 2019 Signal34.com | <a href="/about">About</a> | <Link to={'/'}>Home</Link> | <Link to={'/signup'}>Sign Up</Link> | <a href="/howto">HowTo</a> | <a href="/personal_terms_of_service">Terms of Service</a> | <a href="/privacy_policy">Privacy Policy</a>
        </div>
      </div>
    let shareComponent = <ShareDialog
      show={this.state.shareShow}
      onHide={shareClose}
      ref={this.shareRef}
      onCopyLink={this.onCopyLink}
      onClickShareLink={this.onClickShareLink}
    />
    let permalinkComponent = <PermalinkDialog
      show={this.state.permalinkShow}
      onHide={permalinkClose}
      ref={this.permalinkRef}
      onCopyLink={this.onCopyLink}
      onClickPermalink={this.onClickPermalink}
    />
    let searchbar
    if (ispublicpage || ispublicpageaccount || ispublicpagecontentaccount) {
      searchbar = <PublicSearch ref={this.publicSearchRef} />
    }
    else if (isfollowpage) {
      searchbar = <FollowSearch />
    }
    else {
      searchbar = <Search />
    }
    let embedComponent = <EmbedDialog
      show={this.state.embedShow}
      onHide={embedClose}
      ref={this.embedRef}
      onClickOK={embedClose}
    />
    let dropdownIconThemeMode
    let logoImage
    if (isDarkMode) {
      dropdownIconThemeMode = <FontAwesomeIcon icon={faSun} style={{ 'color': 'rgb(255, 236, 56)', 'backgroundColor': 'transparent', 'paddingLeft': '8px' }} size='lg' />
      logoImage = <a href='#' className='signal34-logo-link' onClick={this.handleClickLogo}><img className='logo' src={Signal34LogoDark} /></a>
    }
    else {
      dropdownIconThemeMode = <FontAwesomeIcon icon={faMoon} style={{ 'color': 'rgb(85, 155, 226)', 'backgroundColor': 'transparent', 'paddingLeft': '8px' }} size='lg' />
      logoImage = <a href='#' className='signal34-logo-link' onClick={this.handleClickLogo}><img className='logo' src={Signal34Logo} /></a>
    }
    let render;
    if (SessionStore.getLogged()) {
      render =
        <div>
          <div className="signal34-header" id="header">
            <div>
              {logoImage}
            </div>
            <div className="signal34-searchbar">
              {searchbar}
            </div>
            <div>
              <Dropdown id="actions-menu" className="actions-menu" align="end">
                <Dropdown.Toggle id="actions-menu-toggle" bsPrefix="btn-action-menu" variant="secondary">
                  <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey={2.1} menukey={MenuCode.MENU_KEY_CONTENTS} href="/contents" onClick={this.handleDropdownItem}>Account</Dropdown.Item>
                  <Dropdown.Item eventKey={2.2} menukey={MenuCode.MENU_KEY_UNTAGGED} href="/untagged" onClick={this.handleDropdownItem}>My Untagged</Dropdown.Item>
                  <Dropdown.Item eventKey={2.3} menukey={MenuCode.MENU_KEY_FOLLOW} href="/network" onClick={this.handleDropdownItem}>Subscriptions</Dropdown.Item>
                  <Dropdown.Item eventKey={2.4} menukey={MenuCode.MENU_KEY_ALL_CONTENTS} href={'/'} onClick={this.handleDropdownItem}>Explore</Dropdown.Item>
                  <Dropdown.Item eventKey={2.5} menukey={MenuCode.MENU_KEY_TAGS} href="/tags" onClick={this.handleDropdownItem}>Manage Tags</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey={2.6} onClick={this.handleAddContent}>Add Content</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey={2.7} menukey={MenuCode.MENU_KEY_SUBSCRIPTION} href="/subscription" onClick={this.handleDropdownItem}>Manage Subscription</Dropdown.Item>
                  <Dropdown.Item eventKey={2.8} onClick={this.handleChangePassword}>Change Password</Dropdown.Item>
                  <Dropdown.Item eventKey={2.9} onClick={this.toggleDarkMode}>
                    {toggleDarkLabel}
                    {dropdownIconThemeMode}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey={2.10} onClick={this.logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <ChangePassword show={this.state.changePasswordShow} onHide={changePasswordClose} />
            {addElement}
          </div>
          <div className="signal34-container">
            {notification}
            {navigation}
            {React.cloneElement(this.props.children, {
              permalinkDialog: this.permalinkDialog,
              setNotSavedEditingFunction: this.setNotSavedEditingFunction,
              setOnYesConfirmFunction: this.setOnYesConfirmFunction,
              setResetEdit: this.setResetEdit,
              callLogInDialog: this.callLogInDialog,
              addDialogVisible: this.addDialogVisible,
              populateAndShow: this.populateAndShow,
              shareDialog: this.shareDialog,
              embedDialog: this.embedDialog,
              notURLNotification: this.notURLNotification,
              openAccountLink: this.openAccountLink,
              pasteNotification: this.pasteNotification,
              setNavigationDefault: this.setNavigationDefault
            })}
          </div>
          {footer}
          <Modal show={this.state.showConfirm}>
            <Modal.Body>
              <p>This page is asking you to confirm that you want to leave - data you have entered may not be saved.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.stayClicked}>Stay on Page</Button>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.leaveClicked}>Leave Page</Button>
            </Modal.Footer>
          </Modal>
          {shareComponent}
          {embedComponent}
          {permalinkComponent}
        </div>
    } else {
      render =
        <div>
          <div className="signal34-header" id="header">
            <div>
              <a href='#' className='signal34-logo-link' onClick={this.handleClickLogo}><img className='logo' src={Signal34Logo} /></a>
            </div>
            <div className="signal34-searchbar">
              {searchbar}
            </div>
            <div>
              <Button variant='primary' className='signal34-bnt-sign-in' onClick={this.handleOpenLoginDialog}>Sign In</Button>
            </div>
          </div>
          <div className="signal34-container">
            {notification}
            {navigation}
            {React.cloneElement(this.props.children, {
              setNotSavedEditingFunction: this.setNotSavedEditingFunction,
              setOnYesConfirmFunction: this.setOnYesConfirmFunction,
              setResetEdit: this.setResetEdit,
              callLogInDialog: this.callLogInDialog,
              addDialogVisible: this.addDialogVisible,
              populateAndShow: this.populateAndShow,
              shareDialog: this.shareDialog,
              embedDialog: this.embedDialog,
              permalinkDialog: this.permalinkDialog,
              notURLNotification: this.notURLNotification,
              openAccountLink: this.openAccountLink,
              pasteNotification: this.pasteNotification,
              setNavigationDefault: this.setNavigationDefault
            })}
          </div>
          {footer}
          <LoginDialog
            show={this.state.showSignIn}
            handleClose={this.handleCloseLoginDialog}
            handleLogin={this.handleLogin}
          />
          {shareComponent}
          {embedComponent}
          {permalinkComponent}
        </div>
    }
    return !isLoaded ? render : null;
  }
}

export default AppComponent
