import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

class ConfirmDialogYesNo extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault()
    this.props.onYesConfirm && this.props.onYesConfirm();
    return false
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onNoConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleFormSubmit}>
          <Modal.Body>
            <p>{this.props.message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="signal34-bnt-default" variant="outline-secondary" type="button" onClick={this.props.onNoConfirm}>{this.props.noText}</Button>
            <Button data-testid="tag-merge-confirm-id" className='signal34-bnt-default' variant='outline-secondary' type='submit' autoFocus={true} tabIndex={0}>{this.props.yesText}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ConfirmDialogYesNo;
