import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import FilterActions from '../actions/FilterActions'
import FilterStore from '../stores/FilterStore'
import Checkbox from './Checkbox'


const OPTIONS = ['Location', 'Title', 'Notes'];

class SearchByTextFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      checkboxes: OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: true
        }),
        {}
      )
    }
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onFilterClear = this.onFilterClear.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.nameInput = React.createRef();
    this.getCheckboxes = this.getCheckboxes.bind(this)
  }

  UNSAFE_componentWillMount() {
    FilterStore.addClearListener(this.onFilterClear);
  }

  componentWillUnmount() {
    FilterStore.removeClearListener(this.onFilterClear);
  }


  componentDidUpdate(prevProps, prevState) {
    prevState === prevState
    if(prevProps.show == false && this.props.show == true){
      this.nameInput.focus()
    }
  }

  onFilterClear() {
    this.setState({
      filter: ''
    });
  }

  handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;

    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }));
  }

  handleFilterChange(event) {
    this.setState({ filter: event.target.value });
  }

  getCheckboxes(){
    var fields = {}
    Object.keys(this.state.checkboxes).forEach(checkbox => {
      if(checkbox === 'Location'){
        fields['Url'] = this.state.checkboxes[checkbox]
      }
      else{
        fields[checkbox] = this.state.checkboxes[checkbox]
      }
    })
    return fields
  }

  okClicked = () => {
    var textValue = this.state.filter;
    var fields = this.getCheckboxes()
    if (textValue !== null && textValue !== '' && (Object.values(fields).every((x) => (x === false)) === false)) {
      FilterActions.filter_text(textValue, fields);
      this.props.onHide();
      this.props.onClick('Text', textValue, fields)
    }
    else {
      this.props.onHide();
    }
  }

  createCheckbox = (option, index) => (
    <div key={'div'+ option} tabIndex={index + 2} className="text-checkbox">
      <Checkbox
        label={option}
        isSelected={this.state.checkboxes[option]}
        onCheckboxChange={this.handleCheckboxChange}
        key={option}
      />
    </div>
  )

  createCheckboxes = () => (
    <div className='checkboxes'>{OPTIONS.map(this.createCheckbox)}</div>
  )

  handleKeyPress(key) {
    if (key.charCode === 13) {
      this.okClicked();
    }
  }

  render() {
    return (
      <Modal
        {...this.props}
        data-testid="search-by-text-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search by text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onKeyPress={this.handleKeyPress}>
            <span>Type the text to search:</span>
            <input type="text" className="form-control" onChange={this.handleFilterChange} value={this.state.filter} autoFocus={true} tabIndex={1} ref={(input) => { this.nameInput = input; }} data-testid='text-to-search-id'/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.createCheckboxes()}
          <Button className ="signal34-bnt-default" variant ="outline-secondary" onClick={this.props.onHide} tabIndex={5}>Cancel</Button>
          <Button className ="signal34-bnt-default" variant ="outline-secondary" onClick={this.okClicked} tabIndex={6}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SearchByTextFilter;
