import keyMirror from 'keymirror';

export default keyMirror({
  RECEIVE_SUBSCRIPTION: null,
  RECEIVE_SUBSCRIPTION_ERROR: null,
  CREATE_SUBSCRIPTION: null,
  CREATE_SUBSCRIPTION_ERROR: null,
  UPDATE_SUBSCRIPTION: null,
  UPDATE_SUBSCRIPTION_ERROR: null,
  DELETE_SUBSCRIPTION: null,
  DELETE_SUBSCRIPTION_ERROR: null,
  CHANGE_SUBSCRIPTION_PAYMENT: null,
  CHANGE_SUBSCRIPTION_AUTO_RENEW: null,
  UPDATE_PAYMENT_METHOD: null,
  UPDATE_PAYMENT_METHOD_ERROR: null,
  SET_AUTO_RENEW: null,
  SET_AUTO_RENEW_ERROR: null,
  UPDATE_ACCOUNT_PROFILE: null,
  UPDATE_ACCOUNT_PROFILE_ERROR: null,
  VALIDATE_ACCOUNT_PROFILE: null,
  VALIDATE_ACCOUNT_PROFILE_ERROR: null
});
