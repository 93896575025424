import keyMirror from 'keymirror';

export default keyMirror({
  CREATE_USER: null,
  CREATE_USER_ERROR: null,
  VALID_EMAIL: null,
  USED_EMAIL: null,
  UPDATE_PASSWORD: null,
  UPDATE_PASSWORD_ERROR: null,
  EMAIL_EXISTS: null,
  EMAIL_NOT_EXISTS: null,
  EMAIL_ERROR: null,
  CONFIRM_EMAIL: null,
  CONFIRM_EMAIL_ERROR: null,
  RESET_PASSWORD: null,
  RESET_PASSWORD_ERROR: null,
  DELETING_USER: null,
  DELETE_USER: null,
  DELETE_USER_ERROR: null,
  EXPORT_USER: null,
  EXPORT_USER_ERROR: null,
  STATISTICS_USER: null,
  STATISTICS_USER_ERROR: null,
  ISLOCKED_USER: null,
  ISLOCKED_USER_ERROR: null,
  STATE_USER: null,
  STATE_USER_ERROR: null,
  CREATE_SOCIAL_USER: null,
  CREATE_SOCIAL_USER_ERROR: null,
  UPDATE_EMAIL: null,
  UPDATE_EMAIL_ERROR: null
});
