import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import Common from '../utils/Common';
/*
  It seems like React has changed the property name in the element in React 17.
  From __reactInternalInstance to __reactFiber.
*/
const keyinstance = '__reactFiber';
const maxLen = Common.MAXLEN_TAG_LABEL;
class TagButton extends Component {
  tagClicked = () => {
    let selects = document.querySelectorAll('.search-elements');
    let select = selects[0];
    let keys = Object.keys(select);
    for (var index = 0; index < keys.length; ++index) {
      if (keys[index].startsWith(keyinstance)) {
        select[keys[index]].return.stateNode.addValue(this.props.tag);
      }
    }
  }

  render() {
    const { tag } = this.props;
    const taglink = window.location.pathname + '?tag=' + encodeURIComponent(tag);
    var tooltip = '';
    var tagtext = tag;
    if (tag.length > maxLen) {
      tooltip = tag;
      tagtext = tag.substring(0, maxLen) + '...';
    }
    return (
      <span>
        <Tooltip content={tooltip} position="top">
          <a href={taglink} onClick={(event) => { event.preventDefault() }}>
            <Button data-tip data-for="registerTip" bsPrefix='btn-tag' className='signal34-tag' size='sm' variant="outline-secondary" disabled={this.props.disabled} onClick={this.tagClicked}>{tagtext}</Button>{' '}
          </a>
        </Tooltip>
      </span>
    );
  }
}

export default TagButton;
