import request from 'superagent/lib/client'
import SessionStore from '../stores/SessionStore'
import HandleException from '../utils/HandleException';
const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const invoicesPath = '/invoices/'

export default {
  getInvoice: (invoice_id) => {
    var params = invoice_id + '/' + SessionStore.getSessionUserName().toString()
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + invoicesPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  emailInvoice: (invoice_number) => {
    var contentBody = { user_id: SessionStore.getSessionUserName().toString(), invoice_number: invoice_number }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + invoicesPath + 'email')
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(contentBody))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}
