import AppDispatcher from '../dispatcher/AppDispatcher';
import VersionConstants from '../constants/VersionConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const CHANGE_EVENT = 'get_version';
const CLIENT_INSTANCE_VERSION='0a93c328-3bbf-4e6e-b020-74ac3abecd6e'

class VersionStoreClass extends EventEmitter {

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }
}

const VersionStore = new VersionStoreClass();

VersionStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case VersionConstants.RECEIVE_VERSION:
      if(action.version != CLIENT_INSTANCE_VERSION){
        VersionStore.emitChange();
      }
      break;

    case VersionConstants.RECEIVE_VERSION_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }

});

export default VersionStore;
