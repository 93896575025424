import ContentActions from '../actions/ContentActions';
import ContentStore from '../stores/ContentStore';
import(/* webpackPreload: true */ '../styles/App.css');
import React, { Component } from 'react';
import TagSelect from './TagSelect';
import Common from '../utils/Common';
import { Modal, Button, Alert, Image } from 'react-bootstrap'
import Ratings from './RatingAPILayer'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import ConfirmDialogYesNo from './ConfirmDialogYesNo'
import Checkbox from './Checkbox'
import(/* webpackPreload: true */ '../styles/open-iconic-bootstrap.css');

const OPTIONS = ['public', 'share'];
class ContentAddComponent extends Component {
  constructor(props) {
    super(props);
    this.maxLengthNotes = Common.MAXLEN_NOTES
    this.maxLength = Common.MAXLEN_LOCATION
    this.state = {
      tags_inputValue: '',
      tags_value: [],
      showConfirm: false,
      showCancelConfirm: false,
      duplicated_id: '',
      duplicated_content_id: '',
      content_data: '',
      content_title: '',
      content_rating: 0,
      content_tags: [],
      content_notes: '',
      characters_notes: this.maxLengthNotes,
      validationMessage: {},
      duplicateListenerEnabled: false,
      isFocusInMenuList: false,
      checkboxes: OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: false
        }),
        {}
      )
    }

    this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
    this.onDuplicatedSubmit = this.onDuplicatedSubmit.bind(this);
    this.onNoConfirm = this.onNoConfirm.bind(this)
    this.onYesConfirm = this.onYesConfirm.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.close = this.close.bind(this)
    this.cancelClicked = this.cancelClicked.bind(this)
    this.saveClicked = this.saveClicked.bind(this)
    this.handleContentDataChange = this.handleContentDataChange.bind(this)
    this.handleContentTitleChange = this.handleContentTitleChange.bind(this)
    this.handleRatingChange = this.handleRatingChange.bind(this)
    this.handleResetRating = this.handleResetRating.bind(this)
    this.handleSelectTagChange = this.handleSelectTagChange.bind(this)
    this.handleInputTagChange = this.handleInputTagChange.bind(this)
    this.handleKeyDownTag = this.handleKeyDownTag.bind(this)
    this.handleNoOptionsMessage = this.handleNoOptionsMessage.bind(this)
    this.handleNotesChange = this.handleNotesChange.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.onError = this.onError.bind(this)
    this.cleanThingsUp = this.cleanThingsUp.bind(this)
    this.componentId = 'contentadd'
    this.onShow = this.onShow.bind(this)
    this.contentref = React.createRef()
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.getCheckboxes = this.getCheckboxes.bind(this)
    this.populateDialog = this.populateDialog.bind(this)
    this.handleEsc = this.handleEsc.bind(this)
    this.stayClicked = this.stayClicked.bind(this);
    this.leaveClicked = this.leaveClicked.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tags_inputValue !== prevState.tags_inputValue) {
      this.setState({
        isFocusInMenuList: false
      });
    }
  }

  UNSAFE_componentWillMount() {
    ContentStore.addConfirmListener(this.onConfirmSubmit);
    ContentStore.addDuplicatedListener(this.onDuplicatedSubmit);
    ErrorStore.addErrorListener(this.onError);
    document.addEventListener('keydown', this.handleEsc);
  }

  componentWillUnmount() {
    ContentStore.removeConfirmListener(this.onConfirmSubmit);
    ContentStore.removeDuplicatedListener(this.onDuplicatedSubmit);
    ErrorStore.removeErrorListener(this.onError);
    document.removeEventListener('keydown', this.handleEsc);
  }

  onConfirmSubmit() {
    this.clearForm()
    this.props.onHide()
  }

  handleEsc(e) {
    if (e.key == 'Escape') {
      var notSaved = this.state.content_data != '' ||
        this.maxLengthNotes - this.state.characters_notes > 0 ||
        this.state.content_tags.length > 0 || this.state.checkboxes.public == true ||
        this.state.checkboxes.share == true || this.state.content_rating > 0 ||
        this.state.content_title != '';
      if (notSaved == true) {
        this.setState({
          showCancelConfirm: true
        })
      }
    }
  }

  stayClicked() {
    this.setState({
      showCancelConfirm: false
    })
  }
  leaveClicked() {
    this.clearForm()
    this.props.onHide()
    this.setState({
      showCancelConfirm: false
    })
  }
  onDuplicatedSubmit(duplicated_id, duplicated_content_id, source) {
    if (source === 'send') {
      this.setState({
        showConfirm: true,
        duplicated_content_id: duplicated_content_id,
        duplicated_id: duplicated_id
      });
    }
  }

  onYesConfirm() {
    this.setState({
      showConfirm: false
    }, () => {
      this.clearForm()
      this.props.calledit && this.props.calledit(this.state.duplicated_id)
    })
  }

  onNoConfirm() {
    this.setState({
      showConfirm: false,
      duplicateListenerEnabled: false,
      duplicated_id: '',
      duplicated_content_id: ''
    })
  }

  handleContentDataChange(e) {
    this.setState({ content_data: e.target.value })
  }

  handleContentTitleChange(e) {
    this.setState({ content_title: e.target.value })
  }


  handleRatingChange(rate) {
    this.setState({
      content_rating: rate
    })
  }

  handleResetRating() {
    this.setState({
      content_rating: 0
    })
  }

  handleSelectTagChange = (value) => {
    this.setState({ tags_value: (value == null) ? [] : value }, function () {
      this.setState({
        content_tags: Common.buildList(this.state.tags_value)
      })
    })
  }

  handleInputTagChange = (inputValue) => {
    this.setState({ tags_inputValue: inputValue });
  }

  handleKeyDownTag = (event) => {
    const { tags_value, tags_inputValue, isFocusInMenuList } = this.state;
    if (!tags_inputValue) return;
    switch (event.key) {
      case 'ArrowUp':
        if (!isFocusInMenuList) {
          this.setState({
            isFocusInMenuList: true
          });
        }
        break;

      case 'ArrowDown':
        if (!isFocusInMenuList) {
          this.setState({
            isFocusInMenuList: true
          });
        }
        break;

      case 'Enter':
      case 'Tab':
        if (!isFocusInMenuList) {
          event.preventDefault();
          if (!Common.checkExist(tags_value, tags_inputValue)) {
            event.preventDefault();
            this.setState({
              tags_inputValue: '',
              tags_value: [...this.state.tags_value, Common.createOption(tags_inputValue)]
            }, function () {
              this.setState({
                content_tags: Common.buildList(this.state.tags_value)
              })
            });
          }
        }
    }
  }

  handleNoOptionsMessage = () => {
    setTimeout(() => { this.setState({ isFocusInMenuList: false }) }, 1);
    return 'New tag: ' + this.state.tags_inputValue
  }

  handleNotesChange(e) {
    var length = e.target.value.length
    var maxlength = e.target.maxLength
    var remainCharacters = maxlength - length
    this.setState({ content_notes: e.target.value, characters_notes: remainCharacters })
  }

  setMessage(field, msg) {
    let val = this.state.validationMessage;
    val[field] = msg
    this.setState({ validationMessage: val })
  }

  validateForm() {
    const { content_data } = this.state;
    if (content_data === '' || content_data.trim() == '') {
      this.setMessage('content_data', 'Content Location is required.')
    }
    return (content_data.length > 0 && content_data.trim().length > 0)
  }

  clearForm() {
    this.setState({
      tags_inputValue: '',
      tags_value: [],
      content_data: '',
      content_title: '',
      content_rating: 0,
      content_tags: [],
      content_notes: '',
      characters_notes: this.maxLengthNotes,
      validationMessage: {},
      duplicateListenerEnabled: false,
      checkboxes: OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: false
        }),
        {}
      )
    })
  }

  close() {
    this.clearForm()
  }

  cancelClicked() {
    var notSaved = this.state.content_data != '' ||
      this.maxLengthNotes - this.state.characters_notes > 0 ||
      this.state.content_tags.length > 0 || this.state.checkboxes.public == true ||
      this.state.checkboxes.share == true || this.state.content_rating > 0 ||
      this.state.content_title != '';
    if (notSaved == true) {
      this.setState({
        showCancelConfirm: true
      })
    }
    else {
      this.clearForm()
      this.props.onHide()
    }
  }

  saveClicked() {
    if (this.validateForm()) {
      var commands = this.getCheckboxes()
      var values = {}
      const { content_data, content_title, content_rating, content_tags, content_notes } = this.state
      var tags = content_tags.reduce((a, o) => (o != process.env.TAG_KEY_ONE && o != process.env.TAG_KEY_TWO && a.push(o), a), [])
      values['content_data'] = content_data
      if (content_title === '' || content_title === '') {
        values['content_title'] = content_data
      }
      else {
        values['content_title'] = content_title
      }
      values['content_rating'] = Common.start2score(content_rating);

      values['tags'] = tags
      values['notes'] = content_notes

      values['public'] = commands['public']
      values['share'] = commands['share']
      this.setState({ duplicateListenerEnabled: true });
      ContentActions.sendContent(this.componentId, values);
    }
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.saveClicked()
    }
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error)
      this.cleanThingsUp()
    }
  }

  cleanThingsUp() {
    this.setState({
      duplicateListenerEnabled: false,
      duplicated_id: '',
      duplicated_content_id: ''
    });
  }

  onShow() {
    setTimeout(() => { this.contentref && this.contentref.current && this.contentref.current.focus() }, 1);
  }

  handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }));
  }

  getCheckboxes() {
    var fields = {}
    Object.keys(this.state.checkboxes).forEach(checkbox => {
      fields[checkbox] = this.state.checkboxes[checkbox]
    })
    return fields
  }

  populateDialog(content) {
    var notes_value = '';
    var isPublic = false;
    var isShare = false;
    if (!(content.local_properties.notes === null || content.local_properties.notes[0] === null || content.local_properties.notes[0] === '')) {
      notes_value = content.local_properties.notes[0]
    }
    var tags_value = [];
    if (typeof content.tags !== 'undefined' && content.tags.length > 0) {
      var tags = content.tags.reduce((a, o) => (o != process.env.TAG_KEY_ONE && o != process.env.TAG_KEY_TWO && a.push(o), a), [])
      tags.forEach(x => tags_value.push({ label: x, value: x }))
    }
    this.setState({
      content_title: content.content_title,
      content_data: content.content_data,
      content_rating: content.content_rating / 2.0,
      tags_value: tags_value,
      content_tags: [...content.tags],
      content_notes: notes_value,
      checkboxes: {
        public: isPublic,
        share: isShare
      }
    })
  }

  render() {
    const { showConfirm, content_data, content_title, content_rating, tags_value, tags_inputValue, content_notes, characters_notes } = this.state
    return (
      <div>
        <ConfirmDialogYesNo
          message={'Content with the same "Location" field already exists would you like to edit that record?'}
          title={'Add Content'}
          onNoConfirm={this.onNoConfirm}
          onYesConfirm={this.onYesConfirm}
          show={showConfirm}
          yesText={'Yes'}
          noText={'No'}
        />
        <Modal show={this.props.show} onHide={this.props.onHide} className="modal-add-content" dialogClassName="modal-90w" backdrop={false} onShow={this.onShow}>
          <Modal.Header closeButton onHide={this.close}>
            <Modal.Title>Add Content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <span className="location-class-left">
                  <label htmlFor="content_data" className="form-label location-class" >Location:</label>
                </span>
                <span className="location-class-right">
                  <Alert variant={'light'} >{this.state.validationMessage['content_data']}</Alert>
                </span>
                <input id="content_data" placeholder="Enter a unique location for your content such as: URL or file path" type="text" value={content_data} onChange={this.handleContentDataChange}
                  className='form-control' tabIndex={0} maxLength={this.maxLength} onKeyPress={this.onKeyPress} ref={this.contentref} />
                <br />
              </div>
              <div>
                <label htmlFor="content_title" className="form-label" >Title:</label>
                <input id="content_title" placeholder="Enter a descriptive title for your content" type="text" value={content_title} onChange={this.handleContentTitleChange}
                  className='form-control' maxLength={this.maxLength} onKeyPress={this.onKeyPress} />
                <br />
              </div>

              <div>
                <label htmlFor="tags" className="form-label tags-label" >Tags:</label><span className="tags-text">Press &lt;Enter&gt; key to add more tags.</span>
                <TagSelect
                  id="tags"
                  value={tags_value}
                  inputValue={tags_inputValue}
                  onChange={this.handleSelectTagChange}
                  onInputChange={this.handleInputTagChange}
                  onKeyDown={this.handleKeyDownTag}
                  noOptionsMessage={this.handleNoOptionsMessage}
                />
                <br />
              </div>
              <div>
                <div>
                  <div className="content-rating-label">
                    <label htmlFor="content_rating" className="form-label rating-label" >Rating:</label>
                  </div>
                  <div className="content-checkbox">
                    <div className="content-checkbox text-checkbox">
                      <Checkbox
                        label={'Share'}
                        name={'share'}
                        isSelected={this.state.checkboxes['share']}
                        onCheckboxChange={this.handleCheckboxChange}
                      />
                    </div>
                    <div className="content-checkbox text-checkbox">
                      <Checkbox
                        label={'Public'}
                        name={'public'}
                        isSelected={this.state.checkboxes['public']}
                        onCheckboxChange={this.handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="rating-box">
                  <Image onClick={this.handleResetRating} src='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 8 8" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"><path fill="silver" d="M4 0C1.79 0 0 1.79 0 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM2.5 1.78L4 3.28l1.5-1.5.72.72L4.72 4l1.5 1.5-.72.72L4 4.72l-1.5 1.5-.72-.72L3.28 4l-1.5-1.5.72-.72z"/></svg>' />
                  <Ratings
                    initialRating={0}
                    fractions={2}
                    stop={5}
                    step={1}
                    emptySymbol={<span className="oi oi-star" style={{ color: 'rgb(203,203,203)', paddingLeft: '2px' }} />}
                    fullSymbol={<span className="oi oi-star" style={{ color: 'rgb(255,192,0)', paddingLeft: '2px' }} />}
                    placeholderSymbol={<span className="oi oi-star" style={{ color: 'rgb(255,192,0)', paddingLeft: '2px' }} />}
                    placeholderRating={content_rating}
                    onChange={this.handleRatingChange}
                  />
                </div>
              </div>

              <div>
                <br />
                <label htmlFor="notes" className="form-label" >Notes:</label>
                <textarea className="form-control" rows="4" id="notes" placeholder="Enter free text such as summary or notes for your content" value={content_notes} onChange={this.handleNotesChange} maxLength={this.maxLengthNotes}></textarea>
                <label className="notes-counter-chars">{characters_notes} characters remaining</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.cancelClicked}>Cancel</Button>
            <Button className="signal34-bnt-default" variant="outline-secondary" disabled={this.state.duplicateListenerEnabled} onClick={this.saveClicked}>OK</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showCancelConfirm}>
          <Modal.Body>
            <p>This page is asking you to confirm that you want to leave - data you have entered may not be saved.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.stayClicked}>Stay on Page</Button>
            <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.leaveClicked}>Leave Page</Button>
          </Modal.Footer>
        </Modal>
      </div >
    )
  }
}

export default ContentAddComponent;
