import React, { Component } from 'react'
import ContentActions from '../actions/ContentActions'
import ContentStore from '../stores/ContentStore'
import ContentAPI from '../apis/ContentsAPI'
import FollowContentItem from './FollowContentItem'
import Pagination from 'react-js-pagination'
import FilterStore from '../stores/FilterStore'
import FilterActions from '../actions/FilterActions'
import { Modal, Button, Container, Row, Col } from 'react-bootstrap'
import FiltersBuilder from '../utils/FiltersBuilder'
import(/* webpackPreload: true */ '../styles/Contents.css');
import SettingOfflineStore from '../stores/SettingOfflineStore'
import SettingStore from '../stores/SettingStore'
import ConfirmDialogYesNo from './ConfirmDialogYesNo'
import ContentConstants from '../constants/ContentConstants'
import ContentEditComponent from './ContentEdit'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import NotificationSystem from 'react-notification-system'
import ContentQueryResult from './ContentQueryResult'
var hash = require('object-hash');

function getContentItem(content, index,
  handler,
  editHandler,
  editingFlag,
  cancelHandler,
  saveHandler,
  resetPageHandler,
  addToMyAccountHandler,
  populateAndShowMethod,
  callLogInDialog,
  navigateRequestHandler,
  saveForNavigateHandler,
  shareContentHandler,
  embedContentHandler,
  network,
  openLinksNewTab,
  notURLNotification,
  openAccountLinkHandler,
  pasteNotification) {
  return (
    <FollowContentItem
      key={content.id}
      content={content}
      callLogInDialog={callLogInDialog}
      addtomyaccounthandler={addToMyAccountHandler}
      populateAndShowMethod={populateAndShowMethod}
      handler={handler}
      index={index}
      edithandler={editHandler}
      editingflag={editingFlag}
      cancel={cancelHandler}
      save={saveHandler}
      resetPage={resetPageHandler}
      navigateRequest={navigateRequestHandler}
      saveForNavigate={saveForNavigateHandler}
      shareContent={shareContentHandler}
      embedContent={embedContentHandler}
      network={network}
      openLinksNewTab={openLinksNewTab}
      ref={'content_' + index}
      notURLNotification={notURLNotification}
      openAccountLink={openAccountLinkHandler}
      pasteNotification={pasteNotification}
    />
  )
}

function getContent(content) {
  if (content.length == 0) {
    return (<Row><Col>There are not any contents</Col></Row>)
  } else { return content }
}

class FollowContentsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contents: [],
      activePage: 1,
      futurePage: -1,
      numFound: 0,
      queryTime: 0,
      partialQueryTime: 0,
      query: '',
      filter_text_query: '',
      started: true,
      sortByName: null,
      sortByDate: null,
      sortBy: props.sortBy,
      sortOrder: props.sortOrder,
      perPage: props.perPage,
      openLinksNewTab: props.openLinksNewTab,
      selectedId: '',
      editing: [],
      showEditConfirm: false,
      duplicated_id: '',
      currentIndex: -1,
      oldValues: {},
      showConfirm: false,
      topDuplicatedEditing: false,
      countAlert: 0,
      retrieveStart: new Date(),
      retrieveTime: new Date(),
      addRenderTime: false,
      addingToMyAccount: false,
      navigateContentRequest: false,
      navigationIndex: -1,
      saveCalledFromNavigate: false,
      network: []
    }
    this.onChange = this.onChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onFilterChanged = this.onFilterChanged.bind(this)
    this.onFilterClear = this.onFilterClear.bind(this)
    this.onReceiveSettings = this.onReceiveSettings.bind(this)
    this.onReceiveSettingsOffline = this.onReceiveSettingsOffline.bind(this)
    this.onConfirmAddSubmit = this.onConfirmAddSubmit.bind(this)
    this.onConfirmUpdateSubmit = this.onConfirmUpdateSubmit.bind(this)
    this.receiveContents = this.receiveContents.bind(this)
    this.setupMenuOptions = this.setupMenuOptions.bind(this)
    this.editRequest = this.editRequest.bind(this)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.onDuplicatedSubmit = this.onDuplicatedSubmit.bind(this)
    this.onNoConfirm = this.onNoConfirm.bind(this)
    this.onYesConfirm = this.onYesConfirm.bind(this)
    this.onUnload = this.onUnload.bind(this)
    this.stayClicked = this.stayClicked.bind(this)
    this.leaveClicked = this.leaveClicked.bind(this)
    this.topDuplicatedSave = this.topDuplicatedSave.bind(this)
    this.topDuplicatedCancel = this.topDuplicatedCancel.bind(this)
    this.topEditComponent = React.createRef()
    this.topEditorDetector = this.topEditorDetector.bind(this)
    this.resetPage = this.resetPage.bind(this)
    this.notSavedEditing = this.notSavedEditing.bind(this)
    this.props.setNotSavedEditingFunction(this.notSavedEditing)
    this.onError = this.onError.bind(this)
    this.componentId = 'followcontentslist'
    this.props.setOnYesConfirmFunction(this.onYesConfirm)
    this.notificationSystem = React.createRef()
    this.queryResultRef = React.createRef()
    this.addToMyAccountClick = this.addToMyAccountClick.bind(this)
    this.resetEdit = this.resetEdit.bind(this)
    this.props.setResetEdit(this.resetEdit)
    this.navigateRequest = this.navigateRequest.bind(this)
    this.saveForNavigate = this.saveForNavigate.bind(this)
    this.navigateToContentRequest = this.navigateToContentRequest.bind(this)
    this.addToMyAccountRequest = this.addToMyAccountRequest.bind(this)
    this.shareContentClick = this.shareContentClick.bind(this)
    this.embedContentClick = this.embedContentClick.bind(this)
    this.onNetworkChange = this.onNetworkChange.bind(this)
    this.isContentEditing = this.isContentEditing.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.setupMenuOptions()
    ContentStore.addFollowContentChangeListener(this.onChange)
    FilterStore.addChangeListener(this.onFilterChanged)
    FilterStore.addClearListener(this.onFilterClear)
    SettingOfflineStore.addReceiveListener(this.onReceiveSettingsOffline)
    SettingStore.addReceiveListener(this.onReceiveSettings)
    ContentStore.addConfirmListener(this.onConfirmAddSubmit)
    ContentStore.addUpdatedListener(this.onConfirmUpdateSubmit)
    ContentStore.addDuplicatedListener(this.onDuplicatedSubmit)
    window.addEventListener('beforeunload', this.onUnload)
    ContentStore.addTopEditorListener(this.topEditorDetector)
    ErrorStore.addErrorListener(this.onError)
    ContentStore.addNavigateToContentRequestListener(this.navigateToContentRequest)
    ContentStore.addAddToMyAccountRequestListener(this.addToMyAccountRequest)
    ContentStore.addNetworkChangeListener(this.onNetworkChange)
  }

  componentWillUnmount() {
    ContentStore.removeFollowContentChangeListener(this.onChange)
    FilterStore.removeChangeListener(this.onFilterChanged)
    FilterStore.removeClearListener(this.onFilterClear)
    SettingOfflineStore.removeReceiveListener(this.onReceiveSettingsOffline)
    SettingStore.removeReceiveListener(this.onReceiveSettings)
    ContentStore.removeConfirmListener(this.onConfirmAddSubmit)
    ContentStore.removeUpdatedListener(this.onConfirmUpdateSubmit)
    ContentStore.removeDuplicatedListener(this.onDuplicatedSubmit)
    window.removeEventListener('beforeunload', this.onUnload)
    ContentStore.removeTopEditorListener(this.topEditorDetector)
    ErrorStore.removeErrorListener(this.onError)
    ContentStore.removeNavigateToContentRequestListener(this.navigateToContentRequest)
    ContentStore.removeAddToMyAccountRequestListener(this.addToMyAccountRequest)
    ContentStore.removeNetworkChangeListener(this.onNetworkChange)
  }

  stopBrowserBack = () => {
    if (window.onpopstate == undefined) {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        if (this.isContentEditing()) {
          this.setState({
            showConfirm: true
          })
          window.history.pushState(null, '', window.location.href);
        } else {
          window.history.go(-1);
        }
      };
    }
  };

  startBrowserBack = (back) => {
    window.onpopstate = undefined;
    if (back) {
      window.history.go(-2);
    } else {
      window.history.back();
    }
  };

  componentDidUpdate() {
    if (this.state.addRenderTime == true) {
      this.setState({
        addRenderTime: false
      }, () => {
        var timeDiff = new Date() - this.state.retrieveTime
        var _queryTime = (parseFloat(this.state.partialQueryTime) + (timeDiff / 1000.0)).toFixed(2)
        this.setState({
          queryTime: _queryTime
        }, () => {
          this.queryResultRef.current.updateValues(this.state.numFound, this.state.queryTime)
        })
      })
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    var result = false
    if ((nextState.contents.length != this.state.contents.length) || (hash(nextState.contents) != hash(this.state.contents))) {
      result = true;
    }
    else if (hash(nextState.editing) != hash(this.state.editing)) {
      return true;
    }
    else if (this.state.showEditConfirm != nextState.showEditConfirm) {
      result = true;
    }
    else if (this.state.showConfirm != nextState.showConfirm) {
      result = true;
    }
    else if (this.state.topDuplicatedEditing != nextState.topDuplicatedEditing) {
      result = true;
    }
    if (result == false && this.state.addRenderTime == true) {
      this.setState({
        addRenderTime: false
      }, () => {
        var timeDiff = new Date() - this.state.retrieveTime
        var _queryTime = (parseFloat(this.state.partialQueryTime) + (timeDiff / 1000.0)).toFixed(2)
        if (this.queryResultRef.current) {
          this.queryResultRef.current.updateValues(this.state.numFound, _queryTime)
        }
      })
    }
    return true;
  }

  setupMenuOptions() {
    var menuOptions = {
      'text': true,
      'type': true,
      'not': true,
      'date': true,
      'tags': true
    }
    FilterActions.filter_setup(menuOptions);
  }

  receiveContents(pageNumber, perPage, query, filterQuery, sort) {
    this.setState({
      retrieveStart: new Date()
    }, () => {
      ContentActions.receiveFollowContents(this.componentId, pageNumber, perPage, query, filterQuery, sort);
    })
  }

  onFilterClear() {
    if (this.state.query.trim().length > 0 ||
      this.state.filter_text_query.trim().length > 0) {
      FilterActions.clear_searchbar();
      this.setState({
        query: '',
        filter_text_query: '',
        activePage: 1,
        cleared: true
      });
    }
  }

  onFilterChanged() {
    var options = FilterStore.getTag();
    var query = FiltersBuilder.createFilterByTags(options);
    var filter_text_query = '';

    // Get filter by date
    var dateFilter = FilterStore.getDate();
    if (dateFilter !== null && dateFilter !== '') {
      var dateFilterTxt = FiltersBuilder.createFilterByDate(query, dateFilter)
      query += dateFilterTxt
    }

    // Get filter by text
    var textFilter = FilterStore.getText();
    var fieldsFilter = FilterStore.getFields();
    if (textFilter !== null && textFilter !== '' && fieldsFilter !== {}) {
      var querytxt = FiltersBuilder.createFilterByText(textFilter, fieldsFilter)
      filter_text_query = querytxt
    }

    // Get data with the filter query
    var sort = this.createSort();
    this.receiveContents(1, this.state.perPage, query, filter_text_query, sort);
    this.setState({
      query: query,
      filter_text_query: filter_text_query,
      activePage: 1
    });
  }

  createSort() {
    const { sortBy, sortOrder } = this.state
    return FiltersBuilder.createSortQuery(sortBy, sortOrder)
  }

  onChange(action) {
    if (action == ContentConstants.RECEIVE_CONTENT) {
      var content = ContentStore.getContent()
      this.stopBrowserBack()
      if (this.state.topDuplicatedEditing === true) {
        var values = {}
        values['id'] = content.id
        values['content_id'] = content.content_id
        values['content_data'] = content.content_data
        values['content_title'] = content.content_title
        values['content_rating'] = content.content_rating
        values['tags'] = content.tags
        if (Array.isArray(content.local_properties.notes)) {
          values['notes'] = content.local_properties.notes[0]
        }
        else {
          values['notes'] = content.local_properties.notes
        }
        this.topEditComponent.current.setValues(values)
        this.setState({
          oldValues: values
        })

        if (this.state.saveCalledFromNavigate == true) {
          this.refs['content_' + this.state.navigationIndex.toString()].closeNavigate()
          this.setState({
            saveCalledFromNavigate: false,
            navigationIndex: -1
          })
        }
      }
      else if (this.state.currentIndex > -1) {
        var values = {}
        values['id'] = content.id
        values['content_id'] = content.content_id
        values['content_data'] = content.content_data
        values['content_title'] = content.content_title
        values['content_rating'] = content.content_rating
        values['tags'] = content.tags
        if (Array.isArray(content.local_properties.notes)) {
          values['notes'] = content.local_properties.notes[0]
        }
        else {
          values['notes'] = content.local_properties.notes
        }
        this.refs['content_' + this.state.currentIndex.toString()].editComponent.current.setValues(values)
        this.setState({
          oldValues: values
        })
      }
      else if (this.state.addingToMyAccount == true) {
        this.props.populateAndShow(content)
        this.setState({
          addingToMyAccount: false
        })
      }
      else if (this.state.navigateContentRequest == true) {
        this.refs['content_' + this.state.navigationIndex.toString()].navigateRef.current.setValues(content)
        var values = {}
        values['id'] = content.id
        values['content_id'] = content.content_id
        values['content_data'] = content.content_data
        values['content_title'] = content.content_title
        values['content_rating'] = content.content_rating
        values['tags'] = content.tags
        if (Array.isArray(content.local_properties.notes)) {
          values['notes'] = content.local_properties.notes[0]
        }
        else {
          values['notes'] = content.local_properties.notes
        }
        this.setState({
          oldValues: values,
          navigateContentRequest: false
        })
      }
    }
    else if (action == ContentConstants.RECEIVE_FOLLOW_CONTENTS) {
      var _contents = ContentStore.getFollowContents();
      var _numFound = ContentStore.getFollowNumFound();
      var _editing = Array(_contents.length).fill(false)
      var _retrieveTime = new Date()
      var timeDiff = _retrieveTime - this.state.retrieveStart
      var _queryTime = (timeDiff / 1000.0).toFixed(2)
      var _network = ContentStore.getNetwork();
      this.setState({
        started: false,
        topDuplicatedEditing: false,
        duplicated_id: '',
        currentIndex: -1,
        oldValues: {}
      })
      this.setState({
        partialQueryTime: _queryTime,
        retrieveTime: _retrieveTime,
        addRenderTime: true
      }, () => {
        this.setState({
          network: _network,
          contents: _contents,
          numFound: _numFound,
          editing: _editing
        })
      })
    }
  }

  onConfirmAddSubmit() {
    if (this.state.saveCalledFromNavigate == true) {
      this.refs['content_' + this.state.navigationIndex.toString()].closeNavigate()
      this.setState({
        saveCalledFromNavigate: false,
        navigationIndex: -1
      })
      var sort = this.createSort()
      this.receiveContents(this.state.activePage, this.state.perPage, this.state.query, this.state.filter_text_query, sort)
    }
  }

  updateContentInList(content, oldIndex) {
    var _contents = [...this.state.contents]
    _contents[oldIndex].id = content.id
    _contents[oldIndex].content_title = content.content_title
    _contents[oldIndex].content_data = content.content_data
    _contents[oldIndex].content_rating = content.content_rating
    _contents[oldIndex].tags = [...content.tags]
    _contents[oldIndex].local_properties.notes = content.local_properties.notes
    _contents[oldIndex].share = content.share
    this.setState({
      contents: _contents,
      currentIndex: -1
    })
  }

  onConfirmUpdateSubmit() {
    var content_updated = ContentStore.getContent();
    var isPublic = content_updated.tags.includes(process.env.TAG_KEY_ONE) ? true : false
    var _editing = Array(this.state.contents.length).fill(false)
    if (this.state.duplicated_id === '') {
      if (!isPublic) {
        var sort = this.createSort()
        this.receiveContents(this.state.activePage, this.state.perPage, this.state.query, this.state.filter_text_query, sort)
      } else {
        this.updateContentInList(content_updated, this.state.currentIndex);
      }
    }
    else {
      const notification = this.notificationSystem.current
      notification.addNotification({
        title: 'Information',
        message: 'Update successful',
        level: 'success',
        position: 'tr'
      })
      this.setState({
        duplicated_id: '',
        content_id: ''
      }, () => {
        var sort = this.createSort()
        this.receiveContents(this.state.activePage, this.state.perPage, this.state.query, this.state.filter_text_query, sort)
      })
    }
    this.setState({
      editing: _editing
    })
  }

  handlePageChange(pageNumber) {
    var _editing = [...this.state.editing]
    var editingFound = false
    var editingIndex = -1
    for (var idx = 0; idx < _editing.length; idx++) {
      if (_editing[idx] === true) {
        editingFound = true
        editingIndex = idx
        break
      }
    }
    if (editingFound === true) {
      var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
    }
    if (editingFound === false) {
      for (var x in this.refs) {
        var x_index = parseInt(x.toString().split('_')[1])
        if (this.refs[x.toString()].getNavigate() === true) {
          editingFound = true
          editingIndex = x_index
          break
        }
      }
      if (editingFound === true) {
        var values = this.refs['content_' + editingIndex].navigateRef.current.getValues()
        if (this.match(this.state.oldValues, values) === true) {
          this.refs['content_' + editingIndex].closeNavigate()
          editingFound = false
        }
      }
    }
    if (editingFound === false) {
      this.setState({
        activePage: pageNumber,
        futurePage: -1
      }, () => {
        var sort = this.createSort()
        this.receiveContents(this.state.activePage, this.state.perPage, this.state.query, this.state.filter_text_query, sort)
      })
    }
    else {
      this.setState({
        futurePage: pageNumber,
        showConfirm: true
      })
    }
  }

  setSettings(sortBy, sortOrder, perPage, openLinksNewTab) {
    if (this.state.sortBy != sortBy ||
      this.state.sortOrder != sortOrder ||
      this.state.perPage != perPage ||
      this.state.openLinksNewTab != openLinksNewTab) {
      this.setState({
        sortBy: sortBy,
        sortOrder: sortOrder,
        perPage: perPage,
        openLinksNewTab: openLinksNewTab,
        activePage: 1
      }, () => {
        var sort = this.createSort()
        this.receiveContents(this.state.activePage, this.state.perPage, this.state.query, this.state.filter_text_query, sort)
      })
    }
  }

  onReceiveSettingsOffline() {
    this.setSettings(
      SettingOfflineStore.getSortBy(),
      SettingOfflineStore.getSortOrder(),
      SettingOfflineStore.getResultPerPage(),
      SettingOfflineStore.getOpenLinksNewTab())
  }

  onReceiveSettings() {
    this.setSettings(
      SettingStore.getSortBy(),
      SettingStore.getSortOrder(),
      SettingStore.getResultPerPage(),
      SettingStore.getOpenLinksNewTab())
  }

  editRequest(index) {
    if (this.state.topDuplicatedEditing === true) {
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
      else {
        this.setState({
          showConfirm: true,
          currentIndex: index
        })
      }
    }
    else {
      var _editing = [...this.state.editing]
      var editingFound = false
      var editingIndex = -1
      for (var idx = 0; idx < _editing.length; idx++) {
        if (_editing[idx] === true) {
          editingFound = true
          editingIndex = idx
          break
        }
      }
      if (editingFound === true) {
        var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
        if (this.match(this.state.oldValues, values) === true) {
          editingFound = false
        }
      }
      if (editingFound === false) {
        _editing = Array(this.state.contents.length).fill(false)
        _editing[index] = true
        this.setState({
          currentIndex: index,
          duplicated_id: ''
        }, () => {
          var content = this.refs['content_' + index.toString()].props.content
          ContentActions.getContent(this.componentId, content.content_id)
          this.setState({
            editing: _editing
          })
        })
      }
      else {
        this.setState({
          currentIndex: index,
          showConfirm: true
        })
      }
    }
  }

  match(original, edited) {
    var originalTags = original.tags
    var publicTag = originalTags.includes(process.env.TAG_KEY_ONE) ? true : false
    var sharedTag = originalTags.includes(process.env.TAG_KEY_TWO) ? true : false
    const index = originalTags.indexOf(process.env.TAG_KEY_ONE);
    if (index > -1) {
      originalTags.splice(index, 1);
    }
    const index2 = originalTags.indexOf(process.env.TAG_KEY_TWO);
    if (index2 > -1) {
      originalTags.splice(index2, 1);
    }
    publicTag = 'public' in original ? original.public : publicTag;
    sharedTag = 'share' in original ? original.share : sharedTag;

    var equal = true

    if (original.id !== edited.id) {
      equal = false
    }

    if (original.content_title !== edited.content_title) {
      equal = false
    }

    if (original.content_data !== edited.content_data) {
      equal = false
    }

    if (original.content_rating !== edited.content_rating) {
      equal = false
    }

    if (JSON.stringify(original.tags) !== JSON.stringify(edited.tags)) {
      equal = false
    }

    if (Array.isArray(edited.notes)) {
      if ((original.notes !== edited.notes[0]) && !(original.notes === null && edited.notes[0] === '')) {
        equal = false
      }
    }
    else {
      if ((original.notes !== edited.notes) && !(original.notes === null && edited.notes === '')) {
        equal = false
      }
    }

    if (publicTag !== edited.public) {
      equal = false
    }

    if (sharedTag !== edited.share) {
      equal = false
    }

    return equal
  }

  save(index, values, change_tags) {
    if (this.match(this.state.contents[index], values)) {
      this.cancel(index)
    }
    else {
      this.setState({
        currentIndex: index,
        changeSearchTags: change_tags
      })
      ContentActions.updateContent(this.componentId, values)
    }
    this.startBrowserBack()
  }

  cancel(index) {
    var _editing = [...this.state.editing]
    _editing[index] = false
    this.setState({
      editing: _editing,
      currentIndex: -1
    })
    this.startBrowserBack()
  }

  saveForNavigate(values) {
    this.setState({
      saveCalledFromNavigate: true
    }, () => {
      ContentActions.sendContent(this.componentId, values)
    })
  }

  onDuplicatedSubmit(duplicated_id, duplicated_content_id, source) {
    if (source === 'put' && this.state.currentIndex !== -1) {
      this.setState({
        showEditConfirm: true,
        duplicated_id: duplicated_id,
        content_id: duplicated_content_id
      });
    }
    else {
      this.setState({
        duplicated_id: duplicated_id,
        content_id: duplicated_content_id
      }, () => {
        ContentActions.getContent(this.componentId, this.state.content_id)
      })
    }
  }

  onYesConfirm() {
    var _editing = Array(this.state.contents.length).fill(false)
    this.setState({
      editing: _editing,
      showEditConfirm: false
    }, () => {
      var duplicatedInCurrentPage = false
      for (var idx = 0; idx < this.state.contents.length; idx++) {
        if (this.state.contents[idx].content_id === this.state.content_id) {
          this.editRequest(idx)
          duplicatedInCurrentPage = true
          break
        }
      }
      if (duplicatedInCurrentPage == false) {
        ContentActions.enableTopEditor(this.state.content_id);
      }
    })
    if (this.state.saveCalledFromNavigate == true) {
      this.refs['content_' + this.state.navigationIndex.toString()].closeNavigate()
      this.setState({
        saveCalledFromNavigate: false,
        navigationIndex: -1
      })
    }
  }

  onNoConfirm() {
    this.setState({
      showEditConfirm: false,
      duplicated_id: '',
      content_id: ''
    })
  }

  isContentEditing() {
    var editingFound = false
    var _editing = [...this.state.editing]
    var editingIndex = -1
    for (var idx = 0; idx < _editing.length; idx++) {
      if (_editing[idx] === true) {
        editingFound = true
        editingIndex = idx
        break
      }
    }
    if (editingFound === true) {
      var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
    }
    if (this.state.topDuplicatedEditing) {
      editingFound = true
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
      }
    }

    if (editingFound === false) {
      for (var x in this.refs) {
        var x_index = parseInt(x.toString().split('_')[1])
        if (this.refs[x.toString()].getNavigate() === true) {
          editingFound = true
          editingIndex = x_index
          break
        }
      }
      if (editingFound === true) {
        var values = this.refs['content_' + editingIndex].navigateRef.current.getValues()
        if (this.match(this.state.oldValues, values) === true) {
          this.refs['content_' + editingIndex].closeNavigate()
          editingFound = false
        }
      }
    }
    return editingFound;
  }

  onUnload(e) {
    var editingFound = this.isContentEditing()
    if (editingFound === true) {
      e.returnValue = 'true'
    }
  }

  leaveClicked() {
    if (this.state.topDuplicatedEditing === true) {
      ContentActions.disableTopEditor();
    }
    if (this.state.futurePage == -1) {
      for (var x in this.refs) {
        if (this.refs[x.toString()].getNavigate() === true) {
          this.refs[x.toString()].closeNavigate()
        }
      }
      var _editing = Array(this.state.contents.length).fill(false)
      this.setState({
        showConfirm: false,
        duplicated_id: '',
        editing: _editing
      }, () => {
        var current = this.refs['content_' + this.state.currentIndex.toString()]
        if (current != undefined) {
          current.openNavigate()
          this.setState({
            navigationIndex: this.state.currentIndex,
            navigateContentRequest: true,
            currentIndex: -1
          }, () => {
            ContentActions.getPublicNthContent(this.componentId, this.state.location, 0)
          })
        }
      })
    }
    else {
      for (var x in this.refs) {
        if (this.refs[x.toString()].getNavigate() === true) {
          this.refs[x.toString()].closeNavigate()
        }
      }
      var _editing = Array(this.state.contents.length).fill(false)
      var backupPage = this.state.futurePage
      this.setState({
        showConfirm: false,
        futurePage: -1,
        editing: _editing
      }, () => {
        this.handlePageChange(backupPage)
      })
    }
    this.startBrowserBack(true);
  }

  stayClicked() {
    this.setState({
      showConfirm: false
    })
  }

  topDuplicatedSave() {
    var values = this.topEditComponent.current.getValues()
    ContentActions.updateContent(this.componentId, values)
    ContentActions.disableTopEditor()
  }

  topDuplicatedCancel() {
    ContentActions.disableTopEditor()
  }

  topEditorDetector() {
    var topEditorState = ContentStore.getTopEditor()
    if (topEditorState === true) {
      this.setState({
        topDuplicatedEditing: true
      }, () => {
        ContentActions.getContent(this.componentId, ContentStore.getContentIdToEdit())
      })
    }
    else {
      this.setState({
        topDuplicatedEditing: false
      })
    }
  }

  resetPage() {
    this.setState({
      activePage: 1,
      futurePage: -1
    })
  }

  notSavedEditing() {
    var notSaved = false
    var editingFound = false
    var editingIndex = -1
    for (var x in this.refs) {
      var x_index = parseInt(x.toString().split('_')[1])
      if (this.refs[x.toString()].getNavigate() === true) {
        editingFound = true
        editingIndex = x_index
        break
      }
    }
    if (editingFound === true) {
      var values = this.refs['content_' + editingIndex].navigateRef.current.getValues()
      if (this.match(this.state.oldValues, values) === false) {
        this.refs['content_' + editingIndex].closeNavigate()
        notSaved = true
      }
    }
    if (this.state.topDuplicatedEditing === true) {
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === false) {
        notSaved = true
      }
    }
    return notSaved
  }

  onError(source, error) {
    if (source == this.componentId && error.code == 0) {
      this.setState({ countAlert: this.state.countAlert + 1 }, () => {
        if (this.state.countAlert == 1) {
          HandleException.handle_error(error);
        } else if (this.state.countAlert == 3) {
          this.setState({ countAlert: 0 });
        }
      })
    } else if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  resetEdit() {
    var _contents = ContentStore.getContents();
    var _numFound = ContentStore.getNumFound();
    var _editing = Array(_contents.length).fill(false)
    this.setState({
      contents: _contents,
      numFound: _numFound,
      editing: _editing,
      topDuplicatedEditing: false
    })
  }

  addToMyAccountClick(index) {
    var content = this.refs['content_' + index.toString()].props.content
    this.setState({
      addingToMyAccount: true
    }, () => {
      ContentActions.getPublicContent(this.componentId, content.content_id)
    })
  }

  navigateRequest(componentId, index, location, nth) {
    var showConfirmFlag = false
    var editingFound = false
    var editingIndex = -1
    if (this.state.topDuplicatedEditing === true) {
      var values = this.topEditComponent.current.getValues()
      if (this.match(this.state.oldValues, values) === true) {
        editingFound = false
        this.setState({
          topDuplicatedEditing: false
        }, () => {
          this.refs['content_' + index.toString()].openNavigate()
          this.setState({
            currentIndex: -1,
            navigationIndex: index,
            navigateContentRequest: true
          }, () => {
            ContentActions.getPublicNthContent(componentId, location, nth)
          })
        })
      }
      else {
        this.setState({
          showConfirm: true,
          currentIndex: index,
          location: location
        })
      }
    }
    else {
      if (this.state.editing.includes(true)) {
        var _editing = [...this.state.editing]
        var editingFound = false
        var editingIndex = -1
        for (var idx = 0; idx < _editing.length; idx++) {
          if (_editing[idx] === true) {
            editingFound = true
            editingIndex = idx
            break
          }
        }
        if (editingFound === true) {
          var values = this.refs['content_' + editingIndex.toString()].editComponent.current.getValues()
          if (this.match(this.state.oldValues, values) === true) {
            editingFound = false
          }
        }
        if (editingFound == false) {
          var _editing = Array(this.state.contents.length).fill(false)
          this.setState({
            editing: _editing,
            duplicated_id: ''
          })
        }
        else {
          showConfirmFlag = true
          this.setState({
            currentIndex: index,
            showConfirm: true,
            location: location
          })
        }
      }
      if (showConfirmFlag == false) {
        for (var x in this.refs) {
          var x_index = parseInt(x.toString().split('_')[1])
          if ((index != x_index) && (this.refs[x.toString()].getNavigate() === true)) {
            editingFound = true
            editingIndex = x_index
            break
          }
        }
        if (editingFound === true) {
          var values = this.refs['content_' + editingIndex].navigateRef.current.getValues()
          if (this.match(this.state.oldValues, values) === true) {
            this.refs['content_' + editingIndex].closeNavigate()
            editingFound = false
          }
        }
        if (editingFound === false) {
          if (this.refs['content_' + index.toString()].getNavigate() === false) {
            this.refs['content_' + index.toString()].openNavigate()
          }
          this.setState({
            currentIndex: -1,
            navigationIndex: index,
            navigateContentRequest: true
          }, () => {
            ContentActions.getPublicNthContent(componentId, location, nth)
          })
        }
        else {
          this.setState({
            currentIndex: index,
            showConfirm: true,
            location: location
          })
        }
      }
    }
  }

  navigateToContentRequest(content_id) {
    for (var x in this.refs) {
      if (content_id === this.refs[x.toString()].props.content.content_id) {
        this.refs[x.toString()].saveOptionClicked()
        break;
      }
    }
    setTimeout(() => ContentActions.navigateToSaveOff(), 100);
  }

  addToMyAccountRequest(content_id) {
    for (var x in this.refs) {
      if (content_id === this.refs[x.toString()].props.content.content_id) {
        this.refs[x.toString()].addToMyAccountClick()
        break;
      }
    }
    setTimeout(() => ContentActions.addToMyAccountOff(), 100);
  }

  embedContentClick(content) {
    ContentAPI.getShareLink(content).then(shareLinkData => {
      if (shareLinkData) {
        this.props.embedDialog && this.props.embedDialog(shareLinkData)
      }
    })
  }

  shareContentClick(content) {
    ContentAPI.getShareLink(content).then(shareLinkData => {
      if (shareLinkData) {
        this.props.shareDialog && this.props.shareDialog(shareLinkData)
      }
    })
  }

  onNetworkChange() {
    var sort = this.createSort()
    this.receiveContents(this.state.activePage, this.state.perPage, this.state.query, this.state.filter_text_query, sort)
    FilterActions.filter_reload_default()
  }

  render() {
    if (this.state.started) {
      return (
        <div></div>
      )
    } else {
      let contentListItems = this.state.contents.map((content, index) => getContentItem(
        content,
        index,
        this.receiveDoubleClick,
        this.editRequest,
        this.state.editing[index],
        this.cancel,
        this.save,
        this.resetPage,
        this.addToMyAccountClick,
        this.props.populateAndShow,
        this.props.callLogInDialog,
        this.navigateRequest,
        this.saveForNavigate,
        this.shareContentClick,
        this.embedContentClick,
        this.state.network,
        this.state.openLinksNewTab,
        this.props.notURLNotification,
        this.props.openAccountLink,
        this.props.pasteNotification))
      return (
        <div className='signal34-contents'>
          <NotificationSystem ref={this.notificationSystem} />
          <ContentQueryResult ref={this.queryResultRef} />
          <div className='signal34-contents-grid'>
            <Container>
              <Row>
                <Col>
                  <ContentEditComponent
                    showedit={this.state.topDuplicatedEditing}
                    ref={this.topEditComponent}
                    save={this.topDuplicatedSave}
                    cancel={this.topDuplicatedCancel}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className='signal34-contents-grid'>
            <Container>
              {getContent(contentListItems)}
            </Container>
          </div>
          <div className='signal34-contents-pagination'>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.perPage}
              totalItemsCount={this.state.numFound}
              onChange={this.handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
          <ConfirmDialogYesNo
            message={'Content with the same "Location" field already exists would you like to edit that record?'}
            title={'Edit Content'}
            onNoConfirm={this.onNoConfirm}
            onYesConfirm={this.onYesConfirm}
            show={this.state.showEditConfirm}
            yesText={'Yes'}
            noText={'No'}
          />
          <Modal show={this.state.showConfirm}>
            <Modal.Body>
              <p>This page is asking you to confirm that you want to leave - data you have entered may not be saved.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.stayClicked}>Stay on Page</Button>
              <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.leaveClicked}>Leave Page</Button>
            </Modal.Footer>
          </Modal>
        </div>
      )
    }
  }
}

export default FollowContentsList
