import request from 'superagent/lib/client'
import SessionStore from '../stores/SessionStore'
import HandleException from '../utils/HandleException'
import Metrics from '../utils/Metrics'

const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const contentsPath = '/contents'
const contentSharePath = '/content/share'
const contentPermalinkPath = '/content/permalink'
const domainPath = '/content/account'
const domainPublicPath = '/content/all'
const domainPublicByAccountPath = '/content'
const showMetrics = process.env.METRICS
const followPath = '/content/network/'
const networkPath = '/network/'
const nthPath = '/contents/group'
const isContentPermalinkPublic = '/content/ispublic/'

export default {
  // Get all the contents from the API.
  getContents: (pageNumber, perPage, query, query_by_text, sort) => {
    var params = '/' + SessionStore.getSessionAccountName().toString() + '/?'
    if (!(typeof pageNumber === 'undefined' && typeof perPage === 'undefined')) {
      params = params + '&page_number=' + pageNumber.toString() + '&per_page=' + perPage.toString();
    }
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '&query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      params = params + '&query_by_text=' + query_by_text;
    }
    if (!(typeof sort === 'undefined' || sort === '')) {
      params = params + '&sort=' + sort
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + domainPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            if (showMetrics == 'true') {
              Metrics.show_metrics(response)
            }
            resolve(JSON.parse(response.text).data)
          }
        })
    })
  },
  // Get a content from the API.
  getContent: (content_id) => {
    var params = contentsPath + '/' + content_id.toString() + '/users/' + SessionStore.getSessionUserName().toString();
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data.content)
          }
        })
    })
  },
  // Get a content from the API.
  getPublicContent: (content_id) => {
    var params = '/' + content_id.toString();
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + domainPublicPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data.content)
          }
        })
    })
  },
  // Get a content from the API.
  getNthContentSameLocation: (location, nth) => {
    var params = { location: location, nth: nth }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + nthPath)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(params))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data.content)
          }
        })
    })
  },
  // Create content.
  createContent: (data) => {
    var tags = []
    if (data.tags) {
      tags = data.tags;
      data.public && tags.indexOf(process.env.TAG_KEY_ONE) === -1 && tags.push(process.env.TAG_KEY_ONE);
      data.share && tags.indexOf(process.env.TAG_KEY_TWO) === -1 && tags.push(process.env.TAG_KEY_TWO);
      data.tags = tags
    }
    var contentBody = { user_id: SessionStore.getSessionUserName().toString(), content_data: data.content_data, content_type: data.content_type, content_title: data.content_title, content_rating: data.content_rating, tags: data.tags, notes: data.notes }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + contentsPath)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(contentBody))
        .end((err, response) => {
          if (err) {
            if (response && response.statusCode == 409) {
              reject({ error: 'Content already exists', id: JSON.parse(response.text).id, content_id: JSON.parse(response.text).content_id, status: response.statusCode });
            }
            else {
              reject(HandleException.formatting_error(err, response))
            }
          } else {
            if (showMetrics == 'true') {
              Metrics.show_metrics(response)
            }
            resolve({ response: 'Ok' })
          }
        })
    });
  },
  // Update content
  putContent: (data) => {
    var tags = []
    if (data.tags) {
      tags = data.tags;
      data.public && tags.indexOf(process.env.TAG_KEY_ONE) === -1 && tags.push(process.env.TAG_KEY_ONE);
      data.share && tags.indexOf(process.env.TAG_KEY_TWO) === -1 && tags.push(process.env.TAG_KEY_TWO);
      data.tags = tags
    }
    return new Promise((resolve, reject) => {
      request
        .put(host + versionPath + contentsPath + '/' + data.id)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(data)
        .end((err, response) => {
          if (err) {
            if (response && response.statusCode == 409) {
              reject({ error: 'Content already exists', id: JSON.parse(response.text).id, content_id: JSON.parse(response.text).content_id, status: response.statusCode });
            }
            else {
              reject(HandleException.formatting_error(err, response))
            }
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  // Delete content.
  deleteContent: (content_id) => {
    return new Promise((resolve, reject) => {
      request
        .delete(host + versionPath + contentsPath + '/' + content_id)
        .withCredentials()
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  // export contents
  exportContents: (query, query_by_text, sort) => {
    var params = '/users/' + SessionStore.getSessionUserName().toString() + '/export';
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '&query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      params = params + '&query_by_text=' + query_by_text;
    }
    if (!(typeof sort === 'undefined' || sort === '')) {
      params = params + '&sort=' + sort
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + contentsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        })
    })
  },
  // Get all the public contents from the API.
  getPublicContents: (pageNumber, perPage, query, query_by_text, sort) => {
    var params = '/?'
    if (!(typeof pageNumber === 'undefined' && typeof perPage === 'undefined')) {
      params = params + '&page_number=' + pageNumber.toString() + '&per_page=' + perPage.toString();
    }
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '&query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      params = params + '&query_by_text=' + query_by_text;
    }
    if (!(typeof sort === 'undefined' || sort === '')) {
      params = params + '&sort=' + sort
    }
    if (SessionStore.getLogged()) {
      params = params + '&account_name=' + btoa(SessionStore.getSessionAccountName())
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + domainPublicPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        })
    })
  },
  getPublicContentsByAccount: (account, pageNumber, perPage, query, query_by_text, sort) => {
    var params = '?{' + encodeURIComponent('+' + account) + '}'
    if (!(typeof pageNumber === 'undefined' && typeof perPage === 'undefined')) {
      params = params + '&page_number=' + pageNumber.toString() + '&per_page=' + perPage.toString();
    }
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '&query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      params = params + '&query_by_text=' + query_by_text;
    }
    if (!(typeof sort === 'undefined' || sort === '')) {
      params = params + '&sort=' + sort
    }
    params = params + '&account_name=' + btoa(SessionStore.getSessionAccountName())
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + domainPublicByAccountPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        })
    })
  },
  getShareLink: (data) => {
    var contentBody = { id: data.id }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + contentSharePath)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(contentBody))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        });
    });
  },
  getContentByShareLink: (slug, count) => {
    var params = slug
    if (!(typeof count === 'undefined')) {
      params = params + '?count=' + count.toString();
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + contentSharePath + '/' + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        });
    });
  },

  //Get permalink
  getPermaLink: (data) => {
    var contentBody = { id: data.content_id }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + contentPermalinkPath)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(contentBody))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        });
    });
  },
  getContentByPermalink: (permalink) => {
    var params = permalink
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + contentPermalinkPath + '/' + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        });
    });
  },
  isPermalinkPublic: (permalink) => {
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + isContentPermalinkPublic + permalink)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        });
    });
  },
  // Get all the public contents from the API.
  getFollowContents: (pageNumber, perPage, query, query_by_text, sort) => {
    var params = '/?'
    if (!(typeof pageNumber === 'undefined' && typeof perPage === 'undefined')) {
      params = params + '&page_number=' + pageNumber.toString() + '&per_page=' + perPage.toString();
    }
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '&query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      params = params + '&query_by_text=' + query_by_text;
    }
    if (!(typeof sort === 'undefined' || sort === '')) {
      params = params + '&sort=' + sort
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + followPath + SessionStore.getSessionAccountName() + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text).data)
          }
        })
    })
  },
  getNetwork: () => {
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + networkPath + btoa(SessionStore.getSessionAccountName()))
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  addToMyNetwork: (account_id) => {
    var params = { account_to_add: account_id }
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + networkPath + btoa(SessionStore.getSessionAccountName()))
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(params))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  removeFromMyNetwork: (account_id) => {
    var params = { account_to_remove: account_id }
    return new Promise((resolve, reject) => {
      request
        .delete(host + versionPath + networkPath + btoa(SessionStore.getSessionAccountName()))
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(params))
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  }
}
