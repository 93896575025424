import AppDispatcher from '../dispatcher/AppDispatcher'
import LoginConstants from '../constants/LoginConstants'


export default {
  googleButtonClicked: () => {
    AppDispatcher.dispatch({
      actionType: LoginConstants.GBUTTON_CLICKED
    })
  },
  googleApiLoaded: () => {
    AppDispatcher.dispatch({
      actionType: LoginConstants.GAPI_INITIALIZED
    })
  }
}
