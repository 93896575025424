import AppDispatcher from '../dispatcher/AppDispatcher';
import FilterConstants from '../constants/FilterConstants';
import { EventEmitter } from 'events';

const CHANGE_EVENT = 'change';
const CLEAR_EVENT = 'clear';
const CLEAR_SEARCH_BAR_EVENT = 'clear_search_bar';
const NOT_TAG_EVENT = 'nottag';
const SETUP_EVENT = 'setup';
const SETTINGS_SEARCH_BAR = 'settings_search_bar';
const RELOAD_DEFAULT_EVENT = 'reload_default';

let _tag = [];
let _no_tag = [];
let _text = '';
let _fields = {};
let _type = '';
let _date = '';

function setTag(tag) {
  _tag = tag;
}

function setNotTag(no_tag) {
  _no_tag = no_tag;
}

function setText(text) {
  _text = text;
}

function setFields(fields) {
  _fields = fields;
}

function setDate(qdate) {
  _date = qdate;
}

function setType(type) {
  _type = type;
}

class FilterStoreClass extends EventEmitter {

  emitChange() {
    setTimeout(() => this.emit(CHANGE_EVENT), 0);
  }

  emitClearSearchBar() {
    setTimeout(() => this.emit(CLEAR_SEARCH_BAR_EVENT), 0);
  }

  emitClear() {
    setTimeout(() => this.emit(CLEAR_EVENT), 0);
  }

  emitNotTag() {
    setTimeout(() => this.emit(NOT_TAG_EVENT));
  }

  emitSetup(options) {
    setTimeout(() => this.emit(SETUP_EVENT, options), 0);
  }

  emitSettingsSearchBar(placeholder, disabled) {
    setTimeout(() => this.emit(SETTINGS_SEARCH_BAR, placeholder, disabled), 0);
  }

  emitReloadDefault() {
    setTimeout(() => this.emit(RELOAD_DEFAULT_EVENT), 0);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  addClearListener(callback) {
    this.on(CLEAR_EVENT, callback)
  }

  removeClearListener(callback) {
    this.removeListener(CLEAR_EVENT, callback)
  }

  addClearSearchBarListener(callback) {
    this.on(CLEAR_SEARCH_BAR_EVENT, callback)
  }

  removeClearSearchBarListener(callback) {
    this.removeListener(CLEAR_SEARCH_BAR_EVENT, callback)
  }

  addAddNotTagListener(callback) {
    this.on(NOT_TAG_EVENT, callback)
  }

  removeAddNotTagListener(callback) {
    this.removeListener(NOT_TAG_EVENT, callback)
  }

  addSetupListener(callback) {
    this.on(SETUP_EVENT, callback)
  }

  removeSetupListener(callback) {
    this.removeListener(SETUP_EVENT, callback)
  }

  addSettingsSearchBar(callback) {
    this.on(SETTINGS_SEARCH_BAR, callback)
  }

  removeSettingsSearchBar(callback) {
    this.removeListener(SETTINGS_SEARCH_BAR, callback)
  }

  addReloadDefaultListener(callback) {
    this.on(RELOAD_DEFAULT_EVENT, callback)
  }

  removeReloadDefaultListener(callback) {
    this.removeListener(RELOAD_DEFAULT_EVENT, callback)
  }

  getTag() {
    return _tag;
  }

  getNotTag() {
    return _no_tag;
  }

  getText() {
    return _text;
  }

  getFields() {
    return _fields;
  }

  getDate() {
    return _date;
  }

  getType() {
    return _type;
  }
}

const FilterStore = new FilterStoreClass();

FilterStore.dispatchToken = AppDispatcher.register(action => {
  switch (action.actionType) {
    case FilterConstants.FILTER_TAG:
      setTag(action.query);
      FilterStore.emitChange();
      break;

    case FilterConstants.FILTER_CLEAR_SEARCH_BAR:
      FilterStore.emitClearSearchBar();
      break;

    case FilterConstants.FILTER_CLEAR:
      setTag([]);
      setNotTag([]);
      setDate('');
      setType('');
      setFields([]);
      setText('');
      FilterStore.emitClear();
      break;

    case FilterConstants.FILTER_NOT_TAG:
      setNotTag(action.tag);
      FilterStore.emitNotTag();
      break;

    case FilterConstants.FILTER_TEXT:
      setText(action.text);
      setFields(action.fields);
      FilterStore.emitChange();
      break;

    case FilterConstants.FILTER_DATE:
      setDate(action.query);
      FilterStore.emitChange();
      break;

    case FilterConstants.FILTER_TYPE:
      setType(action.type);
      FilterStore.emitChange();
      break;

    case FilterConstants.FILTER_SETUP:
      FilterStore.emitSetup(action.options);
      break;

    case FilterConstants.FILTER_REFRESH:
      FilterStore.emitChange();
      break;

    case FilterConstants.FILTER_SETTINGS_SEARCH_BAR:
      FilterStore.emitSettingsSearchBar(action.placeholder, action.disabled);
      break;

    case FilterConstants.FILTER_RELOAD_DEFAULTS:
      FilterStore.emitReloadDefault();
      break;
    default:
  }
});
export default FilterStore;
