import React, { Component } from 'react'
import { Modal, Form, Button, Image, Overlay, Popover } from 'react-bootstrap'
import '../styles/Login.css'
import FaceAPI from '../apis/FaceAPI'
import GoogleLogin from '../containers/GoogleLogin'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import '../styles/Authentication.css'
import SessionAction from '../actions/SessionActions'
import SessionStore from '../stores/SessionStore'

const clientId = process.env.GOOGLE_CLIENTID

class Authentication extends Component {
  constructor(props) {
    super(props)
    this.emailRef = target => this.setState({ emailState: target })
    this.passwordRef = target => this.setState({ passwordState: target })
    this.state = {
      email: '',
      password: '',
      provider: '',
      validationMessage: {}
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.onLoginFacebook = this.onLoginFacebook.bind(this)
    this.onLoginGoogle = this.onLoginGoogle.bind(this)
    this.errorGoogle = this.errorGoogle.bind(this)
    this.onError = this.onError.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onAuthenticate = this.onAuthenticate.bind(this)
    this.container = React.createRef();
    this.componentId = 'authentication'
  }

  componentDidMount() {
    this.checkLoginFacebook()
  }

  UNSAFE_componentWillMount() {
    ErrorStore.addErrorListener(this.onError)
    SessionStore.addAuthenticateListener(this.onAuthenticate)
    this.setState({ email: this.props.email })
  }

  componentWillUnmount() {
    ErrorStore.removeErrorListener(this.onError)
    SessionStore.removeAuthenticateListener(this.onAuthenticate)
  }

  validateForm() {
    if (this.state.email.length === 0) this.setMessage('email')
    if (this.state.password.length === 0) this.setMessage('password')
    return (this.state.email.length > 0 && this.state.password.length > 0)
  }

  setMessage(field) {
    var val = this.state.validationMessage
    val[field] = field + ' is required.'
    this.setState({ validationMessage: val })
  }

  handleChange(event) {
    var val = this.state.validationMessage
    val[event.target.id] = ''
    this.setState({
      [event.target.id]: event.target.value,
      validationMessage: val
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.validateForm()) {
      this.setState({ provider: 'signal34' }, () => {
        var data = { email: this.state.email, password: this.state.password, provider: this.state.provider };
        SessionAction.authenticate(this.componentId, data)
      })
    }
  }

  checkLoginFacebook() {
    FaceAPI.init()
      .then(
        FaceAPI.checkLoginState,
        error => { throw error }
      )
      .then(
        response => (console.log(response.status)),
        error => { throw error }
      )
      .then(
        FaceAPI.fetch,
        error => { throw error }
      )
      .then(
        response => (console.log('connected as ' + response['name'])),
        error => { throw error }
      )
      .catch((error) => {
        console.warn(error)
      })
  }

  onLoginFacebook() {
    FaceAPI.init()
      .then(
        () => { return FaceAPI.login({ scope: 'email' }) },
        error => { throw error }
      )
      .then(
        FaceAPI.fetch,
        error => { throw error }
      )
      .then(
        response => {
          if (!response['email']) {
            FaceAPI.logout()
            throw 'invalid email'
          }
          console.log('connected as ' + response['name'])
          this.setState({ email: response['email'], password: '', provider: 'facebook' }, () => {
            var data = { email: this.state.email, password: this.state.password, provider: this.state.provider };
            SessionAction.authenticate(this.componentId, data)
          })
        },
        error => { throw error }
      )
      .catch((error) => {
        console.warn(error)
      })
  }

  onLoginGoogle(response) {
    this.setState({ email: response['profileObj']['email'], password: '', provider: 'google' }, () => {
      var data = { email: this.state.email, password: this.state.password, provider: this.state.provider };
      SessionAction.authenticate(this.componentId, data)
    })
  }

  errorGoogle(response) {
    console.error(response)
  }

  clearForm() {
    this.setState({
      email: this.props.email,
      password: '',
      validationMessage: {}
    })
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  onClose() {
    this.clearForm()
    this.props.onClose && this.props.onClose(false)
  }

  onAuthenticate() {
    var user_email = SessionStore.getSessionUserEmail().trim().toString();
    var data = SessionStore.getAuthData();
    if (user_email == data.email.trim().toString()) {
      this.clearForm()
      this.props.onClose && this.props.onClose(true)
    } else {
      alert('The user does not have permission to execute the operation.')
    }
  }

  render() {
    return (
      <Modal {...this.props} dialogClassName='signal34-authentication' backdrop={false} onHide={this.onClose} centered>
        <Modal.Header closeButton onClick={this.onClose}>
          <Modal.Title>Authentication required:</Modal.Title>
        </Modal.Header>
        <Modal.Body className='signal34-authentication-body'>
          <div ref={this.container}>
            This operation requires an extra authentication step. Please login in to continue.
            <br />
            <br />
            <div key={'form'} className='signal34-form'>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId='email'>
                  <Form.Control autoFocus type='email' value={this.state.email} onChange={this.handleChange} placeholder='Email' ref={this.emailRef} />
                  <Overlay show={this.state.validationMessage['email'] !== undefined && this.state.validationMessage['email'] !== ''} target={this.state.emailState} placement='right' container={() => this.container.current} containerPadding={20} >
                    <Popover id='popover-email' outOfBoundaries={true}>
                      <Popover.Body>
                        {this.state.validationMessage['email']}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </Form.Group>
                <Form.Group controlId='password'>
                  <Form.Control value={this.state.password} onChange={this.handleChange} type='password' placeholder='Password' ref={this.passwordRef} />
                  <Overlay show={this.state.validationMessage['password'] !== undefined && this.state.validationMessage['password'] !== ''} target={this.state.passwordState} placement='right' container={() => this.container.current} containerPadding={20} >
                    <Popover id='popover-password' >
                      <Popover.Body>
                        {this.state.validationMessage['password']}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </Form.Group>
                <div className='signal34-blue-button'>
                  <Button variant='primary' className='signal34-bnt-primary' type='submit'>Continue</Button>
                </div>
                <div className='signal34-or'>
                  OR
                </div>
                <div>
                  <div className='signal34-authentication-facebook' onClick={this.onLoginFacebook}>
                    <span className="svg-container">
                      <Image src='data:image/svg+xml;utf8,<svg enable-background="new 0 0 128 128" height="24px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><rect fill="%23FFFFFF" height="128" width="128"/></g><path d="M68.877,128V78.188h-17.78V60.425h17.784V44.029c0-16.537,9.764-26.279,24.514-26.279  c7.068,0,12.834,0.756,14.605,0.991v18.573l-11.874-0.005c-8.022,0-9.523,3.979-9.523,9.572v13.544h20.556l-2.904,17.763H86.603V128  H68.877z" fill="%233C579E"/></svg>' />
                    </span>
                    <span align="center">Continue with Facebook</span>
                  </div>
                  <GoogleLogin clientId={clientId} onSuccess={this.onLoginGoogle} onFailure={this.errorGoogle} buttonText='Continue with Google' offline={false} prompt={'select_account'} className={'signal34-authentication-google'} />
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Authentication
