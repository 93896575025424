class MetricsClass {
  show_metrics(response) {
    var data = JSON.parse(response.text).data;
    var header = response.header;
    var nginx_time = 0;
    var controller_time = 0;
    var model_time = 0;
    var solr_time = 0;
    var qtime = 0;
    var solr_write_time = 0;
    var create_document_time = 0;

    if (!data) {
      data = JSON.parse(response.text);
    }

    console.group('Metrics');
    console.log('Query: ' + response.req.url);
    console.group('Server Times');
    if (data['request_id']) {
      console.log('Request ID:' + data['request_id'])
    }

    if (data['QTime'] >= 0) {
      qtime = Number(data['QTime']);
      console.log('Solr query (QTime):' + qtime + ' ms');
    }

    if (data['solr_client_time']) {
      solr_time = Number(data['solr_client_time']);
      console.log('POST HTTP with Rails HTTP user-agent:' + solr_time + ' ms');
    }

    if (data['model_time']) {
      model_time = Number(data['model_time']);
      console.log('Format JSON Response:' + model_time + ' ms');
    }

    if (data['controller_time']) {
      controller_time = Number(data['controller_time']);
      console.log('Rails Puma Server:' + controller_time + ' ms');
    }

    if (header['x-time']) {
      nginx_time = Number(header['x-time']) * 1000;
      console.log('NGINX request:' + nginx_time + ' ms')
    }

    if (data['solrlib_write_time']) {
      solr_write_time = Number(data['solrlib_write_time']);
      console.log('Solr write time:' + solr_write_time + ' ms');
    }

    if (data['create_document_time']) {
      create_document_time = Number(data['create_document_time']);
      console.log('Add content time:' + create_document_time + ' ms');
    }

    console.groupEnd();
    console.group('Client Times');
    if (response.req.url) {
      var url = response.req.url.replace(/ /g, '%20').replace(/"/g, '%22');
      var reqtimes = performance.getEntriesByType('resource').filter(item => item.name.includes(url));
      if (reqtimes && reqtimes.length > 0) {
        var navigationEntries = reqtimes[0]
        // Request + Request Time
        const totalTime = navigationEntries.responseEnd - navigationEntries.requestStart;
        // Response time with cache seek
        const fetchTime = navigationEntries.responseEnd - navigationEntries.fetchStart;
        // Time To First Byte
        const ttfb = navigationEntries.responseStart - navigationEntries.requestStart;
        // Redirect Time
        const redirectTime = navigationEntries.redirectEnd - navigationEntries.redirectStart;
        // Response Time
        const responseTime = navigationEntries.responseEnd - navigationEntries.responseStart
        // DNS Time
        const dnsTime = navigationEntries.domainLookupEnd - navigationEntries.domainLookupStart;
        console.log('Total client connection: ' + Math.round(totalTime) * 100 / 100 + ' ms');
        console.log('Client request: ' + Math.round(ttfb) * 100 / 100 + ' ms');
        console.log('Client response: ' + Math.round(responseTime) * 100 / 100 + ' ms');
        console.log('Duration (responseEnd - startTime): ' + Math.round(navigationEntries.duration) * 100 / 100 + ' ms');
        console.log('DNS time: ' + Math.round(dnsTime) * 100 / 100 + ' ms');
        console.log('Fetch time: ' + Math.round(fetchTime) * 100 / 100 + ' ms');
        console.log('Redirect time: ' + Math.round(redirectTime) * 100 / 100 + ' ms')
      }
    }
    console.groupEnd();
    console.groupEnd();
  }
}

const Metrics = new MetricsClass()

export default Metrics
