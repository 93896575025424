import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap';
import LoginActions from '../actions/LoginActions'
import LoginStore from '../stores/LoginStore'

class GoogleLogin extends Component {
  constructor(props) {
    super(props)
    this.handleSigninSuccess = this.handleSigninSuccess.bind(this)
    this.handleClickButton = this.handleClickButton.bind(this)
    this.googleButtonClicked = this.googleButtonClicked.bind(this)
    this.googleApiLoaded = this.googleApiLoaded.bind(this)
  }

  UNSAFE_componentWillMount() {
    LoginStore.addClickedListener(this.googleButtonClicked)
    LoginStore.addLoadedListener(this.googleApiLoaded)
  }

  componentWillUnmount() {
    LoginStore.removeClickedListener(this.googleButtonClicked)
    LoginStore.removeLoadedListener(this.googleApiLoaded)
  }

  googleButtonClicked() {
    const {
        jsSrc
      } = this.props
    ;((d, s, id, cb) => {
      const element = d.getElementsByTagName(s)[0]
      const fjs = element
      let js = element
      js = d.createElement(s)
      js.id = id
      js.src = jsSrc
      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs)
      } else {
        d.head.appendChild(js)
      }
      js.onload = cb
    })(document, 'script', 'google-login', () => {
      window.gapi.load('auth2', () => {
        LoginActions.googleApiLoaded()
      })
    })
  }

  googleApiLoaded(){
    const {
      isSignedIn,
      onFailure
    } = this.props
    const params = {
      client_id: this.props.clientId,
      cookie_policy: this.props.cookiePolicy,
      login_hint: this.props.loginHint,
      hosted_domain: this.props.hostedDomain,
      fetch_basic_profile: this.props.fetchBasicProfile,
      redirect_uri: this.props.redirectUri,
      discoveryDocs: this.props.discoveryDocs,

      ux_mode: this.props.uxMode,
      scope: this.props.scope,
      access_type: this.props.accessType
    }
    if (!window.gapi.auth2.getAuthInstance()) {
      window.gapi.auth2.init(params).then(
        res => {
          if (isSignedIn && res.isSignedIn.get()) {
            this.handleSigninSuccess(res.currentUser.get())
          }
        },
        err => onFailure(err)
      )
    }
    const autoLoad = true

    if (autoLoad) {
      const auth2 = window.gapi.auth2.getAuthInstance()
      const { onSuccess, onRequest, onFailure, prompt, responseType } = this.props
      const options = {
        prompt
      }
      onRequest()
      if (responseType === 'code') {
        auth2.grantOfflineAccess(options).then(res => onSuccess(res), err => onFailure(err))
      } else {
        auth2.signIn(options).then(res => this.handleSigninSuccess(res), err => onFailure(err))
      }
    }
  }

  handleSigninSuccess(res) {
    const basicProfile = res.getBasicProfile()
    const authResponse = res.getAuthResponse()
    res.googleId = basicProfile.getId()
    res.tokenObj = authResponse
    res.tokenId = authResponse.id_token
    res.accessToken = authResponse.access_token
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName()
    }
    this.props.onSuccess(res)
  }

  handleClickButton() {
    LoginActions.googleButtonClicked()
  }

  render() {
    return (
    <div className={this.props.className} onClick={this.handleClickButton} >
      <span className="svg-container">
      <Image src='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"><rect x="0" y="0" width="48" height="48" fill="white" /><path fill="%23FFC107" d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"/><path fill="%23FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"/><path fill="%234CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"/><path fill="%231976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"/></svg>' />
      </span>
      <span>{this.props.buttonText}</span>
    </div>
    )
  }
}

GoogleLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  jsSrc: PropTypes.string,
  onRequest: PropTypes.func,
  buttonText: PropTypes.string,
  scope: PropTypes.string,
  className: PropTypes.string,
  redirectUri: PropTypes.string,
  cookiePolicy: PropTypes.string,
  loginHint: PropTypes.string,
  hostedDomain: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  disabledStyle: PropTypes.object,
  fetchBasicProfile: PropTypes.bool,
  prompt: PropTypes.string,
  tag: PropTypes.string,
  autoLoad: PropTypes.bool,
  disabled: PropTypes.bool,
  discoveryDocs: PropTypes.array,
  uxMode: PropTypes.string,
  isSignedIn: PropTypes.bool,
  responseType: PropTypes.string,
  type: PropTypes.string,
  accessType: PropTypes.string,
  render: PropTypes.func
}

GoogleLogin.defaultProps = {
  type: 'button',
  tag: 'button',
  buttonText: 'Login with Google',
  scope: 'profile email',
  accessType: 'online',
  prompt: '',
  cookiePolicy: 'single_host_origin',
  fetchBasicProfile: true,
  isSignedIn: false,
  uxMode: 'popup',
  disabledStyle: {
    opacity: 0.6
  },
  onRequest: () => {},
  jsSrc: 'https://apis.google.com/js/client:platform.js'
}

export default GoogleLogin
