import React, { Component } from 'react';
import ContentActions from '../actions/ContentActions'
import(/* webpackPreload: true */ '../styles/App.css');
import TagButton from './TagButton';
import { Row, Col, Dropdown } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';
import Ratings from './RatingAPILayer';
import Common from '../utils/Common';
import ContentEditComponent from './ContentEdit';
import ImageToggle from './ImageToggle';
import(/* webpackPreload: true */ '../styles/open-iconic-bootstrap.css');

function getTags(tag) {
  return (
    <TagButton
      key={tag}
      tag={tag}
    />
  );
}

function isValidUrl(string) {
  try {
    new URL(string)
  }
  catch (_) {
    return false;
  }
  return true
}

class ContentItem extends Component {
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.editComponent = React.createRef();
    this.embedContentClick = this.embedContentClick.bind(this);
    this.onClickUrl = this.onClickUrl.bind(this);
    this.state = {
      tags: [],
      dropdownOpen: false
    }
    this.componentId = 'contentItem'
    this.notURLNotification = this.notURLNotification.bind(this)
  }

  onToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  editContent() {
    this.props.edithandler(this.props.index)
  }

  deleteContent(e) {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this content?')) {
      ContentActions.deleteContent(this.componentId, this.props.content.content_id)
      this.props.resetPage()
    }
  }

  shareContent(e) {
    e.preventDefault();
    this.props.shareContent && this.props.shareContent(this.props.index, this.props.content)
  }

  PermalinkContent(e){
    e.preventDefault();
    this.props.permalinkContent && this.props.permalinkContent(this.props.index, this.props.content)
  }

  handleDoubleClick(e) {
    e.preventDefault();
    var target = e.target || e.srcElement;
    if (target.tagName.toLowerCase() !== 'a') {
      this.editContent()
    }
  }

  embedContentClick() {
    this.props.embedContent && this.props.embedContent(this.props.content)
  }

  notURLNotification() {
    this.props.notURLNotification()
    this.editContent()
  }

  onClickUrl(event) {
    var url = new URL(event.target.href);
    if (url.protocol == 'file:') {
      event.preventDefault();
      navigator.clipboard.writeText(url.href)
      this.props.pasteNotification && this.props.pasteNotification()
    }
  }

  render() {
    const { content } = this.props;
    let tags;
    this.state.tags = content.tags;
    if (this.state.tags) {
      tags = this.state.tags.map(tag => getTags(tag));
    }
    let contentEditClose = () => {
      this.setState({ showEdit: false })
    }
    let menuOptionEmbed
    if (this.props.embedContent) {
      menuOptionEmbed = <Dropdown.Item onClick={this.embedContentClick}>Embed</Dropdown.Item>
    } else {
      menuOptionEmbed = ''
    }
    var urlValue = content.content_data;
    var dateValue = new Date(content.created_at).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    var notesValue = content.local_properties.notes && content.local_properties.notes.length > 0 && content.local_properties.notes.join('') ? content.local_properties.notes.toString() : 'Notes not available for this entry.';
    var titleValue = (content.content_title === undefined || content.content_title === null || /^\s*$/.test(content.content_title) === true) ? content.content_data : content.content_title
    var titleElement = <a href={urlValue} rel="external nofollow" target={this.props.openLinksNewTab ? '_blank' : '_self'} onClick={this.onClickUrl}>{titleValue}</a>
    if (!isValidUrl(urlValue)) {
      titleElement = <span onClick={this.notURLNotification}>{titleValue}</span>
    }
    var urlElement = <a href={urlValue} rel="external nofollow" target={this.props.openLinksNewTab ? '_blank' : '_self'} onClick={this.onClickUrl}>{urlValue}</a>
    if (!isValidUrl(urlValue)) {
      urlElement = <span onClick={this.notURLNotification}>{urlValue}</span>
    }
    return (
      <div>
        <Row className='signal34-content'>
          <Col className='signal34-name'>
            <div className='signal34-data' onDoubleClick={this.handleDoubleClick}>
              <div className='signal34-title'>
                {titleElement}
              </div>
              <div className='signal34-url'>
                {urlElement}
              </div>
              <div className='signal34-menu-score'>
                <Dropdown id="edit-delete-menu" className="edit-delete-menu" show={this.state.dropdownOpen} onToggle={this.onToggle}>
                  <Dropdown.Toggle id="edit-delete-menu-toggle" className="edit-delete-menu-toggle" variant="secondary" as={ImageToggle}>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='edit-delete-togle-menu'>
                    <Dropdown.Item onClick={this.editContent.bind(this)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={this.shareContent.bind(this)}>Share</Dropdown.Item>
                    {menuOptionEmbed}
                    <Dropdown.Item onClick={this.PermalinkContent.bind(this)}>Permalink</Dropdown.Item>
                    <Dropdown.Item onClick={this.deleteContent.bind(this)}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <span className='signal34-score'>
                  <Ratings
                    stop={5}
                    initialRating={Common.score2start(content.content_rating)}
                    emptySymbol={<span className="oi oi-star" style={{ color: 'rgb(203,203,203)', paddingLeft: '2px' }} />}
                    fullSymbol={<span className="oi oi-star" style={{ color: 'rgb(255,192,0)', paddingLeft: '2px' }} />}
                    readonly={true}
                    quiet={true}
                  />
                </span>
              </div>
            </div>
          </Col>
          <Col className='signal34-date-notes'>
            <span onDoubleClick={this.handleDoubleClick}>
              <TextTruncate
                line={2}
                truncateText="…"
                text={dateValue.toString() + ' - ' + notesValue}
              />
            </span>
          </Col>
          <Col className='signal34-tags'>
            <span className='signal34-tags-row' onDoubleClick={this.handleDoubleClick}>
              {tags}
            </span>
            <div className="empty-row"></div>
            <div className="empty-row"></div>
          </Col>
          <Col className='signal34-edit'>
            <ContentEditComponent
              showedit={this.props.editingflag}
              content_id={content.content_id}
              record_id={content.id}
              location={content.content_data}
              title={content.content_title}
              tags={content.tags}
              rating={content.content_rating}
              notes={content.local_properties.notes}
              onHide={contentEditClose}
              ref={this.editComponent}
              save={this.props.save}
              cancel={this.props.cancel}
              index={this.props.index}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentItem;
