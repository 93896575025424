import { EventEmitter } from 'events';

const ERROR_EVENT = 'Error';

class ErrorStoreClass extends EventEmitter {

  emitError(source, error) {
    setTimeout(() => this.emit(ERROR_EVENT, source, error), 0);
  }

  addErrorListener(callback) {
    this.on(ERROR_EVENT, callback)
  }

  removeErrorListener(callback) {
    this.removeListener(ERROR_EVENT, callback)
  }
}

const ErrorStore = new ErrorStoreClass();

export default ErrorStore;