import React, { Component } from 'react';
import SessionStore from '../stores/SessionStore'
import StripeCheckout from 'react-stripe-checkout'
import SubscriptionActions from '../actions/SubscriptionActions';
import SubcriptionStore from '../stores/SubscriptionStore'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

const STPKY = process.env.STRIPE_PUBLISH_KEY;
const PROD_NAME = process.env.SIGNAL34_PRODUCT_NAME;

class UpdatePaymentMethodCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: PROD_NAME,
      user_email: SessionStore.getSessionUserEmail().toString()
    }

    this.onToken = this.onToken.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.onUpdated = this.onUpdated.bind(this)
    this.onError = this.onError.bind(this)
    this.componentId = 'updatepaymentmethod';
  }

  UNSAFE_componentWillMount() {
    SubcriptionStore.addUpdatePaymentMethodListener(this.onUpdated)
    ErrorStore.addErrorListener(this.onError)
  }

  componentWillUnmount() {
    SubcriptionStore.removeChangePaymentListener(this.onUpdated)
    ErrorStore.removeErrorListener(this.onError)
  }

  onUpdated() {
    var subscription_data = SubcriptionStore.getSubscriptionResult()
    if (subscription_data['result']) {
      this.props.handleUpdatedEvent && this.props.handleUpdatedEvent()
    }
  }

  onToken(token) {
    var data = {}
    data['payment_token'] = JSON.stringify(token)
    SubscriptionActions.updatePaymentMethod(this.componentId, data)
  }

  handleClick() {
    this.props.handleClick && this.props.handleClick()
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    const { user_email, product } = this.state;
    let control;
    if (!this.props.disabled) {
      control = <StripeCheckout
        email={user_email}
        billingAddress={false}
        image={require('../images/logopayment.png')}
        name={product}
        panelLabel={'Update Payment method'}
        stripeKey={STPKY}
        token={this.onToken}
        allowRememberMe={false}
        locale={'auto'}
      >
        <button type="button" disabled={this.props.disabled} onClick={this.handleClick} className="signal34-payment-button btn btn-link">{this.props.label}</button>
      </StripeCheckout>
    }
    else {
      control = <button type="button" disabled={this.props.disabled} className="signal34-payment-button btn btn-link">{this.props.label}</button>
    }
    return (
      <div className="signal34-update-payment-button-container">{control}</div>
    )
  }
}
export default UpdatePaymentMethodCard;