import React, { Component } from 'react';
import TagActions from '../actions/TagActions';
import TagStore from '../stores/TagStore';
import CreatableSelect, { components, createFilter } from 'react-select'
import SessionStore from './../stores/SessionStore'
import Tooltip from '@atlaskit/tooltip';
import Common from '../utils/Common';

const background_color_default = 'var(--signal34MultiValueBackgroundColor)'
const color_default = 'var(--signal34MultivalueColor)'

const defaultBackgroundColor = 'var(--primaryBackgroundColor)'
const defaultTextColor = 'var(--primaryTextColor)'
const defaultBarSelectColor = 'var(--signal34SearchSelectBarColor)'
const defaultMenuListColor = 'var(--signal34MenuListColor)'
const transparentBackgroundColor = 'transparent'
const maxLen = Common.MAXLEN_TAG_LABEL;
const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'var(--primaryBackgroundColor)' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? defaultBackgroundColor
          : isFocused
            ? defaultBarSelectColor
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? defaultTextColor
          : isFocused
            ? defaultTextColor
            : null
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: transparentBackgroundColor
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: defaultMenuListColor
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: background_color_default,
      borderColor: background_color_default
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: color_default,
    backgroundColor: background_color_default,
    borderColor: background_color_default,
    borderRadius: '12px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: color_default,
    backgroundColor: background_color_default,
    ':hover': {
      backgroundColor: background_color_default,
      color: color_default
    }
  })
}

class TagSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      isLogged: false
    }

    this.onChangeTag = this.onChangeTag.bind(this);
    this.selectTags = React.createRef();
  }

  UNSAFE_componentWillMount() {
    TagStore.addChangeListener(this.onChangeTag);
  }

  componentDidMount() {
    if (SessionStore.getLogged()) {
      this.setState({
        isLogged: true
      })
      TagActions.recieveAllTags();
    }
    else {
      this.setState({
        isLogged: false
      })
      TagActions.recievePublicTags();
    }
  }

  componentWillUnmount() {
    TagStore.removeChangeListener(this.onChangeTag);
  }

  onChangeTag() {
    var tags = TagStore.getAllTags();
    var publicTags = TagStore.getPublicTags();
    if (tags && tags.length > 0 && this.state.isLogged == true) {
      var onlyTags = tags.filter(item => item.command == '')
      var tag_key_value = onlyTags.map(function (val) {
        var tag = val.tag ? val.tag : val;
        var tooltip = '';
        var label = '';
        if (tag.length > maxLen) {
          tooltip = tag;
          label = tag.substring(0, maxLen) + '...';
        } else {
          label = tag;
        }
        return { value: tag, label: label, tooltip: tooltip }
      });
      this.setState({
        options: tag_key_value
      }, () => {
        this.selectTags.getNextFocusedOption = () => null;
      });
    }
    if (publicTags && publicTags.length > 0 && this.state.isLogged == false) {
      var tag_key_value = publicTags.map(function (val) {
        var tag = val.tag ? val.tag : val;
        var tooltip = '';
        var label = '';
        if (tag.length > maxLen) {
          tooltip = tag;
          label = tag.substring(0, maxLen) + '...';
        } else {
          label = tag;
        }
        return { value: tag, label: label, tooltip: tooltip }
      });
      this.setState({
        options: tag_key_value
      }, () => {
        this.selectTags.getNextFocusedOption = () => null;
      });
    }
  }

  render() {
    const { options } = this.state;
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: 'start'
    };
    const MultiValueContainer = (props) => {
      const { data } = props;
      const { tooltip } = data;
      return (
        <Tooltip content={tooltip}>
          <components.MultiValueContainer {...props} />
        </Tooltip>
      );
    };
    return (
      <div>
        <CreatableSelect
          name={this.props.name}
          isDisabled={this.props.disabled}
          value={this.props.value}
          inputValue={this.props.inputValue}
          isClearable={false}
          isMulti
          maxMenuHeight={225}
          onChange={this.props.onChange}
          onInputChange={this.props.onInputChange}
          onKeyDown={this.props.onKeyDown}
          options={options}
          styles={customStyles}
          noOptionsMessage={this.props.noOptionsMessage}
          placeholder={'Enter your tags'}
          tabSelectsValue={false}
          ref={ref => { this.selectTags = ref; }}
          filterOption={createFilter(filterConfig)}
          components={{ MultiValueContainer }}
        />
      </div>
    );
  }
}
export default TagSelect;
