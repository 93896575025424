import AppDispatcher from '../dispatcher/AppDispatcher';
import SettingConstants from '../constants/SettingConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const RECEIVE_EVENT = 'receive';
const CHANGE_EVENT = 'change';
const SHOW_EVENT = 'show';
const RECEIVE_DARK_MODE_EVENT = 'receive_dark_mode';
const CHANGE_DARK_MODE_EVENT = 'change_dark_mode';

let _settings_sort_by = '';
let _settings_sort_order = '';
let _settings_results_per_page = 0;
let _settings_open_links_new_tab = false;
let _settings_dark_mode = false;

function setSortBy(sort_by) {
  _settings_sort_by = sort_by;
}

function setSortOrder(sort_order) {
  _settings_sort_order = sort_order;
}

function setResultPerPage(results_per_page) {
  _settings_results_per_page = results_per_page;
}

function setOpenLinksNewTab(open_links_new_tab) {
  _settings_open_links_new_tab = open_links_new_tab;
}

function setDarkMode(dark_mode) {
  _settings_dark_mode = dark_mode;
}

class SettingStoreClass extends EventEmitter {

  emitReceive() {
    this.emit(RECEIVE_EVENT);
  }

  addReceiveListener(callback) {
    this.on(RECEIVE_EVENT, callback)
  }

  removeReceiveListener(callback) {
    this.removeListener(RECEIVE_EVENT, callback)
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  emitReceiveDarkMode() {
    this.emit(RECEIVE_DARK_MODE_EVENT);
  }

  addReceiveDarkModeListener(callback) {
    this.on(RECEIVE_DARK_MODE_EVENT, callback)
  }

  removeReceiveDarkModeListener(callback) {
    this.removeListener(RECEIVE_DARK_MODE_EVENT, callback)
  }

  emitChangeDarkMode() {
    this.emit(CHANGE_DARK_MODE_EVENT);
  }

  addChangeDarkModeListener(callback) {
    this.on(CHANGE_DARK_MODE_EVENT, callback)
  }

  removeChangeDarkModeListener(callback) {
    this.removeListener(CHANGE_DARK_MODE_EVENT, callback)
  }

  emitShow() {
    this.emit(SHOW_EVENT)
  }

  addShowListener(callback) {
    this.on(SHOW_EVENT, callback)
  }

  removeShowListener(callback) {
    this.removeListener(SHOW_EVENT, callback)
  }

  getSortBy() {
    return _settings_sort_by;
  }

  getSortOrder() {
    return _settings_sort_order;
  }

  getResultPerPage() {
    return _settings_results_per_page;
  }

  getOpenLinksNewTab() {
    return _settings_open_links_new_tab;
  }

  getDarkMode() {
    return _settings_dark_mode;
  }
}

const SettingStore = new SettingStoreClass();

SettingStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case SettingConstants.RECEIVE_SETTINGS:
      setSortBy(action['settings'].sort_by)
      setSortOrder(action['settings'].sort_order)
      setResultPerPage(action['settings'].results_per_page)
      setOpenLinksNewTab(action['settings'].open_links_new_tab)
      SettingStore.emitReceive();
      break;

    case SettingConstants.RECEIVE_SETTINGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SettingConstants.CHANGE_SETTINGS:
      setSortBy(action['settings'].sort_by)
      setSortOrder(action['settings'].sort_order)
      setResultPerPage(action['settings'].results_per_page)
      setOpenLinksNewTab(action['settings'].open_links_new_tab)
      SettingStore.emitReceive();
      break;

    case SettingConstants.CHANGE_SETTINGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SettingConstants.SHOW_DIALOG_SETTINGS:
      SettingStore.emitShow();
      break;

    case SettingConstants.RECEIVE_DARK_MODE_SETTINGS:
      setDarkMode(action['settings'].dark_mode)
      SettingStore.emitReceiveDarkMode();
      break;

    case SettingConstants.RECEIVE_DARK_MODE_SETTINGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SettingConstants.CHANGE_DARK_MODE_SETTINGS:
      setDarkMode(action['settings'].dark_mode)
      SettingStore.emitChangeDarkMode();
      break;

    case SettingConstants.CHANGE_DARK_MODE_SETTINGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }

});

export default SettingStore;
