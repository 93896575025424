import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import 'react-day-picker/lib/style.css'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import FilterActions from '../actions/FilterActions'
import FilterStore from '../stores/FilterStore'

const options = [
  { value: 'before', label: 'Before the...' },
  { value: 'after', label: 'After the...' }
];

const defaultBackgroundColor = 'var(--primaryBackgroundColor)'
const defaultTextColor = 'var(--primaryTextColor)'
const defaultBarSelectColor = 'var(--signal34SelectBarColor)'
const defaultMenuListColor = 'var(--signal34MenuListColor)'
const defaultSelectTextColor = 'var(--signal34SelectTextColor)'
const defaultSelectBackgroundColor = 'var(--signal34SelectBackgroundColor)'
const transparentBackgroundColor = 'transparent'
const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'var(--primaryBackgroundColor)' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? defaultSelectBackgroundColor
          : isFocused
            ? defaultBarSelectColor
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? defaultSelectTextColor
          : isFocused
            ? defaultTextColor
            : null
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: transparentBackgroundColor
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: defaultMenuListColor
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      backgroundColor: defaultBackgroundColor,
      borderColor: defaultBackgroundColor,
      color: defaultTextColor
    };
  }
}

class SearchByDateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      selectedDay: '',
      filter: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onFilterClear = this.onFilterClear.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.resetSelection = this.resetSelection.bind(this);
  }

  UNSAFE_componentWillMount() {
    FilterStore.addClearListener(this.onFilterClear);
  }

  componentWillUnmount() {
    FilterStore.removeClearListener(this.onFilterClear);
  }

  onFilterClear() {
    this.setState({
      selectedOption: null,
      selectedDay: '',
      filter: ''
    });
  }

  buildQuery(selectedOption, selectedDay) {
    var dateQuery = '';
    if (selectedOption.value == 'before') {
      dateQuery = 'created_at:[* TO ' + new Date(selectedDay).toISOString().split('T')[0] + 'T23:59:59.999Z]';
    } else if (selectedOption.value == 'after') {
      dateQuery = 'created_at:[' + new Date(selectedDay).toISOString().split('T')[0] + 'T00:00:00.000Z TO *]';
    }
    return dateQuery;
  }

  handleChange(selectedOption) {
    const { selectedDay } = this.state;
    this.setState({ selectedOption: selectedOption });
    if (selectedDay !== '' && Date.parse(selectedDay)) {
      var theFilter = this.buildQuery(selectedOption, selectedDay);
      this.setState({ filter: theFilter });
    }
  }

  handleFilterChange(selectedDay) {
    const { selectedOption } = this.state;
    this.setState({ selectedDay: selectedDay });
    if (selectedOption !== null && Date.parse(selectedDay)) {
      var theFilter = this.buildQuery(selectedOption, selectedDay);
      this.setState({ filter: theFilter });
    }
  }

  okClicked = () => {
    var query = this.state.filter;
    if (query !== null && query !== '') {
      FilterActions.filter_date(query);
      this.props.onHide();
      this.props.onClick('Date', query)
    }
  }


  handleKeyPress(key) {
    if (key.charCode === 13) {
      this.okClicked();
    }
  }

  resetSelection() {
    this.setState({
      selectedOption: null,
      selectedDay: ''
    })
  }

  render() {
    const { selectedOption } = this.state;
    const { selectedDay } = this.state;
    return (
      <Modal
        {...this.props}
        data-testid="search-by-date-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search by Date:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onKeyPress={this.handleKeyPress} data-testid="select-interval-test-id">
            <span>Choice</span>
            <Select
              value={selectedOption}
              onChange={this.handleChange}
              options={options}
              autoFocus={true}
              tabIndex={0}
              styles={customStyles}
            />
            <span>Date</span>
          </div>
          <div data-testid="select-day-test-id">
            <DayPickerInput onDayChange={this.handleFilterChange} value={selectedDay} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.props.onHide}>Cancel</Button>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.okClicked}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SearchByDateFilter;
