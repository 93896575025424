import keyMirror from 'keymirror';

export default keyMirror({
  RECEIVE_TAGS: null,
  RECEIVE_ALL_TAGS: null,
  RECEIVE_TAGS_ERROR: null,
  DELETE_TAG: null,
  DELETE_TAG_ERROR: null,
  RECEIVE_TAG: null,
  RECEIVE_TAG_ERROR: null,
  PUT_TAG: null,
  PUT_TAG_ERROR: null,
  CHECK_MERGE_TAG: null,
  CHECK_MERGE_TAG_ERROR: null,
  RECEIVE_PREFIX_TAGS: null,
  RECEIVE_PREFIX_TAGS_ERROR: null,
  RECEIVE_ALL_CONTENTS_TAGS: null,
  RECEIVE_ALL_CONTENTS_TAGS_ERROR: null
});
