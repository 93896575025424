import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import TagStore from '../stores/TagStore'
import NoTagsSelect from '../components/NoTagSelect'
import FilterActions from '../actions/FilterActions'
import FilterStore from '../stores/FilterStore'
import Common from '../utils/Common';

const maxLen = Common.MAXLEN_TAG_LABEL;
class SearchByNotTagFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      tagsFilterOptions: null
    }
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilterClear = this.onFilterClear.bind(this);
    this.onTagFilterChanged = this.onTagFilterChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.resetSelection = this.resetSelection.bind(this)
    TagStore.addChangeContentsTagsListener(this.onTagFilterChanged);
    FilterStore.addChangeListener(this.onFilterChanged);
    FilterStore.addClearListener(this.onFilterClear);
  }

  componentDidUpdate(prevProps) {
    if (this.props.showtags !== prevProps.showtags) {
      if (this.props.showtags != 'true') {
        this.setState({
          selectedOption: null,
          tagsFilterOptions: null,
          options: []
        });
      }

    }
  }

  componentWillUnmount() {
    FilterStore.removeChangeListener(this.onFilterChanged);
    FilterStore.removeClearListener(this.onFilterClear);
    TagStore.removeChangeContentsTagsListener(this.onTagFilterChanged);
  }

  onFilterClear() {
    this.setState({
      selectedOption: null,
      tagsFilterOptions: null
    });
  }

  onTagFilterChanged() {
    if (this.props.showtags == 'true') {
      var tagsfilter = this.state.tagsFilterOptions;
      var tags = TagStore.getContentsTags();
      if (tags) {
        var diff = this.getDiffTags(tagsfilter, tags);
        var tag_key_value = diff.map(function (val) {
          var tag = val.tag ? val.tag : val;
          var tooltip = '';
          var label = '';
          if (tag.length > maxLen) {
            tooltip = tag;
            label = tag.substring(0, maxLen) + '...';
          } else {
            label = tag;
          }
          return { value: tag, label: label, tooltip: tooltip }
        });
        if (this.state.options !== tag_key_value) {
          this.setState({ options: tag_key_value });
        }
      }
    }
  }

  getDiffTags(tagsfilter, tags) {
    if (!tagsfilter) {
      return tags;
    }
    else {
      var diff = [];
      var keys = tagsfilter.map((item) => {
        return item.value;
      });
      diff = tags.filter((item) => {
        return !keys.includes(item);
      })
      return diff;
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  onFilterChanged() {
    var options = FilterStore.getTag();
    this.setState({
      tagsFilterOptions: options
    });
  }

  okClicked = () => {
    var selectValue = this.state.selectedOption;
    if (selectValue) {
      FilterActions.filter_not_tag(selectValue.value);
      this.resetSelection();
      this.props.onHide();
    }
  }

  handleKeyPress(key) {
    if (key.charCode === 13) {
      this.okClicked();
    }
  }

  resetSelection() {
    this.setState({
      selectedOption: null
    })
  }

  render() {
    const { selectedOption, options } = this.state;
    return (
      <Modal
        {...this.props}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search by excluding tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onKeyPress={this.handleKeyPress}>
            <span>Select the tag you want to exclude from search:</span>
            <NoTagsSelect
              value={selectedOption}
              onChange={this.handleChange}
              options={options}
              tabIndex={1}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.props.onHide} tabIndex={2}>Cancel</Button>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.okClicked} tabIndex={3}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SearchByNotTagFilter;
