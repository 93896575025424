import LogoutUtil from '../utils/LogoutUtil'
import * as ErrorCode from '../constants/ErrorCodesConstants';

class HandleExceptionClass {
  constructor() {
    this.show_connection_error = false;
    this.show_internal_error = false;
  }

  handle_error(error) {
    switch (error.code) {
      case ErrorCode.SERVICE_UNAVAILABLE:
      case ErrorCode.NOT_IMPLEMENT:
      case ErrorCode.BAD_GATEWAY:
      case ErrorCode.GATEWAY_TIMEOUT:
        alert('The API server is unavailable due to a connection problem.');
        break;
      case ErrorCode.INTERNAL_SERVER_ERROR:
        alert('An internal error has occurred with the API server.');
        break;
      case ErrorCode.NETWORK_IS_OFFLINE:
        alert('There is no internet connection, please check your Network connection and then try again...');
        break;
      case ErrorCode.UNAUTHORIZED:
        var message = error.description;
        if (message.toLowerCase().localeCompare('invalid email or password') == 0) {
          alert(message);
        } else {
          LogoutUtil.logout();
        }
        break;
      case ErrorCode.NOT_FOUND:
        if (typeof error.description !== 'undefined') {
          var message = error.description;
          if (message.toLowerCase().localeCompare('invalid email or password') == 0) {
            alert(message);
          } else {
            alert(error.description);
          }
        } else {
          alert(error);
        }
        break;
      case ErrorCode.UNPROCESSABLE_ENTITY:
        alert(error.description)
        break;
      default:
        if (typeof error.description !== 'undefined') {
          alert('An unexpected error has occurred.\nError Description: ' + error.description);
        } else {
          alert('An unexpected error has occurred.\nError Description: ' + error);
        }
    }
  }

  formatting_error(error, response) {
    if (!response) {
      return { 'description': error, 'code': ErrorCode.NETWORK_IS_OFFLINE }
    } if (response.statusCode == ErrorCode.SERVICE_UNAVAILABLE ||
      response.statusCode == ErrorCode.NOT_IMPLEMENT ||
      response.statusCode == ErrorCode.BAD_GATEWAY ||
      response.statusCode == ErrorCode.GATEWAY_TIMEOUT) {
      return { 'description': error, 'code': ErrorCode.SERVICE_UNAVAILABLE }
    } if (response.statusCode == ErrorCode.UNPROCESSABLE_ENTITY) {
      return { 'description': response.body.error, 'code': response.statusCode }
    }
    if (response.text) {
      var error_description = JSON.parse(response.text)
      return { 'description': (error_description.error) ? error_description.error : error_description, 'code': response.statusCode }
    }
  }
}

const HandleException = new HandleExceptionClass()

export default HandleException
