import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

class ConfirmDialogAgree extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault()
    this.props.onYesConfirm && this.props.onYesConfirm();
    return false
  }

  render() {
    let url_terms = this.props.personal === true ? './Personal_terms_of_service' : './Business_terms_of_service'
    return (
      <Modal show={this.props.show} onHide={this.props.onNoConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleFormSubmit}>
          <Modal.Body>
            By continuing, you agree to Signal34's <a target='_blank' href={url_terms}>{this.props.personal === true ? '' : 'Business '}Terms of Use</a> and <a target='_blank' href='./Privacy_policy'>Privacy policy</a>
          </Modal.Body>
          <Modal.Footer>
            <Button className='signal34-bnt-default' variant='outline-secondary' type='button' onClick={this.props.onNoConfirm}>Cancel</Button>
            <Button className='signal34-bnt-default' variant='outline-secondary' type='submit' autoFocus={true} tabIndex={0}>OK</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ConfirmDialogAgree;