import React, { Component } from 'react';
import TagActions from '../actions/TagActions';
import TagStore from '../stores/TagStore';
import TagRename from './TagRename';
import ConfirmDialogDelete from './ConfirmDialogDelete';
import ConfirmDialogYesNo from './ConfirmDialogYesNo';
import { ButtonToolbar, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import FilterActions from '../actions/FilterActions'
import(/* webpackPreload: true */ '../styles/Contents.css');
import(/* webpackPreload: true */ '../styles/Tags.css');
import Pagination from 'react-js-pagination'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

const RemotePagination = ({ loading, data, page, sizePerPage, onTableChange, onHandlePageChange, totalSize, onSubmitEdit, onHandlePageSizeChange }) => (
  <div>
    <BootstrapTable
      tableHeaderClass={'col-hidden'}
      remote
      loading={loading}
      keyField="tag"
      data={data}
      columns={[
        {
          dataField: 'tag',
          text: 'Name',
          headerStyle: {
            display: ''
          }
        },
        {
          dataField: 'count',
          text: 'Count',
          headerStyle: {
            display: ''
          }
        },
        {
          dataField: 'command',
          text: 'Command',
          headerStyle: {
            display: ''
          }
        },
        {
          dataField: 'editAction',
          text: 'Edit',
          headerStyle: {
            display: ''
          },
          isDummyField: true,
          csvExport: false,
          style: () => {
            return {
              width: '40px'
            };
          },
          formatter: (cellContent, row) => {
            return (
              <ButtonToolbar>
                <ButtonGroup><TagRename id={row.tag} handleFormSubmit={onSubmitEdit} /></ButtonGroup>
              </ButtonToolbar>
            )
          }
        },
        {
          dataField: 'deleteAction',
          text: 'Delete',
          headerStyle: {
            display: ''
          },
          isDummyField: true,
          csvExport: false,
          style: () => {
            return {
              width: '40px'
            };
          },
          formatter: (cellContent, row) => {
            return (
              <ButtonToolbar>
                <ButtonGroup><ConfirmDialogDelete id={row.tag} type='tag' /></ButtonGroup>
              </ButtonToolbar>
            )
          }
        }
      ]}
      onTableChange={onTableChange}
      overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
      noDataIndication="You do not have any tag."
      bordered={false}
      striped
      condensed
      bootstrap4={true}
    />
    <DropdownButton id="page-size" className="tags-dropdown-button" title={sizePerPage} variant="secondary" onSelect={onHandlePageSizeChange}>
      <Dropdown.Item eventKey="10">10</Dropdown.Item >
      <Dropdown.Item eventKey="25">25</Dropdown.Item>
      <Dropdown.Item eventKey="30">30</Dropdown.Item>
      <Dropdown.Item eventKey="50">50</Dropdown.Item>
    </DropdownButton>
    <Pagination
      activePage={page}
      itemsCountPerPage={sizePerPage}
      totalItemsCount={totalSize}
      onChange={onHandlePageChange}
      itemClass="page-item"
      linkClass="page-link"
    />
  </div>
);

class TagsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      data: [],
      sizePerPage: 10,
      total: 0,
      started: true,
      showConfirm: false
    };
    TagStore.setPage(this.state.page);
    TagStore.setSizePerPage(this.state.sizePerPage);
    this.onChange = this.onChange.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.onYesConfirm = this.onYesConfirm.bind(this);
    this.onNoConfirm = this.onNoConfirm.bind(this);
    this.onCheckMerge = this.onCheckMerge.bind(this);
    this.renameTag = this.renameTag.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.onError = this.onError.bind(this);
    this.componentId = 'tags';
  }

  UNSAFE_componentWillMount() {
    TagStore.addChangeListener(this.onChange);
    TagStore.addCheckMergeListener(this.onCheckMerge);
    ErrorStore.addErrorListener(this.onError);
  }

  componentDidMount() {
    FilterActions.clear_searchbar();
    FilterActions.clear();
    FilterActions.filter_setup({
      'text': false,
      'type': true,
      'not': false,
      'date': false,
      'tags': false
    });
    FilterActions.searchbar_setup('', true);
    TagActions.recieveTags(this.componentId, this.state.page, this.state.sizePerPage);
  }

  componentWillUnmount() {
    TagStore.removeChangeListener(this.onChange);
    TagStore.removeCheckMergeListener(this.onCheckMerge);
    ErrorStore.removeErrorListener(this.onError);
  }

  onChange() {
    var data = TagStore.getTags();
    var tags = data.tags;
    var page = TagStore.getPage();
    var size = TagStore.getSizePerPage();
    var dataCopy = [...this.state.data];
    if (data && tags) {
      this.setState({
        loading: false,
        started: false,
        data: tags,
        total: data.total,
        page: page,
        sizePerPage: size,
        new_tag: '',
        old_tag: ''
      }, () => {
        if (JSON.stringify(this.state.data) !== dataCopy) {
          this.forceUpdate();
        }
      });
    }
  }

  /* Rename Tag */
  renameTag() {
    TagActions.renameTag(
      this.componentId,
      encodeURIComponent(this.state.old_tag),
      encodeURIComponent(this.state.new_tag)
    );
  }

  /* Merge tags event */
  onCheckMerge(e) {
    var maybeMerged = (e.result == 'true');
    if (maybeMerged) {
      this.setState({ showConfirm: true });
    } else {
      this.renameTag();
    }
  }

  /* Yes confirm dialog */
  onYesConfirm() {
    this.renameTag();
    this.setState({ showConfirm: false });
  }

  /* No confirm dialog */
  onNoConfirm() {
    this.setState({ showConfirm: false });
  }

  handleSubmitEdit(old_tag, new_tag) {
    this.setState({ new_tag: new_tag, old_tag: old_tag });
    TagActions.checkMergeTag(
      this.componentId,
      encodeURIComponent(old_tag),
      encodeURIComponent(new_tag)
    );
  }

  handlePageChange(page) {
    const sizePerPage = this.state.sizePerPage
    this.handleTableChange('pagination', { page, sizePerPage })
  }

  handlePageSizeChange(sizePerPage) {
    var currentDataLength = this.state.data.length;
    const page = sizePerPage >= currentDataLength ? 1 : this.state.page;
    this.handleTableChange('pagination', { page, sizePerPage })
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    TagStore.setPage(page)
    TagStore.setSizePerPage(sizePerPage);
    setTimeout(() => {
      TagActions.recieveTags(this.componentId, page, sizePerPage);
      this.setState(() => ({
        loading: false,
        page,
        sizePerPage
      }));
    }, 1000);
    this.setState(() => ({ loading: true }));
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    if (this.state.started) {
      return (
        <div></div>
      );
    } else {
      const { data, sizePerPage, page, loading, total, showConfirm, old_tag, new_tag } = this.state;
      const message = 'Current tag "' + old_tag + '" will be merged into target tag "' + new_tag + '".'
      return (
        <div className='signal34-my-tags col-sm-12 col-xs-12'>
          <h1>My Tags</h1>
          <RemotePagination
            loading={loading}
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={total}
            onTableChange={this.handleTableChange}
            onSubmitEdit={this.handleSubmitEdit}
            onHandlePageChange={this.handlePageChange}
            onHandlePageSizeChange={this.handlePageSizeChange}
          />
          <ConfirmDialogYesNo
            message={message}
            title={'Please confirm:'}
            onNoConfirm={this.onNoConfirm}
            onYesConfirm={this.onYesConfirm}
            show={showConfirm}
            yesText={'OK'}
            noText={'Cancel'}
          />
        </div>
      );
    }
  }
}

export default TagsComponent;
