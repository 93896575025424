import React, { Component } from 'react';
import SessionStore from '../stores/SessionStore'
import StripeCheckout from 'react-stripe-checkout'
import SubscriptionActions from '../actions/SubscriptionActions';
import SubcriptionStore from '../stores/SubscriptionStore'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

const STPKY = process.env.STRIPE_PUBLISH_KEY;
const PROD_NAME = process.env.SIGNAL34_PRODUCT_NAME;

class PayCreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: PROD_NAME,
      subscription: this.props.subscription,
      user_email: SessionStore.getSessionUserEmail().toString(),
      currency: this.props.currency,
      locale: 'auto'
    }

    this.onToken = this.onToken.bind(this)
    this.onSubscribed = this.onSubscribed.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.onClick = this.onClick.bind(this)
    this.stripeCheckoutButton = React.createRef();
    this.handleValidateClick = this.handleValidateClick.bind(this);
    this.onError = this.onError.bind(this);
    this.componentId = 'invoice';
  }

  UNSAFE_componentWillMount() {
    SubcriptionStore.addCreatedListener(this.onSubscribed)
    SubcriptionStore.addChangePaymentListener(this.onChange)
    ErrorStore.addErrorListener(this.onError)
  }

  componentWillUnmount() {
    SubcriptionStore.removeCreatedListener(this.onSubscribed)
    SubcriptionStore.removeChangePaymentListener(this.onChange)
  }

  onChange(subscription) {
    this.setState({ subscription: subscription })
    ErrorStore.removeErrorListener(this.onError)
  }

  onSubscribed() {
    var subscription_data = SubcriptionStore.getSubscriptionResult()
    if (subscription_data['result']) {
      if (subscription_data['user_name'] != null) {
        SessionStore.setUserName(subscription_data['user_name'])
      }
      if (subscription_data['account_name'] != null) {
        SessionStore.setAccountName(subscription_data['account_name'])
      }
      this.props.handlePaymentSuccess && this.props.handlePaymentSuccess()
    }
  }

  onToken(token) {
    var subscription = this.state.subscription
    var data = {}
    data['payment_token'] = JSON.stringify(token)
    data['amount'] = subscription.amount_cents
    // TODO: by default the quantity is one item
    data['quantity'] = 1
    data['interval_count'] = subscription.quantity
    data['interval'] = subscription.period
    data['account_type_name'] = subscription.name
    data['user_name'] = this.props.user_name
    data['account_name'] = this.props.account_name
    data['full_name'] = this.props.full_name
    data['email'] = this.props.email

    SubscriptionActions.renewSubscription(this.componentId, data)
  }

  handleClick() {
    this.props.handleClick && this.props.handleClick()
  }

  handleValidateClick() {
    this.props.handleValidate && this.props.handleValidate()
  }

  onClick() {
    this.stripeCheckoutButton.current.click()
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    const { subscription, user_email, currency, product, locale } = this.state
    const description = subscription.quantity + ' ' + subscription.period + ' ' + subscription.name + ' Subscription'
    let control;
    if (this.props.agree && this.props.validated) {
      control = <StripeCheckout
        amount={subscription.amount_cents}
        email={user_email}
        billingAddress={false}
        description={description}
        image={require('../images/logopayment.png')}
        locale={locale}
        name={product}
        stripeKey={STPKY}
        token={this.onToken}
        currency={currency}
        allowRememberMe={false}
      >
        <button type="button" ref={this.stripeCheckoutButton} disabled={this.props.disabled} className="signal34-payment-button btn btn-link">{this.props.label}</button>
      </StripeCheckout>
    } else {
      if (!this.props.validated) {
        control = <button type="button" ref={this.stripeCheckoutButton} disabled={this.props.disabled} className="signal34-payment-button btn btn-link" onClick={this.handleValidateClick}>{this.props.label}</button>
      } else {
        control = <button type="button" ref={this.stripeCheckoutButton} disabled={this.props.disabled} className="signal34-payment-button btn btn-link" onClick={this.handleClick} >{this.props.label}</button>
      }
    }
    return (
      <div>{control}</div>
    )
  }
}
export default PayCreditCard;
