import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import '../styles/LoaderButton.css';

export default ({
                  isLoading,
                  text,
                  loadingText,
                  className = '',
                  disabled = false,
                  ...props
                }) =>
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <FontAwesomeIcon icon={faUndo} />}
    {!isLoading ? text : loadingText}
  </Button>;
