import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import LoginCard from '../containers/LoginCard'
import '../styles/Login.css'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

class LoginDialog extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClose = this.handleClose.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.componentId = 'loginDialog'
    this.onError = this.onError.bind(this);
  }

  UNSAFE_componentWillMount() {
    ErrorStore.addErrorListener(this.onError)
  }

  componentWillUnmount() {
    ErrorStore.removeErrorListener(this.onError)
  }

  handleClose() {
    this.props.handleClose && this.props.handleClose()
  }

  onLogin() {
    this.props.handleLogin && this.props.handleLogin()
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleClose} className="login-dialog-modal" dialogClassName="modal-90w">
        <Modal.Header closeButton className="login-dialog-header"></Modal.Header>
        <Modal.Body className="login-dialog-modal-body">
          <LoginCard handleLogin={this.onLogin} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default LoginDialog;