import React, { useState, useRef } from 'react';

import RandomText, { generateRandomCaptcha } from './RandomText';

import NoHandleSuccessCallbackError from './errors/noHandleSuccessCallbackError';
import NoHandleFailureCallbackError from './errors/noHandleFailureCallbackError';
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import(/* webpackPreload: true */ '../../styles/Captcha.css');

const ReactCaptcha = ({ charset, length, color, bgColor, reload, reloadText, reloadIcon, handleSuccess, handleFailure }) => {
    if (!handleSuccess) {
        throw new NoHandleSuccessCallbackError('You must provide a callback function for successs');
    }
    if (!handleFailure) {
        throw new NoHandleFailureCallbackError('You must provide a callback function for failure');
    }

    if (typeof handleSuccess !== 'function') {
        throw new TypeError('handleSuccess must be a function');
    }
    if (typeof handleFailure !== 'function') {
        throw new TypeError('handleFailure must be a function');
    }

    const [captcha, setCaptcha] = useState(generateRandomCaptcha(charset, length));
    const inputRef = useRef(null);

    const reloadCaptcha = () => {
        setCaptcha(generateRandomCaptcha(charset, length));
    }

    const evaluateCaptcha = () => {
        if (captcha === inputRef.current.value) {
            handleSuccess();
        }
        else {
            handleFailure();
        }
    }

    return (
        <div className="react-captcha">
            <div className="react-captcha-reload">
                <RandomText text={captcha} color={color} bgColor={bgColor} />
                {reload && <Button variant='outline-secondary' className='react-captcha__reloadBtn' onClick={reloadCaptcha}><FontAwesomeIcon icon={faRedo} size="lg" />{reloadText}{reloadIcon && <img src={reloadIcon} alt='Reload' style={{ width: '20px', height: '20px' }} />}</Button>}
            </div>
            <div className="react-captcha-input">
                <input onChange={evaluateCaptcha} ref={inputRef} type="text" placeholder="Enter captcha" className='react-captcha__inputField' />
            </div>
        </div>
    );
}

ReactCaptcha.defaultProps = {
    charset: 'ulns',
    length: 6,
    color: '#000',
    bgColor: '#fff',
    reload: false,
    reloadText: ''
}

export default ReactCaptcha;
