import AppDispatcher from '../dispatcher/AppDispatcher'
import SettingConstants from '../constants/SettingConstants'
import { EventEmitter } from 'events'
import Cookies from 'universal-cookie'
import { SORT_BY_MATCH_SCORE, SORT_ORDER_BEST_FIRST } from '../utils/FiltersBuilder'

const RECEIVE_EVENT = 'receive';
const CHANGE_EVENT = 'change';
const cookies = new Cookies();

function setSettings(sort_by, sort_order, per_page, open_links_new_tab) {
  const tenYears = new Date(2030, 12, 31);
  cookies.set('sort_by', sort_by, { expires: tenYears })
  cookies.set('sort_order', sort_order, { expires: tenYears });
  cookies.set('per_page', per_page, { expires: tenYears });
  cookies.set('open_links_new_tab', open_links_new_tab, { expires: tenYears })
}

class SettingOfflineStoreClass extends EventEmitter {

  emitReceive() {
    this.emit(RECEIVE_EVENT);
  }

  addReceiveListener(callback) {
    this.on(RECEIVE_EVENT, callback)
  }

  removeReceiveListener(callback) {
    this.removeListener(RECEIVE_EVENT, callback)
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  getSortBy() {
    var sortBy = cookies.get('sort_by');
    if (sortBy === undefined)
      return SORT_BY_MATCH_SCORE
    else
      return sortBy
  }

  getSortOrder() {
    var sortOrder = cookies.get('sort_order');
    if (sortOrder === undefined)
      return SORT_ORDER_BEST_FIRST
    else
      return sortOrder
  }

  getResultPerPage() {
    var perPage = cookies.get('per_page');
    if (perPage === undefined)
      return 10
    else
      return parseInt(perPage)
  }

  getOpenLinksNewTab() {
    var open_links_new_tab = cookies.get('open_links_new_tab');
    if (open_links_new_tab === undefined)
      return true
    else {
      var isTrueSet = (open_links_new_tab == 'true');
      return isTrueSet
    }
  }
}

const SettingOfflineStore = new SettingOfflineStoreClass();

SettingOfflineStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case SettingConstants.RECEIVE_PUBLIC_SETTINGS:
      SettingOfflineStore.emitReceive()
      break;

    case SettingConstants.CHANGE_PUBLIC_SETTINGS:
      setSettings(action['settings'].sort_by, action['settings'].sort_order, action['settings'].results_per_page, action['settings'].open_links_new_tab)
      SettingOfflineStore.emitReceive()
      break;

    default:
  }

});

export default SettingOfflineStore;
