import AppDispatcher from '../dispatcher/AppDispatcher';
import ContentConstants from '../constants/ContentConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const CHANGE_EVENT = 'change';
const CONFIRM_EVENT = 'confirm';
const UPDATED_EVENT = 'updated';
const DUPLICATED_EVENT = 'duplicated';
const EDIT_EVENT = 'edit';
const EXPORT_EVENT = 'export';
const TOP_EDITOR_EVENT = 'topeditor';
const DELETED_EVENT = 'deleted';
const CHANGE_PUBLIC_CONTENTS_EVENT = 'change';
const ADD_TO_MY_ACCOUNT_ON_EVENT = 'addtomyaccounton';
const ADD_TO_MY_ACCOUNT_OFF_EVENT = 'addtomyaccountoff';
const NAVIGATE_TO_SAVE_ON_EVENT = 'navigatetosaveon';
const NAVIGATE_TO_SAVE_OFF_EVENT = 'navigatetosaveoff';
const CHANGE_SHARE_LINK_EVENT = 'sharelink';
const CHANGE_SHARE_CONTENT_EVENT = 'sharecontent';
const CHANGE_PERMALINK_EVENT = 'permalink';
const CHANGE_PERMALINK_CONTENT_EVENT = 'permalinkContent';
const CHANGE_FOLLOW_CONTENTS_EVENT = 'change';
const CHANGE_NETWORK_EVENT = 'changenetwork';
const CHANGE_CONTENT_GUEST = 'contentGuest';
const CHANGE_IS_PUBLIC_PERMALINK = 'isPublicPermalink'

let _contents = [];
let _content = {};
let _numFound = 0;
let _queryTime = 0;
let _contents_export = [];
let _topEditorVisible = false;
let _content_id_to_edit = '';
let _tagsToRemove = [];

// public contents
let _public_contents = [];
let _public_num_found = 0;
let _public_query_time = 0;
let _content_guest = {}

let _content_id_to_add = '';
let _content_id_to_navigate = '';

// share content and link
let _share_link = '';
let _share_content = {};

// permalink content and link
let _permalink = ''
let _permalink_content = {}

// follow contents
let _follow_contents = [];
let _follow_num_found = 0;
let _follow_query_time = 0;

//Network
let _network = [];

//Is public permalink
let _is_public_permalink = {}

function setContents(contents) {
  _contents = contents;
}

function setContent(content) {
  _content = content;
}

function setNumFound(numFound) {
  _numFound = numFound;
}

function setQueryTime(queryTime) {
  _queryTime = queryTime;
}

function setContentsToExport(contents) {
  _contents_export = contents;
}

function setTopEditor(flag) {
  _topEditorVisible = flag
}

function setContentIdToEdit(content_id_to_edit) {
  _content_id_to_edit = content_id_to_edit
}

function setTagFilter(tagsToRemove) {
  _tagsToRemove = tagsToRemove
}

function setPublicContents(contents, num_found, query_time) {
  _public_contents = contents
  _public_num_found = num_found
  _public_query_time = query_time
}

function setContentIdToAdd(content_id) {
  _content_id_to_add = content_id
}

function resetContentIdToAdd() {
  _content_id_to_add = ''
}

function setContentIdToNavigate(content_id) {
  _content_id_to_navigate = content_id
}

function resetNavigateRequest() {
  _content_id_to_navigate = ''
}

function setShareLink(sharelink) {
  _share_link = sharelink
}

function setShareContent(content) {
  _share_content = content;
}

function setPermalink(permalink) {
  _permalink = permalink
}

function setPermalinkContent(content) {
  _permalink_content = content;
}

function setContentGuest(content) {
  _content_guest = content;
}
function setIsPublicPermalink(content) {
  _is_public_permalink = content
}

function setFollowContents(contents, num_found, query_time) {
  _follow_contents = contents
  _follow_num_found = num_found
  _follow_query_time = query_time
}

function setNetwork(network) {
  _network = network
}

class ContentStoreClass extends EventEmitter {

  emitChange(arg) {
    this.emit(CHANGE_EVENT, arg)
  }

  emitConfirm() {
    this.emit(CONFIRM_EVENT)
  }

  emitUpdated() {
    this.emit(UPDATED_EVENT)
  }

  emitDuplicated(arg1, arg2, arg3) {
    this.emit(DUPLICATED_EVENT, arg1, arg2, arg3)
  }

  emitEdit(arg) {
    this.emit(EDIT_EVENT, arg)
  }

  emitExport() {
    this.emit(EXPORT_EVENT)
  }

  emitTopEditorChange() {
    this.emit(TOP_EDITOR_EVENT)
  }

  emitDeleted() {
    this.emit(DELETED_EVENT)
  }

  emitPublicChange(arg) {
    this.emit(CHANGE_PUBLIC_CONTENTS_EVENT, arg);
  }

  emitAddToMyAccountRequest(arg) {
    this.emit(ADD_TO_MY_ACCOUNT_ON_EVENT, arg)
  }

  emitAddToMyAccountReset() {
    this.emit(ADD_TO_MY_ACCOUNT_OFF_EVENT)
  }

  emitNavigateToContentRequest(arg) {
    this.emit(NAVIGATE_TO_SAVE_ON_EVENT, arg)
  }

  emitNavigateToContentReset() {
    this.emit(NAVIGATE_TO_SAVE_OFF_EVENT)
  }

  emitShareLinkChange() {
    this.emit(CHANGE_SHARE_LINK_EVENT)
  }

  emitPermalinkChange() {
    this.emit(CHANGE_PERMALINK_CONTENT_EVENT)
  }

  emitContentGuest() {
    this.emit(CHANGE_CONTENT_GUEST)
  }

  emitIsPublicPermalink() {
    this.emit(CHANGE_IS_PUBLIC_PERMALINK)
  }

  emitShareContentChange() {
    this.emit(CHANGE_SHARE_CONTENT_EVENT)
  }

  emitFollowChange(arg) {
    this.emit(CHANGE_FOLLOW_CONTENTS_EVENT, arg);
  }

  emitNetworkChange() {
    this.emit(CHANGE_NETWORK_EVENT)
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  addConfirmListener(callback) {
    this.on(CONFIRM_EVENT, callback)
  }

  addUpdatedListener(callback) {
    this.on(UPDATED_EVENT, callback)
  }

  addDuplicatedListener(callback) {
    this.on(DUPLICATED_EVENT, callback)
  }

  addExportListener(callback) {
    this.on(EXPORT_EVENT, callback)
  }

  addEditListener(callback) {
    this.on(EDIT_EVENT, callback)
  }

  addTopEditorListener(callback) {
    this.on(TOP_EDITOR_EVENT, callback)
  }

  addDeletedListener(callback) {
    this.on(DELETED_EVENT, callback)
  }

  addPublicContentChangeListener(callback) {
    this.on(CHANGE_PUBLIC_CONTENTS_EVENT, callback)
  }

  addAddToMyAccountRequestListener(callback) {
    this.on(ADD_TO_MY_ACCOUNT_ON_EVENT, callback)
  }

  addAddToMyAccountResetListener(callback) {
    this.on(ADD_TO_MY_ACCOUNT_OFF_EVENT, callback)
  }

  addNavigateToContentRequestListener(callback) {
    this.on(NAVIGATE_TO_SAVE_ON_EVENT, callback)
  }

  addNavigateToContentResetListener(callback) {
    this.on(NAVIGATE_TO_SAVE_OFF_EVENT, callback)
  }

  addShareLinkChangeListener(callback) {
    this.on(CHANGE_SHARE_LINK_EVENT, callback)
  }

  addPermalinkChangeListener(callback) {
    this.on(CHANGE_PERMALINK_EVENT, callback)
  }

  addContentGuestListener(callback) {
    this.on(CHANGE_CONTENT_GUEST, callback)
  }

  addIsPublicPermalinkListener(callback) {
    this.on(CHANGE_IS_PUBLIC_PERMALINK, callback)
  }

  addShareContentChangeListener(callback) {
    this.on(CHANGE_SHARE_CONTENT_EVENT, callback)
  }

  addPermalinkContentChangeListener(callback) {
    this.on(CHANGE_PERMALINK_CONTENT_EVENT, callback)
  }

  addFollowContentChangeListener(callback) {
    this.on(CHANGE_FOLLOW_CONTENTS_EVENT, callback)
  }

  addNetworkChangeListener(callback) {
    this.on(CHANGE_NETWORK_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  removeConfirmListener(callback) {
    this.removeListener(CONFIRM_EVENT, callback)
  }

  removeUpdatedListener(callback) {
    this.removeListener(UPDATED_EVENT, callback)
  }

  removeDuplicatedListener(callback) {
    this.removeListener(DUPLICATED_EVENT, callback)
  }

  removeEditListener(callback) {
    this.removeListener(EDIT_EVENT, callback)
  }

  removeExportListener(callback) {
    this.removeListener(EXPORT_EVENT, callback)
  }

  removeTopEditorListener(callback) {
    this.removeListener(TOP_EDITOR_EVENT, callback)
  }

  removeDeletedListener(callback) {
    this.removeListener(DELETED_EVENT, callback)
  }

  removePublicContentChangeListener(callback) {
    this.removeListener(CHANGE_PUBLIC_CONTENTS_EVENT, callback)
  }

  removeAddToMyAccountRequestListener(callback) {
    this.removeListener(ADD_TO_MY_ACCOUNT_ON_EVENT, callback)
  }

  removeAddToMyAccountResetListener(callback) {
    this.removeListener(ADD_TO_MY_ACCOUNT_OFF_EVENT, callback)
  }

  removeNavigateToContentRequestListener(callback) {
    this.removeListener(NAVIGATE_TO_SAVE_ON_EVENT, callback)
  }

  removeNavigateToContentResetListener(callback) {
    this.removeListener(NAVIGATE_TO_SAVE_OFF_EVENT, callback)
  }

  removeShareLinkChangeListener(callback) {
    this.removeListener(CHANGE_SHARE_LINK_EVENT, callback)
  }

  removePermalinkChangeListener(callback) {
    this.removeListener(CHANGE_PERMALINK_EVENT, callback)
  }

  removeContentGuestListener(callback) {
    this.removeListener(CHANGE_CONTENT_GUEST, callback)
  }

  removeIsPublicPermalink(callback) {
    this.removeListener(CHANGE_IS_PUBLIC_PERMALINK, callback)
  }

  removeShareContentChangeListener(callback) {
    this.removeListener(CHANGE_SHARE_CONTENT_EVENT, callback)
  }

  removeFollowContentChangeListener(callback) {
    this.removeListener(CHANGE_FOLLOW_CONTENTS_EVENT, callback)
  }

  removeNetworkChangeListener(callback) {
    this.removeListener(CHANGE_NETWORK_EVENT, callback)
  }

  getContents() {
    return _contents;
  }

  getContent() {
    return _content;
  }

  getNumFound() {
    return _numFound;
  }

  getQueryTime() {
    return _queryTime;
  }

  getContentsToExport() {
    return _contents_export;
  }

  getTopEditor() {
    return _topEditorVisible;
  }

  getContentIdToEdit() {
    return _content_id_to_edit;
  }

  getTagFilter() {
    return _tagsToRemove;
  }

  getPublicContents() {
    return _public_contents;
  }

  getPublicNumFound() {
    return _public_num_found;
  }

  getPublicQueryTime() {
    return _public_query_time;
  }

  getContentIdToAdd() {
    return _content_id_to_add;
  }

  getContentIdToNavigate() {
    return _content_id_to_navigate;
  }

  getShareLink() {
    return _share_link
  }

  getPermalink() {
    return _permalink
  }

  getContentGuest() {
    return _content_guest
  }

  getIsPublicPermalink() {
    return _is_public_permalink
  }

  getShareContent() {
    return _share_content;
  }

  getPermalinkContent() {
    return _permalink_content;
  }

  getFollowContents() {
    return _follow_contents;
  }

  getFollowNumFound() {
    return _follow_num_found;
  }

  getFollowQueryTime() {
    return _follow_query_time;
  }

  getNetwork() {
    return _network;
  }
}

const ContentStore = new ContentStoreClass();

ContentStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case ContentConstants.RECEIVE_CONTENTS:
      setContents(action.contents);
      setNumFound(action.numFound);
      setQueryTime(action.queryTime);
      ContentStore.emitChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_CONTENTS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_NOTAGS_CONTENTS:
      setContents(action.contents);
      setNumFound(action.numFound);
      setQueryTime(action.queryTime);
      ContentStore.emitChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_NOTAGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_CONTENT:
      setContent(action.content);
      ContentStore.emitChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_CONTENT_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.SEND_CONTENT:
      ContentStore.emitConfirm();
      break;

    case ContentConstants.SEND_CONTENT_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.SEND_CONTENT_DUPLICATE:
      ContentStore.emitDuplicated(action.id, action.content_id, 'send');
      break;

    case ContentConstants.DELETE_CONTENT:
      setTagFilter(action.toRemoveTags)
      ContentStore.emitDeleted();
      break;

    case ContentConstants.DELETE_CONTENT_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.PUT_CONTENT:
      setContent(action.content)
      ContentStore.emitUpdated();
      break;

    case ContentConstants.PUT_CONTENT_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.PUT_CONTENT_DUPLICATE:
      ContentStore.emitDuplicated(action.id, action.content_id, 'put');
      break;

    case ContentConstants.EDIT_CONTENT:
      ContentStore.emitEdit(action.id);
      break

    case ContentConstants.EXPORT_CONTENT:
      setContentsToExport(action.contents);
      ContentStore.emitExport();
      break;

    case ContentConstants.EXPORT_CONTENT_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.ENABLE_TOP_EDITOR:
      setTopEditor(true);
      setContentIdToEdit(action.content_id_to_edit)
      ContentStore.emitTopEditorChange();
      break

    case ContentConstants.DISABLE_TOP_EDITOR:
      setTopEditor(false);
      ContentStore.emitTopEditorChange();
      break

    case ContentConstants.RECEIVE_PUBLIC_CONTENTS:
    case ContentConstants.RECEIVE_PUBLIC_CONTENTS_BY_ACCOUNT:
      setNetwork(action.network);
      setPublicContents(action.contents, action.numFound, action.queryTime);
      ContentStore.emitPublicChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_PUBLIC_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.ADD_TO_MY_ACCOUNT_ON:
      setContentIdToAdd(action.content_id)
      ContentStore.emitAddToMyAccountRequest(action.content_id);
      break

    case ContentConstants.ADD_TO_MY_ACCOUNT_OFF:
      resetContentIdToAdd();
      ContentStore.emitAddToMyAccountReset();
      break

    case ContentConstants.NAVIGATE_TO_SAVE_ON:
      setContentIdToNavigate(action.content_id)
      ContentStore.emitNavigateToContentRequest(action.content_id);
      break

    case ContentConstants.NAVIGATE_TO_SAVE_OFF:
      resetNavigateRequest();
      ContentStore.emitNavigateToContentReset();
      break

    case ContentConstants.RECEIVE_SHARE_LINK:
      setShareLink(action.sharelink);
      ContentStore.emitShareLinkChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_PERMALINK:
      setPermalink(action.permalink);
      ContentStore.emitPermalinkChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_SHARE_LINK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_PERMALINK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_CONTENT_BY_SHARE_LINK:
      setShareContent(action.content);
      ContentStore.emitShareContentChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_CONTENT_BY_PERMALINK:
      setPermalinkContent(action.content);
      ContentStore.emitShareContentChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_CONTENT_BY_SHARE_LINK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_CONTENT_BY_PERMALINK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_FOLLOW_CONTENTS:
      setFollowContents(action.contents, action.numFound, action.queryTime);
      ContentStore.emitFollowChange(action.actionType);
      break;

    case ContentConstants.RECEIVE_FOLLOW_CONTENTS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_NETWORK:
      setNetwork(action.network);
      ContentStore.emitNetworkChange();
      break;

    case ContentConstants.RECEIVE_NETWORK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.ADD_TO_MY_NETWORK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.REMOVE_FROM_MY_NETWORK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case ContentConstants.RECEIVE_CONTENT_GUEST:
      setContentGuest(action.content);
      ContentStore.emitContentGuest(action.actionType);
      break;
    case ContentConstants.RECEIVE_IS_PUBLIC_PERMALINK:
      setIsPublicPermalink(action.content);
      ContentStore.emitIsPublicPermalink(action.actionType);
      break;
    case ContentConstants.RECEIVE_IS_PUBLIC_PERMALINK_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    default:
  }

});

export default ContentStore;
