import AppDispatcher from '../dispatcher/AppDispatcher';
import PlanConstants from '../constants/PlanConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const CHANGE_EVENT = 'change';

let _plans = [];

function setPlans(plans) {
  _plans = plans;
}

class PlanStoreClass extends EventEmitter {

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  getPlans() {
    return _plans;
  }
}

const PlanStore = new PlanStoreClass();

PlanStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case PlanConstants.RECEIVE_PLANS:
      setPlans(action.plans);
      PlanStore.emitChange();
      break;

    case PlanConstants.RECEIVE_PLANS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }

});

export default PlanStore;
