import AppDispatcher from '../dispatcher/AppDispatcher';
import TagActions from '../actions/TagActions';
import TagConstants from '../constants/TagConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const CHANGE_EVENT = 'change';
const CHECK_MERGE_EVENT = 'check_merge';
const CHANGE_CONTENTS_TAGS_EVENT = 'change_contents_tags'

const componentId = 'tagStore';

let _tags = [];
let _all_tags = [];
let _contents_tags =[];
let _tag = {};
let _public_tags = [];

function setTags(tags) {
  _tags = tags;
}

function setTag(tag) {
  _tag = tag;
}

function setAllTags(tags) {
  _all_tags = tags;
}

function setContentsTags(tags) {
  _contents_tags = tags;
}

function setPublicTags(tags) {
  _public_tags = tags;
}

class TagStoreClass extends EventEmitter {

  emitChange() {
    setTimeout(() => this.emit(CHANGE_EVENT), 0);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }

  emitChangeContentsTags() {
    setTimeout(() => this.emit(CHANGE_CONTENTS_TAGS_EVENT), 0);
  }

  addChangeContentsTagsListener(callback) {
    this.on(CHANGE_CONTENTS_TAGS_EVENT, callback)
  }

  removeChangeContentsTagsListener(callback) {
    this.removeListener(CHANGE_CONTENTS_TAGS_EVENT, callback)
  }

  emitCheckMerge(arg) {
    this.emit(CHECK_MERGE_EVENT, arg);
  }

  addCheckMergeListener(callback) {
    this.on(CHECK_MERGE_EVENT, callback)
  }

  removeCheckMergeListener(callback) {
    this.removeListener(CHECK_MERGE_EVENT, callback)
  }

  getPublicTags() {
    return _public_tags;
  }

  getAllTags() {
    return _all_tags;
  }

  getContentsTags() {
    return _contents_tags;
  }

  getTags() {
    return _tags;
  }
  getTag() {
    return _tag;
  }

  setPage(page) {
    this.page = page
  }

  getPage() {
    return this.page
  }

  setSizePerPage(size) {
    this.size_per_page = size
  }

  getSizePerPage() {
    return this.size_per_page
  }
}

const TagStore = new TagStoreClass();

TagStore.dispatchToken = AppDispatcher.register(action => {
  switch (action.actionType) {
    case TagConstants.RECEIVE_TAGS:
      setTags(action.tags);
      TagStore.emitChange();
      break;

    case TagConstants.RECEIVE_ALL_TAGS:
      setAllTags(action.tags);
      TagStore.emitChange();
      break;

    case TagConstants.RECEIVE_TAGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case TagConstants.RECEIVE_TAG:
      setTag(action.tag);
      TagStore.emitChange();
      break;

    case TagConstants.RECEIVE_TAG_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case TagConstants.DELETE_TAG:
      TagActions.recieveAllTags(componentId);
      TagActions.recieveTags(componentId, TagStore.getPage(), TagStore.getSizePerPage());
      break;

    case TagConstants.DELETE_TAG_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case TagConstants.PUT_TAG:
      TagActions.recieveAllTags(componentId);
      TagActions.recieveTags(componentId, TagStore.getPage(), TagStore.getSizePerPage());
      break;

    case TagConstants.PUT_TAG_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case TagConstants.CHECK_MERGE_TAG:
      TagStore.emitCheckMerge(action.result);
      break;

    case TagConstants.CHECK_MERGE_TAG_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case TagConstants.RECEIVE_PREFIX_TAGS:
      setPublicTags(action.tags);
      TagStore.emitChange();
      break;

    case TagConstants.RECEIVE_PREFIX_TAGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case TagConstants.RECEIVE_ALL_CONTENTS_TAGS:
      setContentsTags(action.tags);
      TagStore.emitChangeContentsTags();
      break;

    case TagConstants.RECEIVE_ALL_CONTENTS_TAGS_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }

});

export default TagStore;
