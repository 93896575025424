import React, { Component } from 'react';
import ConfirmDialogYesNo from './ConfirmDialogYesNo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Modal, Alert, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/HelpBlock.css';

const TAG_KEY_ONE = process.env.TAG_KEY_ONE;
const TAG_KEY_TWO = process.env.TAG_KEY_TWO;
const TAG_COMMAND_ONE = process.env.TAG_COMMAND_ONE;
const TAG_COMMAND_TWO = process.env.TAG_COMMAND_TWO;

class TagRename extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      id: props.id,
      new_tag: props.id,
      validationMessage: '',
      disabledSave: true,
      showConfirm: false,
      command: ''
    };
    this.onYesConfirm = this.onYesConfirm.bind(this);
    this.onNoConfirm = this.onNoConfirm.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({ new_tag: e.target.value }, () => {
      if (this.state.new_tag.length === 0 || !this.state.new_tag.trim()) {
        this.setState({
          disabledSave: true
        })
      }
      else {
        this.setState({
          disabledSave: false
        })
      }
    });
  }

  /* Form submit */
  handleFormSubmit(e) {
    e.preventDefault();
    var new_tag = this.state.new_tag;
    var old_tag = this.state.id;
    let msg = new_tag.trim().length === 0 ? 'New tag name is required.' : '';
    msg = new_tag.trim() === old_tag.trim() ? 'The tag name cannot be the same as the previous one' : '';
    this.state.new_tag = new_tag;
    this.setState({ validationMessage: msg });
    if (msg === '') {
      this.handleClose();
      this.props.handleFormSubmit && this.props.handleFormSubmit(old_tag, new_tag);
    }
  }

  /* Popup close */
  handleClose() {
    var backup_tag = this.state.id
    this.setState({
      show: false,
      new_tag: backup_tag,
      validationMessage: ''
    });
  }

  /* Popup show */
  handleShow() {
    if (this.state.id == TAG_KEY_ONE || this.state.id == TAG_KEY_TWO){
      if (this.state.id == TAG_KEY_ONE) {
        this.setState({ command: TAG_COMMAND_ONE });
      }
      if (this.state.id == TAG_KEY_TWO) {
        this.setState({ command: TAG_COMMAND_TWO });
      }
      this.setState({ showConfirm: true });
    } else {
      this.setState({ command: '' });
      this.setState({ show: true });
    }
  }

  /* Yes confirm dialog */
  onYesConfirm() {
    this.setState({ show: true });
    this.setState({ showConfirm: false });
  }

  /* No confirm dialog */
  onNoConfirm() {
    this.setState({ showConfirm: false });
  }

  handleKeyPress(key) {
    if (key.charCode === 13) {
      this.handleFormSubmit(key);
    }
  }

  render() {
    const { id, new_tag, show, disabledSave, showConfirm } = this.state;
    const tooltip_edit = <Tooltip id="modal-tooltip">Rename tag</Tooltip>;
    const handleFocus = (event) => event.target.select();
    const message = 'Current tag "' + this.state.id + '" functionality is to mark ' + this.state.command + ' content items. If you rename it those content items will become private.'
    return (
      <div onKeyPress={this.handleKeyPress}>
        <OverlayTrigger overlay={tooltip_edit}>
          <Button data-testid="tag-rename-id" className="btn-tag-rename" variant="warning" size="sm" onClick={this.handleShow}>
            <span><FontAwesomeIcon icon={faEdit} /></span>
          </Button>
        </OverlayTrigger>
        <Modal show={show} onHide={this.handleClose} className='my-tags-modal-rename'>
          <Form onSubmit={this.handleFormSubmit} autoComplete="off">
            <Modal.Header closeButton>
              <Modal.Title>Rename Tag: {id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId='new_name'>
                <Form.Label>Enter new tag name</Form.Label >
                <Form.Control data-testid="tag-rename-input-id" value={new_tag} onChange={this.handleInputChange} onFocus={handleFocus} autoFocus={true} tabIndex={0} />
                <Alert variant={'light'} className="help-block" >{this.state.validationMessage}</Alert>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" className="signal34-bnt-default" variant="outline-secondary" onClick={this.handleClose}>Close</Button>
              <Button type='submit' className="signal34-bnt-default" variant="outline-secondary" disabled={disabledSave}>Save</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <ConfirmDialogYesNo
            message={message}
            title={'Please confirm:'}
            onNoConfirm={this.onNoConfirm}
            onYesConfirm={this.onYesConfirm}
            show={showConfirm}
            yesText={'OK'}
            noText={'Cancel'}
          />
      </div>
    );
  }
}

export default TagRename;
