import React, { Component } from 'react';
import TagActions from '../actions/TagActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { Modal, Button, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';

class ConfirmDialogDelete extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      show: false,
      id: props.id,
      type: props.type
    };
    this.componentId = 'confirmdialogdelete'
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.handleClose();
    TagActions.deleteTag(this.componentId, encodeURIComponent(this.state.id));
  }

  render() {
    const tooltip_remove = <Tooltip id='modal-tooltip'>Remove tag</Tooltip>;
    return (
      <div>
        <OverlayTrigger overlay={tooltip_remove}>
          <Button data-testid="tag-delete-id" className='btn-tag-delete' variant='danger' size='sm' onClick={this.handleShow}>
            <span><FontAwesomeIcon icon={faTimesCircle} /></span>
          </Button>
        </OverlayTrigger>
        <Modal show={this.state.show} onHide={this.handleClose} className="my-tags-modal-delete">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.id}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleFormSubmit}>
            <Modal.Body>
              <p>Are you sure you want to delete this tag?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className='btn-no-delete-tag' variant='secondary' onClick={this.handleClose}>No</Button>
              <Button className='btn-delete-tag' variant='danger' type='submit' autoFocus={true} tabIndex={0} >Yes</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDialogDelete;
