export const MENU_KEY_CONTENTS = 1
export const MENU_KEY_TAGS = 2
export const MENU_KEY_UNTAGGED = 3
export const MENU_KEY_ALL_CONTENTS = 4
export const MENU_KEY_SUBSCRIPTION = 5
export const MENU_KEY_FOLLOW = 6
export const MENU_KEY_SETTINGS = 7
export const MENU_KEY_ACCOUNT_NAME = 8
export const MENU_KEY_PERMALINK = 9
export const MENU_SHARED_LINK = 10
