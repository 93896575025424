import AppDispatcher from '../dispatcher/AppDispatcher';
import TagConstants from '../constants/TagConstants';
import tagsAPI from '../apis/TagsAPI';

export default {
  recieveTags: (source, page, per_page) => {
    tagsAPI
      .getTags(page, per_page)
      .then(tags => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_TAGS,
          tags: tags
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_TAGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  recieveAllTags: (source, query, query_by_text) => {
    tagsAPI
      .getAllTags(query, query_by_text)
      .then(tags => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_ALL_TAGS,
          tags: tags
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_TAGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  recievePublicTags: (source) => {
    tagsAPI
      .prefixSearch('','','','','')
      .then(tags => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_PREFIX_TAGS,
          tags: tags
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_PREFIX_TAGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  getTag: (source, content_id) => {
    tagsAPI
      .getTag(content_id)
      .then(tag => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_TAG,
          tag: tag
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.RECEIVE_TAG_ERROR,
          message: message,
          source: source
        });
      });
  },
  createTag: (source, content_id, tag) => {
    tagsAPI
      .createTag(content_id, tag)
      .then(tag => {
        AppDispatcher.dispatch({
          actionType: TagConstants.PUT_TAG,
          tag: tag
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.PUT_TAG_ERROR,
          message: message,
          source: source
        });
      });
  },
  renameTag: (source, old_tag, new_tag) => {
    tagsAPI
      .updateTag(old_tag, new_tag)
      .then(tag => {
        AppDispatcher.dispatch({
          actionType: TagConstants.PUT_TAG,
          tag: tag
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.PUT_TAG_ERROR,
          message: message,
          source: source
        });
      });
  },
  deleteTag: (source, tag) => {
    tagsAPI
      .deleteTag(tag)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: TagConstants.DELETE_TAG
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.DELETE_TAG_ERROR,
          message: message,
          source: source
        });
      });
  },
  checkMergeTag: (source, old_tag, new_tag) => {
    tagsAPI
      .checkMergeTag(old_tag, new_tag)
      .then(res => {
        AppDispatcher.dispatch({
          actionType: TagConstants.CHECK_MERGE_TAG,
          result: res
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: TagConstants.CHECK_MERGE_TAG_ERROR,
          message: message,
          source: source
        });
      });
  }
}
