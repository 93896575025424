
import React from 'react';

const Checkbox = (props) => {
  var direction = props.direction ? props.direction : 'right'
  if (direction == 'right') {
    return (
      <label htmlFor={props.id} className={props.className}>
        <input
          type='checkbox'
          id={props.id}
          name={props.name ? props.name : props.label}
          checked={props.isSelected}
          onChange={props.onCheckboxChange}
          disabled={props.disabled}
          ref={props.reference}
        />
        <span>
          {props.label}
        </span>
      </label>
    )
  } else if (direction == 'left') {
    return (
      <div>
        <label htmlFor={props.id}  className={props.className}>{props.label}</label>
        <div className={props.classNameCheckbox}>
          <input
            type='checkbox'
            id={props.id}
            name={props.name ? props.name : props.label}
            checked={props.isSelected}
            onChange={props.onCheckboxChange}
            disabled={props.disabled}
            ref={props.reference}
          />
        </div>
      </div>
    )
  }
};

export default Checkbox;
