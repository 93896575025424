import AppDispatcher from '../dispatcher/AppDispatcher';
import SettingConstants from '../constants/SettingConstants';

export default {
  receiveSettings: () => {
    AppDispatcher.dispatch({
      actionType: SettingConstants.RECEIVE_PUBLIC_SETTINGS
    });
  },
  updateSettings: (sort_by, sort_order, per_page, open_links_new_tab) => {
    AppDispatcher.dispatch({
      actionType: SettingConstants.CHANGE_PUBLIC_SETTINGS,
      settings: { 'sort_by': sort_by, 'sort_order': sort_order, 'results_per_page': per_page, 'open_links_new_tab': open_links_new_tab }
    });
  }
}
