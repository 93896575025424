export default {
  MAXLEN_TAG_LABEL: 18,
  MAXLEN_NOTES: 5242880,
  MAXLEN_LOCATION: 32766,
  hashToString: (hash) => {
    let msg = [];
    if (hash) {
      Object.keys(hash).forEach(function (key) { msg.push(hash[key]) })
      return msg.join('\n');
    }
    else {
      return msg;
    }
  },
  getDate: (dateTime) => {
    var date_content = new Date(dateTime);
    var date = date_content.toLocaleDateString();
    var time = date_content.toLocaleTimeString();
    return (date + ' at ' + time);
  },
  removeDuplicatesArray: (arr) => {
    let unique_array = Array.from(new Set(arr.map(s => s.trim())))
    return unique_array
  },
  errorMessages: (json) => {
    let msg = {};
    Object.keys(json).forEach(function (key) {
      msg[key] = key.toString().replace('_', ' ') + ' ' + json[key]
    });
    return msg;
  },
  checkExist: (arr, val) => {
    if (arr.length === 0) return false;
    return arr.some(item => val.trim() === item.label.trim());
  },
  createOption: (label) => ({
    label,
    value: label
  }),
  buildList: (value) => {
    if (typeof value !== 'undefined' && Array.isArray(value)) {
      var items = value.map(function (item) {
        return item.value.trim();
      });
      return items;
    }
    return [];
  },
  encodeSolrQuery: (val) => {
    var pattern = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"])/g;
    var value = val.replace(pattern, '\\$1');
    return encodeURIComponent(value);
  },
  doubleEncodeSolrQuery: (val) => {
    var pattern = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"])/g;
    var value = val.replace(pattern, '\\$1');
    value = value.replace(/\\/g, '\\\\');
    value = value.replace(/\"/g, '\\\"');
    return encodeURIComponent(value);
  },
  score2start: (val) => {
    var map = { 0: 0, 1: 0.5, 2: 1, 3: 1.5, 4: 2, 5: 2.5, 6: 3, 7: 3.5, 8: 4, 9: 4.5, 10: 5 };
    return val > 0 ? map[val] : 0;
  },
  start2score: (val) => {
    var map = { 0: 0, 0.5: 1, 1: 2, 1.5: 3, 2: 4, 2.5: 5, 3: 6, 3.5: 7, 4: 8, 4.5: 9, 5: 10 };
    return val > 0 ? map[val] : 0;
  }
}
