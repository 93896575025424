import AppDispatcher from '../dispatcher/AppDispatcher';
import SubscriptionConstants from '../constants/SubscriptionConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const CHANGE_EVENT = 'change';
const CREATED_EVENT = 'created';
const UPDATED_PAYMENT_METHOD_EVENT = 'update_payment_method'
const CHANGE_PAYMENT_EVENT = 'change_payment'
const SET_AUTO_RENEW_EVENT = 'set_auto_renew'
const UPDATED_ACCOUNT_PROFILE_EVENT = 'update_account_profile'
const VALIDATE_ACCOUNT_PROFILE_EVENT = 'validate_account_profile'

let _subscription = null;
let _result = null;
let _account_profile = null;

function setSubscription(subscription) {
  _subscription = subscription;
}

function setResult(result) {
  _result = result
}

function setAccountProfileResult(account_profile) {
  _account_profile = account_profile
}

class SubscriptionStoreClass extends EventEmitter {

  emitChange(arg) {
    this.emit(CHANGE_EVENT, arg);
  }

  emitChangePayment(arg) {
    this.emit(CHANGE_PAYMENT_EVENT, arg);
  }

  emitCreate() {
    this.emit(CREATED_EVENT);
  }

  emitUpdatePaymentMethod() {
    this.emit(UPDATED_PAYMENT_METHOD_EVENT);
  }

  emitSetAutoRenew() {
    this.emit(SET_AUTO_RENEW_EVENT);
  }

  emitUpdateAccountProfile() {
    this.emit(UPDATED_ACCOUNT_PROFILE_EVENT);
  }

  emitValidateAccountProfile() {
    this.emit(VALIDATE_ACCOUNT_PROFILE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  addCreatedListener(callback) {
    this.on(CREATED_EVENT, callback)
  }

  addUpdatePaymentMethodListener(callback) {
    this.on(UPDATED_PAYMENT_METHOD_EVENT, callback);
  }

  addSetAutoRenewListener(callback) {
    this.on(SET_AUTO_RENEW_EVENT, callback);
  }

  addChangePaymentListener(callback) {
    this.on(CHANGE_PAYMENT_EVENT, callback);
  }

  addUpdateAccountProfile(callback) {
    this.on(UPDATED_ACCOUNT_PROFILE_EVENT, callback);
  }

  addValidateAccountProfile(callback){
    this.on(VALIDATE_ACCOUNT_PROFILE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  removeCreatedListener(callback) {
    this.removeListener(CREATED_EVENT, callback);
  }

  removeUpdatePaymentMethodListener(callback) {
    this.removeListener(UPDATED_PAYMENT_METHOD_EVENT, callback);
  }

  removeAutoRenewListener(callback) {
    this.removeListener(SET_AUTO_RENEW_EVENT, callback);
  }

  removeChangePaymentListener(callback) {
    this.removeListener(CHANGE_PAYMENT_EVENT, callback);
  }

  removeUpdateAccountProfile(callback) {
    this.removeListener(UPDATED_ACCOUNT_PROFILE_EVENT, callback);
  }

  removeValidateAccountProfile(callback){
    this.removeAllListeners(VALIDATE_ACCOUNT_PROFILE_EVENT, callback);
  }

  getSubscription() {
    return _subscription;
  }

  getSubscriptionResult() {
    return _result;
  }

  getAccountProfileResult() {
    return _account_profile;
  }
}

const SubscriptionStore = new SubscriptionStoreClass();

SubscriptionStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case SubscriptionConstants.RECEIVE_SUBSCRIPTION:
      setSubscription(action.subscription_data);
      SubscriptionStore.emitChange(action.actionType);
      break;

    case SubscriptionConstants.RECEIVE_SUBSCRIPTION_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SubscriptionConstants.CREATE_SUBSCRIPTION:
      setResult(action.subscription_result)
      SubscriptionStore.emitCreate(action.actionType);
      break;

    case SubscriptionConstants.CREATE_SUBSCRIPTION_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SubscriptionConstants.CHANGE_SUBSCRIPTION_PAYMENT:
      SubscriptionStore.emitChangePayment(action.subscription);
      break;

    case SubscriptionConstants.UPDATE_PAYMENT_METHOD:
      setResult(action.subscription_result)
      SubscriptionStore.emitUpdatePaymentMethod(action.actionType);
      break;

    case SubscriptionConstants.UPDATE_PAYMENT_METHOD_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SubscriptionConstants.SET_AUTO_RENEW:
      setResult(action.subscription_result)
      SubscriptionStore.emitSetAutoRenew(action.actionType);
      break;

    case SubscriptionConstants.SET_AUTO_RENEW_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SubscriptionConstants.UPDATE_ACCOUNT_PROFILE:
      setAccountProfileResult(action.subscription_result)
      SubscriptionStore.emitUpdateAccountProfile(action.actionType);
      break;

    case SubscriptionConstants.UPDATE_ACCOUNT_PROFILE_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SubscriptionConstants.VALIDATE_ACCOUNT_PROFILE:
      setAccountProfileResult(action.subscription_result)
      SubscriptionStore.emitValidateAccountProfile(action.actionType);
      break;

    case SubscriptionConstants.VALIDATE_ACCOUNT_PROFILE_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }
});

export default SubscriptionStore;
