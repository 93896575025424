import AppDispatcher from '../dispatcher/AppDispatcher';
import VersionConstants from '../constants/VersionConstants';
import VersionAPI from '../apis/VersionAPI';

export default {
  receiveVersion: (source) => {
    VersionAPI
      .getVersion()
      .then(data => {
        AppDispatcher.dispatch({
          actionType: VersionConstants.RECEIVE_VERSION,
          version: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: VersionConstants.RECEIVE_VERSION_ERROR,
          message: message,
          source: source
        });
      });
  }
}