import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { createFilter } from 'react-select'
import AsyncSelect from 'react-select/async'
import FilterActions from '../actions/FilterActions'
import FilterStore from '../stores/FilterStore'
import TagsAPI from '../apis/TagsAPI'
import FiltersBuilder from '../utils/FiltersBuilder'
const _ = require('lodash')

const defaultBackgroundColor = 'var(--primaryBackgroundColor)'
const defaultTextColor = 'var(--primaryTextColor)'
const defaultBarSelectColor = 'var(--signal34SearchSelectBarColor)'
const defaultMenuListColor = 'var(--signal34MenuListColor)'
const transparentBackgroundColor = 'transparent'

const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'var(--primaryBackgroundColor)' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? defaultBackgroundColor
          : isFocused
            ? defaultBarSelectColor
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? defaultTextColor
          : isFocused
            ? defaultTextColor
            : null
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: transparentBackgroundColor
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: defaultMenuListColor
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      backgroundColor: defaultBackgroundColor,
      borderColor: defaultBackgroundColor,
      color: defaultTextColor
    };
  }
}

class PublicSearchByNotTagFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      tagsFilterOptions: null,
      options: [],
      inputValue: '',
      query: '',
      filter_query: '',
      tagsFiltered: [],
      defaultOptions: []
    }
    this.onFilterChanged = this.onFilterChanged.bind(this)
    this.onFilterClear = this.onFilterClear.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.resetSelection = this.resetSelection.bind(this)
    FilterStore.addChangeListener(this.onFilterChanged)
    FilterStore.addClearListener(this.onFilterClear)
    this.getOptions = _.debounce(this.getOptions.bind(this), 100)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.loadDefaultOptions = this.loadDefaultOptions.bind(this)
  }

  componentWillUnmount() {
    FilterStore.removeChangeListener(this.onFilterChanged);
    FilterStore.removeClearListener(this.onFilterClear);
  }

  onFilterClear() {
    this.setState({
      selectedOption: null,
      tagsFilterOptions: null
    });
  }

  getDiffTags(tagsfilter, tags) {
    var diff = []
    if (tags) {
      diff = tags.filter(tag =>
        !tagsfilter.some(tagfiltered => (tagfiltered.value === tag.value && tagfiltered.label === tag.label)))
    }
    return diff;
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  onFilterChanged() {
    // Get filter by tags
    var options = FilterStore.getTag();
    var tags = []
    options.map(function (item) {
      tags.push({ value: item.value, label: item.value })
    })
    var query = FiltersBuilder.createFilterByTags(options);
    var filter_text_query = '';

    // Get filter by date
    var dateFilter = FilterStore.getDate();
    if (dateFilter !== null && dateFilter !== '') {
      var dateFilterTxt = FiltersBuilder.createFilterByDate(query, dateFilter)
      query += dateFilterTxt
    }

    // Get filter by text
    var textFilter = FilterStore.getText();
    var fieldsFilter = FilterStore.getFields();
    if (textFilter !== null && textFilter !== '' && fieldsFilter !== {}) {
      var querytxt = FiltersBuilder.createFilterByText(textFilter, fieldsFilter)
      filter_text_query = querytxt
    }

    this.setState({
      query: query,
      filter_query: filter_text_query,
      tagsFiltered: tags
    }, () => {
      this.loadDefaultOptions()
    })
  }

  okClicked = () => {
    var selectValue = this.state.selectedOption;
    if (selectValue) {
      FilterActions.filter_not_tag(selectValue.value);
      this.resetSelection();
      this.props.onHide();
    }
  }

  handleKeyPress(key) {
    if (key.charCode === 13) {
      this.okClicked();
    }
  }

  resetSelection() {
    this.setState({
      selectedOption: null
    })
  }

  getOptions = (inputValue, callback) => {
    const { query, filter_query, tagsFiltered } = this.state
    TagsAPI.prefixSearch(inputValue, query, filter_query).then(result => {
      result = this.getDiffTags(tagsFiltered, result)
      this.setState({
        options: result
      }, () => callback(result));
    })
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue });
  }

  loadDefaultOptions = () => {
    const { query, filter_query, tagsFiltered } = this.state
    TagsAPI.prefixSearch('', query, filter_query).then(defaultOptions => {
      defaultOptions = this.getDiffTags(tagsFiltered, defaultOptions)
      this.setState({ defaultOptions });
    })
  }

  render() {
    const { selectedOption, options, inputValue, defaultOptions } = this.state;
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: 'start'
    };
    return (
      <Modal
        {...this.props}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search by excluding tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onKeyPress={this.handleKeyPress}>
            <span>Select the tag you want to exclude from search:</span>
            <AsyncSelect
              options={options}
              value={selectedOption}
              onChange={this.handleChange}
              inputValue={inputValue}
              onInputChange={this.handleInputChange}
              onKeyDown={this.onInputKeyDown}
              loadOptions={this.getOptions}
              tabIndex={1}
              autoFocus={true}
              filterOption={createFilter(filterConfig)}
              loadOptions={this.getOptions}
              defaultOptions={defaultOptions}
              styles={customStyles}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.props.onHide} tabIndex={2}>Cancel</Button>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.okClicked} tabIndex={3}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default PublicSearchByNotTagFilter;
