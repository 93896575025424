import keyMirror from 'keymirror';

export default keyMirror({
  RECEIVE_CONTENTS: null,
  RECEIVE_CONTENTS_ERROR: null,
  RECEIVE_NOTAGS_CONTENTS: null,
  RECEIVE_NOTAGS_ERROR: null,
  SEND_CONTENT: null,
  SEND_CONTENT_ERROR: null,
  SEND_CONTENT_DUPLICATE: null,
  DELETE_CONTENT: null,
  DELETE_CONTENT_ERROR: null,
  RECEIVE_CONTENT: null,
  RECEIVE_CONTENT_ERROR: null,
  PUT_CONTENT: null,
  PUT_CONTENT_ERROR: null,
  PUT_CONTENT_DUPLICATE: null,
  EDIT_CONTENT: null,
  EXPORT_CONTENT: null,
  EXPORT_CONTENT_ERROR: null,
  ENABLE_TOP_EDITOR: null,
  DISABLE_TOP_EDITOR: null,
  RECEIVE_PUBLIC_CONTENTS: null,
  RECEIVE_PUBLIC_CONTENTS_BY_ACCOUNT: null,
  RECEIVE_PUBLIC_ERROR: null,
  RECEIVE_PREFIX_TAGS: null,
  RECEIVE_PREFIX_TAGS_ERROR: null,
  ADD_TO_MY_ACCOUNT_ON: null,
  ADD_TO_MY_ACCOUNT_OFF: null,
  NAVIGATE_TO_SAVE_ON: null,
  NAVIGATE_TO_SAVE_OFF: null,
  RECEIVE_SHARE_LINK: null,
  RECEIVE_SHARE_LINK_ERROR: null,
  RECEIVE_CONTENT_BY_SHARE_LINK: null,
  RECEIVE_CONTENT_BY_SHARE_LINK_ERROR: null,
  RECEIVE_FOLLOW_CONTENTS: null,
  RECEIVE_FOLLOW_CONTENTS_ERROR: null,
  RECEIVE_NETWORK: null,
  RECEIVE_NETWORK_ERROR: null,
  ADD_TO_MY_NETWORK_ERROR: null,
  REMOVE_FROM_MY_NETWORK_ERROR: null,
  RECEIVE_PERMALINK: null,
  RECEIVE_PERMALINK_ERROR: null,
  RECEIVE_CONTENT_BY_PERMALINK: null,
  RECEIVE_CONTENT_BY_PERMALINK_ERROR: null,
  RECEIVE_CONTENT_GUEST:null,
  RECEIVE_IS_PUBLIC_PERMALINK:null,
  RECEIVE_IS_PUBLIC_PERMALINK_ERROR:null
});