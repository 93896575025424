import React, { Component } from 'react'
import ContentsList from './ContentsList'
import SettingStore from '../stores/SettingStore'
import SettingActions from '../actions/SettingActions'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import * as ErrorCode from '../constants/ErrorCodesConstants'

class Contents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notags: false,
      sortBy: '',
      sortOrder: '',
      perPage: 0,
      openLinksNewTab: false
    }
    this.componentId = 'Contents'
    this.onReceiveSettings = this.onReceiveSettings.bind(this)
    this.onError = this.onError.bind(this)
  }

  UNSAFE_componentWillMount() {
    SettingStore.addReceiveListener(this.onReceiveSettings);
    ErrorStore.addErrorListener(this.onError);
  }

  componentDidMount() {
    SettingActions.receiveSettings(this.componentId);
    if(this.props.setNavigationDefault != null) this.props.setNavigationDefault()
  }

  componentWillUnmount() {
    SettingStore.removeReceiveListener(this.onReceiveSettings);
    ErrorStore.removeErrorListener(this.onError);
  }

  onReceiveSettings() {
    var sortBy = SettingStore.getSortBy()
    var sortOrder = SettingStore.getSortOrder()
    var perPage = SettingStore.getResultPerPage()
    var openLinksNewTab = SettingStore.getOpenLinksNewTab()
    this.setState({
      sortBy: sortBy,
      sortOrder: sortOrder,
      perPage: perPage,
      openLinksNewTab: openLinksNewTab
    })
  }

  onError(source, error) {
    if (source == 'app' && error.code == ErrorCode.UNAUTHORIZED) {
      HandleException.handle_error(error);
    }
  }

  render() {
    const { notags } = this.state;
    if (this.state.sortBy == '' || this.state.sortOrder == '' || this.state.perPage == 0) {
      return (
        <div></div>
      )
    } else {
      return (
        <div>
          <ContentsList sortBy={this.state.sortBy}
            sortOrder={this.state.sortOrder}
            perPage={this.state.perPage} notags={notags.toString()}
            setNotSavedEditingFunction={this.props.setNotSavedEditingFunction}
            setOnYesConfirmFunction={this.props.setOnYesConfirmFunction}
            setResetEdit={this.props.setResetEdit}
            shareDialog={this.props.shareDialog}
            permalinkDialog={this.props.permalinkDialog}
            embedDialog={this.props.embedDialog}
            openLinksNewTab={this.state.openLinksNewTab}
            notURLNotification={this.props.notURLNotification}
            pasteNotification={this.props.pasteNotification}
          />
        </div>
      )
    }
  }
}

export default Contents
