import React, { Component } from 'react'

class ContentQueryResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numFound: 0,
      queryTime: 0
    }
    this.updateValues = this.updateValues.bind(this)
  }

  updateValues(newNumFound, newQueryTime){
    this.setState({
      numFound: newNumFound,
      queryTime: newQueryTime
    })
  }

  render() {
    return (
      <div className="signal34-query-results">
        Found {this.state.numFound} results ({this.state.queryTime} seconds)
      </div>
    )
  }
}

export default ContentQueryResult
