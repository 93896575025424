import AppDispatcher from '../dispatcher/AppDispatcher';
import SettingConstants from '../constants/SettingConstants';
import SettingsAPI from '../apis/SettingsAPI';

export default {
  receiveSettings: (source) => {
    SettingsAPI
      .receiveSettings()
      .then(data => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.RECEIVE_SETTINGS,
          settings: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.RECEIVE_SETTINGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  updateSettings: (source, sort_by, sort_order, results_per_page, open_links_new_tab) => {
    SettingsAPI
      .changeSettings(sort_by, sort_order, results_per_page, open_links_new_tab)
      .then(() => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.CHANGE_SETTINGS,
          settings: { 'sort_by': sort_by, 'sort_order': sort_order, 'results_per_page': results_per_page, 'open_links_new_tab' : open_links_new_tab }
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.CHANGE_SETTINGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  showDialogSettings: () => {
    AppDispatcher.dispatch({
      actionType: SettingConstants.SHOW_DIALOG_SETTINGS
    });
  },
  receiveDarkModeSettings: (source) => {
    SettingsAPI
      .receiveDarkModeSettings()
      .then(data => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.RECEIVE_DARK_MODE_SETTINGS,
          settings: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.RECEIVE_DARK_MODE_SETTINGS_ERROR,
          message: message,
          source: source
        });
      });
  },
  updateDarkModeSettings: (source, dark_mode) => {
    SettingsAPI
      .changeDarkModeSettings(dark_mode)
      .then(data => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.CHANGE_DARK_MODE_SETTINGS,
          settings: data
        });
      })
      .catch(message => {
        AppDispatcher.dispatch({
          actionType: SettingConstants.CHANGE_DARK_MODE_SETTINGS_ERROR,
          message: message,
          source: source
        });
      });
  }
}
