import UserStore from '../stores/UserStore';
import React, { Component } from 'react';
import UserActions from '../actions/UserActions';
import '../styles/Login.css';
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import jwtDecode from 'jwt-decode'

class EmailUpdated extends Component {
  constructor(props) {
    super(props);
    this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
    this.onFeedback = this.onFeedback.bind(this);
    this.onError = this.onError.bind(this);
    this.componentId = 'updateEmail';
  }

  UNSAFE_componentWillMount() {
    UserStore.addEmailUpdatedListener(this.onConfirmSubmit);
    UserStore.addFeedbackListener(this.onFeedback);
    ErrorStore.addErrorListener(this.onError);
  }

  componentDidMount() {
    var token = this.props.params.token.split('_').join('.');
    var decoded = jwtDecode(token)
    var user_id = decoded['userid']
    var old_email = decoded['old_email']
    var new_email = decoded['new_email']
    var expire_datetime = decoded['expire_datetime']
    var expiredDate = new Date(expire_datetime)
    var currentDate = new Date()
    if(currentDate > expiredDate){
      this.props.router.push('/email_updated_error?message=Update link expired.')
    }
    else {
      this.updateEmail(user_id, old_email, new_email);
    }
  }

  componentWillUnmount() {
    UserStore.removeEmailUpdatedListener(this.onConfirmSubmit);
    UserStore.removeFeedbackListener(this.onFeedback);
    ErrorStore.removeErrorListener(this.onError);
  }

  updateEmail(user_id, old_email, new_email) {
    UserActions.updateEmail(this.componentId, user_id, old_email, new_email);
  }

  onConfirmSubmit(){
    this.props.router.push('/email_updated_success');
  }

  onFeedback() {
    var error = UserStore.getFeedback()
    this.props.router.push('/email_updated_error?message='+ error['description'])
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    return (
      <div className='Login'>
      </div>
    );
  }
}

export default EmailUpdated;
