import AppDispatcher from '../dispatcher/AppDispatcher';
import InvoiceConstants from '../constants/InvoiceConstants';
import { EventEmitter } from 'events';
import ErrorStore from '../stores/ErrorStore'

const RECEIVE_EVENT = 'receive';

let _invoice = {};

function setInvoice(invoice) {
  _invoice = invoice;
}

class InvoiceStoreClass extends EventEmitter {
  emitReceive(arg) {
    this.emit(RECEIVE_EVENT, arg);
  }

  addReceiveListener(callback) {
    this.on(RECEIVE_EVENT, callback)
  }

  removeReceiveListener(callback) {
    this.removeListener(RECEIVE_EVENT, callback)
  }

  getInvoice() {
    return _invoice;
  }
}

const InvoiceStore = new InvoiceStoreClass();

InvoiceStore.dispatchToken = AppDispatcher.register(action => {
  switch (action.actionType) {
    case InvoiceConstants.RECEIVE_INVOICE:
      setInvoice(action.invoice_data);
      InvoiceStore.emitReceive(action.actionType);
      break;

    case InvoiceConstants.RECEIVE_INVOICE_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case InvoiceConstants.EMAIL_INVOICE_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }
});

export default InvoiceStore;
