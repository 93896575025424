import request from 'superagent/lib/client'
import SessionStore from '../stores/SessionStore'
import HandleException from '../utils/HandleException';

const host = process.env.API_URL
const versionPath = process.env.API_VERSION_PATH
const tagsPath = '/tags'

export default {
  // Get a page of tags from the API.
  getTags: (page, per_page) => {
    var params = '/users/' + SessionStore.getSessionUserName() + '?page_number=' + page + '&per_page=' + per_page
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + tagsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  // Get all tags from the API.
  getAllTags: (query, query_by_text) => {
    var params = '/users/' + SessionStore.getSessionUserName().toString();
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '?query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      if (params.includes('?query=')) {
        params = params + '&'
      } else {
        params = params + '?'
      }
      params = params + 'query_by_text=' + query_by_text;
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + tagsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  // Get a content from the API.
  getTag: () => {
    var params = '?user_id=' + SessionStore.getSessionUserName();
    return new Promise((resolve, reject) => {
      request
        .get(host + versionPath + tagsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        });
    });
  },
  // Create a tag
  createTag: (content_id, tag) => {
    var body = '{"tags":"' + tag.trim() + '"}';
    var params = '?user_id=' + SessionStore.getSessionUserName().toString() + '&content_id=' + content_id
    return new Promise((resolve, reject) => {
      request
        .post(host + versionPath + tagsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(body)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  // Update a tag
  updateTag: (old_tag, new_tag) => {
    var params = '/users/' + SessionStore.getSessionUserName().toString()
    var data = {}
    data['old_tag'] = decodeURIComponent(old_tag)
    data['new_tag'] = decodeURIComponent(new_tag)
    return new Promise((resolve, reject) => {
      request
        .put(host + versionPath + tagsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(data)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text))
          }
        })
    })
  },
  // Delete content.
  deleteTag: (tag) => {
    var params = '/users/' + SessionStore.getSessionUserName().toString()
    var data = {}
    data['tag'] = decodeURIComponent(tag)
    return new Promise((resolve, reject) => {
      request
        .delete(host + versionPath + tagsPath + params)
        .withCredentials()
        .send(data)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve({ response: 'Ok' });
          }
        })
    })
  },
  // check merge tag
  checkMergeTag: (old_tag, new_tag) => {
    var params = '/users/' + SessionStore.getSessionUserName().toString()
    var data = {}
    data['old_tag'] = decodeURIComponent(old_tag)
    data['new_tag'] = decodeURIComponent(new_tag)
    return new Promise((resolve, reject) => {
      request
        .put(host + versionPath + tagsPath + params + '/check')
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(data)
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            resolve(JSON.parse(response.text));
          }
        })
    })
  },
  // Prefix search tags in contents.
  prefixSearch: (prefix, query, query_by_text, use_network_for = '', accounts = '') => {
    var params = '/search/?'
    var versionAPI = '/api/v030'
    if (!(typeof prefix === 'undefined' || prefix === '')) {
      params = params + 'prefix_tag=' + encodeURIComponent(prefix);
    }
    if (!(typeof query === 'undefined' || query === '')) {
      params = params + '&query=' + query;
    }
    if (!(typeof query_by_text === 'undefined' || query_by_text === '')) {
      params = params + '&query_by_text=' + query_by_text;
    }
    if (use_network_for !== '') {
      params = params + '&use_network_for=' + use_network_for;
    }
    if (accounts != '') {
      params = params + '&accounts=' + accounts;
    }
    return new Promise((resolve, reject) => {
      request
        .get(host + versionAPI + tagsPath + params)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .end((err, response) => {
          if (err) {
            reject(HandleException.formatting_error(err, response))
          } else {
            var tags = JSON.parse(response.text).data.tags
            if (response.body.data.not_found != undefined && response.body.data.not_found.length > 0) {
              let message = 'The accounts ' + response.body.data.not_found.join()
                + ' do not exist and they will not be showed'
              alert(message)
            }
            resolve(tags)
          }
        })
    }).catch(HandleException.handle_error)
  }
}
