import { Link } from 'react-router';
import React, { Component } from 'react';
import UserActions from '../actions/UserActions';
import UserStore from '../stores/UserStore';
import { Alert, Form } from 'react-bootstrap';
import LoaderButton from './LoaderButton';
import '../styles/Login.css';
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onFeedback = this.onFeedback.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      token: '',
      password: '',
      retype_password: '',
      validationMessage: {}
    };
    this.componentId = 'resetpassword';
  }

  UNSAFE_componentWillMount() {
    UserStore.addFeedbackListener(this.onFeedback);
    ErrorStore.addErrorListener(this.onError);
  }

  componentDidMount() {
    this.setState({ token: this.props.params.token });
  }

  componentWillUnmount() {
    UserStore.removeFeedbackListener(this.onFeedback);
    ErrorStore.removeErrorListener(this.onError);
  }

  onFeedback() {
    let feedback = UserStore.getFeedback()
    let val = this.state.validationMessage;
    val['retype_password'] = feedback['retype_password'];
    if (val['retype_password'] === '') {
      alert('Password changed.');
      this.setState({ token: '' });
      this.setState({ password: '' });
      this.setState({ retype_password: '' });
      this.props.router.push('/')
    }
    this.setState({ validationMessage: val });
  }

  handleInputChange(e) {
    e.preventDefault();
    let val = this.state.validationMessage;
    val[e.target.id] = '';
    this.setState({
      [e.target.id]: e.target.value,
      validationMessage: val
    })
  }

  validateForm() {
    if (this.state.password.length === 0) this.setMessage('password', 'new password is required.');
    if (this.state.retype_password.length === 0) this.setMessage('retype_password', 'retype password is required.');
    if (this.state.password.length > 0 && this.state.retype_password.length > 0 && this.state.password !== this.state.retype_password) this.setMessage('retype_password', 'password do not match retype again.');
    return (this.state.password.length > 0 && this.state.retype_password.length > 0 && this.state.password === this.state.retype_password)
  }

  setMessage(field, msg) {
    let val = this.state.validationMessage;
    val[field] = msg;
    this.setState({ validationMessage: val })
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      UserActions.resetPassword(this.componentId, this.state.token, this.state.password)
    }
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    return (
      <div className='Login'>
        <div className='card'>
          <div className="center-text-login"><p>Reset Password</p></div>
          <Form onSubmit={this.handleFormSubmit}>
            <Form.Group controlId='password'>
              <Form.Label htmlFor='password'>New password</Form.Label>
              <Form.Control value={this.state.password} onChange={this.handleInputChange} type='password' autoComplete='new-password'/>
              <Alert variant={'light'}>{this.state.validationMessage['password']}</Alert>
            </Form.Group>
            <Form.Group controlId='retype_password'>
              <Form.Label htmlFor='retype_password'>Retype password</Form.Label>
              <Form.Control value={this.state.retype_password} onChange={this.handleInputChange} type='password' autoComplete='new-password'/>
              <Alert variant={'light'}>{this.state.validationMessage['retype_password']}</Alert>
            </Form.Group>
            <LoaderButton block className="signal34-bnt-primary"  variant="primary" type='submit' text='Submit' loadingText='Submit…' />
            <br /><p>If you have changed the password you can <Link to={'/'}>Login</Link></p>
            <br />
          </Form>
        </div>
        <footer>
          <div className='text-center small copyright signal34-footer'>
            <div>
              <span>© 2019 Signal34.com</span> | <a target='_blank' href='./about'>About</a> | <Link to={'/'}>Home</Link> | <Link to={'/signup'}>Sign Up</Link> | <a target='_blank' href='./howto'>HowTo</a> | <a target='_blank' href='./personal_terms_of_service'>Terms of Service</a> | <a target='_blank' href='./privacy_policy'>Privacy policy</a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default ResetPassword;
