import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import(/* webpackPreload: true */ '../styles/App.css');

import {
    FacebookShareButton,
    TwitterShareButton,
    TumblrShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrIcon,
    RedditIcon,
    EmailIcon
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class PermalinkDialog extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            permalinkUrl: '',
            title: ''
        }
        this.clear = this.clear.bind(this)
        this.setPermalink = this.setPermalink.bind(this)
        this.onShow = this.onShow.bind(this)
        this.inputRef = React.createRef()
    }

    clear() {
        this.setState({
            permalinkUrl: '',
            title: ''
        })
    }

    setPermalink(title, link) {
        this.setState({
            permalinkUrl: link,
            title: title
        })
    }

    onShow() {
        setTimeout(() => { this.inputRef && this.inputRef.current && this.inputRef.current.focus() }, 1);
    }

    handleFocus = (event) => event.target.select();

    render() {
        const { permalinkUrl, title } = this.state;
        return (
            <Modal show={this.props.show} backdrop={false} autoFocus={true} onHide={this.props.onHide} onShow={this.onShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Permalink</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="share-row">
                        <div className="share-label">
                            <label>With:</label>
                        </div>
                        <div className="share-social-icons">
                            <div className="share-social-button">
                                <FacebookShareButton url={permalinkUrl} quote={title}>
                                    <svg width="29" height="29" className="share-social-icon"><path d="M23.2 5H5.8a.8.8 0 0 0-.8.8V23.2c0 .44.35.8.8.8h9.3v-7.13h-2.38V13.9h2.38v-2.38c0-2.45 1.55-3.66 3.74-3.66 1.05 0 1.95.08 2.2.11v2.57h-1.5c-1.2 0-1.48.57-1.48 1.4v1.96h2.97l-.6 2.97h-2.37l.05 7.12h5.1a.8.8 0 0 0 .79-.8V5.8a.8.8 0 0 0-.8-.79"></path></svg>
                                </FacebookShareButton>
                            </div>
                            <div className="share-social-button">
                                <TwitterShareButton url={permalinkUrl} quote={title}>
                                    <svg width="29" height="29" className="share-social-icon"><path d="M22.05 7.54a4.47 4.47 0 0 0-3.3-1.46 4.53 4.53 0 0 0-4.53 4.53c0 .35.04.7.08 1.05A12.9 12.9 0 0 1 5 6.89a5.1 5.1 0 0 0-.65 2.26c.03 1.6.83 2.99 2.02 3.79a4.3 4.3 0 0 1-2.02-.57v.08a4.55 4.55 0 0 0 3.63 4.44c-.4.08-.8.13-1.21.16l-.81-.08a4.54 4.54 0 0 0 4.2 3.15 9.56 9.56 0 0 1-5.66 1.94l-1.05-.08c2 1.27 4.38 2.02 6.94 2.02 8.3 0 12.86-6.9 12.84-12.85.02-.24 0-.43 0-.65a8.68 8.68 0 0 0 2.26-2.34c-.82.38-1.7.62-2.6.72a4.37 4.37 0 0 0 1.95-2.51c-.84.53-1.81.9-2.83 1.13z"></path></svg>
                                </TwitterShareButton>
                            </div>
                            <div className="share-social-button">
                                <TumblrShareButton url={permalinkUrl} quote={title}><TumblrIcon size={29} bgStyle={{ fill: 'gray' }} /> </TumblrShareButton>
                            </div>
                            <div className="share-social-button">
                                <EmailShareButton url={permalinkUrl} quote={title}><EmailIcon size={29} bgStyle={{ fill: 'gray' }} /></EmailShareButton>
                            </div>
                            <div className="share-social-button">
                                <RedditShareButton url={permalinkUrl} quote={title}><RedditIcon size={30} bgStyle={{ fill: 'gray' }} /></RedditShareButton>
                            </div>
                        </div>
                    </div>
                    <div className="share-row">
                        <div className="share-label">
                            <label>Link:</label>
                        </div>
                        <div className="share-input">
                            <input type="text" className="form-control" value={permalinkUrl} autoFocus={true} tabIndex={1} readOnly onFocus={this.handleFocus} ref={this.inputRef} />
                        </div>
                        <div className="share-button">
                            <CopyToClipboard text={permalinkUrl}>
                                <button type="button" className="signal34-bnt-default btn btn-outline-secondary" onClick={this.props.onCopyLink}>Copy</button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="share-row"></div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default PermalinkDialog;