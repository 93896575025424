import Common from '../utils/Common';
export const FILTER_BY_TEXT = 1001;
export const FILTER_BY_TYPE = 1002;
export const FILTER_BY_DATE = 1003;

export const SORT_BY_CONTENT_TITLE = 'Content title';
export const SORT_BY_CONTENT_CREATION_DATE = 'Content creation date';
export const SORT_BY_CONTENT_RATING = 'Content rating';
export const SORT_BY_MATCH_SCORE = 'Match score';
export const SORT_ORDER_BEST_FIRST ='Best first';
class FiltersBuilderClass {
  createFilterByDate(query, dateFilter) {
    var dateQuery = dateFilter
    if (query !== null && query !== '') {
      return ' AND ' + dateQuery;
    }
    return dateQuery;
  }

  createFilterByText(textFilter, fieldsFilter) {
    var dataquery = {
      fields: '',
      query: ''
    };
    var fieldsMap = {
      'Url': 'Content.content_data',
      'Title': 'Content.content_title',
      'Notes': 'LocalProperty.local_property_notes'
    }
    for (var key in fieldsFilter) {
      if (fieldsFilter[key] == true) {
        dataquery.fields += fieldsMap[key] + ' ';
      }
    }
    dataquery.fields = dataquery.fields.trim();
    dataquery.query = Common.doubleEncodeSolrQuery(textFilter);
    var textquery = JSON.stringify(dataquery);
    return textquery;
  }

  createFilterByTags(options) {
    var atags = [];
    var anotags = [];

    options.map(function (item) {
      if (item.label.includes('not:')) {
        anotags.push(item.value)
      } else {
        atags.push(item.value)
      }
    })

    var tags = atags.map(function (item) {
      return '\"' + Common.encodeSolrQuery(item) + '\"';
    }).join(encodeURIComponent(' && '))

    var notags = anotags.map(function (item) {
      return '\"' + Common.encodeSolrQuery(item) + '\"';
    }).join(encodeURIComponent(','))

    if (tags && tags.length > 0) {
      tags = 'tags_str:(' + tags + ')'
      tags += (notags && notags.length > 0) ? ' AND -tags_str:(' + notags + ')' : ''
    } else {
      tags = (notags && notags.length > 0) ? '-tags_str:(' + notags + ')' : ''
    }

    return tags
  }

  createFilterByType(query, type) {
    var typeQuery = 'Content.content_type:' + type
    if (query !== null && query !== '') {
      return ' AND ' + typeQuery
    }
    return typeQuery
  }

  createSortQuery(sortBy, sortOrder) {
    var sortString = ''
    if (sortBy === SORT_BY_CONTENT_TITLE) {
      if (sortOrder === 'A > Z') {
        sortString = 'Content.content_title_sortable asc'
      } else if (sortOrder === 'Z > A') {
        sortString = 'Content.content_title_sortable desc'
      }
    } else if (sortBy === SORT_BY_CONTENT_CREATION_DATE) {
      if (sortOrder === 'Newest on top') {
        sortString = 'created_at desc'
      } else if (sortOrder === 'Oldest on top') {
        sortString = 'created_at asc'
      }
    } else if (sortBy === SORT_BY_CONTENT_RATING) {
      if (sortOrder === 'High > Low') {
        sortString = 'LocalProperty.local_property_score desc'
      } else if (sortOrder === 'Low < High') {
        sortString = 'LocalProperty.local_property_score asc'
      }
    } else if (sortBy === SORT_BY_MATCH_SCORE) {
      sortString = 'score desc'
    }
    return sortString;
  }
  /* build label for filters (text, type, date) */
  buildLabelFilter(filterType, value, callBackFunction, maxLen = Common.MAXLEN_TAG_LABEL) {
    if (value !== '') {
      switch (filterType) {
        case 'Text':
          var tooltip = value;
          if (value.length > maxLen) {
            tooltip = value;
            value = value.substring(0, maxLen) + '...';
          }
          callBackFunction && callBackFunction(FILTER_BY_TEXT, value, tooltip);
          break;
        case 'Date':
          var afterRegEx = /created_at:\[\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)\sTO\s\*\]/
          var beforeREgEx = /created_at:\[\*\sTO\s\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)\]/
          if (afterRegEx.test(value) === true) {
            callBackFunction && callBackFunction(FILTER_BY_DATE, 'date-after: ' + value.match(/\d{4}-[01]\d-[0-3]\d/gm));
          } else if (beforeREgEx.test(value) === true) {
            callBackFunction && callBackFunction(FILTER_BY_DATE, 'date-before: ' + value.match(/\d{4}-[01]\d-[0-3]\d/gm));
          } else {
            callBackFunction && callBackFunction(FILTER_BY_DATE, 'date-cannot-parse:: ' + value);
          }
          break
      }
    }
  }
}
const FiltersBuilder = new FiltersBuilderClass()
export default FiltersBuilder