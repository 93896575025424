import AppDispatcher from '../dispatcher/AppDispatcher'
import LoginConstants from '../constants/LoginConstants'
import { EventEmitter } from 'events'
import ErrorStore from '../stores/ErrorStore'

const CLICKED_EVENT = 'clicked'
const INITIALIZED_EVENT = 'initialized';

let _loaded = false

function setLoaded(newLoaded) {
  _loaded = newLoaded
}

class LoginStoreClass extends EventEmitter {

  emitGoogleClicked() {
    this.emit(CLICKED_EVENT)
  }

  emitLoaded() {
    this.emit(INITIALIZED_EVENT);
  }

  addClickedListener(callback) {
    this.on(CLICKED_EVENT, callback)
  }

  addLoadedListener(callback) {
    this.on(INITIALIZED_EVENT, callback)
  }

  removeClickedListener(callback) {
    this.removeListener(CLICKED_EVENT, callback)
  }

  removeLoadedListener(callback) {
    this.removeListener(INITIALIZED_EVENT, callback)
  }

  getLoaded() {
    return _loaded;
  }
}

const LoginStore = new LoginStoreClass();

LoginStore.dispatchToken = AppDispatcher.register(action => {

  switch (action.actionType) {
    case LoginConstants.GBUTTON_CLICKED:
      LoginStore.emitGoogleClicked()
      break;

    case LoginConstants.GBUTTON_CLICKED_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case LoginConstants.GAPI_INITIALIZED:
      setLoaded(true);
      LoginStore.emitLoaded()
      break;

    case LoginConstants.GAPI_INITIALIZED_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    default:
  }
});

export default LoginStore;
