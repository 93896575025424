import React, { Component } from 'react'
import ContentActions from '../actions/ContentActions'
import ContentStore from '../stores/ContentStore'
import PublicContentItem from './PublicContentItem'
import { Container, Row, Col } from 'react-bootstrap'
import(/* webpackPreload: true */ '../styles/Contents.css');
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'

function getContentItem(
  content,
  index,
  handler,
  editHandler,
  editingFlag,
  cancelHandler,
  saveHandler,
  resetPageHandler,
  addToMyAccountHandler,
  populateAndShowMethod,
  callLogInDialog,
  navigateRequestHandler,
  saveForNavigateHandler,
  shareContentHandler) {
  return (
    <PublicContentItem
      key={content.id}
      content={content}
      callLogInDialog={callLogInDialog}
      addtomyaccounthandler={addToMyAccountHandler}
      populateAndShowMethod={populateAndShowMethod}
      handler={handler}
      index={index}
      edithandler={editHandler}
      editingflag={editingFlag}
      cancel={cancelHandler}
      save={saveHandler}
      resetPage={resetPageHandler}
      navigateRequest={navigateRequestHandler}
      shareContent={shareContentHandler}
      ref={'content_' + index}
      disabled={true}
      saveForNavigate={saveForNavigateHandler}
      hideOptionsLine={true}
      hideMenuDropDown={true}
      network={[]}
    />
  )
}

function getContent(content) {
  if (content.length == 0) {
    return (<Row><Col>The content does not exist</Col></Row>)
  } else { return content }
}

class EmbedContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contents: [],
      started: true,
      invalidToken: false,
      countAlert: 0
    }
    this.onShareContentChange = this.onShareContentChange.bind(this)
    this.onError = this.onError.bind(this)
    this.componentId = 'embedcontent'
  }

  UNSAFE_componentWillMount() {
    ContentStore.addShareContentChangeListener(this.onShareContentChange)
    ErrorStore.addErrorListener(this.onError)
    if (this.props && this.props.token) {
      ContentActions.getContentByShareLink(this.componentId, this.props.token, false)
    }
  }

  componentWillUnmount() {
    ContentStore.removeShareContentChangeListener(this.onShareContentChange)
    ErrorStore.removeErrorListener(this.onError)
  }

  onShareContentChange() {
    var _content = ContentStore.getShareContent()
    var _contents = [];
    _contents.push(_content.content)
    this.setState({
      started: false,
      contents: _contents
    })
  }

  onError(source, error) {
    if (error.description == 'Invalid share link') {
      this.setState({
        invalidToken: true
      })
    } else {
      if (source == this.componentId && error.code == 0) {
        this.setState({ countAlert: this.state.countAlert + 1 }, () => {
          if (this.state.countAlert == 1) {
            HandleException.handle_error(error);
          } else if (this.state.countAlert == 3) {
            this.setState({ countAlert: 0 });
          }
        })
      } else if (source == this.componentId) {
        HandleException.handle_error(error);
      }
    }
  }

  render() {
    if (this.state.invalidToken) {
      return (
        <div className='signal34-contents-embed'>
          <Container>
            <div className="signal34-content row">Invalid token</div>
          </Container>
        </div>
      )
    } else if (this.state.started) {
      return (
        <div></div>
      )
    } else {
      let contentListItems = this.state.contents.map((content, index) => getContentItem(content, index)
      )
      return (
        <div className='signal34-contents-embed'>
          <Container>
            {getContent(contentListItems)}
          </Container>
        </div>
      )
    }
  }
}
export default EmbedContent