import keyMirror from 'keymirror';

export default keyMirror({
  FILTER_TAG: null,
  FILTER_CLEAR: null,
  FILTER_CLEAR_SEARCH_BAR: null,
  FILTER_ADD_NOT_TAG: null,
  FILTER_TEXT: null,
  FILTER_DATE: null,
  FILTER_TYPE: null,
  FILTER_SETUP: null,
  FILTER_REFRESH: null,
  FILTER_SETTINGS_SEARCH_BAR: null,
  FILTER_RELOAD_DEFAULTS: null
});
