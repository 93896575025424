import AppDispatcher from '../dispatcher/AppDispatcher'
import SessionConstants from '../constants/SessionConstants'
import { EventEmitter } from 'events'
import ErrorStore from '../stores/ErrorStore'
import Cookies from 'universal-cookie'

const LOGIN_EVENT = 'login';
const LOGOUT_EVENT = 'logout';
const AUTHENTICATE_EVENT = 'authenticate'
let _userData = null;
const cookies = new Cookies();

function setUserData(userData) {
  _userData = userData;
}

class SessionStoreClass extends EventEmitter {

  emitLogin() {
    this.emit(LOGIN_EVENT)
  }

  addLoginListener(callback) {
    this.on(LOGIN_EVENT, callback)
  }

  removeLoginListener(callback) {
    this.removeListener(LOGIN_EVENT, callback)
  }

  emitLogout() {
    this.emit(LOGOUT_EVENT)
  }

  addLogoutListener(callback) {
    this.on(LOGOUT_EVENT, callback)
  }

  removeLogoutListener(callback) {
    this.removeListener(LOGOUT_EVENT, callback)
  }

  emitAuthenticate() {
    this.emit(AUTHENTICATE_EVENT)
  }

  addAuthenticateListener(callback) {
    this.on(AUTHENTICATE_EVENT, callback)
  }

  removeAuthenticateListener(callback) {
    this.removeListener(AUTHENTICATE_EVENT, callback)
  }

  syncLocalStorage() {
    var user_data = localStorage.getItem('user_data');
    if (!user_data || user_data === 'undefined') {
      var user_cookie = cookies.get('user_data');
      if (user_cookie !== undefined) {
        localStorage.setItem('user_data', JSON.stringify(user_cookie));
        return true;
      }
      return false;
    }
    return true;
  }

  getSessionUserName() {
    var user_name = SessionStore.getUserDataItem('user_name');
    if (!user_name) {
      SessionStore.cleanData();
    }
    return user_name;
  }

  getSessionAccountName() {
    var account_name = SessionStore.getUserDataItem('account_name');
    if (account_name == null) {
      SessionStore.cleanData();
    }
    return account_name;
  }

  getSessionUserEmail() {
    var email = SessionStore.getUserDataItem('email');
    if (email == null) {
      SessionStore.cleanData();
    }
    return email;
  }

  getLogged() {
    var logged = this.syncLocalStorage();
    if (!logged) {
      SessionStore.cleanData(false);
      return false;
    }
    return true;
  }

  getSessionProvider() {
    var provider = SessionStore.getUserDataItem('provider');
    if (provider == null) {
      SessionStore.cleanData();
    }
    return provider;
  }

  getSessionUserCreatedAt() {
    var created_at = SessionStore.getUserDataItem('created_at');
    if (created_at == null) {
      SessionStore.cleanData();
    }
    return created_at;
  }

  getUserDataItem(key) {
    var user_data = localStorage.getItem('user_data');
    if (user_data && user_data !== 'undefined') {
      var data_item = JSON.parse(user_data);
      return data_item[key];
    }
    return null;
  }

  cleanData(redirect = true) {
    cookies.remove('jwt', { path: '/' });
    cookies.remove('user_data', { path: '/' });
    localStorage.removeItem('user_data');
    localStorage.removeItem('dark_mode');
    if (redirect) {
      window.location.href = '/';
    }
  }

  isSessionRemoved() {
    var user_data = window.localStorage.getItem('user_data')
    return user_data == null
  }

  setUserSession(user_name, email, provider, created_at, account_name) {
    var user_data = { user_name: user_name, email: email, provider: provider, created_at: created_at, account_name: account_name };
    localStorage.setItem('user_data', JSON.stringify(user_data));
    cookies.set('user_data', JSON.stringify(user_data), {
      path: '/',
      maxAge: 31557600,
      secure: true,
      sameSite: 'lax'
    });
  }

  setUserName(user_name) {
    var email = SessionStore.getUserDataItem('email');
    var provider = SessionStore.getUserDataItem('provider');
    var created_at = SessionStore.getUserDataItem('created_at');
    var account_name = SessionStore.getUserDataItem('account_name');
    this.setUserSession(user_name, email, provider, created_at, account_name);
  }

  setAccountName(account_name) {
    var user_name = SessionStore.getUserDataItem('user_name');
    var email = SessionStore.getUserDataItem('email');
    var provider = SessionStore.getUserDataItem('provider');
    var created_at = SessionStore.getUserDataItem('created_at');
    this.setUserSession(user_name, email, provider, created_at, account_name);
  }

  setDarkMode(dark_mode) {
    localStorage.setItem('dark_mode', dark_mode);
  }

  getDarkMode() {
    return localStorage.getItem('dark_mode') === 'true';
  }

  getAuthData() {
    return _userData;
  }
}

const SessionStore = new SessionStoreClass();

SessionStore.dispatchToken = AppDispatcher.register(action => {
  switch (action.actionType) {
    case SessionConstants.CREATE_SESSION:
      var session = action.session;
      SessionStore.setUserSession(session['user_name'], session['email'], session['provider'], session['created_at'], session['account_name']);
      SessionStore.emitLogin();
      break;

    case SessionConstants.CREATE_SESSION_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SessionConstants.DESTROY_SESSION:
    case SessionConstants.DESTROY_SESSION_ERROR:
      SessionStore.emitLogout();
      break;

    case SessionConstants.AUTHENTICATE_SESSION:
      setUserData(action.auth_data)
      SessionStore.emitAuthenticate();
      break;

    case SessionConstants.AUTHENTICATE_SESSION_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;

    case SessionConstants.CREATE_SOCIAL_SESSION:
      var session = action.session;
      SessionStore.setUserSession(session['user_name'], session['email'], session['provider'], session['created_at'], session['account_name']);
      SessionStore.emitLogin();
      break;

    case SessionConstants.CREATE_SOCIAL_SESSION_ERROR:
      ErrorStore.emitError(action.source, action.message);
      break;
    default:
  }
});

export default SessionStore
