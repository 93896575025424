import React, { Component } from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import Loadable from 'react-loadable';
import App from './components/App';
import Login from './containers/Login';

const Signup = Loadable({
  loader: () => import(/* webpackChunkName: "Signup" */ './containers/Signup'),
  loading: () => <div>Loading ... </div>
})

const SignupBusiness = Loadable({
  loader: () => import(/* webpackChunkName: "SignupBusiness" */ './containers/SignupBusiness'),
  loading: () => <div>Loading ... </div>
})

const ForgotPassword = Loadable({
  loader: () => import(/* webpackChunkName: "ForgotPassword" */ './containers/ForgotPassword'),
  loading: () => <div>Loading ... </div>
})

const Contents = Loadable({
  loader: () => import(/* webpackChunkName: "Contents" */ './components/Contents'),
  loading: () => <div>Loading ... </div>
})

const ContentAdd = Loadable({
  loader: () => import(/* webpackChunkName: "ContentAdd" */ './components/ContentAdd'),
  loading: () => <div>Loading ... </div>
})

const ContentEdit = Loadable({
  loader: () => import(/* webpackChunkName: "ContentEdit" */ './components/ContentEdit'),
  loading: () => <div>Loading ... </div>
})

const ContentsNoTags = Loadable({
  loader: () => import(/* webpackChunkName: "ContentsNoTags" */ './components/ContentsNoTags'),
  loading: () => <div>Loading ... </div>
})

const ResetPassword = Loadable({
  loader: () => import(/* webpackChunkName: "ResetPassword" */ './containers/ResetPassword'),
  loading: () => <div>Loading ... </div>
})

const ConfirmEmail = Loadable({
  loader: () => import(/* webpackChunkName: "ConfirmEmail" */ './containers/ConfirmEmail'),
  loading: () => <div>Loading ... </div>
})

const ConfirmSuccess = Loadable({
  loader: () => import(/* webpackChunkName: "ConfirmSuccess" */ './containers/ConfirmSuccess'),
  loading: () => <div>Loading ... </div>
})

const ConfirmError = Loadable({
  loader: () => import(/* webpackChunkName: "ConfirmError" */ './containers/ConfirmError'),
  loading: () => <div>Loading ... </div>
})

const EmailUpdated = Loadable({
  loader: () => import(/* webpackChunkName: "EmailUpdated" */ './containers/EmailUpdated'),
  loading: () => <div>Loading ... </div>
})

const EmailUpdatedSuccess = Loadable({
  loader: () => import(/* webpackChunkName: "EmailUpdatedSuccess" */ './containers/EmailUpdatedSuccess'),
  loading: () => <div>Loading ... </div>
})

const EmailUpdatedError = Loadable({
  loader: () => import(/* webpackChunkName: "EmailUpdatedError" */ './containers/EmailUpdatedError'),
  loading: () => <div>Loading ... </div>
})

const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ './components/NotFound'),
  loading: () => <div>Loading ... </div>
})

const Tags = Loadable({
  loader: () => import(/* webpackChunkName: "Tags" */ './components/Tags'),
  loading: () => <div>Loading ... </div>
})

const ChangePassword = Loadable({
  loader: () => import(/* webpackChunkName: "ChangePassword" */ './components/ChangePassword'),
  loading: () => <div>Loading ... </div>
})

const Search = Loadable({
  loader: () => import(/* webpackChunkName: "Search" */ './components/Search'),
  loading: () => <div>Loading ... </div>
})

const Help = Loadable({
  loader: () => import(/* webpackChunkName: "Help" */ './components/Help'),
  loading: () => <div>Loading ... </div>
})

const Subscription = Loadable({
  loader: () => import(/* webpackChunkName: "Subscription" */ './components/Subscription'),
  loading: () => <div>Loading ... </div>
})

const PublicContents = Loadable({
  loader: () => import(/* webpackChunkName: "PublicContents" */ './components/PublicContents'),
  loading: () => <div>Loading ... </div>
})

const ShareContent = Loadable({
  loader: () => import(/* webpackChunkName: "ShareContent" */ './components/ShareContent'),
  loading: () => <div>Loading ... </div>
})

const FollowContents = Loadable({
  loader: () => import(/* webpackChunkName: "FollowContents" */ './components/FollowContents'),
  loading: () => <div>Loading ... </div>
})

class Root extends Component {
  render() {
    return (
      <Router history={this.props.history}>
        <Route path='/signin' component={Login} history={this.props.history} />
        <Route path='/Help' component={Help} />
        <Route path='/signup' component={Signup} history={this.props.history} />
        <Route path='/signup_business' component={SignupBusiness} history={this.props.history} />
        <Route path='/forgot_password' component={ForgotPassword} />
        <Route path='/reset_password/:token' component={ResetPassword} />
        <Route path='/confirm_email/:token' component={ConfirmEmail} />
        <Route path='/confirm_success' component={ConfirmSuccess} />
        <Route path='/confirm_error' component={ConfirmError} />
        <Route path='/email_updated/:token' component={EmailUpdated} />
        <Route path='/email_updated_success' component={EmailUpdatedSuccess} />
        <Route path='/email_updated_error' component={EmailUpdatedError} />
        <Route exact path='/contents' component={App} history={this.props.history}>
          <IndexRoute component={Contents} history={this.props.history} />
          <Route exact path='/' component={PublicContents} history={this.props.history} />
          <Route exact path='/explore' component={PublicContents} history={this.props.history} />
          <Route exact path='/contents/:account' component={PublicContents} history={this.props.history} />
          <Route exact path='/network' component={FollowContents} history={this.props.history} />
          <Route exact path='/search' component={Search} />
          <Route exact path='/add_content' component={ContentAdd} history={this.props.history} />
          <Route exact path='/edit_content' component={ContentEdit} history={this.props.history} />
          <Route exact path='/untagged' component={ContentsNoTags} history={this.props.history} />
          <Route exact path='/tags' component={Tags} history={this.props.history} />
          <Route exact path='/subscription' component={Subscription} history={this.props.history} />
          <Route exact path='/change_password' component={ChangePassword} history={this.props.history} />
          <Route exact path='/:token' component={ShareContent} history={this.props.history} />
        </Route>
        <Route path='*' component={NotFound} />
      </Router>
    );
  }
}

export default Root;
