import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import SettingStore from '../stores/SettingStore'
import SessionStore from '../stores/SessionStore'
import SettingActions from '../actions/SettingActions'
import SettingOfflineStore from '../stores/SettingOfflineStore'
import PublicSettingActions from '../actions/SettingOfflineActions'
import HandleException from '../utils/HandleException'
import ErrorStore from '../stores/ErrorStore'
import Checkbox from './Checkbox'
import { SORT_BY_CONTENT_TITLE, SORT_BY_CONTENT_CREATION_DATE, SORT_BY_CONTENT_RATING, SORT_BY_MATCH_SCORE, SORT_ORDER_BEST_FIRST } from '../utils/FiltersBuilder'

const defaultBackgroundColor = 'var(--primaryBackgroundColor)'
const defaultTextColor = 'var(--primaryTextColor)'
const defaultBarSelectColor = 'var(--signal34SelectBarColor)'
const defaultMenuListColor = 'var(--signal34MenuListColor)'
const defaultSelectTextColor = 'var(--signal34SelectTextColor)'
const defaultSelectBackgroundColor = 'var(--signal34SelectBackgroundColor)'
const transparentBackgroundColor = 'transparent'
const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'var(--primaryBackgroundColor)' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? defaultSelectBackgroundColor
          : isFocused
            ? defaultBarSelectColor
            : null,
      color: isDisabled
        ? null
        : isSelected
          ? defaultSelectTextColor
          : isFocused
            ? defaultTextColor
            : null
    }
  },
  input: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: transparentBackgroundColor
    }
  },
  menuList: (styles) => {
    return {
      ...styles,
      color: defaultTextColor,
      backgroundColor: defaultMenuListColor
    }
  },
  singleValue: (styles) => {
    return {
      ...styles,
      backgroundColor: defaultBackgroundColor,
      borderColor: defaultBackgroundColor,
      color: defaultTextColor
    };
  }
}

class SearchSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sortByOptions: [
        { value: SORT_BY_CONTENT_TITLE, label: SORT_BY_CONTENT_TITLE },
        { value: SORT_BY_CONTENT_CREATION_DATE, label: SORT_BY_CONTENT_CREATION_DATE },
        { value: SORT_BY_CONTENT_RATING, label: SORT_BY_CONTENT_RATING },
        { value: SORT_BY_MATCH_SCORE, label: SORT_BY_MATCH_SCORE }
      ],
      sortByTitleOptions: [{ value: 'A > Z', label: 'A > Z' }, { value: 'Z > A', label: 'Z > A' }],
      sortByCreationOptions: [{ value: 'Newest on top', label: 'Newest on top' }, { value: 'Oldest on top', label: 'Oldest on top' }],
      sortByRating: [{ value: 'High > Low', label: 'High > Low' }, { value: 'Low < High', label: 'Low < High' }],
      sortByMatchScore: [{ value: SORT_ORDER_BEST_FIRST, label: SORT_ORDER_BEST_FIRST }],
      perPageOptions: [{ value: 10, label: '10' }, { value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }],
      sortBy: SORT_BY_CONTENT_CREATION_DATE,
      sortByOption: 'Newest on top',
      recordsByPage: 10,
      options: [{ value: 'Newest on top', label: 'Newest on top' }, { value: 'Oldest on top', label: 'Oldest on top' }],
      openLinksNewTab: false
    }
    this.onReceiveSettings = this.onReceiveSettings.bind(this)
    this.onReceiveSettingsOffline = this.onReceiveSettingsOffline.bind(this)
    this.baseState = this.state
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.onError = this.onError.bind(this)
    this.setStateOptions = this.setStateOptions.bind(this)
    this.componentId = 'searchsettings'
  }

  UNSAFE_componentWillMount() {
    SettingStore.addReceiveListener(this.onReceiveSettings)
    SettingOfflineStore.addReceiveListener(this.onReceiveSettingsOffline)
    ErrorStore.addErrorListener(this.onError)
  }

  componentWillUnmount() {
    SettingStore.removeReceiveListener(this.onReceiveSettings)
    SettingOfflineStore.removeReceiveListener(this.onReceiveSettingsOffline)
    ErrorStore.removeErrorListener(this.onError)
  }

  setStateOptions(sortBy, sortOrder, perPage, openLinksNewTab) {
    const { sortByTitleOptions, sortByCreationOptions, sortByRating, sortByMatchScore } = this.state
    var options = sortByTitleOptions
    if (sortBy == SORT_BY_CONTENT_CREATION_DATE) {
      options = sortByCreationOptions
    }
    else if (sortBy == SORT_BY_CONTENT_RATING) {
      options = sortByRating
    }
    else if (sortBy == SORT_BY_MATCH_SCORE) {
      options = sortByMatchScore
    }
    this.setState({
      sortBy: sortBy,
      sortByOption: sortOrder,
      recordsByPage: perPage,
      options: options,
      openLinksNewTab: openLinksNewTab
    }, () => { this.baseState = this.state })
  }

  onReceiveSettings() {
    this.setStateOptions(
      SettingStore.getSortBy(),
      SettingStore.getSortOrder(),
      SettingStore.getResultPerPage(),
      SettingStore.getOpenLinksNewTab()
    )
  }

  onReceiveSettingsOffline() {
    this.setStateOptions(
      SettingOfflineStore.getSortBy(),
      SettingOfflineStore.getSortOrder(),
      SettingOfflineStore.getResultPerPage(),
      SettingOfflineStore.getOpenLinksNewTab())
  }

  onSortByChange = (sortByChoosed) => {
    const { sortByTitleOptions, sortByCreationOptions, sortByRating, sortByMatchScore } = this.state
    var sortBy = sortByChoosed['value']
    var options = sortByTitleOptions
    if (sortBy == SORT_BY_CONTENT_CREATION_DATE) {
      options = sortByCreationOptions
    }
    else if (sortBy == SORT_BY_CONTENT_RATING) {
      options = sortByRating
    }
    else if (sortBy == SORT_BY_MATCH_SCORE) {
      options = sortByMatchScore
    }
    var sortByOption = options[0].value
    this.setState({
      sortBy: sortBy,
      options: options,
      sortByOption: sortByOption
    })
  }

  onSortOrderChange = (sortOrderChoosed) => {
    var theValue = sortOrderChoosed['value']
    this.setState({ sortByOption: theValue })
  }

  onPerPageChange = (perPageChoosed) => {
    var theValue = perPageChoosed['value']
    this.setState({ recordsByPage: theValue })
  }

  onOpenLinksNewTab = () => {
    var checkedVal = !this.state.openLinksNewTab
    this.setState({ openLinksNewTab: checkedVal })
  }

  saveClicked = () => {
    const { sortBy, sortByOption, recordsByPage, openLinksNewTab } = this.state
    if ((window.location.pathname == '/' ||
      window.location.pathname == '/content' ||
      window.location.pathname == '/network')
      && !SessionStore.getLogged()) {
      PublicSettingActions.updateSettings(sortBy, sortByOption, recordsByPage, openLinksNewTab)
    } else {
      SettingActions.updateSettings(this.componentId, sortBy, sortByOption, recordsByPage, openLinksNewTab)
    }
    this.props.onHide()
  }

  closeClicked = () => {
    this.setState(this.baseState, () => { this.props.onHide() })

  }

  handleKeyPress(key) {
    if (key.charCode === 13) {
      this.saveClicked();
    }
  }

  handleShow() {
    this.props.onShow && this.props.onShow()
  }

  onError(source, error) {
    if (source == this.componentId) {
      HandleException.handle_error(error);
    }
  }

  render() {
    const { sortByOptions, options, perPageOptions, sortBy, sortByOption, recordsByPage, openLinksNewTab } = this.state;
    return (
      <Modal
        {...this.props}
        onShow={this.handleShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="search-settings-modal-body">
            <div className="search-settings-dialog" onKeyPress={this.handleKeyPress}>
              <div>
                <label className="settings-label">Sort by:</label>
                <Select className="sort-by"
                  onChange={this.onSortByChange}
                  options={sortByOptions}
                  value={sortByOptions.find(option => option.value === sortBy)}
                  autoFocus={true}
                  tabIndex={1}
                  styles={customStyles}
                />
              </div>
              <br />
              <div>
                <label className="settings-label">Sort order:</label>
                <Select className="sort-order"
                  onChange={this.onSortOrderChange}
                  options={options}
                  value={options.find(option => option.value === sortByOption)}
                  tabIndex={2}
                  styles={customStyles}
                />
              </div>
              <br />
            </div>
            <div className="search-settings-dialog">
              <div>
                <label className="settings-label">Display:</label>
                <Select className="per-page"
                  onChange={this.onPerPageChange}
                  options={perPageOptions}
                  value={perPageOptions.find(option => option.value === recordsByPage)}
                  tabIndex={3}
                  styles={customStyles}
                />
                <label className="settings-label">results per page.</label>
              </div>
              <br />
            </div>
            <div className="search-settings-dialog">
              <Checkbox
                label={'Open links in new tab:'}
                isSelected={openLinksNewTab}
                onCheckboxChange={this.onOpenLinksNewTab}
                key={'opennewtab'}
                direction={'left'}
                className={'settings-label'}
                classNameCheckbox={'settings-checkbox'}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.closeClicked} tabIndex={4}>Close</Button>
          <Button className="signal34-bnt-default" variant="outline-secondary" onClick={this.saveClicked} tabIndex={5}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default SearchSettings;
